import React from 'react';
import { useGraphMediaBreakpoints } from '../../utils';
import { NarrowView } from './NarrowView';
import { WideView } from './WideView';

function getChartHeightFromBreakpoints(breakpoints: any) {
	const { small, medium, large } = breakpoints;
	if (large) return 28;
	if (medium && !large) return 28;
	if (small && !medium && !large) return 28;
	return 28;
}

export const KPICharts = ({ filters }: any) => {
	const { small, medium, large } = useGraphMediaBreakpoints();

	const chartHeight = getChartHeightFromBreakpoints({
		large,
		medium,
		small,
	});

	if (large) {
		return <WideView filters={filters} chartHeight={chartHeight} />;
	} else {
		return <NarrowView filters={filters} chartHeight={chartHeight} />;
	}
};
