import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IonIcon } from '@ionic/react';

const MainContent = styled('div')(({ theme }) => ({
	color: theme.palette.OP_Grey[50],
	textAlign: 'center',
	margin: 'auto',
}));

const IconDiv = styled('div')(({ theme }) => ({
	paddingRight: '5px',
}));

const Icon = styled(IonIcon)(({ theme }) => ({
	height: '20px',
	width: '20px',
}));

const DescriptionContent = styled('div')(({ theme }) => ({
	paddingBottom: '30px',
}));

const Header = styled(Typography)(({ theme }) => ({
	fontFamily: 'Industry-Demi',
	FontWeight: 600,
	lineHeight: '23px',
	padding: 0,
	marginBottom: '20px',
	fontSize: '28px',
}));
const Text = styled('div')(({ theme }) => ({
	paddingTop: '2px',
}));

const Description = styled(Typography)(({ theme }) => ({
	color: theme.palette.OP_Grey[90],
	textAlign: 'center',
}));

const ButtonBase = styled(Button)(({ theme }) => ({
	padding: '14px 32px',
	maxHeight: 52,
	borderRadius: 100,
	margin: theme.spacing(2),
	marginTop: 0,
	marginBottom: 0,
	boxSizing: 'border-box',
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	':hover': {
		backgroundColor: theme.palette.primary.dark,
	},
	':disabled': {
		backgroundColor: theme.palette.OP_Grey[30],
		p: {
			color: theme.palette.OP_Grey[20],
		},
	},
}));

const ButtonText = styled('div')(({ theme }) => ({
	fontSize: '13px',
	paddingTop: '2px',
	display: 'flex',
	lineHeight: '16px',
	color: 'white',
	margin: 'auto',
}));

type RetryReportProps = {
	retryTrigger: any
};

export const RetryReport = ({retryTrigger}: RetryReportProps) => {
	return (
		<MainContent>
			<Header>Not available</Header>
			<DescriptionContent>
				<Description variant="body1">
					Could not load disinfection data at the moment.
				</Description>
				<Description variant="body1">Try again.</Description>
			</DescriptionContent>

			<StyledButton type="submit" onClick={retryTrigger}>
				<ButtonText>
					<IconDiv>
						<svg
							width="20"
							height="20"
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M7.91643 6.92163C9.27313 6.0151 10.8682 5.53125 12.4999 5.53125C13.5483 5.53125 14.2843 5.56665 14.7987 5.62371C15.2742 5.67646 15.6703 5.75673 15.9415 5.92093C16.0877 6.00945 16.321 6.19839 16.381 6.53362C16.4412 6.86986 16.285 7.11753 16.1984 7.22537C16.1081 7.33795 16.0078 7.40978 15.9561 7.44413C15.9258 7.46428 15.8989 7.48018 15.8783 7.49171C15.8679 7.49757 15.8586 7.50257 15.8507 7.50667C15.8483 7.50795 15.846 7.50916 15.8438 7.51028C15.8425 7.51095 15.8412 7.5116 15.84 7.51222C15.8383 7.51304 15.8368 7.51382 15.8354 7.51454L15.8333 7.51558L15.8323 7.51607C15.8318 7.51631 15.8313 7.51655 15.4999 6.84375L15.8313 7.51655C15.4597 7.69959 15.0101 7.54675 14.8271 7.17518C14.8208 7.16242 14.8149 7.14958 14.8094 7.13665C14.7576 7.12931 14.6991 7.12186 14.6333 7.11457C14.1969 7.06616 13.5225 7.03125 12.4999 7.03125C11.1649 7.03125 9.85981 7.42713 8.74978 8.16883C7.63975 8.91053 6.77459 9.96474 6.2637 11.1981C5.7528 12.4315 5.61913 13.7887 5.87958 15.0981C6.14003 16.4075 6.78291 17.6102 7.72691 18.5542C8.67092 19.4982 9.87365 20.1411 11.183 20.4016C12.4924 20.662 13.8496 20.5283 15.083 20.0174C16.3164 19.5065 17.3706 18.6414 18.1123 17.5313C18.854 16.4213 19.2499 15.1163 19.2499 13.7812C19.2499 13.367 19.5857 13.0312 19.9999 13.0312C20.4141 13.0312 20.7499 13.367 20.7499 13.7812C20.7499 15.4129 20.266 17.008 19.3595 18.3647C18.453 19.7214 17.1645 20.7788 15.657 21.4033C14.1495 22.0277 12.4907 22.1911 10.8904 21.8727C9.29004 21.5544 7.82003 20.7687 6.66625 19.6149C5.51247 18.4611 4.72673 16.9911 4.4084 15.3907C4.09008 13.7904 4.25345 12.1316 4.87788 10.6241C5.5023 9.11662 6.55972 7.82815 7.91643 6.92163Z"
								fill="white"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.9697 2.18842C12.2626 1.89553 12.7374 1.89553 13.0303 2.18842L16.7803 5.93842C17.0732 6.23131 17.0732 6.70619 16.7803 6.99908L13.0303 10.7491C12.7374 11.042 12.2626 11.042 11.9697 10.7491C11.6768 10.4562 11.6768 9.98131 11.9697 9.68842L15.1893 6.46875L11.9697 3.24908C11.6768 2.95619 11.6768 2.48131 11.9697 2.18842Z"
								fill="white"
							/>
						</svg>
					</IconDiv>
					<Text>
						<FormattedMessage id="retry" defaultMessage="Retry" />
					</Text>
				</ButtonText>
			</StyledButton>
		</MainContent>
	);
};
