import { defineMessages } from 'react-intl';

export default defineMessages({
	robotDetails: {
		id: 'Devices.robotDetails',
		defaultMessage: 'Robot details',
		description: 'Robot details',
	},
	robotSystem: {
		id: 'Devices.robotSystem',
		defaultMessage: 'System',
		description: 'Robot system',
	},
	robotNetwork: {
		id: 'Devices.robotNetwork',
		defaultMessage: 'Network',
		description: 'Robot network',
	},
	robotHealth: {
		id: 'Devices.robotHealth',
		defaultMessage: 'Robot Health',
		description: 'Robot Health',
	},
	name: {
		id: 'Devices.name',
		defaultMessage: 'Name',
		description: 'Name',
	},
	nameHint: {
		id: 'Devices.nameHint',
		defaultMessage: 'Enter Name',
		description: 'Enter Name',
	},
	location: {
		id: 'Devices.location',
		defaultMessage: 'Location',
		description: 'Location',
	},
	locationHint: {
		id: 'Devices.locationHint',
		defaultMessage: 'Enter Location',
		description: 'Enter Location',
	},
	timeZone: {
		id: 'Devices.timeZone',
		defaultMessage: 'Time Zone',
		description: 'Time Zone',
	},
	Battery: {
		id: 'Devices.battery',
		defaultMessage: 'Battery',
		description: 'Battery',
	},
	charging: {
		id: 'Devices.charging',
		defaultMessage: 'Charging',
		description: 'Charging',
	},
	status: {
		id: 'Devices.status',
		defaultMessage: 'Status',
		description: 'Status',
	},
	inUseSince: {
		id: 'Devices.inUseSince',
		defaultMessage: 'In use since',
	},
	operationHoursTotal: {
		id: 'Devices.operationHoursTotal',
		defaultMessage: 'Operation hours',
	},
	disinfectionHoursTubes: {
		id: 'Devices.disinfectionHoursTubes',
		defaultMessage: 'Disinfection hours - UVC Tubes',
	},
	distanceTravelledTotal: {
		id: 'Devices.distanceTravelledTotal',
		defaultMessage: 'Distance travelled - Total',
	},
	distanceTravelledDuringDisinfection: {
		id: 'Devices.distanceTravelledDuringDisinfection',
		defaultMessage: 'Distance travelled',
	},
	truphoneDataPlan: {
		id: 'Devices.truphoneDataPlan',
		defaultMessage: 'TruPhone 4G data plan',
	},
	type: {
		id: 'Devices.type',
		defaultMessage: 'Type',
		description: 'Type',
	},
	typeHint: {
		id: 'Devices.typeHint',
		defaultMessage: 'Enter Type',
		description: 'Enter Type',
	},
	gobeCare: {
		id: 'Devices.gobeCare',
		defaultMessage: 'GoBe Care',
		description: 'GoBe Care',
	},
	linkedTo: {
		id: 'Devices.linkedTo',
		defaultMessage: 'Linked to',
		description: 'Linked to',
	},
	robotGroup: {
		id: 'Devices.robotGroup',
		defaultMessage: 'Robot Group',
		description: 'Robot Group',
	},
	robotGroupHint: {
		id: 'Devices.robotGroupHint',
		defaultMessage: 'Select Robot Group',
		description: 'Select Robot Group',
	},
	userGroups: {
		id: 'Users.userGroups',
		defaultMessage: 'User Groups',
		description: 'User group names',
	},
	userGroupsHint: {
		id: 'Users.userGroupsHint',
		defaultMessage: 'Select User Groups',
		description: 'Select User Group',
	},
	no: {
		id: 'Devices.no',
		defaultMessage: 'No',
		description: 'No',
	},
	yes: {
		id: 'Devices.yes',
		defaultMessage: 'Yes',
		description: 'Yes',
	},
	available: {
		id: 'Devices.available',
		defaultMessage: 'Available',
		description: 'Available',
	},
	reserved: {
		id: 'Devices.reserved',
		defaultMessage: 'Reserved',
		description: 'Reserved',
	},
	onHold: {
		id: 'Devices.onHold',
		defaultMessage: 'On Hold',
		description: 'On Hold',
	},
	configuring: {
		id: 'Devices.configuring',
		defaultMessage: 'Configuring',
		description: 'Configuring',
	},
	inSession: {
		id: 'Devices.inSession',
		defaultMessage: 'In Session',
		description: 'In Session',
	},
	unavailable: {
		id: 'Devices.unavailable',
		defaultMessage: 'Unavailable',
		description: 'Unavailable',
	},
	updating: {
		id: 'Devices.updating',
		defaultMessage: 'Updating',
		description: 'Updating',
	},
	onCall: {
		id: 'Devices.onCall',
		defaultMessage: 'On Call',
		description: 'On Call',
	},
	online: {
		id: 'Devices.online',
		defaultMessage: 'Available',
		description: 'Available',
	},
	offline: {
		id: 'Devices.offline',
		defaultMessage: 'Offline',
		description: 'Offline',
	},
	serialNumber: {
		id: 'Devices.serialNumber',
		defaultMessage: 'Serial Number',
		description: 'Serial Number',
	},
	softwareVersion: {
		id: 'Devices.softwareVersion',
		defaultMessage: 'Software Version',
		description: 'Software Version',
	},
	dateAdded: {
		id: 'Devices.dateAdded',
		defaultMessage: 'Date Added',
		description: 'Date Added',
	},
	wifi: {
		id: 'Devices.wifi',
		defaultMessage: 'WiFi Network',
		description: 'WiFi Network',
	},
	lte: {
		id: 'Devices.lte',
		defaultMessage: 'LTE Network',
		description: 'LTE Network',
	},
	goBeServer: {
		id: 'Devices.goBeServer',
		defaultMessage: 'GoBe Server Network',
		description: 'GoBe Server Network',
	},
	connected: {
		id: 'Devices.connected',
		defaultMessage: 'Connected',
		description: 'Connected',
	},
	ssid: {
		id: 'Devices.ssid',
		defaultMessage: 'SSID',
		description: 'SSID',
	},
	mac: {
		id: 'Devices.mac',
		defaultMessage: 'MAC',
		description: 'MAC',
	},
	ip: {
		id: 'Devices.ip',
		defaultMessage: 'IP',
		description: 'IP',
	},
});
