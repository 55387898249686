import {
	IonIcon
} from '@ionic/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
// Styling
// custom components
import isAuthenticated from '../Authentication/Authenticated';
import Messages from './RobotTransferModal.messages';
// // icons
import {
	Button, Dialog, TextField, Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { closeCircleOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { ClassicSpinner } from 'react-spinners-kit';
import styled from 'styled-components';
import { publish } from '../../actions/publish';
import { subscribeToOrganization } from '../../providers/mqtt';
import { useTypedSelector } from '../../reducers';
import { store } from '../../store/store';
import { Device } from '../../types/types';
import { equalityFnc } from '../../utils/conformState';
import { b64EncodeUnicode } from '../../utils/encoding';

const ContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '50%',
	alignSelf: 'center',
	justifyContent: 'space-between',
	flex: 1,
}));

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '100%',
	alignSelf: 'center',
}));

const ErrorWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginBottom: theme.spacing(4),
}));

const ErrorIcon = styled(IonIcon)(({ theme }) => ({
	color: theme.palette.error.main,
	marginRight: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'.MuiPaper-rounded': {
		borderRadius: 10,
	},
	'.MuiDialog-paper': {
		padding: '62px 0px',
		minHeight: 560,
	},
}));

const StyledForm = styled('form')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
}));

const Label = styled(Typography)(({ theme }) => ({
	fontSize: '0.68rem',
	color: theme.palette.text.disabled,
	marginBottom: theme.spacing(1),
	lineHeight: '0.75rem',
	marginLeft: '13px',
}));

const OrgNameText = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.disabled,
	marginLeft: '15px',
	marginBottom: theme.spacing(6),
}));

const OrgWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	width: '100%',
}));

const ButtonBase = styled(Button)(({ theme }) => ({
	padding: '14px 32px',
	maxHeight: 52,
	borderRadius: 100,
	margin: theme.spacing(2),
	marginTop: 0,
	marginBottom: 0,
	boxSizing: 'border-box',
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	':hover': {
		backgroundColor: theme.palette.primary.dark,
	},
	':disabled': {
		backgroundColor: theme.palette.OP_Grey[30],
		p: {
			color: theme.palette.OP_Grey[20],
		},
	},
}));

const StyledOutlineButton = styled(ButtonBase)(({ theme }) => ({
	color: theme.palette.primary.main,
	border: '1px solid ' + theme.palette.primary.main,
	':hover': {
		border: '1px solid ' + theme.palette.primary.dark,
		color: theme.palette.primary.dark,
		backgroundColor: 'white',
	},
}));

interface ButtonTextProps {
	theme: any;
	lightText?: boolean;
}

const ButtonText = styled(Typography)`
	font-size: 0.94rem;
	line-height: 1.5rem;
	color: ${(p: ButtonTextProps) => (p.lightText ? 'white' : 'inherit')};
	text-transform: capitalize;
`;

const FooterWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignContent: 'center',
	justifyContent: 'center',
}));

const DisabledHelpText = styled(Typography)(({ theme }) => ({
	color: theme.palette.OP_Grey[40],
}));

const RobotTransfer: FC = (props: any) => {
	const {
		collection,
		unCheckAll,
	} = props;
	const user = useTypedSelector(state => state.accountState.user);
	const { handleSubmit } = useForm();
	let encodedUser = b64EncodeUnicode(user.username);

	const [selectedOrgId, setSelectedOrgId] = useState('');
	const [robotsOrgId, setRobotsOrgId] = useState('');
	const [robotsWithRobotGroup, setRobotsWithRobotGroup] = useState<any>([]);
	const [deviceTransferNotification, setDeviceTransferNotification] = useState<any>({});
	const [customError, setCustomError] = useState('');
	const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true);
	const [isTransfering, setIsTransfering] = useState<boolean>(false);

	const organizations = useTypedSelector(
		state => state.organizationState.organizations,
		(left, right) => equalityFnc(left, right)
	);
	const accountsOrganization = useTypedSelector(
		state => state.accountState.user.selectedOrganizationId
	);
	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization
	);

	useEffect(() => {
		if (collection && collection.length > 0 && collection[0].orgId) {
			setRobotsOrgId(collection[0].orgId);
		}
	}, [collection]);

	useEffect(() => {
		let robotsWithRG: any = [];
		collection.forEach((robot: any) => {
			if (robot.deviceGroupsIds && robot.deviceGroupsIds.length > 0) {
				robotsWithRG = [...robotsWithRG, robot];
			}
		});

		setRobotsWithRobotGroup(robotsWithRG);
	}, [collection]);

	const handleTransferDeviceNotification = useCallback(notification => {
		setDeviceTransferNotification({
			message: notification?.message || '',
			status: notification?.status || 'error',
			failedTransferDeviceIDs: notification?.failedTransferDeviceIDs,
		});
		if (notification?.status == 'success' && notification?.failedTransferDeviceIDs?.length <= 0) {
			setIsTransfering(false);
			setIsSubmitEnabled(true);
			props.onDismiss();
		}
		//For some reason, this callback gets added incrementally everytime the form is submitted.
		setCustomError(notification?.message);
		setIsTransfering(false);
	}, []);

	useEffect(() => {
		if (deviceTransferNotification) {
			if (
				deviceTransferNotification?.status == 'success' &&
				!deviceTransferNotification?.failedTransferDeviceIDs?.length
			) {
				props.onDismiss();
				unCheckAll();
			}
		}
	}, [deviceTransferNotification]);

	const handleCustomEventListenerDevice = useCallback(event => {
		handleTransferDeviceNotification((event as CustomEvent)?.detail || {});
	}, []);

	useEffect(() => {
		window.addEventListener('transferDevices', handleCustomEventListenerDevice);
		return () => {
			window.removeEventListener('transferDevices', handleCustomEventListenerDevice);
		};
	}, [handleTransferDeviceNotification]);

	const onTransfer = handleSubmit(data => {
		const deviceType = collection[0].deviceType;
		const deviceOrgId = collection[0].orgId;
		const states = store.getState();

		if (collection?.length > 0 && deviceOrgId && accountsOrganization) {
			const groupedDevices: Record<string, Array<string>> = {};
			//make sure to remove robots that is already a part of a device group from the old group.
			collection.forEach((element: any) => {
				const e = states.deviceState.devicesByOrganizationId[deviceOrgId][
					element.deviceId
				] as Device;
				if (e && e.deviceGroupsIds) {
					if (Array.isArray(groupedDevices[e.deviceGroupsIds[0]]))
						groupedDevices[e.deviceGroupsIds[0]].push(e.deviceId);
					else groupedDevices[e.deviceGroupsIds[0]] = [e.deviceId];
				}
			});
		}

		let wrappedClient = states.mqttState.client;
		subscribeToOrganization(wrappedClient, selectedOrgId);
		publish(`microservice/${accountsOrganization}/${encodedUser}/changeDeviceOrg`, {
			data: {
				devices: collection,
				deviceType: deviceType,
				orgId: selectedOrgId,
			},
		});
		setIsSubmitEnabled(false);
		setIsTransfering(true);
	});

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		const options = [];
		let currentEntities: any;
		if (entity === 'organizations') {
			if (organizations) {
				const transferrableOrganizations = { ...organizations };
				delete transferrableOrganizations[accountsOrganization]; // we don't want to be able to transfer to the current org
				currentEntities = Object.assign(transferrableOrganizations);
			}
		}
		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				options.push({
					label: currentEntities[i][labelProperty],
					value: currentEntities[i][valueProperty],
				});
			}
		}

		return options;
	};

	const onOrgChange = (orgId: string) => {
		if (orgId) {
			setSelectedOrgId(orgId);
		} else {
			alert('Cloud not find matching target organization');
		}
	};

	const transferButtonDisabled = useMemo(() => {
		if (
			!robotsOrgId ||
			selectedOrgId === robotsOrgId ||
			selectedOrgId === '' ||
			collection.length < 1 ||
			isTransfering ||
			deviceTransferNotification.status === 'error'
		) {
			return true;
		} else {
			return false;
		}
	}, [robotsOrgId, selectedOrgId, collection.length, isTransfering]);

	return (
		<StyledDialog open={props.isOpen} onClose={props.onDismiss}>
			<StyledForm onSubmit={onTransfer}>
				<ContentWrapper>
					<Wrapper>
						<Typography variant="h2" color="primary" align="center">
							<FormattedMessage id="transfer-robot" defaultMessage="Transfer robot" />
						</Typography>
						<Typography variant="body1" color="textPrimary" align="center">
							{collection[0].name}
						</Typography>
					</Wrapper>
					<Wrapper>
						<OrgWrapper>
							<Label>
								<FormattedMessage
									id="from-organisation"
									defaultMessage="From organisation"
								/>
							</Label>
							<OrgNameText variant="body1">{selectedOrganization.name}</OrgNameText>
						</OrgWrapper>
						<Autocomplete
							options={selectOptions('organizations', 'name', 'orgId')}
							getOptionLabel={(option: any) => option.label}
							style={{ width: '100%', marginBottom: 24 }}
							onChange={(event, value) => onOrgChange(value?.value)}
							renderInput={(params: any) => (
								<TextField
									{...params}
									required
									label={<FormattedMessage {...Messages.toOrganization} />}
									variant="outlined"
									disabled={transferButtonDisabled}
								/>
							)}
						/>

						{isTransfering ? (
							<DisabledHelpText>
								<FormattedMessage
									id="transfer-robot-helptext"
									defaultMessage="The robot will be unassigned from its Robot Group. Users in {orgName} will lose access to it."
									values={{
										orgName: selectedOrganization.name,
									}}
								/>
							</DisabledHelpText>
						) : (
							<Typography variant="body1" color="textSecondary">
								<FormattedMessage
									id="transfer-robot-helptext"
									defaultMessage="The robot will be unassigned from its Robot Group. Users in {orgName} will lose access to it."
									values={{
										orgName: selectedOrganization.name,
									}}
								/>
							</Typography>
						)}
					</Wrapper>
					<div>
						{deviceTransferNotification.status === 'error' || deviceTransferNotification?.failedTransferDeviceIDs?.length ? (
							<ErrorWrapper>
								<ErrorIcon size="small" icon={closeCircleOutline} />
								<Typography color="error">
									<FormattedMessage
										id="could-not-transfer"
										defaultMessage="Could not transfer {robotName}.{transferError} Try again."
										values={{ robotName: collection[0].name, transferError: customError ? ' Reason: '+ customError + " " : '' }}
									/>
								</Typography>
							</ErrorWrapper>
						) : null}
						<FooterWrapper>
							<StyledOutlineButton onClick={props.onDismiss}>
								<ButtonText color="primary">
									<FormattedMessage id="cancel" defaultMessage="Cancel" />
								</ButtonText>
							</StyledOutlineButton>
							<StyledButton type="submit" disabled={transferButtonDisabled}>
								<ButtonText lightText>
									{isTransfering ? (
										<ClassicSpinner size={18} />
									) : (
										<FormattedMessage {...Messages.save} />
									)}
								</ButtonText>
							</StyledButton>
						</FooterWrapper>
					</div>
				</ContentWrapper>
			</StyledForm>
		</StyledDialog>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	devices: state.deviceState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(RobotTransfer), 'RobotTransfer')
);
