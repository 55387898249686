import React, { FC } from 'react';
import { IonGrid, IonRow, IonList, IonItem, IonLabel, IonListHeader } from '@ionic/react';
import isAuthenticated from '../Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotForm.messages';
import classes from './RobotForm.module.css';
import classNames from 'classnames';
import { format, secondsToHours } from 'date-fns';
import { UVCTubes } from './UVCTubes';
import { UVDBatteries } from './UVDBatteries';
import styled from 'styled-components';

const ValueWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	marginLeft: theme.spacing(2),
}));

interface RobotFormProps {
	deviceId: any;
	device: any;
}

const RobotHealthUVD = (props: RobotFormProps) => {
	const { device } = props;
	const { mongoDevice } = device;

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel>
							<FormattedMessage {...Messages.robotHealth} />
						</IonLabel>
					</IonListHeader>

					<IonItem disabled>
						<IonLabel position="fixed" className={classNames(classes.formLb)}>
							<FormattedMessage
								id="uvcTubeLifetime"
								defaultMessage="UV-C Tube lifetime"
							/>
						</IonLabel>
						{mongoDevice?.status?.uvcTubes?.length > 0 ? (
							<ValueWrapper>
								<UVCTubes tubeArr={mongoDevice?.status?.uvcTubes} />
							</ValueWrapper>
						) : (
							<IonLabel className={classes.inputLb}>-</IonLabel>
						)}
					</IonItem>

					<IonItem disabled>
						<IonLabel position="fixed" className={classNames(classes.formLb)}>
							<FormattedMessage
								id="batteryCapacity"
								defaultMessage="Battery capacity"
							/>
						</IonLabel>
						{mongoDevice?.status?.batteries?.length > 0 ? (
							<ValueWrapper>
								<UVDBatteries batteryArr={mongoDevice?.status?.batteries} />
							</ValueWrapper>
						) : (
							<IonLabel className={classes.inputLb}>-</IonLabel>
						)}
					</IonItem>

					<IonItem disabled>
						<IonLabel position="fixed" className={classNames(classes.formLb)}>
							<FormattedMessage {...Messages.operationHoursTotal} />
						</IonLabel>
						<IonLabel className={classes.inputLb}>
							{mongoDevice?.status?.operationSeconds
								? secondsToHours(mongoDevice.status.operationSeconds) + ' h'
								: '-'}
						</IonLabel>
					</IonItem>

					<IonItem disabled>
						<IonLabel position="fixed" className={classNames(classes.formLb)}>
							<FormattedMessage {...Messages.distanceTravelledDuringDisinfection} />
						</IonLabel>

						<IonLabel className={classes.inputLb}>
							{Math.floor(mongoDevice?.status?.distanceTraveled) || 0} m
						</IonLabel>
					</IonItem>

					<IonItem disabled>
						<IonLabel position="fixed" className={classNames(classes.formLb)}>
							<FormattedMessage {...Messages.inUseSince} />
						</IonLabel>
						<IonLabel className={classes.inputLb}>
							{new Date(mongoDevice?.manufacturingDate)?.getTime() > 0
								? format(new Date(mongoDevice?.manufacturingDate), 'PPP')
								: '-'}
						</IonLabel>
					</IonItem>
				</IonList>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(RobotHealthUVD, 'RobotHealthUVD'));
