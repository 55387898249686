import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { ChartHeader, ChartMainDiv } from '../../components/ChartHeader';
import { ChartPaper } from '../../components/ChartPaper';
import { ChartWrapper } from '../../components/ChartWrapper';
import { useIntl } from 'react-intl';
import { IonIcon } from '@ionic/react';
import RoomsDisinfectedByDateChart from './RoomsDisinfectedByDateChart';
import { helpCircle } from 'ionicons/icons';

interface Props {
	filters: any;
	chartHeight: any;
}

export const RoomsDisinfectedByDate = ({ chartHeight, filters }: Props) => {
	const intl = useIntl();

	return (
		<ChartPaper>
			<ChartMainDiv>
				<ChartHeader
					label={intl.formatMessage({
						id: 'DisinfectionDashboard.roomsByDate',
						defaultMessage: 'Rooms disinfected per day',
					})}
				/>
				<div>
					<Tooltip
						direction="down"
						className="tooltip-dashboard"
						content={intl.formatMessage({
							id: 'DisinfectionDashboard.roomsByDate.description',
							defaultMessage:
								'The number of rooms disinfected per day in the selected time period. If the chart is blank, no data is matching the filters.',
						})}
					>
						<IonIcon size="small" icon={helpCircle} />
					</Tooltip>
				</div>
			</ChartMainDiv>

			<ChartWrapper chartHeight={chartHeight}>
				<RoomsDisinfectedByDateChart filters={filters} />
			</ChartWrapper>
		</ChartPaper>
	);
};
