import React from 'react';
import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MapWrapper } from './MapWrapper';

export const Header = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	lineHeight: '23px',
	padding: 0,
	marginBottom: theme.spacing(2),
	color: theme.palette.OP_Grey[50],
}));

export const LoadingExposureMap = () => {
	return (
		<MapWrapper light>
			<Header variant="h4">
				<FormattedMessage
					id="loadingExposureMapHeader"
					defaultMessage="Loading UV Irradiation exposure map"
				/>
			</Header>
			<IonSpinner name="lines" />
		</MapWrapper>
	);
};
