import { defineMessages } from 'react-intl';

export default defineMessages({
	accountSettings: {
		id: 'AccountSetup.accountSettings',
		defaultMessage: 'Account Settings',
		description: 'Account Settings',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},

	notificationItems: {
		notifyWhen: {
			id: 'notificationItems.notifyWhen',
			defaultMessage: 'Notify me when',
			description: 'Notify me when',
		},
		resetPassword: {
			id: 'notificationItems.resetPassword',
			defaultMessage: 'I need to reset my password',
			description: 'I need to reset my password',
		},
		addedToRobotGroup: {
			id: 'notificationItems.addedToRobotGroup',
			defaultMessage: 'I am <b>added</b> to a <b>Robot Group</b>',
			description: 'I am <b>added</b> to a <b>Robot Group</b>',
		},
		addedToUserGroup: {
			id: 'notificationItems.addedToUserGroup',
			defaultMessage: 'I am <b>added</b> to a <b>User Group</b>',
			description: 'I am <b>added</b> to a <b>User Group</b>',
		},
		removedUserGroup: {
			id: 'notificationItems.removedUserGroup',
			defaultMessage: 'I am <b>removed</b> from a <b>User group</b>',
			description: 'I am <b>removed</b> from a <b>User group</b>',
		},
		addedToOrg: {
			id: 'notificationItems.addedToOrg',
			defaultMessage: 'I am <b>added</b> to a new <b>Organization</b>',
			description: 'I am <b>added</b> to a new <b>Organization</b>',
		},
		removedOrg: {
			id: 'notificationItems.removedOrg',
			defaultMessage: 'I am <b>removed</b> from an <b>Organization</b>',
			description: 'I am <b>removed</b> from an <b>Organization</b>',
		},
		newUpgrades: {
			id: 'notificationItems.newUpgrades',
			defaultMessage: 'New software upgrades and fixes released',
			description: 'New software upgrades and fixes released',
		},
		orgSettingsChange: {
			id: 'notificationItems.orgSettingsChange',
			defaultMessage: 'Notify me when',
			description: "My organization's settings change",
		},
		adminSettings: {
			id: 'notificationItems.adminSettings',
			defaultMessage: 'I become or stop being an administrator',
			description: 'I become or stop being an administrator',
		},
		usersAdmins: {
			id: 'notificationItems.usersAdmins',
			defaultMessage: 'Users become or stop being administrators',
			description: 'Users become or stop being administrators',
		},
		edited: {
			id: 'notificationItems.edited',
			defaultMessage: 'User and robot groups are edited',
			description: 'User and robot groups are edited',
		},
		robotAddedRemoved: {
			id: 'notificationItems.robotAddedRemoved',
			defaultMessage: 'Robot is edited or removed from organization',
			description: 'Robot is edited or removed from organization',
		},
		robotRestShut: {
			id: 'notificationItems.robotRestShut',
			defaultMessage: 'Robot is shut down or restarted',
			description: 'Robot is shut down or restarted',
		},
		robotSettings: {
			id: 'notificationItems.robotSettings',
			defaultMessage: 'Robot settings are changed',
			description: 'Robot settings are changed',
		},
		robotOutside: {
			id: 'notificationItems.robotOutside',
			defaultMessage:
				'Robot is left outside the charging dock for an extended period of time',
			description: 'Robot is left outside the charging dock for an extended period of time',
		},
	},
	profileSettings: {
		id: 'AccountSetup.profileSettings',
		defaultMessage: 'Profile settings',
		description: 'Profile settings',
	},
	save: {
		id: 'AccountManagementPage.save',
		defaultMessage: 'Save',
		description: 'Save',
	},
	notificationSettings: {
		id: 'AccountSetup.notificationSettings',
		defaultMessage: 'Notification settings',
		description: 'Notification settings',
	},
	agreements: {
		id: 'Organizations.agreement',
		defaultMessage: 'Agreements',
		description: 'Accepted Agreements',
	},
	version: {
		id: 'Organizations.version',
		defaultMessage: 'Version',
		description: 'Version',
	},
	acceptedDate: {
		id: 'Organizations.acceptedDate',
		defaultMessage: 'Accepted Date',
		description: 'Accepted Date',
	},
	acceptedTime: {
		id: 'Organizations.acceptedTime',
		defaultMessage: 'Accepted Time',
		description: 'Accepted Time',
	},
	acceptedBy: {
		id: 'Organizations.acceptedBy',
		defaultMessage: 'Accepted By',
		description: 'Accepted By',
	},
});
