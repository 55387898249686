import { Grid, Typography, useTheme } from '@material-ui/core';
import { secondsToHours } from 'date-fns';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ProgressBar } from './ProgressBar';

const Wrapper = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 4,
	maxWidth: 224,
}));

const TextWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: 4,
}));

const StyledText = styled(Typography)(({ theme }) => ({
	display: 'block',
	color: theme.palette.OP_Grey[40],
	fontSize: '0.625rem',
	lineHeight: '0.75rem',
}));

const MAX_TUBE_VALUE_SECONDS = 12000 * 3600; // 12.000 hours converted to seconds
const TUBE_MAX_LEVEL = 11000 * 3600; // 1.000 hours left is critical

export const Tube = ({ operationSeconds, index }: any) => {
	const theme = useTheme();
	const criticalColor = theme.palette.error.main;

	const valueLeft =
		operationSeconds > MAX_TUBE_VALUE_SECONDS
			? 0
			: secondsToHours(MAX_TUBE_VALUE_SECONDS - operationSeconds);

	const progressValue = useMemo(() => {
		let value = operationSeconds;
		if (operationSeconds > MAX_TUBE_VALUE_SECONDS) value = MAX_TUBE_VALUE_SECONDS;
		if (operationSeconds < 0) value = 0;

		return (value / MAX_TUBE_VALUE_SECONDS) * 100;
	}, [operationSeconds]);

	const isCritical = operationSeconds >= TUBE_MAX_LEVEL;

	return (
		<Wrapper item>
			<TextWrapper>
				<StyledText>
					<FormattedMessage id="tube" defaultMessage="Tube" /> {index + 1}
				</StyledText>
				<StyledText>{numeral(valueLeft).format('0,0')} h left</StyledText>
			</TextWrapper>
			<ProgressBar value={progressValue} color={isCritical ? criticalColor : 'auto'} />
		</Wrapper>
	);
};
