import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	colorAndPosition: {
		backgroundColor: '#242525',
		marginTop: '50px',
	},
}));
export const Snackbar = ({ open, onClose, message, snackbarType = 'error' }) => {
	const classes = useStyles();
	return (
		<MuiSnackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
		>
			<MuiAlert elevation={6} variant="filled" severity={snackbarType} onClose={onClose} className={classes.colorAndPosition}>
				<span id="message-id"> {message} </span>
			</MuiAlert>
		</MuiSnackbar>
	);
};
