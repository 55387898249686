import React from 'react';
import { mapRobotName } from '../../../../utils/mapRobotName';
import { PopoverMenuItem, NavigationMenuItem } from './PopoverMenuItems';
import { StyledPopover, SecondPopoverContent } from './styles';
require('react-datepicker/dist/react-datepicker.css');

type SecondaryPopoverProps = {
	open?: boolean;
	anchorEl?: any;
	onClose?: any;
	filterCategory?: any;
	repeatRobotIds?: any;
	onGoBack?: any;
	options?: any;
	onSelectOption?: any;
	getIsSelected: any;
};

export const SecondaryPopover = ({
	open,
	anchorEl,
	onClose,
	filterCategory,
	repeatRobotIds,
	onGoBack,
	options,
	onSelectOption,
	getIsSelected,
}: SecondaryPopoverProps) => {
	return (
		<StyledPopover
			open={open || false}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<SecondPopoverContent>
				<NavigationMenuItem keyName={filterCategory || ''} onClick={onGoBack} />
				{options.map((option: string, index: any) => (
					<PopoverMenuItem
						selected={getIsSelected(filterCategory, option)}
						key={option + index}
						showIcon={false}
						keyName={mapRobotName(option, filterCategory, repeatRobotIds)}
						onClick={() => onSelectOption(option)}
					/>
				))}
			</SecondPopoverContent>
		</StyledPopover>
	);
};
