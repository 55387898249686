import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Header } from './LoadingExposureMap';
import { MapWrapper } from './MapWrapper';

const Description = styled(Typography)(({ theme }) => ({
	color: theme.palette.OP_Grey[50],
	maxWidth: '50%',
	textAlign: 'center',
}));

export const ExposureMapStatus = ({ header, description }: any) => {
	return (
		<MapWrapper light>
			<Header variant="h4">{header}</Header>
			<Description variant="body1">{description}</Description>
		</MapWrapper>
	);
};
