import {
	PositionsReportTable,
	ReportInfo,
	ReportTable,
	ReportTabs,
} from '@blue-ocean-robotics/seto_ui';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { setParameter } from '../../../actions/setParam';
import isAuthenticated from '../../../components/Authentication/Authenticated';
import { useTypedSelector } from '../../../reducers';
import { secondsToHMS } from '../utils';

import { DisinfectionPositionStatus } from '@blue-ocean-robotics/seto_ui';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import axios, { AxiosRequestConfig } from 'axios';
import { ClassicSpinner } from 'react-spinners-kit';
import styled, { ThemeProvider } from 'styled-components';
import { OPTheme } from '../../../muitheme/theme';
import { mapRobotName } from '../../../utils/mapRobotName';
import { mongodbClient } from './../../../providers/mongodbClient';
import { ExposureMapStatus } from './Exposuremap/ExposureMapStatus';
import { LoadingExposureMap } from './Exposuremap/LoadingExposureMap';
import { LoadingSkeleton } from './LoadingSkeleton';
import { downloadPdf } from './PDF/PDFreport';
import lightTheme from './ReportTheme';
import { ExposureMap } from './Exposuremap/ExposureMap';
import { RetryReport } from './RetryReport';

const Wrapper = styled(MuiDialogContent)(({ theme }) => ({
	margin: 0,
	padding: theme.spacing(8),
	paddingBottom: theme.spacing(6),
	':first-child': {
		paddingTop: theme.spacing(8),
	},
	minHeight: 680, // to avoid it "jumping" when clicking the "Details" tab
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
	marginBottom: theme.spacing(6),
	display: 'flex',
	div: {
		padding: 0,
	},
	h3: {
		padding: 0,
		paddingLeft: 12,
		margin: 0,
		marginTop: 3,
	},
}));

const ContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	padding: 0,
	margin: 0,
}));

const ActionWrapper = styled(DialogActions)(({ theme }) => ({
	justifyContent: 'flex-end',
	padding: 0,
	paddingRight: theme.spacing(8),
	paddingBottom: theme.spacing(6),
}));


const StyledButton = styled(Button)(({ theme }) => ({
	padding: '7px 16px',
	height: 30,
	boxShadow: 'none',
	textTransform: 'capitalize',
	color: 'white',
	backgroundColor: theme.palette.primary.main,
	':hover': {
		backgroundColor: theme.palette.primary.dark,
		color: 'white',
		boxShadow: 'none',
	},
	':disabled': {
		backgroundColor: theme.palette.OP_Grey[30],
		color: theme.palette.OP_Grey[15],
	},
}));

const StyledButtonOutline = styled(StyledButton)(({ theme }) => ({
	border: '1px solid ' + theme.palette.primary.main,
	height: 30,
	backgroundColor: 'transparent',
	boxSizing: 'border-box',
	color: theme.palette.primary.main,
	':hover': {
		backgroundColor: 'transparent',
		border: '1px solid ' + theme.palette.primary.dark,
		color: theme.palette.primary.dark,
	},
}));

const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: '13px',
	lineHeight: '16px',
	color: 'white',
}));

const PrimaryButtonText = styled(ButtonText)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const RobotName = styled.span(({ theme }) => ({
	fontWeight: 400,
}));
const RobotNameWrapper = styled.div(({ theme }) => ({
	color: '#444444',
	whiteSpace: 'nowrap',
	fontWeight: 500,
	fontSize: '17px',
	lineHeight: '24px',
}));

interface ReportProps {
	open: boolean;
	onClose?(): void;
	classes?: Record<string, string>;
	mongoJWT?: any;
	selectedOrganizationId?: any;
	disinfectionId: any; // id of the row, that is clicked in the disinfection table
}

const useStyles = makeStyles((theme: OPTheme) =>
	createStyles({
		root: {
			borderRadius: 5,
			color: theme.palette.OP_Grey[90],
			maxWidth: 'initial',
			width: '70%',
		},
		map: {
			border: '1px solid ' + theme.palette.OP_Grey[30],
			borderRadius: '2.5px',
		},
	})
);

enum DisinfectionModes {
	Predefined = 'predefined_disinfection_available',
	Smart = 'smart_disinfection_available',
	Manual = 'manual_disinfection_available',
	Stationary = 'stationary_disinfection_available',
}
enum DisinfectionType {
	Predefined = 'Predefined',
	Smart = 'Smart',
	Manual = 'Manual',
	Stationary = 'Stationary',
	'Remote controlled' = 'Remote controlled',
}

function getDisinfectionType(type: DisinfectionType) {
	switch (type) {
		case DisinfectionType.Predefined:
			return DisinfectionModes.Predefined;
		case DisinfectionType.Smart:
			return DisinfectionModes.Smart;
		case DisinfectionType.Manual:
		case 'Remote controlled':
			return DisinfectionModes.Manual;
		case DisinfectionType.Stationary:
			return DisinfectionModes.Stationary;
		default:
			return DisinfectionModes.Stationary;
	}
}

// This is a map from the keys used in the disinfectionData object received from the database into the internal keys used by the rest of the code
const interruptionKeys = {
	internalError: 'interruptionsInternalError',
	lowBattery: 'interruptionsLowBattery',
	functionButton: 'interruptionsFunctionButton',
	resetButton: 'interruptionsResetButton',
	heatDetection: 'interruptionsHeatDetection',
	walkDetection: 'interruptionsWalkDetection',
	personDetection: 'interruptionsPersonDetection',
	connection: 'interruptionsConnection',
	connectionUnstable: 'interruptionsConnectionUnstable',
	connectionClosed: 'interruptionsConnectionClosed',
	tabletPermission: 'interruptionsTabletPermission',
	tabletMovement: 'interruptionsTabletMovement',
	fromUI: 'interruptionsFromUI',
	localizationLoss: 'interruptionsLocalizationLoss',
	robotStuck: 'interruptionsRobotStuck',
};

const mapErrorMessages = {
	disinfectionNotLocalized: {
		header: <FormattedMessage id="exposureMapError" defaultMessage="Map not available" />,
		description: (
			<FormattedMessage
				id="notLocalizedErrorDescription"
				defaultMessage="Disinfection started without selecting room"
			/>
		),
	},
	exposureMapError: {
		header: <FormattedMessage id="exposureMapError" defaultMessage="Map not available" />,
		description: '',
	},
};

const DisinfectionReport: FC<ReportProps> = props => {
	const { mongoJWT, selectedOrganizationId, disinfectionId } = props;

	const classes = useStyles(props);
	const exportImageRef = useRef<() => ImageData>();
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	const [disinfectionData, setDisinfectionData] = useState<any>(null);

	const [disinfectionPositions, setDisinfectionPositions] = useState([]);

	const withPositions = disinfectionData?.type === DisinfectionType.Predefined;
	const startMoment = disinfectionData?.start ? moment(disinfectionData.start) : null;
	const endMoment = disinfectionData?.end ? moment(disinfectionData.end) : null;

	const getTaskDurationInSeconds =
		startMoment && endMoment ? endMoment.diff(startMoment, 'seconds') : 0;

	const taskDuration = secondsToHMS(getTaskDurationInSeconds);
	const history = useHistory();
	const [srcExposure, setSrcExposure] = useState<any>('');
	const [srcMap, setSrcMap] = useState<any>('');
	const [srcExposurePath, setSrcExposurePath] = useState<any>('');
	const [srcMapPath, setSrcMapPath] = useState<any>('');
	const [mapData, setMapData] = useState({ origin: { x: 0, y: 0, yaw: 0 }, resolution: 1 });

	const [dataError, setDataError] = useState(false);
	const [exposureMapError, setExposureMapError] = useState(false);
	const [mapLoading, setMapLoading] = useState(false);
	const [disinfectionNotLocalized, setDisinfectionNotLocalized] = useState(false);
	const [pdfGenerating, setPdfGenerating] = useState(false);

	useEffect(() => {
		if (exposureMapError) {
			setMapLoading(false);
		}
	}, [exposureMapError]);

	useEffect(() => {
		var filter = { _id: disinfectionId };
		mongodbClient
			.readCollection(filter, mongoDbConfig.collections.disinfectionCollection)
			.then((result: any) => {
				if (!result[0]) {
					setDataError(true);
				} else {
					setDisinfectionData(result[0]);
				}
			})
			.catch((err: any) => {
				setDataError(true);
				console.error(`Failed to fetch disinfection collection: ${err}`);
			});
	}, [disinfectionId]);

	const retryLoadingReportData = useCallback(() => {
		var filter = { _id: disinfectionId };
		mongodbClient
			.readCollection(filter, mongoDbConfig.collections.disinfectionCollection)
			.then((result: any) => {
				if (!result[0]) {
					setDataError(true);
				} else {
					setDataError(false);
					setDisinfectionData(result[0]);
				}
			})
			.catch((err: any) => {
				setDataError(true);
				console.error(`Failed to fetch disinfection collection: ${err}`);
			});
	}, [disinfectionId]);

	useEffect(() => {
		if (
			!disinfectionData?.mapId &&
			!disinfectionData?.heatMapFile &&
			disinfectionData?.room === ''
		) {
			setDisinfectionNotLocalized(true);
		}
	}, [disinfectionData?.mapId, disinfectionData?.heatMapFile, disinfectionData?.room]);

	const resetMapData = () => {
		setMapData({ origin: { x: 0, y: 0, yaw: 0 }, resolution: 1 });
	};

	const getUVDImageConfig = useTypedSelector(
		state => state.getUVDImageConfigState.getUVDImageConfig
	);

	const downloadButtonDisabled = useMemo(() => {
		return !disinfectionData || pdfGenerating;
	}, [disinfectionData, pdfGenerating]);
	const tableData = {
		overviewTable: {
			disinfectionType: getDisinfectionType(disinfectionData?.type),
			taskDuration: taskDuration,
			...(!!disinfectionData?.interruptions?.cause && {
				interruptionCause: disinfectionData?.interruptions?.cause,
			}),
		},
		detailsTable: {
			...(disinfectionData?.department && {
				department: disinfectionData.department,
			}),
			...(disinfectionData?.room && {
				roomName: disinfectionData.room,
			}),
			disinfectionType: getDisinfectionType(disinfectionData?.type),
			status: disinfectionData?.status || 'incomplete', // must be an enum
			operator: disinfectionData?.startedBy || 'Data missing',
			taskStarted: disinfectionData?.start
				? moment(disinfectionData.start).format('DD-MM-YY HH:mm')
				: 'Data missing',
			taskEnded: disinfectionData?.end
				? moment(disinfectionData?.end).format('DD-MM-YY HH:mm')
				: 'Data missing',
			taskEndedReport: disinfectionData?.end
				? moment(disinfectionData?.end).format('DD MMMM YYYY')
				: 'Data missing',
			taskDuration: taskDuration,
			uvcLightDuration: disinfectionData?.uvcLightDuration
				? secondsToHMS(disinfectionData.uvcLightDuration)
				: 'Data missing',
			...Object.entries(interruptionKeys).reduce(
				(result, [dataKey, internalKey]) =>
					!!disinfectionData?.interruptions[dataKey]
						? {
								...result,
								[internalKey]: disinfectionData?.interruptions[dataKey],
						  }
						: result,
				{}
			),
			room: disinfectionData?.room,
			...(!!disinfectionData?.interruptions?.operator && {
				interruptionsOperator: disinfectionData?.interruptions?.operator,
			}),
			...(!!disinfectionData?.interruptions?.connection && {
				interruptionsConnection: disinfectionData?.interruptions?.connection,
			}),
			...(!!disinfectionData?.interruptions?.tabletMovement && {
				interruptionsTabletMovement: disinfectionData?.interruptions?.tabletMovement,
			}),
			...(!!disinfectionData?.interruptions?.bodyHeatDetection && {
				interruptionsBodyHeat: disinfectionData?.interruptions?.bodyHeatDetection,
			}),
			...(!!disinfectionData?.interruptions?.walkDetection && {
				interruptionsWalkDetection: disinfectionData?.interruptions?.walkDetection,
			}),
			...(disinfectionData?.type === 'Predefined' && {
				positionsDisinfected: disinfectionData?.positions?.filter(
					(d: any) => d.status === 'disinfected'
				).length,
			}),
			...(disinfectionData?.type === 'Predefined' && {
				positionsRemaining: disinfectionData?.positions?.filter(
					(d: any) => d.status === 'remaining'
				).length,
			}),
			...(disinfectionData?.type === 'Predefined' && {
				positionsRemaining: disinfectionData?.positions?.filter(
					(d: any) => d.status === 'failed'
				).length,
			}),
		},
	};

	const getStatusEnum = (status: string) => {
		switch (status) {
			case 'disinfected':
				return DisinfectionPositionStatus.Success;
			case 'failed':
				return DisinfectionPositionStatus.Failed;
			case 'remaining':
				return DisinfectionPositionStatus.None;
		}
	};

	useEffect(() => {
		if (disinfectionNotLocalized) {
			return;
		}

		setSrcMapPath('');
		setSrcExposurePath('');

		if (!disinfectionData) {
			setMapLoading(true);
			return;
		}

		if (!disinfectionData?.mapId || !disinfectionData?.heatMapFile) {
			setExposureMapError(true);
		}

		if (disinfectionData?.mapId && disinfectionData?.heatMapFile) {
			setMapLoading(true);

			mongodbClient
				.readCollection(
					{ _id: disinfectionData.mapId },
					mongoDbConfig.collections.mapCollection
				)
				.then(mapData => {
					if (mapData[0]?.navigationMapFile) {
						mongodbClient
							.readCollection(
								{ _id: mapData[0]?.navigationMapFile },
								mongoDbConfig.collections.fileCollection
							)
							.then(fileData => {
								if (fileData[0]?.name) {
									setSrcMapPath(fileData[0]?.name);
								}
							})
							.catch(err => {
								setMapLoading(false);
								setExposureMapError(true);

								console.log('MongoReportPage error in reading files', err);
							});
					}

					const map = mapData.length > 0 ? mapData[0] : [];
					setMapData({ origin: map.origin, resolution: map.resolution });

					if (disinfectionData?.type === 'Predefined') {
						// Only "Predefined" disinfections should have positions
						const zone: any = _.first(
							map.zones.filter((zone: any) => zone.name === disinfectionData.room)
						);
						const disinfectionPositions = zone?.mapItems
							.filter((item: any) => item.category === 'Disinfection position')
							.map((position: any) => ({
								...position,
								status: disinfectionData.positions.find(
									(pos: any) => pos.name === position.name
								)?.status,
							}));

						const formattedPositions = disinfectionPositions.map(
							(positionItem: any) => ({
								uuid: positionItem?._id.toString() || '',
								position: {
									x: positionItem?.position?.x,
									y: positionItem?.position?.y,
								},
								text: positionItem.order,
								yaw: positionItem?.position?.yaw,
								status: getStatusEnum(positionItem.status),
								order: positionItem.order,
								name: positionItem.name,
							})
						);

						setDisinfectionPositions(formattedPositions);
					}
				})
				.catch(err => {
					setMapLoading(false);
					setExposureMapError(true);
					console.log('MongoReportPage error in reading maps', err);
				});
		}

		if (disinfectionData?.heatMapFile) {
			setMapLoading(true);

			mongodbClient
				.readCollection(
					{ _id: disinfectionData.heatMapFile },
					mongoDbConfig.collections.fileCollection
				)
				.then(fileData => {
					if (fileData[0]?.name) {
						setSrcExposurePath(fileData[0]?.name);
					}
				})
				.catch(err => {
					setMapLoading(false);
					setExposureMapError(true);
					console.log('MongoReportPage error in reading files', err);
				});
		}
	}, [disinfectionData, disinfectionNotLocalized]);

	useEffect(() => {
		if (disinfectionNotLocalized) {
			return;
		}

		setSrcExposure('');
		setSrcMap('');
		if (!mongoJWT || !disinfectionData || !srcMapPath || !srcExposurePath) {
			setMapLoading(true);
			return;
		}

		const url = getUVDImageConfig.url;

		const robotId = disinfectionData?.robotId;
		if (!robotId) return;

		const dataHeatMap = JSON.stringify({
			robotId: robotId,
			orgId: selectedOrganizationId,
			imageName: srcExposurePath,
			folder: 'heat_map',
		});

		const dataNavigationMap = JSON.stringify({
			robotId: robotId,
			orgId: selectedOrganizationId,
			imageName: srcMapPath,
			folder: 'navigation_map',
		});

		const token = mongoJWT;

		var config: AxiosRequestConfig = {
			method: 'post',
			responseType: 'blob',
			timeout: 0,
			url: url,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		};

		const getBase64 = (file: Blob, cb: (result: string) => void) => {
			var reader = new FileReader();
			reader.onloadend = function() {
				if (typeof reader.result === 'string') {
					cb(reader.result);
				} else {
					setMapLoading(false);
					setExposureMapError(true);
					console.log('Error: Could not read map image blob');
				}
			};

			reader.onerror = function(error) {
				setMapLoading(false);
				setExposureMapError(true);
				console.log('Error: ', error);
			};
			reader.readAsDataURL(file);
		};

		axios({ ...config, data: dataHeatMap })
			.then(function(response) {
				if (response.data.size > 0) {
					getBase64(response.data, (b64data: any) => {
						setSrcExposure(b64data);
						setMapLoading(false);
					});
				} else {
					console.warn('Warning: Exposure map data is empty');
					setMapLoading(false);
					setExposureMapError(true);
				}
			})
			.catch(function(error) {
				setMapLoading(false);
				setExposureMapError(true);
				console.log(error);
			});

		axios({ ...config, data: dataNavigationMap })
			.then(function(response) {
				if (response.data.size > 0) {
					getBase64(response.data, (b64data: any) => {
						setSrcMap(b64data);
						setMapLoading(false);
					});
				} else {
					console.warn('Warning: Map data is empty');
					setMapLoading(false);
					setExposureMapError(true);
				}
			})
			.catch(function(error) {
				setMapLoading(false);
				setExposureMapError(true);
				console.log(error);
			});
	}, [
		history.location.pathname,
		mongoJWT,
		disinfectionData,
		srcMapPath,
		srcExposurePath,
		disinfectionData?.mapId,
		disinfectionData?.heatMapFile,
		disinfectionNotLocalized,
	]);

	const handlePdfReady = () => {
		setPdfGenerating(false);
	};

	const handlePdfExport = () => {
		setPdfGenerating(true);
		const exportImage = exportImageRef.current;

		downloadPdf(
			{
				...disinfectionData,
				taskDuration,
				uvcLightDuration: secondsToHMS(disinfectionData.uvcLightDuration),
				disinfectionPositions: {
					disinfectedPositions: disinfectionPositions.filter(
						(disinfectionPosition: any) =>
							disinfectionPosition.status == DisinfectionPositionStatus.Success
					),
					remainingPositions: disinfectionPositions.filter(
						(disinfectionPosition: any) =>
							disinfectionPosition.status == DisinfectionPositionStatus.None
					),
					failedPositions: disinfectionPositions.filter(
						(disinfectionPosition: any) =>
							disinfectionPosition.status == DisinfectionPositionStatus.Failed
					),
				},
				interruptions: Object.entries(interruptionKeys).reduce(
					(result, [dataKey, internalKey]) =>
						!!disinfectionData?.interruptions[dataKey]
							? {
									...result,
									[internalKey]: disinfectionData?.interruptions[dataKey],
							  }
							: result,
					{}
				),
				...(exposureMapError && {
					mapError: {
						header: mapErrorMessages.exposureMapError.header,
						description: mapErrorMessages.exposureMapError.description,
					},
				}),
				...(disinfectionNotLocalized && {
					mapError: {
						header: mapErrorMessages.disinfectionNotLocalized.header,
						description: mapErrorMessages.disinfectionNotLocalized.description,
					},
				}),
			},
			() => handlePdfReady(),
			typeof exportImage === 'function' ? exportImage() : undefined
		);
	};

	const handelClose = () => {
		setDisinfectionPositions([]);
		setSrcExposure('');
		setSrcMap('');
		resetMapData();
		setDisinfectionData(null);

		if (props.onClose) props.onClose();
	};

	return (
		<ThemeProvider theme={lightTheme}>
			<MuiThemeProvider theme={lightTheme}>
				<Dialog classes={{ paper: classes.root }} onClose={handelClose} open={props.open}>
					{dataError ? (
						<Wrapper>
							<HeaderWrapper>
								<ReportInfo complete={false} />
							</HeaderWrapper>
							<ContentWrapper>
								<ExposureMapStatus
									header={mapErrorMessages.exposureMapError.header}
									description={mapErrorMessages.exposureMapError.description}
								/>
								<RetryReport retryTrigger={retryLoadingReportData} />
							</ContentWrapper>
						</Wrapper>
					) : !disinfectionData ? (
						<LoadingSkeleton />
					) : (
						<Wrapper>
							<HeaderWrapper>
								<ReportInfo
									complete={
										disinfectionData?.status &&
										disinfectionData?.status === 'complete'
											? true
											: false
									}
								/>
								<RobotNameWrapper>
									<FormattedMessage
										id="robot-name"
										defaultMessage={`Robot Name: {name}`}
										values={{
											name: (
												<RobotName>
													{mapRobotName(
														disinfectionData.robotId,
														'robotId'
													)}
												</RobotName>
											),
										}}
									/>
								</RobotNameWrapper>
							</HeaderWrapper>
							<ContentWrapper>
								{disinfectionNotLocalized ? (
									<ExposureMapStatus
										header={mapErrorMessages.disinfectionNotLocalized.header}
										description={
											mapErrorMessages.disinfectionNotLocalized.description
										}
									/>
								) : srcMap && srcExposure ? (
									<ExposureMap
										mapData={mapData}
										disinfectionPositions={disinfectionPositions}
										exportImageRef={exportImageRef}
										srcExposure={srcExposure}
										srcMap={srcMap}
										classes={{ root: classes.map }}
									/>
								) : exposureMapError ? (
									<ExposureMapStatus
										header={mapErrorMessages.exposureMapError.header}
										description={mapErrorMessages.exposureMapError.description}
									/>
								) : (
									<LoadingExposureMap />
								)}
								<ReportTabs
									tabContent1={<ReportTable data={tableData.overviewTable} />}
									tabContent2={
										withPositions ? (
											<PositionsReportTable
												disinfectedPositions={disinfectionPositions.filter(
													(disinfectionPosition: any) =>
														disinfectionPosition.status ==
														DisinfectionPositionStatus.Success
												)}
												remainingPositions={disinfectionPositions.filter(
													(disinfectionPosition: any) =>
														disinfectionPosition.status ==
														DisinfectionPositionStatus.None
												)}
												failedPositions={disinfectionPositions.filter(
													(disinfectionPosition: any) =>
														disinfectionPosition.status ==
														DisinfectionPositionStatus.Failed
												)}
											/>
										) : (
											undefined
										)
									}
									tabContent3={<ReportTable data={tableData.detailsTable} />}
								/>
							</ContentWrapper>
						</Wrapper>
					)}
					<ActionWrapper>
						<StyledButtonOutline
							variant="outlined"
							onClick={handelClose}
							color="primary"
						>
							<PrimaryButtonText>
								<FormattedMessage id="close" defaultMessage="Close" />
							</PrimaryButtonText>
						</StyledButtonOutline>
						{pdfGenerating ? (
							<StyledButton variant="contained">
								<div style={{ marginRight: 8 }}>
									<ClassicSpinner size={16} />
								</div>
								<ButtonText>
									<FormattedMessage
										id="download-pdf"
										defaultMessage="Download PDF"
									/>
								</ButtonText>
							</StyledButton>
						) : (
							<StyledButton
								variant="contained"
								onClick={() => handlePdfExport()}
								disabled={downloadButtonDisabled}
							>
								<ButtonText>
									<FormattedMessage
										id="download-pdf"
										defaultMessage="Download PDF"
									/>
								</ButtonText>
							</StyledButton>
						)}
					</ActionWrapper>
				</Dialog>
			</MuiThemeProvider>
		</ThemeProvider>
	);
};

const mapStateToProps = (state: any) => ({
	mongoJWT: state.accountState.user.mongoJWT,
	selectedOrganizationId: state.accountState.user.selectedOrganizationId,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(DisinfectionReport), 'DisinfectionReport'));
