import React, { FC, useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonList,
	IonListHeader,
	IonLabel,
	IonItem,
	IonInput,
	IonCheckbox,
	IonNote,
} from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import isAuthenticated from '../../components/Authentication/Authenticated';
import classes from './UserForm.module.css';
import classNames from 'classnames';
import Messages from './UserForm.messages';
import { User } from '../../types/types';
import { getSessionTimeDescription } from '../../utils/time';
import { useTypedSelector } from '../../reducers';
import moment from 'moment';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import ReactSelect from '../ReactSelect/ReactSelect';
import { ShortInputLimit } from '../../utils/validator';

interface UserFormProps {
	currentUser: User;
	setAdmin: any;
	orgId: string;
	setValues: any;
}

const UserFormEditable: FC<UserFormProps> = (props: any) => {
	const { intl, currentUser, setAdmin, orgId, setValues } = props;
	const { control, register } = useForm();
	const [changingUser, setChangingUser] = useState(JSON.parse(JSON.stringify(currentUser)));
	const [isAdmin, setIsAdmin] = useState(false);
	const [userAdded] = useState(new Date(changingUser.dateJoined || 1));

	const { username } = changingUser;
	const isSelf = useTypedSelector(state => state.accountState.user).username === username;

	const userGroups = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	);
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		setIsAdmin(currentUser.role && currentUser.role?.match(/admin|Admin/g) ? true : false);
	}, [currentUser.role]);

	const updateLocalUserState = (e: CustomEvent, prop: string) => {
		const change = e.detail.value;
		const userToChange = changingUser;
		userToChange[prop] = change;
		setChangingUser(userToChange);
	};
	const alphabetsOnlyValidation = (event: any) => {
		const pattern = /[a-zA-ZæÆøØåÅ ]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	};
	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;
		const options = [];
		let currentEntities: any;
		if (entity === 'userGroup') {
			currentEntities = userGroups;
		}
		if (entity === 'deviceGroup') {
			currentEntities = deviceGroups;
		}

		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}

		return options;
	};

	const deviceGroupOptions = (user: User) => {
		const options: Array<{ label: string; value: string }> = [];

		const keyObj: any = {};
		user.deviceGroupsIds?.forEach(id => {
			if (deviceGroups && deviceGroups[id]) {
				if (keyObj[id] === undefined) {
					keyObj[id] = id;
					options.push({
						label: deviceGroups[id].name,
						value: id,
					});
				}
			}
		});

		return options;
	};

	const userGroupOptions = (user: User) => {
		const options: Array<{ label: string; value: string }> = [];

		const keyObj: any = {};
		user.userGroupsIds?.forEach(id => {
			if (userGroups && userGroups[id]) {
				if (keyObj[id] === undefined) {
					keyObj[id] = id;
					options.push({
						label: userGroups[id].name,
						value: id,
					});
				}
			}
		});
		return options;
	};

	return (
		<form className={classes.editForm} id="editUserForm">
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList>
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<FormattedMessage {...Messages.userDetails} />
							</IonLabel>
						</IonListHeader>

						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.name} />
							</IonLabel>
							<IonInput
								className={classNames(classes.editable, classes.firstName)}
								name="firstName"
								type="text"
								placeholder={intl.formatMessage({
									id: 'Users.firstNameHint',
								})}
								defaultValue={changingUser.firstName || ''}
								value={changingUser.firstName || ''}
								ref={register({ required: true })}
								onIonChange={e => {
									updateLocalUserState(e, 'firstName');
									setValues(e.detail.value, 'firstName');
								}}
								onKeyPress={e => alphabetsOnlyValidation(e)}
								debounce={400}
								maxlength={ShortInputLimit}
							/>

							<IonInput
								className={classes.editable}
								name="lastName"
								type="text"
								placeholder={intl.formatMessage({
									id: 'Users.lastNameHint',
								})}
								defaultValue={changingUser.lastName || ''}
								value={changingUser.lastName || ''}
								ref={register({ required: true })}
								onIonChange={e => {
									updateLocalUserState(e, 'lastName');
									setValues(setValues(e.detail.value, 'lastName'));
								}}
								debounce={400}
								onKeyPress={e => alphabetsOnlyValidation(e)}
								maxlength={ShortInputLimit}
							/>
						</IonItem>

						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.email} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>{username}</IonLabel>
							{/* <IonInput
								// className={isEditable ? classes.editable : ''}
								className=""
								type="email"
								name="email"
								placeholder={intl.formatMessage({
									id: 'Users.emailHint',
								})}
								value={username}
								ref={register({ required: true })}
								readonly
								onIonChange={e => updateLocalUserState(e, 'username')}
							/> */}
						</IonItem>
						<IonItem disabled={isSelf}>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.administrator} />
							</IonLabel>
							<IonCheckbox
								hidden={isSelf}
								checked={isAdmin}
								onIonChange={e => {
									setIsAdmin(e.detail.checked);
									setAdmin(e.detail.checked);
								}}
								name="isAdmin"
							/>
							<IonLabel className={classes.chkLb}>
								{isAdmin
									? intl.formatMessage({
											id: 'Users.yes',
									  })
									: intl.formatMessage({
											id: 'Users.no',
									  })}
							</IonLabel>
						</IonItem>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.dateAdded} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>
								{`${userAdded.getDate()}/${userAdded.getMonth() +
									1}/${userAdded.getFullYear()}`}
							</IonLabel>
						</IonItem>
						{spinoutType !== 'beam' && (
							<div>
								<IonItem>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.robotGroup} />:
									</IonLabel>
									<AutoComplete
										options={selectOptions(
											'deviceGroup',
											'name',
											'deviceGroupId'
										)}
										required={false}
										onChange={(e: any) => {
											setValues(e, 'deviceGroupsIds');
										}}
										defaultValue={deviceGroupOptions(currentUser)}
										style={{ width: 300 }}
										placeHolder={intl.formatMessage({
											id: 'UsersPage.deviceGroupHint',
										})}
										isMulti
										variant={'outlined'}
										filterSelectedOptions
										disableClearable
									/>
								</IonItem>
								<IonItem>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.userGroups} />:
									</IonLabel>
									<AutoComplete
										options={selectOptions('userGroup', 'name', 'userGroupId')}
										required={false}
										onChange={(e: any) => {
											setValues(e, 'userGroupsIds');
										}}
										defaultValue={userGroupOptions(currentUser)}
										style={{ width: 300 }}
										placeHolder={intl.formatMessage({
											id: 'UsersPage.userGroupsHint',
										})}
										isMulti
										variant={'outlined'}
										filterSelectedOptions
										disableClearable
									/>
								</IonItem>
							</div>
						)}
					</IonList>
				</IonRow>
			</IonGrid>
		</form>
	);
};

export default injectIntl(isAuthenticated(UserFormEditable, 'UserFormEditable'));
