import React, { useEffect, useState } from 'react';
import { ChartHeaderWrapper, ChartHeader, ChartMainDiv } from '../../components/ChartHeader';
import { CircularProgress, Typography } from '@material-ui/core';
import { mongodbClient } from '../../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../../reducers';
import { Charts } from '../../charts.enum';
import { ChartPaper } from '../../components/ChartPaper';
import EmptyChartComponent from '../../components/EmptyChartComponent';
import { SingleValueChartWrapper } from '../../components/SingleValueChartWrapper';
import { IonIcon } from '@ionic/react';
import Tooltip from 'react-tooltip-lite';
import { helpCircle } from 'ionicons/icons';

interface Props {
	filters: any;
	smallScreen?: boolean;
	header: any;
	tooltipDescription?: any;
	aggregationPipelineQueryFunction: any;
	chartId: Charts;
}

export const CountChart = ({
	smallScreen,
	filters,
	header,
	aggregationPipelineQueryFunction,
	chartId,
	tooltipDescription,
}: Props) => {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [chartValue, setChartValue] = useState<any>(null);

	useEffect(() => {
		const getData = async () => {
			if (mongoUser) {
				setChartValue(null);

				const queryDataResult = await mongodbClient.aggregateCollection(
					aggregationPipelineQueryFunction(filters),
					DISINFECTION_COLLECTION
				);

				setChartValue(queryDataResult[0]?.value || []);
			}
		};
		getData().catch(console.error);
	}, [mongoUser, filters]);

	const getCountChartContent = (chartId: Charts) => {
		if (!chartValue) {
			return (
				<Typography variant="h2">
					<CircularProgress />
				</Typography>
			);
		} else if (chartValue.length == 0) {
			return <EmptyChartComponent chartId={chartId} />;
		} else {
			return <Typography variant="h2">{chartValue}</Typography>;
		}
	};

	return (
		<ChartPaper>
			<ChartMainDiv>
				<ChartHeaderWrapper smallScreen={smallScreen}>
					<ChartHeader smallChart="small" label={header} />
				</ChartHeaderWrapper>
				<div>
					<Tooltip
						direction="bottom"
						className="tooltip-dashboard"
						content={tooltipDescription}
					>
						<IonIcon size="small" icon={helpCircle} />
					</Tooltip>
				</div>
			</ChartMainDiv>
			<SingleValueChartWrapper>{getCountChartContent(chartId)}</SingleValueChartWrapper>
		</ChartPaper>
	);
};
