import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { mongodbClient } from '../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../reducers';
import { Charts } from '../charts.enum';
import EmptyChartComponent from '../components/EmptyChartComponent';

type InterruptionRateChartProps = {
	filters: any;
};

export default function InterruptionRateChart({ filters }: InterruptionRateChartProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [interruptionRateData, setInterruptionRateData] = useState<any>(null);

	useEffect(() => {
		const getInterruptionRateData = async () => {
			if (mongoUser) {
				const getInterruptionRateDataQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$addFields: {
							interruptions: {
								$objectToArray: '$interruptions',
							},
						},
					},
					{
						$unwind: {
							path: '$interruptions',
						},
					},
					{
						$match: {
							$expr: {
								$ne: ['$interruptions.k', 'cause'],
							},
						},
					},
					{
						$group: {
							_id: '$_id',
							interruptions: {
								$push: '$interruptions.v',
							},
						},
					},
					{
						$addFields: {
							interruptions: {
								$reduce: {
									input: '$interruptions',
									initialValue: {
										sum: 0,
									},
									in: {
										sum: {
											$add: ['$$value.sum', '$$this'],
										},
									},
								},
							},
						},
					},
					{
						$facet: {
							interrupted: [
								{
									$match: {
										$expr: {
											$gt: ['$interruptions.sum', 0],
										},
									},
								},
							],
							uninterrupted: [
								{
									$match: {
										$expr: {
											$eq: ['$interruptions.sum', 0],
										},
									},
								},
							],
						},
					},
					{
						$project: {
							interrupted: {
								$cond: {
									if: { $isArray: '$interrupted' },
									then: { $size: '$interrupted' },
									else: 0,
								},
							},
							uninterrupted: {
								$cond: {
									if: { $isArray: '$uninterrupted' },
									then: { $size: '$uninterrupted' },
									else: 0,
								},
							},
						},
					},
				];

				setInterruptionRateData(null);

				const interruptionRateDataResult = await mongodbClient.aggregateCollection(
					getInterruptionRateDataQuery,
					DISINFECTION_COLLECTION
				);

				if (
					interruptionRateDataResult[0].interrupted === 0 &&
					interruptionRateDataResult[0].uninterrupted === 0
				) {
					setInterruptionRateData({});
				} else {
					setInterruptionRateData({
						interrupted: interruptionRateDataResult[0].interrupted,
						uninterrupted: interruptionRateDataResult[0].uninterrupted,
					});
				}
			}
		};
		getInterruptionRateData().catch(console.error);
	}, [mongoUser, filters]);

	const data = {
		labels: ['Uninterrupted', 'Interrupted'],
		datasets: [
			{
				label: 'Interruption rate',
				data: [interruptionRateData?.uninterrupted, interruptionRateData?.interrupted],
				backgroundColor: ['rgb(221, 221, 221)', 'rgb(41, 153, 222)'],
				hoverOffset: 4,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				color: 'white',
				font: {
					size: 14,
					weight: 700,
				},
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	if (!interruptionRateData) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress />
			</div>
		);
	} else if (Object.keys(interruptionRateData).length == 0) {
		return <EmptyChartComponent chartId={Charts.interruptionRateDonut} />;
	} else {
		return (
			<div>
				<Doughnut data={data} options={options} />
			</div>
		);
	}
}
