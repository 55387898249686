import { defineMessages } from 'react-intl';

export default defineMessages({
	disinfectionType: {
		id: 'DisinfectionReport.disinfectionType',
		defaultMessage: 'Disinfection type',
		description: 'Disinfection type',
	},
	stationary_disinfection_available: {
		id: 'DisinfectionReport.stationary_disinfection_available',
		defaultMessage: 'Stationary',
		description: 'Stationary',
	},
	status: {
		id: 'DisinfectionReport.status',
		defaultMessage: 'Status',
		description: 'Status',
	},
	startedBy: {
		id: 'DisinfectionReport.operator',
		defaultMessage: 'Started by',
		description: 'Started by',
	},
	submittedBy: {
		id: 'DisinfectionReport.submittedBy',
		defaultMessage: 'Submitted by',
		description: 'Submitted by',
	},
	taskStarted: {
		id: 'DisinfectionReport.taskStarted',
		defaultMessage: 'Disinfection started',
		description: 'Disinfection started',
	},
	taskEnded: {
		id: 'DisinfectionReport.taskEnded',
		defaultMessage: 'Disinfection ended',
		description: 'Disinfection ended',
	},
	taskDuration: {
		id: 'DisinfectionReport.taskDuration',
		defaultMessage: 'Total duration',
		description: 'Total duration',
	},
	uvcLightDuration: {
		id: 'DisinfectionReport.uvcLightDuration',
		defaultMessage: 'UV-C light duration',
		description: 'UV-C light duration',
	},
	interruptionsInternalError: {
		id: 'DisinfectionReport.interruptionsInternal',
		defaultMessage: 'Interruptions by internal error',
	},
	interruptionsLowBattery: {
		id: 'DisinfectionReport.interruptionsLowBattery',
		defaultMessage: 'Interruptions by low battery',
	},
	interruptionsFunctionButton: {
		id: 'DisinfectionReport.interruptionsFunctionButton',
		defaultMessage: 'Interruptions by Fn button',
	},
	interruptionsResetButton: {
		id: 'DisinfectionReport.interruptionsResetButton',
		defaultMessage: 'Interruptions by Reset button',
	},
	interruptionsHeatDetection: {
		id: 'DisinfectionReport.interruptionsHeatDetection',
		defaultMessage: 'Interruptions by heat detection',
	},
	interruptionsWalkDetection: {
		id: 'DisinfectionReport.interruptionsWalkDetection',
		defaultMessage: 'Interruptions by walk detection',
	},
	interruptionsPersonDetection: {
		id: 'DisinfectionReport.interruptionsPersonDetection',
		defaultMessage: 'Interruptions by person detection',
	},
	interruptionsConnection: {
		id: 'DisinfectionReport.interruptionsConnection',
		defaultMessage: 'Interruptions by connectivity loss',
	},
	interruptionsConnectionUnstable: {
		id: 'DisinfectionReport.interruptionsConnectionUnstable',
		defaultMessage: 'Interruptions by connectivity loss',
	},
	interruptionsConnectionClosed: {
		id: 'DisinfectionReport.interruptionsConnectionClosed',
		defaultMessage: 'Interruptions by the app being closed',
	},
	interruptionsTabletPermission: {
		id: 'DisinfectionReport.interruptionsTabletPermission',
		defaultMessage: 'Interruptions by missing iPad motion sensors permissions',
	},
	interruptionsTabletMovement: {
		id: 'DisinfectionReport.interruptionsTabletMovement',
		defaultMessage: 'Interruptions by Tablet movement',
		description: 'Interruptions by Tablet movement',
	},
	interruptionsFromUI: {
		id: 'DisinfectionReport.interruptionsFromUI',
		defaultMessage: 'Interruptions from UI',
	},
	interruptionsLocalizationLoss: {
		id: 'DisinfectionReport.interruptionsLocalizationLoss',
		defaultMessage: 'Interruptions by lost localisation',
	},
	interruptionsRobotStuck: {
		id: 'DisinfectionReport.interruptionsRobotStuck',
		defaultMessage: 'Interruptions by robot stuck',
	},
	interruptionsOperator: {
		id: 'DisinfectionReport.interruptionsOperator',
		defaultMessage: 'Interruptions by the operator',
	},
	interruptionsBodyHeat: {
		id: 'DisinfectionReport.interruptionsBodyHeat',
		defaultMessage: 'Interruptions by Body heat detection',
		description: 'Interruptions by Body heat detection',
	},
	downloadPdf: {
		id: 'DisinfectionReport.downloadPdf',
		defaultMessage: 'Download PDF',
		description: 'Download PDF',
	},
	closeReport: {
		id: 'DisinfectionReport.closeReport',
		defaultMessage: 'Close',
		description: 'Close',
	},
	positionsDisinfected: {
		id: 'DisinfectionReport.positionsDisinfected',
		defaultMessage: 'Positions disinfected',
		description: 'Positions Disinfected',
	},
	positionsRemaining: {
		id: 'DisinfectionReport.positionsDisinfected',
		defaultMessage: 'Positions remaining',
		description: 'Positions Remaining',
	},
	positionsFailed: {
		id: 'DisinfectionReport.positionsFailed',
		defaultMessage: 'Positions failed driving to point',
		description: 'Positions Failed',
	},
});
