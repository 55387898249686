import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'RobotMoveGroup.title',
		defaultMessage: 'Move To Robot Group',
		description: 'Move To Robot Group',
	},
	switch: {
		id: 'RobotMoveGroup.switch',
		defaultMessage: 'Choose The Robot Group',
		description: 'Choose The Robot Group',
	},
	cancel: {
		id: 'RobotMoveGroup.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	move: {
		id: 'RobotMoveGroup.move',
		defaultMessage: 'Move',
		description: 'Move',
	},
	from: {
		id: 'RobotMoveGroup.from',
		defaultMessage: 'Please choose new group for :',
		description: 'From :',
	},
	toGroup: {
		id: 'RobotMoveGroup.toGroup',
		defaultMessage: 'To Group',
		description: 'To Group',
	},
	fromGroup: {
		id: 'RobotMoveGroup.fromGroup',
		defaultMessage: 'From Robot Group :',
		description: 'From Robot Group',
	},
});
