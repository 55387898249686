import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from 'react-router-dom';
import { IonApp, isPlatform, IonSplitPane, IonLoading, IonPage } from '@ionic/react';
import { IntlProvider } from 'react-intl';
import DirectionProvider from 'react-with-direction/dist/DirectionProvider';
import flatten from 'flat';
import { StylesProvider } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { muiTheme } from './muitheme/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from 'styled-components';

import Menu from './components/Menu/Menu';
import Home from './pages/Home/Home';
import LoginPage from './pages/Login/LoginPage';
import LoginSSOPage from './pages/Login/LoginSSOPage';
import LoginSSOSandboxResultPage from './pages/Login/LoginSSOSandboxResultPage';
import AccountCreatePage from './pages/AccountCreate/AccountCreate';
import AccountCreateConfirmPage from './pages/AccountCreate/AccountCreateConfirm';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import FleetManagementPage from './pages/FleetManagement/FleetManagement';
import HelpcenterPage from './pages/Helpcenter/HelpcenterPage';
import SettingPage from './pages/Settings/SettingsPage';
import ResetPassword from './pages/SetPassword/SetPassword';
import AcceptInvitePage from './pages/AcceptInvite/AcceptInviteAcceptPage';
import ConfirmAccountPage from './pages/AcceptInvite/AcceptInviteConfirmPage';
import SetLanguage from './pages/SetLanguage/SetLanguage';
import Agreements from './pages/Agreements/Agreements';
import OrganizationSetup from './pages/OrganizationSetup/OrganizationSetupPage';
import AccountSetup from './pages/AccountSetup/AccountSetup';
import locales from './translation/locales';
import DisinfectionDashboardPage from './pages/DisinfectionDashboard/DisinfectionDashboardPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';

/* Platform specifics */
import MainContent from './components/MainContent/MainContent';
import { useTypedSelector } from './reducers';
import { store } from './store/store';
import { APPLICATION_VERSION } from './actions/types';
import { activeFeatureFlags } from './config/FeatureFlags';
import MongoClientLoader from './components/MongoClientLoader/MongoClientLoader';

const Router: any = isPlatform('electron') ? HashRouter : BrowserRouter;
const codes: any = locales.codes;
const messages: any = {
	de: locales.de,
	en: locales.en,
	da: locales.da,
	ar: locales.ar,
	zh: locales.zh,
};
const defaultLanguage = 'english';

const App = (props: any) => {
	const userLanguage = useTypedSelector(state => state.accountState.user.language);
	let language = codes[userLanguage ? userLanguage : defaultLanguage];
	language = language || 'en';
	const [showLoading, setShowLoading] = useState(false);

	const fetchData = useTypedSelector(state => state.fetchDataState);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		fetch('/conf/mongoDbConfig.json').then(response => {
			response.json().then(data => {
				const mongoDbConfig = data[`mongoDbConfig${process.env.REACT_APP_FM || ''}`];
				store.dispatch({
					type: 'MONGODB_CONFIG',
					payload: mongoDbConfig,
				});
			});
		});
	});

	useEffect(() => {
		fetch('/conf/getUVDImageConfig.json').then(response => {
			response.json().then(data => {
				const getUVDImageConfig =
					data[`getUVDImageConfig${process.env.REACT_APP_FM || ''}`];
				store.dispatch({
					type: 'GET_UVD_IMAGE_CONFIG',
					payload: getUVDImageConfig,
				});
			});
		});
	});

	useEffect(() => {
		fetch('/conf/ssoConfig.json').then(response => {
			response.json().then(data => {
				const ssoConfig = data[`ssoConfig${process.env.REACT_APP_FM || ''}`];
				store.dispatch({
					type: 'SSO_CONFIG',
					payload: ssoConfig,
				});
			});
		});
	});

	useEffect(() => {
		if (!spinoutType) {
			fetch('/version.json').then(response => {
				response.json().then(data => {
					let initialState = {
						version: '',
						platform: '',
						spinoutType: '',
					};

					(window as any).version = data.version;

					initialState.version = data.version;
					initialState.platform = data.platform;
					initialState.spinoutType = data.spinout;

					store.dispatch({
						type: APPLICATION_VERSION,
						payload: initialState,
					});
				});
			});
		}
	}, [spinoutType]);

	useEffect(() => {
		setShowLoading(fetchData.showLoader);
	}, [fetchData.showLoader]);

	return (
		<IntlProvider locale={language} messages={flatten(messages[language])}>
			<DirectionProvider direction={messages[language].Direction}>
				<StylesProvider injectFirst>
					<ThemeProvider theme={muiTheme}>
						<MuiThemeProvider theme={muiTheme}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<IonApp>
									<IonLoading
										cssClass="my-custom-class"
										isOpen={showLoading}
										onDidDismiss={() => setShowLoading(false)}
										message="Loading..."
									/>
									<MongoClientLoader />
									<Router>
										<Switch>
											<Route path="/" component={LoginPage} exact />
											<Route
												path="/sso-login"
												component={LoginSSOPage}
												exact
											/>
											<Route
												path="/sso-login/:token"
												component={LoginSSOPage}
												exact
											/>
											<Route
												path="/sso-sandbox"
												component={LoginSSOSandboxResultPage}
												exact
											/>

											<Route
												path="/forgotpassword"
												component={ForgotPasswordPage}
												exact
											/>
											<Route
												path="/confirm-account/:token"
												component={SetLanguage}
											/>
											<Route
												path="/accept-invite/:token"
												component={AcceptInvitePage}
											/>
											<Route
												path="/reset-password/:token"
												component={ResetPassword}
												exact
											/>
											<Route
												path="/setlanguage"
												component={SetLanguage}
												exact
											/>
											<Route
												path="/agreements/:language/:token"
												component={Agreements}
												exact
											/>
											<Route
												path="/confirm-user/:token"
												component={ConfirmAccountPage}
											/>
											<Route
												path="/confirm-organization/:token"
												component={SetLanguage}
												exact
											/>
											{activeFeatureFlags.PUBLIC_ACCOUNT_CREATION && (
												<Route
													path="/account-create"
													component={AccountCreatePage}
													exact
												/>
											)}
											{activeFeatureFlags.PUBLIC_ACCOUNT_CREATION && (
												<Route
													path="/account-created-confirmation/:token"
													component={AccountCreateConfirmPage}
													exact
												/>
											)}
											<Route>
												<IonSplitPane contentId="main">
													<Menu />
													<IonPage id="main">
														<Switch>
															<Route
																path="/disinfection-dashboard/:query?"
																component={
																	DisinfectionDashboardPage
																}
																exact
															/>
															<Route
																path="/organization-setup/:token?"
																component={OrganizationSetup}
																exact
															/>
															<Route
																path="/account-setup/:token?"
																component={AccountSetup}
																exact
															/>
															<Route
																path="/fleetManagement"
																component={FleetManagementPage}
																exact
															/>
															{spinoutType !== 'beam' && (
																<Route
																	path="/helpcenter"
																	component={HelpcenterPage}
																	exact
																/>
															)}
															<Route
																path="/settings"
																component={SettingPage}
																exact
															/>
															<Route
																render={() => {
																	if (
																		window.location.pathname.split(
																			'/'
																		)[1] === 'gobe'
																	)
																		return (
																			<Redirect to="/gobe" />
																		);
																}}
															/>
															<Route
																render={() => {
																	return <Redirect to="/" />;
																}}
															/>
														</Switch>
													</IonPage>
												</IonSplitPane>
											</Route>
										</Switch>
									</Router>
								</IonApp>
							</MuiPickersUtilsProvider>
						</MuiThemeProvider>
					</ThemeProvider>
				</StylesProvider>
			</DirectionProvider>
		</IntlProvider>
	);
};

export default App;
