import React from 'react';
import styled from 'styled-components';

import DisinfectionTable from './Charts/tables/DisinfectionTable';
import { KPICharts } from './Charts/KPICharts';
import TroubleshootingDataCharts from './Charts/TroubleshootingDataCharts/TroubleshootingDataCharts';
import {
	CategoryScale,
	Chart,
	LinearScale,
	BarElement,
	Tooltip,
	ArcElement,
	Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Wrapper = styled.div`
	background-color: #eeeeee;
	padding-bottom: 58px;
`;

const Spacer = styled.div`
	height: ${(p: { height: any }) => p.height}px;
	width: 100%;
`;

type DisinfectionDashboardProps = {
	filters: any;
};

const DisinfectionDashboard = ({ filters }: DisinfectionDashboardProps) => {
	Chart.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Tooltip,
		ArcElement,
		Legend,
		ChartDataLabels
	);
	return (
		<Wrapper>
			<DisinfectionTable filters={filters} />
			<Spacer height={16} />
			<KPICharts filters={filters} />
			<Spacer height={16} />
			<TroubleshootingDataCharts filters={filters} />
			<Spacer height={16} />
		</Wrapper>
	);
};
export default DisinfectionDashboard;
