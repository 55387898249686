import { createSelector } from 'reselect';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

const itemsSelector = state => {
	return state && state.items;
};

export const sortSelector = state => {
	return state && state.sortParams;
};

function orderByType(data, type, key) {
	switch (type) {
		case 'date':
			return Date.parse(data);
		case 'float':
			return parseFloat(data);
		default: {
			switch (key) {
				case 'name':
				case 'deviceGroupName':
				case 'userGroupName':
				case 'location':
				case 'deviceId':
					return data?.toLowerCase();
				case 'userGroups':
				case 'deviceGroups':
					return data?.length;
				case 'linkedGroups':
				case 'devicesIds':
					return data?.length;
				default:
					return data;
			}
		}
	}
}

export const sortedCollection = createSelector(itemsSelector, sortSelector, (collection, sort) => {
	if (sort) {
		if (sort.key == 'userGroups' || sort.key == 'deviceGroups') {
			let array = collection.filter(item => item[sort.key]?.length > 1); // get sort based on length
			let alpha = collection.filter(item => item[sort.key]?.length <= 1); // get sort based on name
			let arrayData = orderBy(
				array,
				c => orderByType(get(c, sort.key), sort.type, sort.key),
				[sort.order || 'desc']
			);
			let alphaData = orderBy(
				alpha,
				c =>
					orderByType(
						get(c, sort.key == 'userGroups' ? 'userGroupName' : 'deviceGroupName'),
						sort.type,
						sort.key == 'userGroups' ? 'userGroupName' : 'deviceGroupName'
					),
				[sort.order || 'desc']
			);
			console.log(alphaData, 'alphaData');
			let newArray =
				sort.order == 'desc' ? arrayData.concat(alphaData) : alphaData.concat(arrayData); // concat sort based on array length & name
			return newArray;
		} else {
			return orderBy(collection, c => orderByType(get(c, sort.key), sort.type, sort.key), [
				sort.order || 'desc',
			]);
		}
	}
});
