import React, { FC, useState, useEffect, useMemo } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonAvatar,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
	IonButton,
} from '@ionic/react';
import { camera, globe, checkmarkCircle, logoHtml5 } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';
import { useForm } from 'react-hook-form';
import capitalize from '../../actions/capitalize';

import classes from './ProfileSettings.module.css';
import classNames from 'classnames';

import Messages from './ProfileSettings.messages';
import passwordMsg from '../PasswordRequirements/PasswordRequirements.messages';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { publish } from '../../actions/publish';
import {
	checkOneLowerCase,
	checkOneNumeric,
	checkOneUpperCase,
	checkPassword,
	checkLength,
	ShortInputLimit,
} from '../../utils/validator';
import PasswordRequirementPopper from '../PasswordRequirementPopper/PasswordRequirementPopper';

import { TextField, MenuItem, makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Snackbar } from '../Snackbar';

import userAvatar from '../../assets/images/userAvatar.svg';
interface ProfileSettingsSubmenuProps {
	goTo?: () => void;
}
const StyledWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
const StyledIconsFlex = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
}));
const StyledTextField = styled(TextField)(() => ({
	marginBottom: 21,
	'&.Mui-focused': {
		color: '#118ABD',
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: '#eeeeee',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#eeeeee',
	},
}));

const useStyles = makeStyles({
	valueLabel: {
		color: '#118ABD',
		'&.Mui-focused': {
			color: '#118ABD',
		},
	},
	noValueLabel: {
		color: '1E1F22',
		'&.Mui-focused': {
			color: '#118ABD',
		},
	},
});
const StyledCircleIcon = styled('div')(() => ({
	marginRight: 5,
}));
type UserFrom = {
	firstName: string;
	lastName: string;
	password: string;
	retypedPassword: string;
};

const ProfileSettingsSubmenu: FC<ProfileSettingsSubmenuProps> = (props: any) => {
	const { intl } = props;
	const user = useTypedSelector(state => state.accountState.user);
	const { reset } = useForm<UserFrom>({
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
		},
	});

	const [showInputErrorPassword, setShowInputErrorPassword] = useState(false);
	const [passwordLengthError, setPasswordLengthError] = useState('');
	const [showInputErrorRetyped, setShowInputErrorRetyped] = useState(false);
	const [retypedLengthError, setRetypedLengthError] = useState('');
	// set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [editHidden, setEditHidden] = useState(true);
	const [reader] = useState(new FileReader());

	const [passwordsNotMatch, setPasswordsNotMatch] = useState(true);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [defaultLanguage, setDefaultLanguage] = useState<any>(
		intl.formatMessage({
			id: 'AccountManagementPage.english',
		})
	);

	const [userPicture, setUserPicture] = useState<any>();
	const [passwordValue, setPasswordValue] = useState('');
	const [retypedPasswordValue, setRetypedPasswordValue] = useState('');
	const [showPasswordText, setShowPasswordText] = useState(false);
	const [showReTypePasswordText, setShowRetypePasswordText] = useState(false);
	const [passwordSavedSnackBarOpen, setPasswordSavedSnackbarOpen] = useState(false);

	const username = useTypedSelector(state => state.accountState.user.username);

	const profilePictureLink = useTypedSelector(
		state => state.accountState.user.profilePictureLink
	);
	let encodedUser = window.btoa(username);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const isSso = useTypedSelector(state => state.accountState.user.isSso);

	useEffect(() => {
		setUserPicture(profilePictureLink);
	}, [profilePictureLink]);

	const checkPasswordLength = (event: any, targetName: any) => {
		const value = event.target.value;
		const password = passwordValue;
		const retypedPassword = retypedPasswordValue;

		switch (targetName) {
			case 'password':
				if (value.length < 8) {
					setShowInputErrorPassword(true);

					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLength' })
					);

					if (retypedPassword && retypedPassword.length >= 8) {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
					setPasswordsNotMatch(true);
				} else if (!checkOneLowerCase(value)) {
					setShowInputErrorPassword(true);

					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLowerCase' })
					);
				} else if (!checkOneNumeric(value)) {
					setShowInputErrorPassword(true);

					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordNumeric' })
					);
				} else if (!checkOneUpperCase(value)) {
					setShowInputErrorPassword(true);

					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordUpperCase' })
					);
				} else {
					setShowInputErrorPassword(false);
					setPasswordPopperVisible(false);
					setPasswordLengthError('');
					if (password === retypedPassword) setPasswordsNotMatch(false);
				}

				break;
			case 'retypedPassword':
				if (value.length < 8) {
					setShowInputErrorRetyped(true);
					setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordLength' }));
					setPasswordsNotMatch(true);
				} else {
					setShowInputErrorRetyped(false);
					setRetypedLengthError('');
				}

				if (value && value.length >= 8) {
					if (password !== value) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
						setPasswordsNotMatch(true);
					} else if (!checkOneLowerCase(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordLowerCase' })
						);
					} else if (!checkOneNumeric(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNumeric' })
						);
					} else if (!checkOneUpperCase(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordUpperCase' })
						);
					} else {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
						setRetypedPasswordPopperVisible(false);
						if (password === retypedPassword) setPasswordsNotMatch(false);
					}
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setFirstName(firstName ? firstName : user.firstName);
		setLastName(lastName ? lastName : user.lastName);
		setTimeout(() => {
			setDefaultLanguage(capitalize(JSON.parse(JSON.stringify(user?.language))));
		}, 250);
	}, [user, user.language]);

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				setUserPicture(srcObject);
			});
		};
		reader.readAsDataURL(f);
		setEditHidden(true);
	};

	const setUrlPicture = (e: CustomEvent) => {
		if (isURL(e.detail.value, { allow_underscores: true })) {
			setUserPicture(e.detail.value);
		} else {
			// display error
		}
	};

	const handleDisableButton = () => {
		const hasChangedPassword = passwordValue ? true : retypedPasswordValue ? true : false;
		const hasChangedFirstName = user ? firstName !== user.firstName : false;
		const hasChangedLastName = user ? lastName !== user.lastName : false;
		const hasChangedLanguage = user
			? defaultLanguage?.toUpperCase() !== user.language?.toUpperCase()
			: false;
		const hasChangedPictureUrl = profilePictureLink !== userPicture;
		if (
			hasChangedPassword ||
			hasChangedFirstName ||
			hasChangedLastName ||
			hasChangedLanguage ||
			hasChangedPictureUrl
		) {
			if (hasChangedPassword) {
				if (checkPassword(passwordValue) && checkPassword(retypedPasswordValue)) {
					if (passwordValue === retypedPasswordValue) {
						return false;
					} else return true;
				} else return true;
			}
			if (hasChangedFirstName && firstName?.length > 0) return false;
			if (hasChangedLastName && lastName?.length > 0) return false;
			if (hasChangedLanguage && defaultLanguage?.length > 0) return false;
			if (hasChangedPictureUrl && userPicture?.length > 0) return false;
			else return true;
		} else return true;
	};

	const onChangeContent = (value: string) => {
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const updateUserProfile = () => {
		const dataObj = {
			user: user,
			updates: {
				language: defaultLanguage,
				firstName: firstName ? firstName : user.firstName,
				lastName: lastName ? lastName : user.lastName,
				profilePictureLink: userPicture === profilePictureLink ? undefined : userPicture,
			},
		};
		const password = passwordValue;
		const retypedPassword = retypedPasswordValue;

		if (
			password &&
			retypedPassword &&
			password === retypedPassword &&
			checkPassword(retypedPassword)
		) {
			publish(`microservice/${encodedUser}/updatePassword`, {
				data: { username: username, password: retypedPassword },
				requestId: 'updatePasswordId',
			});
			window.sessionStorage.setItem('psw', window.btoa(retypedPassword));
			setPasswordsNotMatch(true);

			reset();
		} else if (password !== retypedPassword) {
			setPasswordsNotMatch(true);
		}

		publish(`microservice/${encodedUser}/updateOwnInfo`, {
			data: dataObj,
			requestId: 'updateOwnInfoId',
		});
		setPasswordValue('');
		setRetypedPasswordValue('');
		setDisplayIconFirstName(false);
		setDisplayIconLastName(false);
		setPasswordSavedSnackbarOpen(true);
	};
	const [passwordAnchorEl, setPasswordAnchorEl] = useState(null);
	const [retypedPasswordAnchorEl, setRetypedPasswordAnchorEl] = useState(null);
	const [passwordPopperVisible, setPasswordPopperVisible] = useState(false);
	const [retypedPasswordPopperVisible, setRetypedPasswordPopperVisible] = useState(false);

	const togglePasswordEye = () => {
		setShowPasswordText(!showPasswordText);
	};
	const toggleRetypePasswordEye = () => {
		setShowRetypePasswordText(!showReTypePasswordText);
	};

	const handleFocus = (event: any, fieldType: any) => {
		if (fieldType === 'password') {
			setPasswordAnchorEl(event.currentTarget);
			setPasswordPopperVisible(true);
		}
		if (fieldType === 'retypedPassword') {
			setRetypedPasswordAnchorEl(event.currentTarget);
			setRetypedPasswordPopperVisible(true);
		}
	};
	const passwordListValues = useMemo(() => {
		if (passwordValue !== retypedPasswordValue) {
			setShowInputErrorRetyped(true);
			setPasswordsNotMatch(true);
			setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' }));
		} else {
			setShowInputErrorRetyped(false);
			setPasswordsNotMatch(false);
			setRetypedLengthError('');
		}
		return [
			{
				value: <FormattedMessage {...passwordMsg.passwordLength} />,
				valid: checkLength(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordLowerCase} />,
				valid: checkOneLowerCase(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordUpperCase} />,
				valid: checkOneUpperCase(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordNumeric} />,
				valid: checkOneNumeric(passwordValue),
			},
		];
	}, [passwordValue]);
	const retypedPasswordListValues = useMemo(() => {
		return [
			{
				value: <FormattedMessage id="Message" defaultMessage="Password must match" />,
				valid: retypedPasswordValue.length > 0 && retypedPasswordValue === passwordValue,
			},
		];
	}, [retypedPasswordValue, passwordValue]);
	const handleBlur = (fieldType: any) => {
		if (fieldType === 'password') {
			setPasswordAnchorEl(null);
			setPasswordPopperVisible(false);
		}
		if (fieldType === 'retypedPassword') {
			setRetypedPasswordAnchorEl(null);
			setRetypedPasswordPopperVisible(false);
		}
	};
	const [displayIconFirstName, setDisplayIconFirstName] = useState(false);
	const [displayIconLastName, setDisplayIconLastName] = useState(false);
	const handleBlurOnNames = (nameType: any) => {
		if (nameType === 'firstName') {
			setDisplayIconFirstName(firstName.length > 0 && user?.firstName !== firstName);
		}
		if (nameType === 'lastName') {
			setDisplayIconLastName(lastName.length > 0 && user?.lastName !== lastName);
		}
	};

	const Class = useStyles();
	return (
		<div>
			<Snackbar
				open={passwordSavedSnackBarOpen}
				onClose={() => setPasswordSavedSnackbarOpen(false)}
				message={intl.formatMessage({
					id: 'ConfirmPage.changesSaved',
				})}
				snackbarType="success"
			/>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<FormattedMessage {...Messages.accountSettings} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</IonRow>
				<IonRow>
					<IonCol className={classes.firstCol}>
						<IonList>
							<StyledWrapper>
								<StyledTextField
									style={{ marginTop: 14 }}
									type="text"
									label={intl.formatMessage({
										id: 'InviteModal.firstname',
									})}
									placeholder={intl.formatMessage({
										id: 'InviteModal.firstnameHint',
									})}
									onChange={e => setFirstName(e.target.value)}
									defaultValue={user.firstName}
									InputLabelProps={{
										className: firstName
											? Class.valueLabel
											: Class.noValueLabel,
									}}
									onBlur={e => handleBlurOnNames('firstName')}
									InputProps={{
										endAdornment: (
											<div>
												{displayIconFirstName && (
													<StyledCircleIcon>
														<IonIcon
															size="small"
															color="primary"
															icon={checkmarkCircle}
														/>
													</StyledCircleIcon>
												)}
											</div>
										),
									}}
									inputProps={{ maxLength: ShortInputLimit }}
								/>
								<StyledTextField
									type="text"
									label={intl.formatMessage({
										id: 'InviteModal.lastname',
									})}
									placeholder={intl.formatMessage({
										id: 'InviteModal.lastnameHint',
									})}
									onChange={e => setLastName(e.target.value)}
									defaultValue={user.lastName}
									InputLabelProps={{
										className: lastName ? Class.valueLabel : Class.noValueLabel,
									}}
									onBlur={e => handleBlurOnNames('lastName')}
									InputProps={{
										endAdornment: (
											<div>
												{displayIconLastName && (
													<StyledCircleIcon>
														<IonIcon
															size="small"
															color="primary"
															icon={checkmarkCircle}
														/>
													</StyledCircleIcon>
												)}
											</div>
										),
									}}
									inputProps={{ maxLength: ShortInputLimit }}
								/>
								{spinoutType !== 'beam' && (
									<StyledTextField
										select
										value={defaultLanguage}
										label="Language"
										InputLabelProps={{
											className: Class.valueLabel,
										}}
										onChange={e => setDefaultLanguage(e.target.value)}
									>
										<MenuItem
											value={intl.formatMessage({
												id: 'AccountManagementPage.english',
											})}
										>
											<FormattedMessage id="AccountManagementPage.english" />
										</MenuItem>
										{/*
										<MenuItem
											value={intl.formatMessage({
												id: 'AccountManagementPage.danish',
											})}
										>
											<FormattedMessage id="AccountManagementPage.danish" />
										</MenuItem>
										*/}
									</StyledTextField>
								)}
								{!isSso && (
									<StyledWrapper>
										<StyledTextField
											type={showPasswordText ? 'text' : 'password'}
											label={intl.formatMessage({
												id: 'SetPassword.password',
											})}
											onFocus={e => handleFocus(e, 'password')}
											onBlur={e => handleBlur('password')}
											required={true}
											onChange={e => {
												setPasswordValue(e.target.value);
												checkPasswordLength(e, 'password');
											}}
											error={
												passwordValue.length > 0 && showInputErrorPassword
													? true
													: false
											}
											placeholder="Enter your new password"
											helperText={
												passwordValue.length > 0
													? passwordLengthError
													: null
											}
											value={passwordValue}
											InputLabelProps={{
												className: passwordValue
													? Class.valueLabel
													: Class.noValueLabel,
											}}
											InputProps={{
												endAdornment: (
													<StyledIconsFlex>
														{!showInputErrorPassword &&
															passwordValue.length > 0 && (
																<StyledCircleIcon>
																	<IonIcon
																		size="small"
																		color="primary"
																		icon={checkmarkCircle}
																	/>
																</StyledCircleIcon>
															)}
														<StyledCircleIcon>
															<IonIcon
																size="small"
																onClick={togglePasswordEye}
																icon={
																	showPasswordText
																		? eyeOffSharp
																		: eyeSharp
																}
															/>
														</StyledCircleIcon>
													</StyledIconsFlex>
												),
											}}
										/>

										<PasswordRequirementPopper
											anchorEl={passwordAnchorEl}
											open={passwordPopperVisible}
											valueList={passwordListValues}
										/>
										<PasswordRequirementPopper
											anchorEl={retypedPasswordAnchorEl}
											open={retypedPasswordPopperVisible}
											valueList={retypedPasswordListValues}
										/>
										<StyledTextField
											type={showReTypePasswordText ? 'text' : 'password'}
											label={intl.formatMessage({
												id: 'SetPassword.retypePassword',
											})}
											onChange={e => {
												setRetypedPasswordValue(e.target.value);
												checkPasswordLength(e, 'retypedPassword');
											}}
											onFocus={e => handleFocus(e, 'retypedPassword')}
											onBlur={e => handleBlur('retypedPassword')}
											required={true}
											value={retypedPasswordValue}
											InputLabelProps={{
												className: retypedPasswordValue
													? Class.valueLabel
													: Class.noValueLabel,
											}}
											error={
												retypedPasswordValue.length > 0 &&
												showInputErrorRetyped &&
												passwordsNotMatch
													? true
													: false
											}
											placeholder="Enter your password again"
											helperText={
												retypedPasswordValue.length > 0
													? retypedLengthError
													: null
											}
											InputProps={{
												endAdornment: (
													<StyledIconsFlex>
														{!showInputErrorRetyped &&
															retypedPasswordValue.length > 0 && (
																<StyledCircleIcon>
																	<IonIcon
																		size="small"
																		color="primary"
																		icon={checkmarkCircle}
																	/>
																</StyledCircleIcon>
															)}
														<StyledCircleIcon>
															<IonIcon
																size="small"
																onClick={toggleRetypePasswordEye}
																icon={
																	showReTypePasswordText
																		? eyeOffSharp
																		: eyeSharp
																}
															/>
														</StyledCircleIcon>
													</StyledIconsFlex>
												),
											}}
										/>
									</StyledWrapper>
								)}
							</StyledWrapper>
						</IonList>
					</IonCol>
					<IonCol>
						<IonList>
							{spinoutType !== 'beam' && (
								<IonListHeader className={classes.profileListHeader} lines="none">
									<div className={classes.profileHeader}>
										<IonLabel>
											{user?.firstName} {user?.lastName}
										</IonLabel>
									</div>
								</IonListHeader>
							)}
							{spinoutType !== 'beam' && (
								<IonItem lines="none">
									<IonAvatar
										className={classNames(
											classes.userAvatar,
											'ion-margin-end ion-margin-start'
										)}
									>
										<img src={userAvatar} alt="avatar" />
									</IonAvatar>
								</IonItem>
							)}
						</IonList>
					</IonCol>
				</IonRow>
				<IonRow className={classes.btnRow}>
					<IonCol className={classes.submitCol}>
						<IonButton
							disabled={handleDisableButton()}
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							onClick={updateUserProfile}
						>
							<FormattedMessage {...Messages.save} />
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default injectIntl(isAuthenticated(ProfileSettingsSubmenu, 'ProfileSettingsSubmenu'));
