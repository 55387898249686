import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BatteryProgress } from './BatteryProgress';

interface Props {
	batteryArr: any[];
}

export const UVDBatteries = ({ batteryArr }: Props) => {
	const theme = useTheme();
	const smallScreens = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Grid
			container
			direction="column"
			xs={12}
			lg={6}
			style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 550 }}
			spacing={smallScreens ? 0 : 1}
		>
			{batteryArr.map((item: any, index: number) => (
				<BatteryProgress {...item} index={index} />
			))}
		</Grid>
	);
};
