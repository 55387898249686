import { SSO_CONFIG } from '../actions/types';

const Initial_State = {
	ssoConfig: {
		baseUrl: '',
	},
};

export default (state = Initial_State, action: any) => {
	switch (action.type) {
		case SSO_CONFIG:
			return {
				...state,
				ssoConfig: action.payload,
			};
		default:
			return state;
	}
};
