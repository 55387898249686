import React, { FC, useState, useCallback, Fragment, useEffect } from 'react';
import { IonButton, IonIcon, IonLabel, IonAvatar } from '@ionic/react';
import { add, checkmarkCircleOutline } from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { publish } from '../../actions/publish';
import { sortedCollection } from '../../selectors';

import isAuthenticated from '../../components/Authentication/Authenticated';
import Tooltip from 'react-tooltip-lite';
import ListGrid from '../../components/ListGrid/ListGrid';
import CreateGroupModal from './CreateGroupModal';
import AddItemsModal from '../CreateAddItemsModal/CreateAddItemsModal';
import EditGroupModal from './EditGroupModal';
import UserGroupEdit from './UserGroupEdit';
import SearchBar from '../SearchBar/SearchBar';
import FilterList from '../../components/FilterList/FilterList';

import { formatTimeAgo } from '../../utils/formatTime';

import classes from './UserGroups.module.css';
import classNames from 'classnames';

import Messages from './UserGroups.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { styled } from '@material-ui/styles';
import { Divider, MenuItem } from '@material-ui/core';
import userGroupAvatar from '../../assets/images/userGroupAvatar.svg';
import { getCircularReplacer } from '../../utils/conformState';
import EllipsisText from '../EllipsisText/EllipsisText';
import differenceInYears from 'date-fns/differenceInYears';

const headers = [
	{ title: Messages.name, property: 'name', style: { minWidth: '40%' } },
	{ title: Messages.linkedRobotGroups, property: 'deviceGroups' },
	{ title: Messages.dateAdded, property: 'dateJoined' },
	{ title: Messages.members, property: 'members', centered: true },
];

interface UserGroupsProps {
	filterValues: any;
	orgId: string;
	editableUserGroup?: any;
}
const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
const UserGroups: FC<UserGroupsProps> = (props: any) => {
	const { intl, users, userGroups, history, editableUserGroup } = props;
	const { userGroupsByOrganizationId, addUsersOpen, createdGroup, sortParams } = userGroups;
	const { usersByOrganizationId } = users;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const [selectedGroup, setSelectedGroup] = useState<any>(null);
	const [availableUsers, setAvailableUsers] = useState<any[]>([]);
	const [showCreateModal, setCreateModal] = useState(false);
	const [showAddUsersModal, setAddUsersModal] = useState(false);
	const [showEditGroupModal, setEditGroupModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [userGroupsByOrg, setUserGroupsByOrg] = useState<any>();
	const [pageSize, setPageSize] = useState(11);
	const [createdIndex, setCreatedIndex] = useState(-1);
	const [searchText, setSearchText] = useState('');
	const deviceGroupsState = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[props.orgId]
	);

	const goToGroup = useCallback(
		(group: any) => {
			history.push({
				pathname: '/fleetManagement',
				data: {
					tab: 'robotGroups',
					orgId: props.orgId,
					editableRobotGroup: group,
				},
			});
		},
		[history, props.orgId]
	);

	const getGroupName = (data: any) => {
		Object.values(data).forEach((element: any) => {
			if (!element?.deviceGroups) {
				element.deviceGroups = [];
				element.deviceGroupName = '';
			}
			if (element?.deviceGroupsIds?.length > 0) {
				let deviceGroups: any = [];
				element?.deviceGroupsIds.map((id: any) => {
					let groupResult = deviceGroupsState[id];
					console.log('inn', groupResult, 'ress');
					if (groupResult) {
						deviceGroups.push(groupResult);
					}
				});
				element.deviceGroupName =
					deviceGroups.length > 1
						? intl.formatMessage(
								{
									id: 'nrGroups',
								},
								{ number: deviceGroups.length }
						  )
						: deviceGroups.length > 0
						? deviceGroups[0].name
						: '';
				element.deviceGroups = deviceGroups;
			}
		});
		return data;
	};

	useEffect(() => {
		if (!editableUserGroup) return;
		setSelectedGroup(editableUserGroup);
		setIsEditing(true);
	}, [editableUserGroup]);

	useEffect(() => {
		if (history.location.data?.editableUserGroup) {
			const userGroup = history.location.data.editableUserGroup;
			setSelectedGroup(JSON.parse(JSON.stringify(userGroup, getCircularReplacer())));
			history.location.data.editableUserGroup = undefined;
		}
	}, [history.location.data]);

	useEffect(() => {
		if (!userGroupsByOrganizationId[props.orgId]) return;

		if (searchText) {
			let searchedUserGroups = JSON.parse(
				JSON.stringify(userGroupsByOrganizationId[props.orgId], getCircularReplacer())
			);
			if (searchedUserGroups != null) {
				searchedUserGroups = getGroupName(searchedUserGroups);
			}
			for (const key in searchedUserGroups) {
				let name = searchedUserGroups[key].name;
				if (name.toLowerCase().indexOf(searchText.toLowerCase()) < 0)
					delete searchedUserGroups[key];
			}
			setUserGroupsByOrg(searchedUserGroups);
		} else {
			if (userGroupsByOrganizationId[props.orgId] != null) {
				userGroupsByOrganizationId[props.orgId] = getGroupName(
					userGroupsByOrganizationId[props.orgId]
				);
			}
			setUserGroupsByOrg(userGroupsByOrganizationId[props.orgId]);
		}
	}, [props.orgId, searchText, userGroupsByOrganizationId]);

	useEffect(() => {
		if (addUsersOpen) {
			if (!selectedGroup) return;
			setAvailableUsers(
				usersByOrganizationId[props.orgId]
					? !selectedGroup.usersIds
						? Object.values(usersByOrganizationId[props.orgId])
						: Object.values(usersByOrganizationId[props.orgId]).filter(
								(u: any) =>
									userGroupsByOrganizationId[selectedGroup.orgId][
										selectedGroup.userGroupId
									].usersIds.findIndex((id: string) => id === u.username) < 0
						  )
					: []
			);
			setAddUsersModal(true);
		} else {
			setAddUsersModal(false);
		}
	}, [
		addUsersOpen,
		props.orgId,
		selectedGroup,
		userGroupsByOrganizationId,
		usersByOrganizationId,
	]);

	useEffect(() => {
		if (createdGroup && userGroupsByOrg) {
			setCreatedIndex(
				Object.values(userGroupsByOrg).findIndex(
					(gr: any) => gr.userGroupId === createdGroup.userGroupId
				)
			);
			setTimeout(() => {
				props.setParameter('createdGroup', 'CREATED_NEW_USER_GROUP', null);
				setCreatedIndex(-1);
			}, 5000);
		}
	}, [createdGroup, userGroupsByOrg, props]);

	const formatTemplate = useCallback(
		(item: any, property: string, index: number) => {
			switch (property) {
				case 'name':
					return (
						<div className={classes.nameContainer}>
							<IonAvatar className={classes.userAvatar}>
								<IonIcon
									className={classNames(classes.profilePic)}
									size="large"
									icon={userGroupAvatar}
								/>
							</IonAvatar>
							<IonLabel className={classes.nameLb}>
								<EllipsisText text={item?.name} />
							</IonLabel>
						</div>
					);
				case 'dateJoined':
					if (createdIndex === index) {
						return (
							<IonLabel className={classes.createdUserGroup} color="primary">
								<IonIcon
									className={classes.createIcon}
									slot="end"
									size="small"
									color="primary"
									icon={checkmarkCircleOutline}
								/>
								<FormattedMessage {...Messages.justCreated} />
							</IonLabel>
						);
					} else if (item.createdAt) {
						const now = Date.now(); // for testing => new Date('2020-04-27T10:03:48.114Z').getTime();
						const nowDt = new Date(now);
						const dateJoined = new Date(item.createdAt);

						const yearDiff = differenceInYears(nowDt, dateJoined);

						if (yearDiff > 0) {
							return (
								<FormattedMessage
									{...Messages.yearsAgo}
									values={{ number: yearDiff }}
								/>
							);
						} else return formatTimeAgo(dateJoined, now);
					} else return null;
				case 'deviceGroups':
					if (item.deviceGroups?.length > 1) {
						return (
							<IonLabel className={classes.groupNameLb}>
								<Tooltip
									direction="up"
									content={
										<ul className={classes.groupNameItem}>
											{item.deviceGroups.map((gr: any, i: number) => {
												return (
													<div
														key={i}
														className={classes.deviceGroupTooltip}
														onClick={(event: any) => {
															event.stopPropagation();
															goToGroup(gr);
														}}
													>
														<li className={classes.deviceGroupTitle}>
															<IonLabel>{gr.name}</IonLabel>
															<br />
														</li>
													</div>
												);
											})}
										</ul>
									}
								>
									{item.deviceGroupName}
								</Tooltip>
							</IonLabel>
						);
					} else
						return (
							<div
								className={classes.groupLink}
								onClick={(event: any) => {
									event.stopPropagation();
									if (item.deviceGroups.length > 0)
										goToGroup(item.deviceGroups[0]);
								}}
							>
								{item.deviceGroupName?.length > 15
									? item?.deviceGroupName.substring(0, 15) + '...'
									: item?.deviceGroupName}
							</div>
						);

				case 'members':
					return <IonLabel className={classes.membersCount}>{item.members}</IonLabel>;
				default:
					return item[property];
			}
		},
		[createdIndex]
	);

	const onFilterChange = (data: any) => {
		switch (data) {
			case 'all':
				setUserGroupsByOrg(userGroupsByOrganizationId[props.orgId]);
				break;
			case 'noMembers':
				let noMembers = JSON.parse(
					JSON.stringify(userGroupsByOrganizationId[props.orgId], getCircularReplacer())
				);
				for (const key in noMembers) {
					if (noMembers[key].members > 0) delete noMembers[key];
				}
				setUserGroupsByOrg(noMembers);
				break;
			case 'noDeviceGroups':
				let noDeviceGroups = JSON.parse(
					JSON.stringify(userGroupsByOrganizationId[props.orgId], getCircularReplacer())
				);
				for (const key in noDeviceGroups) {
					if (noDeviceGroups[key].deviceGroupsIds !== null) delete noDeviceGroups[key];
				}
				setUserGroupsByOrg(noDeviceGroups);
				break;
			default:
				break;
		}
	};

	const onEdit = (group: any) => {
		setIsEditing(prev => !prev);
		setSelectedGroup(group);
	};

	const onCancelEdit = () => {
		props.setParameter('addUsersCount', 'USERS_ADDED_TO_GROUP_STATE', 0);
		setEditGroupModal(false);
	};

	const onDeleteGroup = () => {
		props.setParameter('addUsersCount', 'USERS_ADDED_TO_GROUP_STATE', 0);
		setIsEditing(false);
		setEditGroupModal(false);
		setSelectedGroup(null);
	};
	const openAddUsers = (group: any) => {
		props.setParameter('userGroups', 'CHANGE_ADD_USERS_TO_GROUP_STATE', true);
		setSelectedGroup(group);
	};
	const onAddUsers = (selectedItems: any) => {
		publish(`microservice/${props.orgId}/${encodedUser}/updateUserGroupUsers`, {
			data: {
				userGroupId: selectedGroup.userGroupId,
				add: selectedItems.map((u: any) => u.username),
			},
			requestId: 'newUserGroup',
		});
		props.setParameter('userGroups', 'CHANGE_ADD_USERS_TO_GROUP_STATE', false);

		if (selectedItems.length > 0) {
			props.setParameter('addUsersCount', 'USERS_ADDED_TO_GROUP_STATE', selectedItems.length);
		}
	};

	const formatData = useCallback((data: any) => {
		return data.map((d: any) => {
			let filterIds; // here remove all duplicate ids
			if (d.usersIds) {
				filterIds = d.usersIds.filter((c: any, index: any) => {
					return d.usersIds.indexOf(c) == index;
				});
			}
			d.members = filterIds && filterIds.length > 0 ? filterIds.length : null;
			return d;
		});
	}, []);

	return (
		<>
			{!isEditing ? (
				<Fragment>
					<div className={classes.leftSide}>
						<div className={classes.createBtnContainer}>
							<IonIcon
								slot="icon-only"
								// size="small"
								color="primary"
								className={classes.inviteIcon}
								src="./assets/icons/group-add.svg"
							/>
							<IonLabel color="primary" className={classes.inviteLb}>
								<FormattedMessage id="UserGroups.create" />
							</IonLabel>
							<IonButton
								className={classes.inviteBtn}
								shape="round"
								onClick={() => setCreateModal(true)}
							>
								<IonIcon slot="icon-only" size="small" icon={add} />
							</IonButton>
						</div>
						{createdGroup ? (
							<IonLabel className={classes.createdUserGroup}>
								<IonIcon
									slot="end"
									size="small"
									color="primary"
									icon={checkmarkCircleOutline}
								/>
								<FormattedMessage
									{...Messages.created}
									values={{ name: <span>{createdGroup.name}</span> }}
								/>
							</IonLabel>
						) : null}
						<SearchBar
							searchText={searchText}
							placeholder={props.intl.formatMessage({
								id: 'UserGroups.search',
							})}
							onChange={(value: any) => setSearchText(value)}
						/>
						<FilterList filterValues={props.filterValues} onChange={onFilterChange} />
					</div>
					<div className={classes.rightSide}>
						<ListGrid
							id="users-group-list-grid"
							headers={headers}
							data={sortedCollection({
								items: userGroupsByOrg
									? formatData(Object.values(userGroupsByOrg))
									: [],
								sortParams: sortParams,
							})}
							pageSize={pageSize}
							itemTemplate={(item, property, index) => {
								return formatTemplate(item, property, index);
							}}
							moreContent={(item: any, onClosePopover: any) => {
								return (
									<>
										<StyledMenuItem onClick={() => onEdit(item)}>
											<FormattedMessage {...Messages.edit} />
										</StyledMenuItem>
										<Divider />
										<StyledMenuItem
											onClick={() => {
												openAddUsers(item);
												onClosePopover();
											}}
										>
											<FormattedMessage {...Messages.addUsers} />
										</StyledMenuItem>
									</>
								);
							}}
							noDataTemplate={
								<>
									<IonLabel className={classes.noData}>
										<FormattedMessage {...Messages.noUserGroups} />
									</IonLabel>
									<IonLabel
										className={classNames(classes.noData, classes.noDataHint)}
									>
										<FormattedMessage {...Messages.noUserGroupsHint} />
									</IonLabel>
								</>
							}
							onRowClick={onEdit}
							createdIndex={createdIndex}
							sortType="SET_USER_GROUPS_SORT_PARAMS"
							listType="User Groups"
						/>
					</div>
					<CreateGroupModal
						isOpen={showCreateModal}
						onDismiss={() => setCreateModal(false)}
						orgId={props.orgId}
						title={intl.formatMessage({
							id: 'UserGroups.createModal.title',
						})}
						titleHint={intl.formatMessage({
							id: 'UserGroups.createModal.titleHint',
						})}
					/>
				</Fragment>
			) : (
				<UserGroupEdit
					userGroup={selectedGroup}
					onClose={() => setIsEditing(false)}
					onEditOpen={() => setEditGroupModal(true)}
					history={history}
				/>
			)}

			<AddItemsModal
				isOpen={showAddUsersModal}
				showHeaderIcon
				title={<FormattedMessage {...Messages.addUsers} />}
				titleHint={
					<FormattedMessage
						{...Messages.addUsersHint}
						values={{
							group: selectedGroup ? (
								<span className={classes.groupName}>{selectedGroup.name}</span>
							) : (
								''
							),
						}}
					/>
				}
				availableTitle={<FormattedMessage {...Messages.availableUsers} />}
				selectedTitle={<FormattedMessage {...Messages.selectedUsers} />}
				selectedTxt={Messages.usersSelected}
				noSelection={Messages.noSelection}
				searchMessageId="UserGroups.createModal.searchUsers"
				orgId={props.orgId}
				initialData={selectedGroup}
				nameProperty="username"
				byKey="username"
				availableItems={availableUsers}
				onAdd={onAddUsers}
				onDismiss={() =>
					props.setParameter('userGroups', 'CHANGE_ADD_USERS_TO_GROUP_STATE', false)
				}
			/>
			<EditGroupModal
				isOpen={showEditGroupModal}
				onDismiss={onCancelEdit}
				onDelete={onDeleteGroup}
				orgId={props.orgId}
				initialGroup={selectedGroup}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	users: state.usersState,
	userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(UserGroups), 'UserGroups'));
