import React, { FC, useState, useEffect } from 'react';

import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl } from 'react-intl';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import UserFormEditable from './UserFormEditable';
import UserFormNonEditable from './UserFormNonEditable';

interface UserFormProps {
	user: any;
	isEditable?: boolean;
	save: boolean;
	cancel: boolean;
	setEditable: any;
	onClose: any;
}

const UserForm: FC<UserFormProps> = (props: any) => {
	const { isEditable, save, cancel, setEditable, onClose, user } = props;
	const usernameForEncoding = useTypedSelector(state => state.accountState.user.username);
	const [isAdmin, setIsAdmin] = useState(false);
	const [values, setValues] = useState<Record<string, any>>({});
	const [encodedUser] = useState(b64EncodeUnicode(usernameForEncoding));
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);
	const orgType = useTypedSelector(state => state.selectedOrganizationState.organization.orgType);
	const userGroups = useTypedSelector(
		state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	);
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);

	useEffect(() => {
		return function cleanup() {
			if (!window.location.pathname.includes('/fleetManagement')) onClose();
		};
	}, [onClose]);

	useEffect(() => {
		setIsAdmin(user.role && user.role?.match(/admin|Admin/g) ? true : false);
	}, [user.role]);

	useEffect(() => {
		if (save) {
			onEditUserSubmit(values);
			setEditable(false);
			setValues({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [save]);

	useEffect(() => {
		if (cancel) {
			setEditable(false);
			setValues({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cancel]);

	const onEditUserRole = () => {
		const currentRole = user.role && user.role?.match(/admin|Admin/g) ? true : false;

		if (currentRole !== isAdmin) {
			let topic, data;
			if (currentRole === false) {
				topic = `microservice/${orgId}/${encodedUser}/assignRoleToUsers/${orgType}`;
				topic = isAdmin ? topic + 'Admin' : topic + 'User';
				data = {
					usersIds: [user.username],
					roleId: orgType + (isAdmin ? 'Admin' : 'User'),
				};
			} else {
				topic = `microservice/${orgId}/${encodedUser}/unassignRoleFromUsers`;
				data = {
					usersIds: [user.username],
				};
			}

			publish(topic, {
				requestId: 'unassignFromRoleId',
				data,
			});
		}
	};

	const onEditUserSubmit = (data: any) => {
		const reviseEntities: Array<string> = [
			'userGroupsIds',
			// 'devicesIds',
			'deviceGroupsIds',
		];
		let userObj: Record<string, any> = {
			firstName: data.firstName,
			lastName: data.lastName,
			username: user.username,
			userGroupsIds: data.userGroupsIds,
			removedUserGroupsIds: [],
			deviceGroupsIds: data.deviceGroupsIds,
			removedDeviceGroupsIds: [],
		};

		reviseEntities.forEach(revisedEntity => {
			const removedProp =
				'removed' + revisedEntity.charAt(0).toUpperCase() + revisedEntity.slice(1);
			if (userObj[revisedEntity] === null || userObj[revisedEntity]?.length === 0) {
				userObj[removedProp] = user[revisedEntity] || [];
				delete userObj[revisedEntity];
			} else {
				if (userObj[revisedEntity]) {
					// add removed entities to removed prop and remove entities from updated data if it already exist in edited device.
					for (let i = 0; i < (user[revisedEntity] || []).length; i++) {
						const index = userObj[revisedEntity].findIndex(
							(e: any) => e.value === user[revisedEntity][i]
						);
						if (index >= 0) userObj[revisedEntity].splice(index, 1);
						else userObj[removedProp].push(user[revisedEntity][i]);
					}

					if (userObj[revisedEntity].length === 0) {
						delete userObj[revisedEntity];
					} else {
						let valueArr: Array<string> = [];
						userObj[revisedEntity].forEach((e: any) => {
							valueArr.push(e.value);
						});
						userObj[revisedEntity] = valueArr;
					}
					if (userObj[removedProp].length === 0) delete userObj[removedProp];
				}
			}
		});

		let tempRemovedUserGroupsIds: any[] = [];
		userObj.removedUserGroupsIds?.forEach((id: any) => {
			if (userGroups && userGroups[id]) {
				tempRemovedUserGroupsIds = [...tempRemovedUserGroupsIds, id];
			}
		});

		userObj.removedUserGroupsIds = tempRemovedUserGroupsIds;
		if (userObj.removedUserGroupsIds)
			userObj.removedUserGroupsIds.forEach((userGroup: Array<string>) => {
				publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
					requestId: 'updateUserGroupUsers',
					data: {
						userGroupId: userGroup,
						remove: [user.username],
					},
				});
			});

		if (userObj.userGroupsIds)
			userObj.userGroupsIds.forEach((userGroup: Array<string>) => {
				publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
					requestId: 'updateUserGroupUsers',
					data: {
						userGroupId: userGroup,
						add: [user.username],
					},
				});
			});

		let tempRemovedDeviceGroupsIds: any[] = [];
		userObj.removedDeviceGroupsIds?.forEach((id: any) => {
			if (deviceGroups && deviceGroups[id]) {
				tempRemovedDeviceGroupsIds = [...tempRemovedDeviceGroupsIds, id];
			}
		});
		userObj.removedDeviceGroupsIds = tempRemovedDeviceGroupsIds;

		if (
			(userObj.removedDeviceGroupsIds && userObj.removedDeviceGroupsIds.length > 0) ||
			(userObj.deviceGroupsIds && userObj.deviceGroupsIds.length > 0)
		) {
			publish(`microservice/${orgId}/${encodedUser}/updateUserDeviceGroups`, {
				requestId: 'updateUserDeviceGroups',
				data: {
					userId: user.username,
					remove: userObj.removedDeviceGroupsIds || [],
					add: userObj.deviceGroupsIds || [],
				},
			});
		}

		// for (const key in userObj) {
		// 	if (userObj[key] === undefined || userObj[key] === null) {
		// 		delete userObj[key];
		// 	}
		// 	if (
		// 		key === 'userGroupsIds' ||
		// 		key === 'removedUserGroupsIds' ||
		// 		key === 'deviceGroupsIds' ||
		// 		key === 'removedDeviceGroupsIds' ||
		// 		key === 'devicesIds' ||
		// 		key === 'removedUserDevices'
		// 	) {
		// 		delete userObj[key];
		// 	}
		// }
		if (
			(userObj.firstName !== user.firstName && userObj.firstName) ||
			(userObj.lastName !== user.lastName && userObj.lastName) ||
			userObj.username !== user.username
		)
			publish(`microservice/${orgId}/${encodedUser}/updateUserInfo`, {
				requestId: 'someId',
				data: {
					user: user,
					updates: userObj,
				},
			});
		setTimeout(() => {
			onEditUserRole();
		}, 250);
	};

	if (isEditable) {
		return (
			<UserFormEditable
				currentUser={user}
				setAdmin={(edit: boolean) => {
					setIsAdmin(edit);
				}}
				orgId={orgId}
				setValues={(value: any, key: string) => {
					const copy = values;
					copy[key] = value;
					setValues(copy);
				}}
			/>
		);
	} else {
		return <UserFormNonEditable user={user} orgId={orgId} />;
	}
};

export default injectIntl(isAuthenticated(UserForm, 'UserForm'));
