import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

export const FailedPositionIcon = () => (
	<Svg viewBox="0 0 25 20" style={{ height: 40, width: 'auto' }}>
		<Path
			d="M20 9.6C20 14.9019 10 24 10 24C10 24 -2.59836e-08 14.9019 -1.67389e-08 9.6C-7.49427e-09 4.29807 4.23703 7.38784e-09 10 1.74364e-08C15.763 2.74849e-08 20 4.29807 20 9.6Z"
			stroke="#B5221B"
			strokeWidth={1}
			fill="#B5221B"
		/>
	</Svg>
);

export const DisinfectedPositionIcon = () => (
	<Svg viewBox="0 0 25 20" style={{ height: 40, width: 'auto' }}>
		<Path
			d="M20 9.6C20 14.9019 10 24 10 24C10 24 -2.59836e-08 14.9019 -1.67389e-08 9.6C-7.49427e-09 4.29807 4.23703 7.38784e-09 10 1.74364e-08C15.763 2.74849e-08 20 4.29807 20 9.6Z"
			stroke="#22A2D9"
			strokeWidth={1}
			fill="#22A2D9"
		/>
	</Svg>
);

export const RemainingPositionIcon = () => (
	<Svg viewBox="0 0 25 20" style={{ height: 40, width: 'auto' }}>
		<Path
			d="M20 9.6C20 14.9019 10 24 10 24C10 24 -2.59836e-08 14.9019 -1.67389e-08 9.6C-7.49427e-09 4.29807 4.23703 7.38784e-09 10 1.74364e-08C15.763 2.74849e-08 20 4.29807 20 9.6Z"
			stroke="#22A2D9"
			strokeWidth={1}
			fill="#fff"
		/>
	</Svg>
);
