import { useState, useEffect, useCallback } from 'react';
import { mongodbClient } from './../../providers/mongodbClient';
import { useTypedSelector } from '../../reducers';
import _ from 'lodash';

let fetchedOnce = false;
let watchesCreated = false;

function useDisinfectionCount(mongoUser: any) {
	const [disinfectionCount, setDisinfectionCount] = useState<any>([]);
	const [disinfectionCountUpdate, setDisinfectionCountUpdate] = useState<any>({});

	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	useEffect(() => {
		if (!disinfectionCountUpdate) return;

		if (disinfectionCountUpdate.operationType == 'insert') {
			if (!disinfectionCountUpdate.fullDocument?.robotId) return;
			const robotId = disinfectionCountUpdate.fullDocument.robotId;
			if (!disinfectionCount[robotId]) {
				disinfectionCount[robotId] = [
					{
						count: 1,
						disinfectionIds: [disinfectionCountUpdate.fullDocument._id],
						_id: robotId,
					},
				];
				setDisinfectionCount({ ...disinfectionCount });
				return;
			}

			disinfectionCount[robotId][0].count += 1;
			disinfectionCount[robotId][0].disinfectionIds.push(
				disinfectionCountUpdate.fullDocument._id
			);
			setDisinfectionCount({ ...disinfectionCount });
			return;
		}

		// 'delete'
		if (!disinfectionCountUpdate.documentKey?._id) return;
		for (const key in disinfectionCount) {
			const disinfectionIds = disinfectionCount[key][0].disinfectionIds;
			for (const did of disinfectionIds) {
				if (did.toString() == disinfectionCountUpdate.documentKey._id.toString()) {
					disinfectionCount[key][0].count -= 1;
					const index = disinfectionIds.indexOf(did);
					disinfectionIds.splice(index, 1);
					setDisinfectionCount({ ...disinfectionCount });
					return;
				}
			}
		}
	}, [disinfectionCountUpdate]);

	const trackCountDisinfection = useCallback(async () => {
		if (watchesCreated) return;
		watchesCreated = true;

		try {
			const filter = {
				operationType: { $in: ['insert', 'delete'] },
			};
			const changeStream = mongodbClient.watchCollection(
				filter,
				mongoDbConfig.collections.disinfectionCollection
			);
			for await (const change of changeStream) {
				setDisinfectionCountUpdate(change);
			}
		} catch (error) {
			console.warn('Watch disinfection collection ERROR:', error);
			watchesCreated = false;
			trackCountDisinfection();
		}
	}, [mongoUser]);

	useEffect(() => {
		if (fetchedOnce && disinfectionCount.length == 0) {
			fetchedOnce = false;
		}
	}, [disinfectionCount, fetchedOnce]);

	useEffect(() => {
		if (fetchedOnce) return;
		if (!mongodbClient.user) return;
		fetchedOnce = true;
		const query = [
			{
				$match: { status: { $in: ['complete', 'incomplete'] } },
			},
			{
				$group: {
					_id: '$robotId',
					count: { $sum: 1 },
					disinfectionIds: {
						$addToSet: '$_id',
					},
				},
			},
		];
		mongodbClient
			.aggregateCollection(query, mongoDbConfig.collections.disinfectionCollection)
			.then((result: any) => {
				setDisinfectionCount(_.groupBy(result, '_id'));
				trackCountDisinfection();
			})
			.catch((err: any) => {
				console.error(`Failed to fetch disinfection collection count: ${err}`);
			});
	}, [mongoUser]);

	return disinfectionCount;
}

export { useDisinfectionCount };
