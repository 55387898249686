import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { fontFamilies } from './fontFamilies';
import { Industry } from '../../../muitheme/fonts';
import { muiTheme } from '../../../muitheme/theme';

export enum FontWeight {
	Light = 300,
	Normal = 400,
	Bold = 500,
	ExtraBold = 700,
}

export enum TextTransform {
	Uppercase = 'uppercase',
}

const customPalette = {
	blue: {
		brand: '#2677A7',
		primary: '#22A2D9',
		dark: '#118ABD',
	},
	green: {
		'400': '#1A933C',
		'600': '#1A9B37',
		'700p': '#07892B',
		'800': '#007821',
		'900': '#00590C',
	},
	greyscale: {
		'900': '#242525',
		'800': '#444444',
		'500': '#666666',
		'400': '#AAAAAA',
		'300': '#CCCCCC',
		'200': '#DDDDDD',
		'150': '#EEEEEE',
		'100': '#FAFAFA',
		'0': '#FFFFFF',
	},
	orange: {
		'800p': '#D43900',
		'900': '#BC2A00',
	},
	red: {
		'800p': '#B5221B',
		'900': '#A81B14',
	},
	transparent: {
		'80': 'rgba(255, 255, 255, 0.8)',
		'30': 'rgba(255, 255, 255, 0.3)',
		'20': 'rgba(255, 255, 255, 0.2)',
		'10': 'rgba(255, 255, 255, 0.1)',
		dark20: 'rgba(36, 37, 37, 0.2)',
	},
	yellow: {
		light: '#FFC400',
		dark: '#C0990F',
	},
	data: {
		blue: {
			100: '#003556',
			90: '#004B78',
			80: '#08629A',
			70: '#167DBC',
			60: '#2999DE',
			50: '#40B6FF',
			40: '#6BC7FF',
			30: '#96D7FF',
			20: '#C1E7FF',
			10: '#ECF8FF',
		},
	},
	OP_Grey: {
		90: '#242525',
		80: '#444444',
		50: '#666666',
		40: '#AAAAAA',
		30: '#CCCCCC',
		20: '#DDDDDD',
		15: '#EEEEEE',
		10: '#FAFAFA',
		0: '#FFFFFF',
	},
	battery: {
		red: '#A81B14',
		yellow: '#FFC400',
		green: 'rgba(7, 137, 43)',
		grey: '#AAAAAA',
	},
};

const customTypography = {
	h1: {
		fontFamily: fontFamilies.industry,
		textTransform: TextTransform.Uppercase,
		fontWeight: FontWeight.Bold,
		fontSize: '2rem',
		lineHeight: 1.2,
	},
	h2: {
		fontFamily: fontFamilies.industry,
		textTransform: TextTransform.Uppercase,
		fontWeight: FontWeight.Bold,
		fontSize: '1.75rem',
	},
	h3: {
		fontFamily: fontFamilies.industry,
		textTransform: TextTransform.Uppercase,
		fontWeight: FontWeight.Bold,
		fontSize: '1.5rem',
	},
	h4: {
		fontFamily: fontFamilies.industry,
		textTransform: TextTransform.Uppercase,
		fontWeight: FontWeight.Bold,
		fontSize: '1.125rem',
	},
	h5: {
		fontSize: '1.75rem',
	},
	h6: {
		fontSize: '1.125rem',
		fontWeight: FontWeight.Normal,
	},
	subtitle1: {
		fontSize: '1rem',
	},
	subtitle2: {
		fontSize: '1rem',
		fontWeight: 500,
	},
	body1: {
		fontSize: '1rem',
	},
	body2: {
		fontSize: '0.875rem',
	},
	button: {
		fontWeight: FontWeight.Normal,
	},
	button1: {
		fontSize: '1.125rem',
		textTransform: 'initial',
	},
	button2: {
		fontSize: '1rem',
		textTransform: 'initial',
	},
	button3: {
		fontSize: '1.25rem',
		textTransform: 'initial',
	},
	button4: {
		fontSize: '1.5rem',
		textTransform: 'initial',
	},
	overline: {
		fontSize: '0.9375rem',
		fontWeight: FontWeight.Bold,
	},
};

export const lightTheme = createTheme({
	palette: {
		primary: muiTheme.palette.primary,
		secondary: muiTheme.palette.secondary,
		text: {
			primary: '#FFF',
			secondary: '#000',
		},
		warning: {
			main: customPalette.yellow.light,
			dark: customPalette.yellow.dark,
		},
		error: red,
		type: 'light',
		contrastThreshold: 3,
		tonalOffset: 0.2,
		...customPalette,
	},
	typography: {
		fontFamily: fontFamilies.roboto,
		fontSize: 16,
		...customTypography,
	},
	mixins: {
		toolbar: {
			minHeight: '64',
		},
	},
	spacing: factor => `${0.25 * factor}rem`,
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					userSelect: 'none',
				},
				'@font-face': [Industry.light, Industry.medium, Industry.bold],
			},
		},
	},
});

export default lightTheme;
