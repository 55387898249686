import { defineMessages } from 'react-intl';

export default defineMessages({
	filter: {
		id: 'filter',
		defaultMessage: 'Filter',
		description: 'Filter',
	},
	users: {
		id: 'Menu.UsersTitle',
		defaultMessage: 'Users',
		description: 'Users tab',
	},
	userGroups: {
		id: 'UsersPage.userGroups',
		defaultMessage: 'User groups',
		description: 'User groups tab',
	},
	robots: {
		id: 'Menu.RobotsTitle',
		defaultMessage: 'Robots',
		description: 'Robots tab',
	},
	deviceGroups: {
		id: 'InviteModal.deviceGroups',
		defaultMessage: 'Device groups',
		description: 'Device groups tab',
	},
	organizationInfo: {
		id: 'Organizations.info',
		defaultMessage: 'Organization info',
		description: 'Organization info',
	},
	disableDelOrgRobot: {
		id: 'Organizations.disableDeleteOrgBasedOnRobot',
		defaultMessage: 'Cannot delete an organization that has at least one robot in it',
		description: 'Cannot delete an organization that has at least one robot in it',
	},
	disableDelOrgUser: {
		id: 'Organizations.disableDeleteOrgBasedOnUserPartOf',
		defaultMessage: 'You cannot delete an organization which you are part of',
		description: 'You cannot delete an organization which you are part of',
	},
	disableDelBorOrg: {
		id: 'Organizations.disableBorOrg',
		defaultMessage: 'You cannot delete Blue Ocean Robotics',
		description: 'You cannot delete Blue Ocean Robotics',
	},
	disableDelBasedOnChildOrgRobot: {
		id: 'Organizations.disableDelBasedOnChildOrgRobot',
		defaultMessage:
			'Cannot delete an organization as child organization has at least one robot in it',
		description:
			'Cannot delete an organization as child organization has at least one robot in it',
	},
	all: {
		id: 'all',
		defaultMessage: 'All',
		description: 'All',
	},
	admins: {
		id: 'admins',
		defaultMessage: 'Admins',
		description: 'Admins',
	},
	notAdmins: {
		id: 'notAdmins',
		defaultMessage: 'Non admins',
		description: 'Non admins',
	},
	registrationIncomplete: {
		id: 'registrationIncomplete',
		defaultMessage: 'Registration incomplete',
		description: 'Registration incomplete',
	},
	other: {
		id: 'other',
		defaultMessage: 'Other',
		description: 'Other',
	},
	deleteTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Delete this {item}',
		description: 'Delete this {item}',
	},
	deleteOrgTitle: {
		id: 'deleteOrgTitle',
		defaultMessage: 'Are you sure you want to delete {item} organization?',
		description: 'Are you sure you want to delete {item} organization?',
	},
	deleteErrorTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Error deleting this {item}',
		description: 'Error deleting this {item}',
	},
	noMembers: {
		id: 'noMembers',
		defaultMessage: 'No Members',
		description: 'No Members',
	},
	noDeviceGroups: {
		id: 'noDeviceGroups',
		defaultMessage: 'No Robot Groups',
		description: 'No Robot Groups',
	},
	available: {
		id: 'available',
		defaultMessage: 'Available',
		description: 'Available',
	},
	unavailable: {
		id: 'unavailable',
		defaultMessage: 'Unavailable',
		description: 'Unavailable',
	},
	unassigned: {
		id: 'unassigned',
		defaultMessage: 'Unassigned',
		description: 'Unassigned',
	},
	decommissioned: {
		id: 'decommissioned',
		defaultMessage: 'Decommissioned',
		description: 'Decommissioned',
	},
});
