import React, { FC } from 'react';
import classes from './Robots.module.css';
import { IonLabel } from '@ionic/react';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import { UVDAvatar } from './UVDAvatar';
import styled from 'styled-components';
import EllipsisText from '../EllipsisText/EllipsisText';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
}));

const AvatarWrapper = styled('div')(({ theme }) => ({
	marginRight: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
interface RobotNameProps {
	robotId: string;
	orgId: string;
	selectRobots: boolean;
	isOffline?: boolean;
}

const RobotName: FC<RobotNameProps> = props => {
	const { robotId, orgId, selectRobots, isOffline } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'pictureLink', 'name'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	return (
		<Wrapper>
			{!selectRobots && (
				<AvatarWrapper>
					<UVDAvatar disabled={isOffline} />
				</AvatarWrapper>
			)}
			<IonLabel className={classes.nameLb}>
				<EllipsisText text={robot.name} />
			</IonLabel>
		</Wrapper>
	);
};

export default RobotName;
