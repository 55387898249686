import React from 'react';
import ReactDatePicker from 'react-datepicker';
import classes from '../Filter.module.css';
import { subDays } from 'date-fns/esm';
import moment from 'moment';
import { StyledDatePickerPopover, PopoverContent } from './styles';
require('react-datepicker/dist/react-datepicker.css');

type DatePickerProps = {
	open?: boolean;
	anchorEl?: any;
	onClose?: any;
	onChange?: any;
	startDate?: any;
	endDate?: any;
};

export const DatePickerPopover = ({
	open,
	anchorEl,
	onClose,
	startDate,
	endDate,
	onChange,
}: DatePickerProps) => {
	return (
		<StyledDatePickerPopover
			open={open || false}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
		>
			<PopoverContent>
				<ReactDatePicker
					selected={startDate}
					onChange={onChange}
					startDate={startDate}
					endDate={endDate}
					maxDate={moment().toDate()}
					dayClassName={date => {
						return date >= subDays(startDate, 1) && date <= endDate
							? classes.selectedDateRange
							: '';
					}}
					selectsRange
					inline
					showYearDropdown
				/>
			</PopoverContent>
		</StyledDatePickerPopover>
	);
};
