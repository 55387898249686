import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { mongodbClient } from '../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../reducers';
import { Charts } from '../charts.enum';
import EmptyChartComponent from '../components/EmptyChartComponent';

type InterruptionReasonsChartProps = {
	filters: any;
};

export default function InterruptionReasonsChart({ filters }: InterruptionReasonsChartProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [interruptionReasonsData, setInterruptionReasonsData] = useState<any>(null);

	useEffect(() => {
		const getInterruptionReasonsData = async () => {
			if (mongoUser) {
				const getInterruptionReasonsDataQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$group: {
							_id: {},
							walkDetection: {
								$sum: '$interruptions.walkDetection',
							},
							connectionClosed: {
								$sum: '$interruptions.connectionClosed',
							},
							tabletMovement: {
								$sum: '$interruptions.tabletMovement',
							},
							connectionUnstable: {
								$sum: '$interruptions.connectionUnstable',
							},
						},
					},
					{
						$project: {
							_id: 0,
							'Walk detected': '$walkDetection',
							'Connectivity loss': '$connectionClosed',
							'Tablet movement': '$tabletMovement',
							'Aborted by operator': '$connectionUnstable',
						},
					},
				];

				setInterruptionReasonsData(null);

				const interruptionReasonsDataResult = await mongodbClient.aggregateCollection(
					getInterruptionReasonsDataQuery,
					DISINFECTION_COLLECTION
				);

				setInterruptionReasonsData(interruptionReasonsDataResult[0] || []);
			}
		};
		getInterruptionReasonsData().catch(console.error);
	}, [mongoUser, filters]);

	const data = {
		labels: Object.keys(interruptionReasonsData || {}),
		datasets: [
			{
				label: 'Interruption reasons',
				data: Object.values(interruptionReasonsData || {}),
				borderColor: 'rgb(41, 153, 222)',
				backgroundColor: 'rgb(41, 153, 222)',
			},
		],
	};

	//A workaround for TS error: type 'string' is not assignable to type '"y" | "x" | undefined'.
	const y: 'y' = 'y';
	const options = {
		indexAxis: y,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				color: 'white',
				font: {
					size: 14,
					weight: 700,
				},
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	if (!interruptionReasonsData) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress />
			</div>
		);
	} else if (interruptionReasonsData.length == 0) {
		return <EmptyChartComponent chartId={Charts.interruptionReasonsBar} />;
	} else {
		return (
			<div>
				<Bar data={data} options={options} />
			</div>
		);
	}
}
