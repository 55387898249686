import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { construct } from 'ionicons/icons';
import { UVDMongoDevice } from '../../types/types';
import styled from 'styled-components';
import { Paper, Typography, Grid } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { fontFamilies } from '../../muitheme/fonts';
import { ReactComponentOrElement } from '@ionic/react/dist/types/hooks/useOverlay';
import _ from 'lodash';

const StyledPaper = styled(Paper)(({ theme }) => ({
	boxShadow: '0 2px 10px 0 rgb(0 0 0 / 4%)',
	overflow: 'hidden',
	padding: theme.spacing(2),
	width: 360,
}));

const TopWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	marginBottom: theme.spacing(1),
}));

const Header = styled(Typography)(({ theme }) => ({
	fontSize: '0.875rem',
	lineHeight: '1.25rem',
	fontFamily: fontFamilies.industryDemi,
}));

const ServiceIcon = styled(IonIcon)(({ theme }) => ({
	marginRight: theme.spacing(2),
	height: 16,
	width: 16,
}));

const ListItemText = styled(Typography)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '1rem',
}));

const SubLabel = styled(Typography)(({ theme }) => ({
	fontSize: '0.625rem',
	lineHeight: '0.75rem',
	color: theme.palette.OP_Grey[40],
}));

const Divider = styled('div')(({ theme }) => ({
	height: 1,
	width: '100%',
	backgroundColor: theme.palette.OP_Grey[15],
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(3),
}));

interface ListItemProps {
	label: ReactComponentOrElement;
	subLabel?: string;
	value: string;
}

const ListItem = ({ label, subLabel, value }: ListItemProps) => {
	return (
		<Grid container item spacing={2}>
			<Grid item xs={6}>
				<ListItemText>{label}</ListItemText>
				{subLabel ? <SubLabel>{subLabel}</SubLabel> : null}
			</Grid>
			<Grid item xs={6}>
				<ListItemText>{value}</ListItemText>
			</Grid>
		</Grid>
	);
};

interface RobotServiceInfoProps {
	robotId: string;
	orgId: string;
	device: UVDMongoDevice;
}

export const RobotServiceInfoUVD = (props: RobotServiceInfoProps) => {
	const { mongoDevice } = props.device;

	const appServiceGroup = useMemo(() => {
		const version = mongoDevice?.version;

		return [
			{
				id: 'uvd-app',
				label: <FormattedMessage id="uvd-app" defaultMessage="UVD Robots App" />,
				value: version?.seto,
			},
			{
				id: 'system-manager',
				label: <FormattedMessage id="system-manager" defaultMessage="System manager" />,
				value: version?.setoManager,
			},
			{
				id: 'language-pack',
				label: <FormattedMessage id="language-pack" defaultMessage="Language pack" />,
				value: version?.language,
			},
			{
				id: 'carrierboard-software',
				label: (
					<FormattedMessage
						id="carrierboard-software"
						defaultMessage="Carrierboard software"
					/>
				),
				value: version?.carrierboard,
			},
		];
	}, [mongoDevice]);

	const batteryServiceGroup = useMemo(() => {
		const batteries = mongoDevice?.version?.batteries;

		const serialNumbers = _.chain(batteries)
			.map(item => item.serialNumber)
			.uniq()
			.value();

		const batteryFamilies = _.chain(batteries)
			.map(item => item.family)
			.uniq()
			.value();

		const batteryVersions = _.chain(batteries)
			.map(item => item.version)
			.uniq()
			.value();

		return [
			{
				id: 'battery-serial-numbers',
				label: (
					<FormattedMessage
						id="battery-serial-numbers"
						defaultMessage="Battery Serial numbers"
					/>
				),
				value: serialNumbers.join(', '),
			},
			{
				id: 'battery-version',
				label: <FormattedMessage id="battery-version" defaultMessage="Battery version" />,
				value: batteryVersions.join(', '),
				subLabel: batteryVersions.length > 1 ? 'Technical Service needed' : undefined,
			},
			{
				id: 'battery-family',
				label: <FormattedMessage id="battery-family" defaultMessage="Battery family" />,
				value: batteryFamilies.join(', '),
				subLabel: batteryFamilies.length > 1 ? 'Technical Service needed' : undefined,
			},
			{
				id: 'charger-system',
				label: <FormattedMessage id="charger-system" defaultMessage="Charger system" />,
				value: mongoDevice?.charger,
			},
		];
	}, [mongoDevice]);

	const hardwareServiceGroup = useMemo(() => {
		const version = mongoDevice?.version;

		return [
			{
				id: 'plc-version',
				label: <FormattedMessage id="plc-version" defaultMessage="PLC version" />,
				value: version?.plc?.number,
				subLabel: version?.plc?.hasNewAvailable && 'Technical Service needed',
			},
			{
				id: 'plc-model',
				label: <FormattedMessage id="plc-model" defaultMessage="PLC model" />,
				value: version?.plc?.model,
				subLabel: version?.plc?.hasNewAvailable && 'Technical Service needed',
			},
			{
				id: 'hardware',
				label: <FormattedMessage id="hardware" defaultMessage="Hardware" />,
				value: version?.hardware,
			},
			{
				id: 'motor-controller-firmware',
				label: (
					<FormattedMessage
						id="motor-controller-firmware"
						defaultMessage="Motor Controller Firmware"
					/>
				),
				value: version?.motorController,
			},
			{
				id: 'pir-firmware',
				label: <FormattedMessage id="pir-firmware" defaultMessage="PIR Firmware" />,
				value: version?.pir,
			},
		];
	}, [mongoDevice]);

	return (
		<StyledPaper>
			<TopWrapper>
				<ServiceIcon icon={construct} color="primary" />
				<Header color="primary">
					<FormattedMessage id="service" defaultMessage="Service" />
				</Header>
			</TopWrapper>
			<Grid container spacing={2}>
				{appServiceGroup.map(item => (
					<ListItem key={item.id} {...item} />
				))}
				<Divider />
				{batteryServiceGroup.map(item => (
					<ListItem key={item.id} {...item} />
				))}
				<Divider />
				{hardwareServiceGroup.map(item => (
					<ListItem key={item.id} {...item} />
				))}
			</Grid>
		</StyledPaper>
	);
};
