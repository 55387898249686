import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { BatteryIcon } from './BatteryIcon';
import { BatteryChargingIcon } from './BatteryChargingIcon';
import { BatteryPluggedInIcon } from './BatteryPluggedInIcon';

interface Props {
	batteryPercent: number;
	charging: boolean;
	online?: boolean;
	isOffline?: boolean;
	pluggedIn?: boolean;
}

export const Battery = ({ batteryPercent, charging, pluggedIn, isOffline }: Props) => {
	const theme = useTheme();
	const batteryPercentage = charging ? 19 : (batteryPercent / 100) * 19;

	const batteryColor = useMemo(() => {
		if (isOffline) {
			return theme.palette.battery.grey;
		}
		if (batteryPercent <= 25) {
			if (batteryPercent <= 10) {
				return theme.palette.battery.red;
			} else {
				return theme.palette.battery.yellow;
			}
		} else {
			return theme.palette.battery.green;
		}
	}, [batteryPercent, isOffline]);

	if (charging) {
		return <BatteryChargingIcon color={batteryColor} isOffline={isOffline} />;
	}

	if (pluggedIn) {
		return <BatteryPluggedInIcon color={batteryColor} isOffline={isOffline} />;
	}

	return <BatteryIcon color={batteryColor} width={batteryPercentage} />;
};
