import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Fade } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			position: 'fixed',
			overflow: 'auto',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		outer: {
			position: 'absolute',
			minHeight: '100vh',
			top: 0,
			left: 0,
			right: 0,
			margin: '0 auto',
			display: 'flex',
			alignItems: 'center',
			padding: '2rem',
			width: '100%',
			pointerEvents: 'none',
		},
		paper: {
			backgroundColor: 'white',
			borderRadius: 10,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 0, 3),
			position: 'relative',
			maxWidth: 613,
			margin: '0 auto',
			width: '100%',
			pointerEvents: 'auto',
		},
	})
);

interface IBasicModal {
	children: any;
	onClose: Function;
	open: boolean;
	className?: string;
}

export default function BasicModal(props: IBasicModal) {
	const { children, onClose, className } = props;
	let { open } = props;

	const classes = useStyles();

	const handleClose = () => {
		const closeResponse: boolean | undefined = onClose();
		if (closeResponse == undefined || closeResponse != false) {
			open = false;
		}
	};

	const classNames = [classes.paper, className];

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.outer}>
					<div className={classNames.join(' ')}>{children}</div>
				</div>
			</Fade>
		</Modal>
	);
}
