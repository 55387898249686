import React, { FC, useState, Fragment, useEffect, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import classes from './Robots.module.css';
import Messages from './Robots.messages';
import RobotEditUVD from '../../components/RobotEdit/RobotEditUVD';
import { AppRootState, useTypedSelector } from '../../reducers';
import RobotsOptions from './RobotsOptions';
import RobotsList from './RobotsList';
import { equalityFnc, getDevices } from '../../utils/conformState';
import { Device } from '../../types/types';
import { store } from '../../store/store';
import { mongodbClient } from './../../providers/mongodbClient';
import _, { isEmpty } from 'lodash';
import { getRobotStatus } from '../../utils/robotStatusConverter';
import getUnixTime from 'date-fns/getUnixTime';

interface RobotsProps {
	filterValues: any;
	orgId: string;
	editableRobotId?: any;
	history: any;
	connectionStatus: any;
	disinfectionCount: any;
}

const Robots: FC<RobotsProps> = (props: any) => {
	const { intl, organization, editableRobotId, history, filterValues, connectionStatus, disinfectionCount } = props;

	const orgId = organization.orgId;
	const organizationName = organization.name;
	const selectedOrganizationType = organization.orgType;
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId,
		(left, right) => equalityFnc(left, right)
	);
	const devices = useTypedSelector(
		state => getDevices(state, orgId),
		(left, right) => equalityFnc(left, right)
	);

	const sortParams = useTypedSelector(
		state => state.deviceState.sortParams,
		(left, right) => equalityFnc(left, right)
	);

	const [isEditing, setIsEditing] = useState(false);
	const [isAddNewRobotShow, setAddNewRobotShow] = useState(false);
	const [devicesToShow, setDevicesToShow] = useState<any>([]);
	const [deviceUpdate, setDeviceUpdate] = useState<any>({});
	const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(undefined);
	const [searchText, setSearchText] = useState('');
	const [deviceGroupNames, setDeviceGroupNames] = useState<string[]>([]);
	const [filterValue, setFilterValue] = useState<string>('all');
	const [disinfectionLoader, setDisinfectionLoader] = useState(false);
	const [selectRobots, setSelectRobots] = useState(false);
	const [selectedRobots, updateSelectedRobots] = useState<any[]>([]);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const mongoDevices = useTypedSelector(state => state.deviceState.mongoDevices);

	useEffect(() => {
		let isLoaderEmpty = isEmpty(disinfectionCount);
		setDisinfectionLoader(isLoaderEmpty);
	}, [disinfectionCount, disinfectionLoader]);

	const headers =
		spinoutType !== 'uvd'
			? [
					{
						title: Messages.name,
						property: 'name',
						checkbox: selectRobots,
						onUnCheck: () => {
							setSelectRobots(false);
							unCheckAll();
						},
						selectedNumber: selectedRobots.length,
					},
					{ title: Messages.group, property: 'deviceGroupName' },
					{ title: Messages.location, property: 'location' },
					{ title: Messages.status, property: 'status' },
					{ title: Messages.charge, property: 'level' },
					{ title: Messages.serialNumber, property: 'deviceId' },
			  ]
			: [
					{
						title: Messages.name,
						property: 'robotName',
						checkbox: selectRobots,
						onUnCheck: () => {
							setSelectRobots(false);
							unCheckAll();
						},
						selectedNumber: selectedRobots.length,
					},
					{ title: Messages.status, property: 'uvdstatus' },
					{ title: Messages.battery, property: 'battery' },
					{
						title: Messages.disinfections,
						property: 'disinfectionCount',
						rightAligned: true,
						style: {
							maxWidth: 170,
							display: 'flex',
							justifyContent: 'flex-end',
						},
					},
					{ title: Messages.lastSeen, property: 'lastSeen' },
			  ];

	const handleOnCheck = (robot: any, bool: boolean) => {
		if (bool) {
			let obj = selectedRobots.find(o => o.deviceId === robot.deviceId);
			if (!obj) updateSelectedRobots(selectedRobots.concat(robot));
		} else {
			updateSelectedRobots(
				selectedRobots.filter((item: any) => item.deviceId !== robot.deviceId)
			);
		}
	};
	const checkAll = (data: any) => {
		updateSelectedRobots(data);
	};
	const unCheckAll = () => {
		updateSelectedRobots([]);
	};

	useEffect(() => {
		if (!editableRobotId) return;
		setSelectedDeviceId(editableRobotId);
		setIsEditing(true);
	}, [editableRobotId]);

	const getDeviceGroupName = useCallback(
		device => {
			if (device.deviceGroupsIds) {
				if (deviceGroups != null && deviceGroups[orgId] != null) {
					for (const key in deviceGroups[orgId]) {
						if (deviceGroups[orgId][key]?.deviceGroupId === device.deviceGroupsIds[0])
							return deviceGroups[orgId][key].name;
					}
				}
			} else {
				return intl.formatMessage({ id: 'unassigned' });
			}
		},
		[orgId, deviceGroups, intl]
	);

	const getIsCharging = useCallback((item: any) => {
		if (!item?.mongoDevice) return true;

		const status = item.mongoDevice.status.state;

		if (
			status === 'start_charging' ||
			status === 'charging' ||
			status === 'charged' ||
			status === 'fully_charged'
		) {
			return true;
		}
		return false;
	}, []);

	const robotListData = useMemo(() => {
		const filteringArr = devicesToShow.map((device: any) => {
			return {
				...device,
				isOffline: connectionStatus && connectionStatus[device.deviceId]
					?  !connectionStatus[device.deviceId].online
					: true,
				uvdstatus: device?.mongoDevice
					? 'Offline'
					: device?.status?.job
					? getRobotStatus(device?.status?.job)
					: null,
				battery: device.mongoDevice
					? Math.floor(device.mongoDevice?.status?.batteryMergerPercentage || 0)
					: null,
				lastSeen: connectionStatus && connectionStatus[device.deviceId]
					? connectionStatus[device.deviceId].lastSeen
					: null,
				robotName: device.name.toLowerCase(),
				disinfectionCount: disinfectionCount
					? device?.mongoDevice &&
					  disinfectionCount[device.deviceId]?.length > 0 &&
					  disinfectionCount[device.deviceId][0].count
					: 0,
			};
		});

		let orderedList = _.orderBy(filteringArr, [sortParams.key], [sortParams.order]);

		if (searchText !== '') {
			orderedList = orderedList.filter((r: any) => {
				return (
					r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
					r.deviceId.indexOf(searchText.toLowerCase()) > -1
				);
			});
		}

		return orderedList.map((item: any) => ({
			...item,
			isCharging: getIsCharging(item),
			mongoDevice: item?.mongoDevice ? item.mongoDevice : null,
			deviceGroupName: getDeviceGroupName(item),
		}));
	}, [devicesToShow, sortParams, searchText, connectionStatus, disinfectionCount]);

	useEffect(() => {
		if (!deviceGroups[orgId]) return;

		let names: any = Object.values(deviceGroups[orgId]).map(
			(deviceGroup: any) => deviceGroup.name
		);

		setDeviceGroupNames(names);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceGroups, filterValues, orgId]);

	useEffect(() => {
		if (filterValue === undefined) return;
		let all: any = devices || [];

		// DO NOT REMOVE THIS! robot was passed by ref and not value
		all = JSON.parse(JSON.stringify(all));

		if (spinoutType === 'uvd') {
			all = all.map((device: any) => {
				return { ...device, mongoDevice: mongoDevices[device.deviceId] };
			});
		}

		const devicesInfo = JSON.parse(
			JSON.stringify(
				(store.getState() as AppRootState).deviceState.devicesByOrganizationId[orgId] || {}
			)
		);
		const deviceIdKeys = Object.keys(devicesInfo);

		switch (filterValue) {
			case 'all':
				setDevicesToShow(all);
				break;
			case 'unassigned':
				let unassigned: any = all.filter((item: Device) => {
					if (!deviceIdKeys.includes(item.deviceId)) return null;
					if (!devicesInfo[item.deviceId].deviceGroupsIds?.length) return item;
					return null;
				});
				setDevicesToShow(unassigned);
				break;
			case 'decommissioned':
				let decommissioned: any = all.filter((item: Device) => {
					if (!deviceIdKeys.includes(item.deviceId)) return null;
					if (devicesInfo[item.deviceId].decommissioned) return item;
					return null;
				});
				setDevicesToShow(decommissioned);
				break;
			default:
				if (deviceGroupNames.findIndex((name: any) => name === filterValue) > -1) {
					let byGroup: any = all.filter((item: any) => {
						if (!deviceIdKeys.includes(item.deviceId)) return null;
						const deviceGroupId = devicesInfo[item.deviceId].deviceGroupsIds
							? devicesInfo[item.deviceId].deviceGroupsIds[0]
							: null;
						if (
							deviceGroups[orgId][deviceGroupId] &&
							deviceGroups[orgId][deviceGroupId].name === filterValue
						)
							return item;
						return null;
					});
					setDevicesToShow(byGroup);
				}
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterValue, mongoDevices, devices]);

	useEffect(() => {
		if (history.location.data?.editableRobot) {
			const robot = history.location.data.editableRobot;
			setSelectedDeviceId(JSON.parse(JSON.stringify(robot)).deviceId);
			history.location.data.editableRobot = undefined;
		}
	}, [history.location.data]);

	useEffect(() => {
		if (selectedOrganizationType === 'bor') {
			setAddNewRobotShow(true);
		}
	}, [selectedOrganizationType]);

	useEffect(() => {
		switch (deviceUpdate.operationType) {
			case 'insert': {
				break;
			}
			case 'update': {
				if (devicesToShow.length === 0) return;

				const newArr = devicesToShow.map((device: any) => {
					if (device.deviceId === deviceUpdate.fullDocument.robotId) {
						return {
							...device,
							mongoDevice: deviceUpdate.fullDocument,
						};
					} else {
						return device;
					}
				});

				setDevicesToShow(newArr);
				break;
			}
			case 'replace': {
				break;
			}
			case 'delete': {
				break;
			}
			default: {
				break;
			}
		}
	}, [deviceUpdate]);

	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	const { mongoUser } = props;

	const trackRobotInfo = useCallback(async () => {
		const mongo = mongoUser.mongoClient(
			store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
		);
		const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
		const dbCollection = db.collection(mongoDbConfig.collections.robotCollection);

		try {
			let changeStream = dbCollection.watch();
			for await (const change of changeStream) {
				setDeviceUpdate(change);
			}
		} catch (error) {
			console.warn('Watch robotInfo collection ERROR:', error);
			trackRobotInfo();
		}
	}, [mongoUser, devicesToShow]);

	useEffect(() => {
		if (spinoutType !== 'uvd') return;

		if (mongoUser) {
			mongodbClient
				.readCollection({}, mongoDbConfig.collections.robotCollection)
				.then((result: any) => {
					trackRobotInfo();
				})
				.catch((err: any) => {
					console.error(`Failed to find collection (Robots): ${err}`);
				});
		}
	}, [mongoUser]);

	return !isEditing ? (
		<Fragment>
			<div className={classes.leftSide}>
				<RobotsOptions
					showTransferRobot={false} // Disable robot transfer menu option to switch off multi-robot transfer feature
					organizationName={organizationName}
					selectedRobots={selectedRobots}
					updateSelectedRobots={updateSelectedRobots}
					selectRobots={selectRobots}
					setSelectRobots={setSelectRobots}
					isAddNewRobotShow={isAddNewRobotShow}
					deviceToShow={robotListData}
					searchText={searchText}
					setSearchText={setSearchText}
					filterValues={filterValues}
					setFilterValue={setFilterValue}
					headers={headers}
					handleOnCheck={selectRobots && handleOnCheck}
					unCheckAll={unCheckAll}
				/>
			</div>
			<div className={classes.rightSide}>
				<RobotsList
					robotHeaders={headers}
					setSelectedDeviceId={setSelectedDeviceId}
					setIsEditing={setIsEditing}
					data={robotListData}
					selectRobots={selectRobots}
					selectedRobots={selectedRobots}
					disinfectionLoader={disinfectionLoader}
					onUnCheck={() => {
						setSelectRobots(false);
						unCheckAll();
					}}
					checkAll={checkAll}
					unCheckAll={unCheckAll}
					handleOnCheck={selectRobots && handleOnCheck}
					check
				/>
			</div>
		</Fragment>
	) : (
		<RobotEditUVD
			disinfectionLoader={disinfectionLoader}
			device={robotListData.find(item => item.deviceId === selectedDeviceId)}
			onClose={() => setIsEditing(false)}
			orgId={orgId}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.selectedOrganizationState.organization,
	mongoUser: state.accountState.user.mongoUser,
	mongoDevices: state.deviceState.mongoDevices,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(Robots), 'Robots'));
