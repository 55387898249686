import styled from 'styled-components';

export const SingleValueChartWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin: 0;
		padding: 0;
	}
`;
