import React, { FC, useState } from 'react';
import {
	IonLabel,
	IonCard,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonIcon,
	IonAvatar,
	IonButton,
	IonFabButton,
	IonFab,
	IonGrid,
	IonRow,
	IonRouterLink,
	IonCol,
} from '@ionic/react';
import { closeCircle, trash, camera, close, openOutline, timeOutline } from 'ionicons/icons';
import Tooltip from 'react-tooltip-lite';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './InfoCard.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './InfoCard.messages';
import userAvatar from '../../assets/images/userAvatar.svg';

interface InfoCardProps {
	imgUrl?: string;
	noImg?: any;
	title: string;
	subTitle?: any;
	email: string;
	noteContent?: React.ReactNode;
	entityType?: string;
	onClose: () => void;
	onEdit?: (e: any) => void;
	onEditPic?: (e: any) => void;
	onDelete?: (e: any) => void;
	avatarClass?: any;
	disinfections?: number;
	lastSeen?: Date;
	deviceId?: string;
}

const InfoCard: FC<InfoCardProps> = props => {
	const { disinfections, lastSeen, deviceId } = props;
	const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

	const onCancelDelete = () => {
		setShowDeleteOverlay(false);
	};
	const onConfirmedDelete = () => {
		setShowDeleteOverlay(false);
		if (props.onDelete) {
			props.onDelete(props.email);
		}
	};

	function getLastSeenInDays(lastSeen: Date) {
		var today = new Date().getTime();
		var diffInMs = Math.abs(today - lastSeen.getTime());
		return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	}

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	return (
		<div className={classes.infoContainer}>
			<IonCard className={classes.dataCard}>
				<IonCardContent>
					<IonItem lines="none" className={classes.dataCardContent}>
						<div className={classes.profileContainer}>
							<IonAvatar
								className={
									props.avatarClass ? props.avatarClass : classes.userAvatar
								}
							>
								<IonIcon
									className={classNames(classes.profilePic, classes.noPic)}
									size="large"
									icon={props.noImg ? props.noImg : userAvatar}
								/>
							</IonAvatar>
							<IonLabel className={classes.titleLb}>
								<IonCardTitle>
									<div className={classes.textWrap}>{props.title}</div>
								</IonCardTitle>
								{props.subTitle}
								<div className={classes.noteContainer}>{props.noteContent}</div>
							</IonLabel>
						</div>
						{props.onDelete ? (
							<IonFab
								vertical="bottom"
								horizontal="end"
								className={classes.deleteFab}
							>
								<Tooltip
									direction="down"
									content={<FormattedMessage {...Messages.deleteTitle} />}
								>
									<IonFabButton
										size="small"
										onClick={() => setShowDeleteOverlay(true)}
									>
										<IonIcon icon={trash} />
									</IonFabButton>
								</Tooltip>
							</IonFab>
						) : props.onEdit ? (
							<IonFab vertical="bottom" horizontal="end" className={classes.editFab}>
								<IonFabButton size="small" onClick={props.onEdit}>
									<IonIcon
										className={classes.fabIcon}
										src="./assets/icons/edit.svg"
									/>
								</IonFabButton>
							</IonFab>
						) : (
							''
						)}
					</IonItem>
					{spinoutType === 'uvd' && props.entityType === 'robot' ? (
						<IonRow>
							<IonCol>
								<IonLabel>
									{<FormattedMessage {...Messages.disinfections} />}:
									<IonRouterLink
										href={'/disinfection-dashboard/robotId=' + deviceId}
										className={classes.disinfectionsLink}
									>
										{disinfections ? disinfections : 0}{' '}
										<IonIcon
											icon={openOutline}
											className={classes.disinfectionRedirectIcon}
										></IonIcon>
									</IonRouterLink>
								</IonLabel>
							</IonCol>
							<IonCol className={classes.alignRight}>
								<IonLabel>
									<IonIcon
										size="small"
										icon={timeOutline}
										className={classes.lastUsedIcon}
									/>
									{getLastSeenInDays(lastSeen ? lastSeen : new Date())} days ago
								</IonLabel>
							</IonCol>
						</IonRow>
					) : (
						<></>
					)}
				</IonCardContent>
			</IonCard>
			{showDeleteOverlay ? (
				<IonGrid className={classes.deleteOverlay}>
					<IonRow>
						{/* <IonFab horizontal="center">
							<IonFabButton
								size="small"
								className={classes.closeFabBtn}
								onClick={() => setShowDeleteOverlay(false)}
							>
								<IonIcon size="small" icon={close} />
							</IonFabButton>
						</IonFab> */}
						<IonLabel className={classes.confirmLb}>
							<FormattedMessage {...Messages.confirm} />
						</IonLabel>
					</IonRow>
					<IonRow className={classes.actionContainer}>
						<IonButton
							className={classes.cancelBtn}
							shape="round"
							onClick={onCancelDelete}
						>
							<FormattedMessage {...Messages.cancel} />
						</IonButton>
						<IonButton
							className={classes.deleteBtn}
							shape="round"
							onClick={onConfirmedDelete}
						>
							<FormattedMessage {...Messages.delete} />
						</IonButton>
					</IonRow>
				</IonGrid>
			) : null}
		</div>
	);
};

export default injectIntl(isAuthenticated(InfoCard, 'InfoCard'));
