import React from 'react';
import { getRobotStatus } from '../../../utils/robotStatusConverter';
import { UVDPulseIcon } from './icons/UVDPulseIcon';
import { radioButtonOnOutline } from 'ionicons/icons';
import { UVDBattery } from '../../Robots/UVDBattery';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import {
	StyledPaper,
	Header,
	NameWrapper,
	RobotName,
	StyledIonIcon,
	StatusWrapper,
	StatusText,
	FooterText,
	FooterWrapper,
} from './styles';

interface Props {
	device: any;
}

const getInternetConnectionString = (internetPreference: string) => {
	if (internetPreference === 'wifi') {
		return <FormattedMessage id="wifi" defaultMessage="WiFi" />;
	} else if (internetPreference === 'data') {
		return <FormattedMessage id="mobile-data" defaultMessage="Mobile data" />;
	}
	return null;
};

export const UVDRobotCard = ({ device }: Props) => {
	const robotStatus = device?.mongoDevice?.status?.job
		? getRobotStatus(device?.mongoDevice?.status?.job)
		: null;

	const lastSeen = device?.lastTimeActive
		? format(new Date(device.lastTimeActive), 'PP')
		: 'Not available';

	const internetConnection = device?.mongoDevice?.internetPreference
		? getInternetConnectionString(device?.mongoDevice?.internetPreference)
		: null;

	return (
		<StyledPaper isOffline={device.isOffline}>
			<Header>
				<NameWrapper>
					<UVDPulseIcon showPulse={false} isOffline={device.isOffline} />
					<RobotName variant="body2">{device.name}</RobotName>
				</NameWrapper>
				<StyledIonIcon isOffline={device.isOffline} icon={radioButtonOnOutline} />
			</Header>
			{device?.mongoDevice ? (
				<StatusWrapper>
					<StatusText variant="body1">
						{device.isOffline ? (
							<FormattedMessage id="offline" defaultMessage="Offline" />
						) : (
							robotStatus
						)}
					</StatusText>
					<UVDBattery mongoDevice={device?.mongoDevice} isOffline={device.isOffline} />
				</StatusWrapper>
			) : null}
			<FooterWrapper>
				<FooterText></FooterText>
				{!device.isOffline ? <FooterText>{internetConnection}</FooterText> : null}
				{device.isOffline ? (
					<FooterText>
						<FormattedMessage id="lastSeen" defaultMessage="Last seen" />: {lastSeen}
						{internetConnection ? ' | ' : null}
						{internetConnection ? internetConnection : ''}
					</FooterText>
				) : null}
			</FooterWrapper>
		</StyledPaper>
	);
};
