import styled from 'styled-components';
import { Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
require('react-datepicker/dist/react-datepicker.css');

export const PopoverContent = styled.div`
	padding: 8px;
	min-width: 200px;
`;

export const SecondPopoverContent = styled(PopoverContent)`
	min-height: 300px;
`;

export const StyledPopover = withStyles({
	root: {
		marginTop: 4,
		'& .MuiTypography-body1': {
			fontSize: 15,
		},
		'& .MuiPopover-paper': {
			borderRadius: 10,
			boxShadow: '0px 2px 10px #00000029',
		},
	},
})(Popover);

export const StyledDatePickerPopover = withStyles({
	root: {
		'& .MuiTypography-body1': {
			fontSize: 15,
		},
		'& .MuiPopover-paper': {
			borderRadius: 10,
			boxShadow: '0px 2px 10px #00000029',
		},
		'& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),		.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),		.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),		.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)': {
			backgroundColor: '#22a2d9',
			opacity: '0.75',
			borderRadius: '50%',
		},
		'& .react-datepicker__day--selected:hover': {
			backgroundColor: '#22a2d9',
			borderRadius: '50%',
		},
		'& .react-datepicker__day--selected': {
			backgroundColor: '#22a2d9',
			borderRadius: '50%',
		},
		'& .react-datepicker__day:hover': {
			backgroundColor: '##f0f0f0',
			borderRadius: '50%',
		},
	},
})(Popover);
