import { MONGODB_CONFIG, RELOAD_DISINFECTION_COLLECTION } from '../actions/types';

const Initial_State = {
	reloadDisinfectionCollection: false,
	mongoDbConfig: {
		realmAppId: '',
		database: '',
		disinfectionCollection: '',
		mongoClient: '',
		collections: {
			disinfectionCollection: '',
			robotConnectionStatusCollection: '',
			robotCollection: '',
			mapCollection: '',
			fileCollection: '',
		},
	},
};

export default (state = Initial_State, action: any) => {
	switch (action.type) {
		case MONGODB_CONFIG:
			return {
				...state,
				mongoDbConfig: action.payload,
			};
		case RELOAD_DISINFECTION_COLLECTION:
			return {
				...state,
				reloadDisinfectionCollection: action.payload.reloadDisinfectionCollection,
			};

		default:
			return state;
	}
};
