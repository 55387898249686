import React, { FC, Fragment, useState, useEffect } from 'react';
import { IonLabel, IonIcon, IonList, IonListHeader } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import InfoCard from '../InfoCard/InfoCard';
import UsersGrid from '../UsersGrid/UsersGrid';
import EditImageModal from '../EditImageModal/EditImageModal';
import AddItemsModal from '../CreateAddItemsModal/CreateAddItemsModal';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';

import classes from './UserGroups.module.css';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './UserGroups.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { styled } from '@material-ui/styles';
import { Divider, MenuItem } from '@material-ui/core';
import userGroupAvatar from '../../assets/images/userGroupAvatar.svg';
import UserMoveGroup from '../UserMoveGroup/UserMoveGroup';
import { BackToListNavButton } from '../RobotEdit/BackToListNavButton';
interface UserGroupEditProps {
	userGroup: any;
	onClose: () => void;
	onEditOpen: () => void;
}

const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));

const UserGroupEdit: FC<UserGroupEditProps> = (props: any) => {
	const { users, userGroups, userGroup, onClose, history } = props;
	const { usersByOrganizationId, addToGroupOpen } = users;
	const { userGroupsByOrganizationId } = userGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const [showMoveToGroupModal, setMoveToGroupModal] = useState(false);
	const [selectMoveUser, setSelectMoveUser] = useState<any>(null);
	const [editPicOpen, setEditPicOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(userGroup);
	const [groupMembers, setGroupMembers] = useState([]);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [showAddToGroupModal, setAddToGroupModal] = useState(false);
	const [availableGroups, setAvailableGroups] = useState<any[]>([]);

	useEffect(() => {
		if (!userGroup) return;
		setSelectedGroup(userGroupsByOrganizationId[userGroup.orgId][userGroup.userGroupId]);
	}, [userGroup, userGroupsByOrganizationId]);

	useEffect(() => {
		if (!selectedGroup || !selectedGroup.usersIds || !selectedGroup.orgId) return;
		const usersByOrg = usersByOrganizationId[selectedGroup.orgId]
			? Object.values(usersByOrganizationId[selectedGroup.orgId])
			: [];
		const members: any = usersByOrg.filter(
			(u: any) => selectedGroup.usersIds.findIndex((id: string) => id === u.username) > -1
		);
		setGroupMembers(members);
	}, [selectedGroup, usersByOrganizationId]);

	useEffect(() => {
		if (addToGroupOpen) {
			if (!selectedUser) return;
			setAvailableGroups(
				userGroupsByOrganizationId[selectedGroup.orgId]
					? !selectedUser.userGroupsIds
						? Object.values(userGroupsByOrganizationId[selectedGroup.orgId])
						: Object.values(userGroupsByOrganizationId[selectedGroup.orgId]).filter(
								(gr: any) =>
									selectedUser.userGroupsIds.findIndex(
										(id: string) => id === gr.userGroupId
									) < 0
						  )
					: []
			);
			setAddToGroupModal(true);
		} else {
			setAddToGroupModal(false);
		}
	}, [addToGroupOpen, selectedGroup, selectedUser, userGroupsByOrganizationId]);

	const onEditUser = async (user: any) => {
		history.push({
			pathname: '/fleetManagement',
			data: { tab: 'users', orgId: user.orgId, editableUser: user },
		});
	};
	const openAddToGroup = (user: any) => {
		props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', true);
		setSelectedUser(user);
	};
	const openMoveToGroup = (user: any) => {
		setSelectMoveUser(user);
		setMoveToGroupModal(true);
	};
	const onAddToGroup = (selectedItems: any) => {
		selectedItems.forEach((item: any) => {
			publish(`microservice/${selectedGroup.orgId}/${encodedUser}/updateUserGroupUsers`, {
				data: {
					userGroupId: item.userGroupId,
					add: [selectedUser.username],
				},
				requestId: 'updateUserGroup',
			});
		});
		if (selectedItems.length > 0) {
			props.setParameter('addUsersCount', 'USERS_ADDED_TO_GROUP_STATE', selectedItems.length);
		}
		props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', false);
	};

	const onRemoveUser = (username: string) => {
		publish(`microservice/${selectedGroup.orgId}/${encodedUser}/updateUserGroupUsers`, {
			data: {
				userGroupId: selectedGroup.userGroupId,
				remove: [username],
			},
			requestId: 'updateUserGroup',
		});
	};
	const onSavePicture = (link: any) => {
		publish(`microservice/${userGroup.orgId}/${encodedUser}/updateUserGroupInfo`, {
			data: {
				userGroupId: userGroup.userGroupId,
				pictureLink: link,
			},
			requestId: 'updateUserGroup',
		});
		setEditPicOpen(false);
	};
	return (
		<Fragment>
			<div className={classes.editLeftSide}>
				<BackToListNavButton
					onClick={onClose}
					label={
						<FormattedMessage id="all-user-group" defaultMessage="All User Groups" />
					}
				/>
				<InfoCard
					imgUrl={selectedGroup.pictureLink}
					noImg={userGroupAvatar}
					title={selectedGroup.name}
					subTitle={
						selectedGroup.usersIds && selectedGroup.usersIds.length > 0 ? (
							<FormattedMessage
								{...Messages.memberCount}
								values={{
									count: selectedGroup.usersIds.length,
								}}
							/>
						) : null
					}
					email={selectedGroup.name}
					onClose={onClose}
					onEdit={props.onEditOpen}
					onEditPic={() => setEditPicOpen(true)}
				/>
			</div>
			<div className={classes.rightSide}>
				<UsersGrid
					data={groupMembers}
					moreContent={(item: any, onClosePopover: any) => {
						return (
							<>
								<StyledMenuItem onClick={() => onEditUser(item)}>
									<FormattedMessage {...Messages.editUser} />
								</StyledMenuItem>
								<Divider />
								<StyledMenuItem
									onClick={() => {
										openAddToGroup(item);
									}}
								>
									<FormattedMessage {...Messages.addToGroup} />
								</StyledMenuItem>
								<Divider />
								<StyledMenuItem
									onClick={() => {
										openMoveToGroup(item);
									}}
								>
									<FormattedMessage {...Messages.moveToGroup} />
								</StyledMenuItem>
								<Divider />
								<StyledMenuItem
									onClick={() => {
										onRemoveUser(item.username);
										onClosePopover();
									}}
								>
									<FormattedMessage {...Messages.remove} />
								</StyledMenuItem>
							</>
						);
					}}
					onRowClick={onEditUser}
					history={history}
				/>
			</div>
			{showMoveToGroupModal && (
				<UserMoveGroup
					isOpen={showMoveToGroupModal}
					onDismiss={() => {
						setMoveToGroupModal(false);
						setSelectMoveUser(null);
					}}
					user={selectMoveUser}
					group={selectedGroup}
				/>
			)}

			<AddItemsModal
				isOpen={showAddToGroupModal}
				title={<FormattedMessage {...Messages.addToGroup} />}
				titleHint={
					<FormattedMessage
						{...Messages.addToGroupModal.addToGroupsHint}
						values={{
							user: selectedUser ? (
								<span className={classes.groupName}>{selectedUser.username}</span>
							) : (
								''
							),
						}}
					/>
				}
				availableTitle={<FormattedMessage {...Messages.addToGroupModal.availableGroups} />}
				selectedTitle={<FormattedMessage {...Messages.addToGroupModal.selectedGroups} />}
				selectedTxt={Messages.addToGroupModal.groupsSelected}
				noSelection={Messages.addToGroupModal.noSelection}
				searchMessageId="Users.searchGroup"
				orgId={selectedGroup.orgId}
				initialData={selectedUser}
				nameProperty="name"
				availableItems={availableGroups}
				onAdd={onAddToGroup}
				onDismiss={() => props.setParameter('users', 'CHANGE_ADD_TO_GROUP_STATE', false)}
			/>
			<EditImageModal
				isOpen={editPicOpen}
				title={<FormattedMessage {...Messages.editPicTitle} values={{ item: 'Group' }} />}
				subTitle={selectedGroup.name}
				pictureLink={selectedGroup.pictureLink}
				noImg={userGroupAvatar}
				onSave={onSavePicture}
				onDismiss={() => setEditPicOpen(false)}
			/>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	users: state.usersState,
	userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(UserGroupEdit), 'UserGroupEdit'));
