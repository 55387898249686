import { UPDATE_MICROPHONE, UPDATE_SPEAKER, UPDATE_CAMERA } from '../actions/types';

export type HardwareState = {
	settings: {
		camera?: { id: string; name: string };
		microphone?: { id: string; name: string; microphoneLevel: number };
		speakers?: { id: string; name: string; speakerLevel: number };
	};
};
const initialState: HardwareState = {
	settings: {
		camera: undefined,
		microphone: undefined,
		speakers: undefined,
	},
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case UPDATE_MICROPHONE: {
			const microphone: HardwareState['settings']['microphone'] | undefined =
				action.payload.microphone;
			return {
				...state,
				settings: {
					...state.settings,
					microphone: microphone
						? { ...state.settings.microphone, ...microphone }
						: undefined,
				},
			};
		}
		case UPDATE_SPEAKER: {
			const speaker: HardwareState['settings']['speakers'] | undefined =
				action.payload.speakers;
			return {
				...state,
				settings: {
					...state.settings,
					speakers: speaker ? { ...state.settings.speakers, ...speaker } : undefined,
				},
			};
		}
		case UPDATE_CAMERA: {
			const camera: HardwareState['settings']['camera'] | undefined = action.payload.camera;
			return {
				...state,
				settings: {
					...state.settings,
					camera: camera ? { ...state.settings.camera, ...camera } : undefined,
				},
			};
		}
		default:
			return state;
	}
};
