import React from 'react';
import styled from 'styled-components';
import { Battery } from '../Battery/Battery';
import { Theme, Typography } from '@material-ui/core';
import { getChargingStatusAndIcon } from '../../utils/robotStatusConverter';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
}));

interface StyledProps {
	theme: Theme;
	isOffline?: boolean;
}

const BatteryText = styled(Typography)<StyledProps>`
	padding-left: ${p => p.theme.spacing(2)}px;
	color: ${p => (p.isOffline ? p.theme.palette.OP_Grey[40] : p.theme.palette.OP_Grey[90])};
`;

interface Props {
	mongoDevice: any;
	isOffline: boolean;
}

export const UVDBattery = ({ mongoDevice, isOffline }: Props) => {
	const chargingStatus = mongoDevice?.status?.job
		? getChargingStatusAndIcon(mongoDevice.status.job)
		: null;

	return (
		<Wrapper>
			<Battery
				charging={chargingStatus ? chargingStatus.charging : false}
				batteryPercent={mongoDevice.status?.batteryMergerPercentage}
				isOffline={isOffline}
				pluggedIn={chargingStatus ? chargingStatus.pluggedIn : false}
			/>
			<BatteryText isOffline={isOffline}>
				{Math.floor(mongoDevice.status?.batteryMergerPercentage || 0)} %
				{chargingStatus?.label ? ', ' + chargingStatus.label : ''}
			</BatteryText>
		</Wrapper>
	);
};
