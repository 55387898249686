import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'ProfileSetup.title',
		defaultMessage: 'Settings',
		description: 'Settings',
	},

	profileTab: {
		id: 'profile',
		defaultMessage: 'Profile',
		description: 'Profile tab',
	},
	organization: {
		id: 'AccountManagementPage.organization',
		defaultMessage: 'Organization',
		description: 'Organization tab',
	},

	profile: {
		id: 'AccountSetup.profile',
		defaultMessage: 'Profile',
		description: 'Profile',
	},
	notifications: {
		id: 'AccountSetup.notifications',
		defaultMessage: 'Notifications',
		description: 'Notifications',
	},
});
