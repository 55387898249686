import {
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { checkLogin } from '../../providers/mqtt';
import classes from './Login.module.css';
import classNames from 'classnames';
import isAuthenticated from '../../components/Authentication/Authenticated';
import Checkbox from '../../components/Checkbox/Checkbox';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';

import { setParameter } from '../../actions/setParam';
import { SET_MQTTCLIENT, UPDATE_USER, SIGN_OUT_USER } from '../../actions/types';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';

import { eyeSharp, eyeOffSharp, key } from 'ionicons/icons';
import LoginForwarding from './LoginForwarding';
import { useTypedSelector } from '../../reducers';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../utils/encoding';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { EmailInputLimit, InputLimit } from '../../utils/validator';

const AlphaOverlay = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;
	color: black;
	z-index: 100;
	opacity: 0.5;
	font-weight: 100;
	font-family: 'Industry';
`;

type FormData = {
	email: string;
	password: string;
};

const LoginPage: React.FC = (props: any) => {
	const { setParameter, fetchData } = props;
	const [rememberChecked, setRememberChecked] = useState(false);
	const [customErrors, setCustomErrors] = useState('');
	const [readyForLogin, setReadyForLogin] = useState(false);
	const [passwordType, setPasswordType] = useState(true);
	const remember = localStorage.getItem('rememberMe');
	const { control, handleSubmit, errors } = useForm<FormData>();
	const [savedSpinoutType, setSavedSpinoutType] = useState('');

	useEffect(() => {
		props.setParameter('', SIGN_OUT_USER, {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const user = useTypedSelector(state => state.accountState.user);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		if (spinoutType) {
			setSavedSpinoutType(spinoutType);
		}
	}, [spinoutType]);

	useEffect(() => {
		if (fetchData.showLoader) {
			setParameter('showLoader', 'CHANGE_LOADER', false);
		}
		if (remember === 'true') {
			let email: any = localStorage.getItem('u');
			email = email.toLowerCase();

			let password = localStorage.getItem('p');
			if (!password || !email) return;
			email = b64DecodeUnicode(email);
			password = b64DecodeUnicode(password);

			checkLogin(email, password, (response: any) => {
				if (response && response.connected) {
					setParameter('showLoader', 'CHANGE_LOADER', true);
					setReadyForLogin(true);
					const userInfo = user as any;
					userInfo.loggedIn = true;
					userInfo.username = email;
					userInfo.password = password;
					setParameter('user', UPDATE_USER, userInfo);
					setParameter('client', SET_MQTTCLIENT, response.client);
				} else {
					setParameter('client', SET_MQTTCLIENT, { connected: false });
					if (response.error) {
						setCustomErrors(response.error);
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayPassword = () => {
		setPasswordType(prev => !prev);
	};

	const loginUser = handleSubmit(data => {
		checkLogin(data.email, data.password, async (response: any) => {
			if (response && response.connected) {
				props.setParameter('showLoader', 'CHANGE_LOADER', true);
				setReadyForLogin(true);
				const account = user as any;
				if (rememberChecked) {
					window.localStorage.setItem('rememberMe', 'true');
					// this section needs to be revisited PWA needs to have a cookie policy, and other devices needs to store cookies only
					window.localStorage.setItem('u', b64EncodeUnicode(data.email.toLowerCase()));
					window.localStorage.setItem('p', b64EncodeUnicode(data.password));
				}
				account.loggedIn = true;
				account.username = data.email.toLowerCase();
				account.password = data.password;
				props.setParameter('user', UPDATE_USER, account);
				props.setParameter('client', SET_MQTTCLIENT, response.client);
			} else {
				props.setParameter('client', SET_MQTTCLIENT, { connected: false });
				if (response.error) {
					setCustomErrors(
						response.error.includes('Bad User Name') ? (
							<FormattedMessage id="LoginPage.invalidLogin" />
						) : (
							response.error
						)
					);
				}
			}
		});
	});

	const forgotPassword = () => {
		props.history.push('/forgotpassword');
	};

	if (remember === 'true') {
		return (
			<div>
				<LoginForwarding inheritedProps={props} />
			</div>
		);
	} else
		return (
			<IonPage>
				<LoginForwarding inheritedProps={props} />
				<IonContent>
					<IonGrid className={classNames(classes.loginGrid, 'ion-no-padding')}>
						<IonRow className={classes.loginGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							>
								<div className={classNames(classes.homeCover)} />
							</IonCol>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.loginFormContainer}>
									<IonGrid>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classNames(classes.logo)} />
											<IonLabel
												className={classNames(
													'ion-text-center',
													classes.welcomeLb
												)}
											>
												<FormattedMessage id="LoginPage.welcome" />
											</IonLabel>
										</IonRow>
										<form onSubmit={loginUser} className={classes.loginForm}>
											<IonRow className={classes.loginRow}>
												<IonLabel className={classes.errorContainer}>
													{customErrors}
												</IonLabel>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonList
													className={classNames(
														classes.loginList,
														'ion-padding',
														classes.loginRow
													)}
												>
													<FormInputListItem
														name="email"
														type="email"
														control={control}
														onKeyPress={event => {
															if (event.key === 'Enter')
																document
																	.getElementById('login_submit')
																	?.click();
														}}
														rules={{ required: true }}
														errorMsg={
															errors.email && 'Email is invalid'
														}
														required
														label={props.intl.formatMessage({
															id: 'LoginPage.username',
														})}
														placeholderText={props.intl.formatMessage({
															id: 'LoginPage.usernameHint',
														})}
														onChange={() => setCustomErrors('')}
														maxLength={EmailInputLimit}
													/>
													<FormInputListItem
														type={passwordType ? 'password' : 'text'}
														name="password"
														control={control}
														onKeyPress={event => {
															if (event.key === 'Enter')
																document
																	.getElementById('login_submit')
																	?.click();
														}}
														rules={{ required: true }}
														errorMsg={
															errors.password &&
															'Password is required'
														}
														required
														label={props.intl.formatMessage({
															id: 'LoginPage.password',
														})}
														placeholderText={props.intl.formatMessage({
															id: 'LoginPage.passwordHint',
														})}
														iconUrl={
															passwordType ? eyeSharp : eyeOffSharp
														}
														iconCallback={displayPassword}
														maxLength={InputLimit}
														onChange={() => setCustomErrors('')}
													/>
												</IonList>
											</IonRow>
											<IonRow
												className={classNames(
													'ion-justify-content-center',
													'ion-padding'
												)}
											>
												<IonCol className={classes.forgotPswLinkWrapper}>
													<IonLabel
														className={classNames(
															classes.forgotPswLink
														)}
														onClick={forgotPassword}
													>
														<FormattedMessage id="LoginPage.forgot" />
													</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol>
													<IonCol>
														<IonButton
															id="login_submit"
															size="large"
															type="submit"
															shape="round"
															fill="outline"
															className={classes.loginBtn}
														>
															<FormattedMessage id="LoginPage.login" />
														</IonButton>
													</IonCol>
												</IonCol>
											</IonRow>
										</form>
										<IonRow className={classNames(classes.ssoRow)}>
											<IonCol>
												<IonRow
													className={classNames(
														'ion-padding',
														'ion-padding',
														classes.dividerContainer
													)}
												>
													<IonCol>
														<IonRow className={classes.ssoElem}>
															<IonCol>
																<hr
																	className={classNames(
																		classes.divider
																	)}
																/>
															</IonCol>
															<IonCol
																size="auto"
																className={classes.dividerFont}
															>
																Or access quickly
															</IonCol>
															<IonCol>
																<hr className={classes.divider} />
															</IonCol>
														</IonRow>
													</IonCol>
												</IonRow>
											</IonCol>
										</IonRow>
										<IonRow className={classNames(classes.ssoRow)}>
											<IonCol>
												<IonRow className={classNames('ion-padding')}>
													<IonCol>
														<IonButton
															id="sso_button"
															size="large"
															type="button"
															shape="round"
															text-align="right"
															fill="outline"
															className={classes.loginSSOBtn}
															onClick={() => {
																props.history.push('/sso-login');
															}}
														>
															{/* <div className={classes.ssoTxt}>
																<FormattedMessage id="LoginPage.sso" />
															</div> */}
															<IonIcon
																// className={classes.ssoIcon}
																slot="start"
																icon={key}
															></IonIcon>
															<FormattedMessage id="LoginPage.sso" />
														</IonButton>
													</IonCol>
												</IonRow>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonRow>
								<footer className={(classes.footerContainer, classes.ssoPadding)}>
									<FMFooterLogo />
								</footer>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
};

const mapStateToProps = (state: any) => ({
	fetchData: state.fetchDataState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(LoginPage), 'LoginPage')
);
