import {
	IonButton, IonCol, IonGrid, IonIcon,
	IonLabel, IonList,
	IonListHeader, IonRow
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
// Styling
import classes from './AddNewRobot.module.css';
// custom components
import isAuthenticated from '../Authentication/Authenticated';
import AddNewRobotForm from './AddNewRobotForm';
import Messages from './AddNewRobotModal.messages';
// icons
import { closeCircleOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { ClassicSpinner } from 'react-spinners-kit';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import BasicModal from '../BasicModal/BasicModal';
const addRobotIcon = './assets/icons/robot-add.svg';

const AddNewRobot: React.FC = (props: any) => {
	const { selectedOrganization } = props;
	const { handleSubmit, control, watch, errors, reset, setError } = useForm();
	const username = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	let encodedUser = b64EncodeUnicode(username);
	const [customError, setCustomError] = useState('');

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const [deviceVerifyNotification, setDeviceVerifyNotification] = useState<any>({});
	const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true);

	const handleDeviceNotification = useCallback(notification => {
		setDeviceVerifyNotification({
			message: notification.message || '',
			status: notification?.status || 'error',
		});
		if (notification?.status == 'error') {
			if (notification.message == 'Duplicate Serial Number') {
				setError('serialNumberUnique', {
					type: 'manual',
					message: 'error',
				});
			} else {
				setCustomError(notification.message);
			}
		}
		setTimeout(() => {
			setDeviceVerifyNotification({});
		}, 5000);
		setIsSubmitEnabled(true);
	}, []);

	useEffect(() => {
		if (deviceVerifyNotification) {
			if (deviceVerifyNotification?.status == 'success') {
				props.onDismiss();
				reset();
			}
		}
	}, [deviceVerifyNotification]);

	const handleCustomEventListenerDevice = useCallback(event => {
		handleDeviceNotification((event as CustomEvent)?.detail || {});
	}, []);

	useEffect(() => {
		window.addEventListener('deviceCreate', handleCustomEventListenerDevice);
		return () => {
			window.removeEventListener('deviceCreate', handleCustomEventListenerDevice);
		};
	}, [handleDeviceNotification]);

	const isDeviceNameTaken = (deviceName: string) => {
		if (devices[selectedOrganizationId]) {
			let devicesByOrg = devices[selectedOrganizationId];
			let isTaken = false;
			Object.values(devicesByOrg).forEach(element => {
				if (element?.name.toUpperCase() === deviceName?.toUpperCase()) {
					isTaken = true;
				}
			});
			return isTaken;
		} else return;
	};

	const onUpdate = handleSubmit(data => {
		data.serialNumber = data.serialNumber.trim();
		// using device serial number as name as as of this commit they are the same
		if (isDeviceNameTaken(data.serialNumber)) {
			setError('serialNumberUnique', {
				type: 'manual',
				message: 'error',
			});
			return;
		}
		const dataObj = {
			name: data.serialNumber,
			location: data.location || null,
			deviceId: data.serialNumber,
			orgId: selectedOrganization.orgId,
		};

		publish(`microservice/${dataObj.orgId}/${encodedUser}/registerDevice`, {
			requestId: 'someId',
			data: {
				name: dataObj.name,
				orgId: dataObj.orgId,
				location: dataObj.location,
				deviceId: dataObj.deviceId,
				deviceType: 'uvd',
			},
		});
		setIsSubmitEnabled(false);
		// props.onDismiss();
		// reset();
	});

	return (
		<BasicModal open={props.isOpen} onClose={props.onDismiss}>
			<form onSubmit={onUpdate} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonLabel color="primary" className={classes.headerTitle}>
								<FormattedMessage {...Messages.title} />
							</IonLabel>
						</div>
					</IonListHeader>
				</IonList>
				<IonGrid className={classes.modalContent}>
					<IonRow>
						<AddNewRobotForm watch={watch} control={control} errors={errors} />
					</IonRow>
					{customError && (
						<IonRow>
							<div className={classes.errorContainer}>
								<IonIcon icon={closeCircleOutline} />
								<IonLabel>{customError}</IonLabel>
							</div>
						</IonRow>
					)}
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={props.onDismiss}
							>
								<FormattedMessage {...Messages.cancel} />
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								id="add-robot-save-btn"
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
								disabled={!isSubmitEnabled}
							>
								{isSubmitEnabled ? (
									<FormattedMessage {...Messages.save} />
								) : (
									<ClassicSpinner size={24} />
								)}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(AddNewRobot), 'AddNewRobot')
);
