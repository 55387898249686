export const SET_MQTTCLIENT = 'SET_MQTTCLIENT';
export const UPDATE_USER = 'UPDATE_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const UPDATE_FIRSTNAME = 'UPDATE_FIRSTNAME';
export const UPDATE_LASTNAME = 'UPDATE_LASTNAME';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_PROFILEPICTURELINK = 'UPDATE_PROFILEPICTURELINK';
export const UPDATE_SELECTEDORGANIZATIONID = 'UPDATE_SELECTEDORGANIZATIONID';
export const UPDATE_USERGROUPS = 'UPDATE_USERGROUPS';
export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_DEVICES_BEGIN = 'FETCH_DEVICES_BEGIN';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAILURE = 'FETCH_DEVICES_FAILURE';
export const INSERT_NEW_DEVICE_BEGIN = 'INSERT_NEW_DEVICE_BEGIN';
export const INSERT_NEW_DEVICE_SUCCESS = 'INSERT_NEW_DEVICE_SUCCESS';
export const DELETE_DEVICE_BEGIN = 'DELETE_DEVICE_BEGIN';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const INSERT_NEW_DEVICE_GROUP = 'INSERT_NEW_DEVICE_GROUP';
export const FETCH_DEVICE_GROUPS_BEGIN = 'FETCH_DEVICE_GROUPS_BEGIN';
export const FETCH_DEVICE_GROUPS_SUCCESS = 'FETCH_DEVICE_GROUPS_SUCCESS';
export const FETCH_DEVICE_GROUPS_FAILURE = 'FETCH_DEVICE_GROUPS_FAILURE';
export const UPDATE_DEVICE_GROUP = 'UPDATE_DEVICE_GROUP';
export const UPDATE_DEVICE_GROUP_DEVICES = 'UPDATE_DEVICE_GROUP_DEVICES';
export const UPDATE_DEVICE_GROUP_USER_GROUPS = 'UPDATE_DEVICE_GROUP_USER_GROUPS';
export const DELETE_DEVICE_GROUP = 'DELETE_DEVICE_GROUP';
export const DELETE_DEVICE_GROUP_DEVICE = 'DELETE_DEVICE_GROUP_DEVICE';
export const SET_USERS_SORT_PARAMS = 'SET_USERS_SORT_PARAMS';
export const SET_USER_GROUPS_SORT_PARAMS = 'SET_USER_GROUPS_SORT_PARAMS';
export const SET_DEVICES_SORT_PARAMS = 'SET_DEVICES_SORT_PARAMS';
export const SET_DEVICE_GROUPS_SORT_PARAMS = 'SET_DEVICE_GROUPS_SORT_PARAMS';
export const INSERT_NEW_USER = 'INSERT_NEW_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const SET_ACTIONSTATE = 'SET_ACTIONSTATE';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_DEVICEGROUPS = 'UPDATE_DEVICEGROUPS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_USER_ON_EDIT = 'UPDATE_USER_ON_EDIT';
export const UPDATE_SEL_ORG_ORGID = 'UPDATE_SEL_ORG_ORGID';
export const UPDATE_SEL_ORG_CONTACTPERSON = 'UPDATE_SEL_ORG_CONTACTPERSON';
export const UPDATE_SEL_ORG_DEVICEGROUPS = 'UPDATE_SEL_ORG_DEVICEGROUPS';
export const UPDATE_SEL_ORG_NAME = 'UPDATE_SEL_ORG_NAME';
export const UPDATE_SEL_ORG_LANGUAGE = 'UPDATE_SEL_ORG_LANGUAGE';
export const UPDATE_SEL_ORG_CHILDORGS = 'UPDATE_SEL_ORG_CHILDORGS';
export const UPDATE_SEL_ORG_USERGROUPS = 'UPDATE_SEL_ORG_USERGROUPS';
export const DELETE_SEL_ORG_USERGROUP = 'DELETE_SEL_ORG_USERGROUP';
export const UPDATE_SEL_ORG_USERS = 'UPDATE_SEL_ORG_USERS';
export const UPDATE_SEL_ORG_USERLIST = 'UPDATE_SEL_ORG_USERLIST';
export const UPDATE_SEL_ORG_SAVED_USER_ROLES = 'UPDATE_SEL_ORG_SAVED_USER_ROLES';
export const UPDATE_SEL_ORG_ORGTYPE = 'UPDATE_SEL_ORG_ORGTYPE';
export const INSERT_NEW_USER_GROUP = 'INSERT_NEW_USER_GROUP';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_GROUP = 'SET_USER_GROUP';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const INSERT_NEW_ORGANIZATION_BEGIN = 'INSERT_NEW_ORGANIZATION_BEGIN';
export const INSERT_NEW_ORGANIZATION_SUCCESS = 'INSERT_NEW_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_SHOWING_ORGANIZATION = 'FETCH_SHOWING_ORGANIZATION';
export const GET_ORGANIZATION_STRUCTURE = 'GET_ORGANIZATION_STRUCTURE';
export const FETCH_DEVICE_GROUPS_BY_ORGANIZATION = 'FETCH_DEVICE_GROUPS_BY_ORGANIZATION';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const UPDATE_USER_GROUP_USERS = 'UPDATE_USER_GROUP_USERS';
export const UPDATE_USER_GROUP_DEVICE_GROUPS = 'UPDATE_USER_GROUP_DEVICE_GROUPS';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const INITIALIZE_DEVICES = 'INITIALIZE_DEVICES';
export const INITIALIZE_USERS = 'INITIALIZE_USERS';
export const INITIALIZE_DEVICE_GROUPS = 'INITIALIZE_DEVICE_GROUPS';
export const INITIALIZE_USER_GROUPS = 'INITIALIZE_USER_GROUPS';
export const INITIALIZE_ORGANIZATIONS = 'INITIALIZE_ORGANIZATIONS';
export const FETCH_USER_GROUPS_BEGIN = 'FETCH_USER_GROUPS_BEGIN';
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS';
export const FETCH_USER_GROUPS_FAILURE = 'FETCH_USER_GROUPS_FAILURE';
export const RESET_APP = 'RESET_APP';
export const FETCH_ORGANIZATION_CHILDREN = 'FETCH_ORGANIZATION_CHILDREN';
export const INITIALIZE_USER_ROLES = 'INITIALIZE_USER_ROLES';
export const INITIALIZE_USER_GROUP_ROLES = 'INITIALIZE_USER_GROUP_ROLES';
export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const AWAIT_DATA_FETCH = 'AWAIT_DATA_FETCH';
export const ORGANIZATIONS_FETCHED = 'ORGANIZATIONS_FETCHED';
export const ROLE_NAMES_FETCHED = 'ROLE_NAMES_FETCHED';
export const ORGANIZATION_INFORMATION_FETCHED = 'ORGANIZATION_INFORMATION_FETCHED';
export const ORGANIZATION_CHILDREN_FETCHED = 'ORGANIZATION_CHILDREN_FETCHED';
export const ROLES_FETCHED = 'ROLES_FETCHED';
export const CHANGE_USER_INVITE_STATE = 'CHANGE_USER_INVITE_STATE';
export const UPDATE_DEVICE_PROPS = 'UPDATE_DEVICE_PROPS';
export const SET_ADMIN_ROLE = 'SET_ADMIN_ROLE';
export const UPDATE_USERS_USER_GROUP = 'UPDATE_USERS_USER_GROUP';
export const UPDATE_ACCOUNT_USER_GROUPS = 'UPDATE_ACCOUNT_USER_GROUPS';
export const UPDATE_USER_DEVICE_GROUPS = 'UPDATE_USER_DEVICE_GROUPS';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_DEVICES_DEVICE_GROUP = 'UPDATE_DEVICES_DEVICE_GROUP';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_RESPONSE = 'CONFIRM_ACCOUNT_RESPONSE';
export const CONFIRM_ACCOUNT_CREATE_RESPONSE = 'CONFIRM_ACCOUNT_CREATE_RESPONSE';
export const CLEAR_PASSWORD_REDUCER = 'CLEAR_PASSWORD_REDUCER';
export const MQTT_CONFIG = 'MQTT_CONFIG';
export const SET_DEVICE_FOR_TRANSFER = 'SET_DEVICE_FOR_TRANSFER';
export const CHANGE_ADD_NEW_ROBOT_STATE = 'CHANGE_ADD_NEW_ROBOT_STATE';
export const CHANGE_ADD_NEW_ORGANIZATION_STATE = 'CHANGE_ADD_NEW_ORGANIZATION_STATE';
export const CHANGE_ADD_USERS_TO_GROUP_STATE = 'CHANGE_ADD_USERS_TO_GROUP_STATE';
export const USERS_ADDED_TO_GROUP_STATE = 'USERS_ADDED_TO_GROUP_STATE';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const UPDATE_SEL_ORG_ADD_NEW_CHILD_ORG = 'UPDATE_SEL_ORG_ADD_NEW_CHILD_ORG';
export const INSERT_NEW_CHILD_ORG = 'INSERT_NEW_CHILD_ORG';
export const UNLINK_DEVICE_FROM_ORG = 'UNLINK_DEVICE_FROM_ORG';
export const CHANGE_ADD_TO_GROUP_STATE = 'CHANGE_ADD_TO_GROUP_STATE';
export const ACCEPT_INVITE_RESPONSE = 'ACCEPT_INVITE_RESPONSE';
export const USER_INVITED = 'USER_INVITED';
export const ORGANIZATION_INFORMATION_DENIED = 'ORGANIZATION_INFORMATION_DENIED';
export const USER_ROLES_UPDATE = 'USER_ROLES_UPDATE';
export const CREATED_NEW_USER_GROUP = 'CREATED_NEW_USER_GROUP';
export const USER_ROLES_UNASSIGN = 'USER_ROLES_UNASSIGN';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_DEVICESIDS = 'UPDATE_DEVICESIDS';
export const UPDATE_ISACTIVE = 'UPDATE_ISACTIVE';
export const UPDATE_LASTLOGIN = 'UPDATE_LASTLOGIN';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CREATED_NEW_DEVICE_GROUP = 'CREATED_NEW_DEVICE_GROUP';
export const DEVICES_ADDED_TO_GROUP_STATE = 'DEVICES_ADDED_TO_GROUP_STATE';
export const CHANGE_ADD_DEVICES_TO_GROUP_STATE = 'CHANGE_ADD_DEVICES_TO_GROUP_STATE';
export const UPDATE_AGREEMENTS = 'UPDATE_AGREEMENTS';
export const SET_SHOW_MENU = 'SET_SHOW_MENU';
export const SET_SHOW_ABSOLUTE_MENU = 'SET_SHOW_ABSOLUTE_MENU';
export const SET_IS_EXPANDED = 'SET_IS_EXPANDED';
export const UPDATE_ACCEPTED_AGREEMENTS = 'UPDATE_ACCEPTED_AGREEMENTS';
export const SET_ACCOUNT_INFORMATION = 'SET_ACCOUNT_INFORMATION';
export const UPDATE_ORG_ACCEPTED_AGREEMENTS = 'UPDATE_ORG_ACCEPTED_AGREEMENTS';
export const UNLOAD_ORG_ACCEPTED_AGREEMENTS = 'UNLOAD_ORG_ACCEPTED_AGREEMENTS';
export const SET_RELOAD_ORGANIZATION = 'SET_RELOAD_ORGANIZATION';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_RESPONSE = 'PASSWORD_RESET_RESPONSE';
export const UPDATE_SEL_ORG_SHIPPINGADDRESS = 'UPDATE_SEL_ORG_SHIPPINGADDRESS';
export const UPDATE_SEL_ORG_BILLINGADDRESS = 'UPDATE_SEL_ORG_BILLINGADDRESS';
export const UPDATE_SEL_ORG_CREATEDAT = 'UPDATE_SEL_ORG_CREATEDAT';
export const UPDATE_MICROPHONE = 'UPDATE_MICROPHONE';
export const UPDATE_SPEAKER = 'UPDATE_SPEAKER';
export const UPDATE_CAMERA = 'UPDATE_CAMERA';
export const APPLICATION_VERSION = 'APPLICATION_VERSION';
export const ACCOUNT_CREATE_DETAILS_UPDATED = 'ACCOUNT_CREATE_DETAILS_UPDATED';
export const ACCOUNT_CREATE_REQUEST_ID_CREATED = 'ACCOUNT_CREATE_REQUEST_ID_CREATED';
export const ACCOUNT_CREATE_STATUS_UPDATED = 'ACCOUNT_CREATE_STATUS_UPDATED';
export const ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED = 'ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED';
export const ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED =
	'ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED';
export const FETCH_ORGANIZATION_SSO_CONFIGURATION = 'FETCH_ORGANIZATION_SSO_CONFIGURATION';
export const UPDATE_ISSSO = 'UPDATE_ISSSO';
export const UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION =
	'UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION';
export const UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION =
	'UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION';
export const SET_ACCOUNT_USER_JWT = 'SET_ACCOUNT_USER_JWT';
export const SET_ACCOUNT_USER_MONGO_USER = 'SET_ACCOUNT_USER_MONGO_USER';
export const FETCH_MONGO_DEVICES_SUCCESS = 'FETCH_MONGO_DEVICES_SUCCESS';
export const MONGODB_CONFIG = 'MONGODB_CONFIG';
export const RELOAD_DISINFECTION_COLLECTION = 'RELOAD_DISINFECTION_COLLECTION';
export const GET_UVD_IMAGE_CONFIG = 'GET_UVD_IMAGE_CONFIG';
export const SSO_CONFIG = 'SSO_CONFIG';
export const SUBSCRIBE_TO_ORGANIZATION = 'SUBSCRIBE_TO_ORGANIZATION';
export const RESET_SUBSCRIBE_TO_ORGANIZATION = 'RESET_SUBSCRIBE_TO_ORGANIZATION';
