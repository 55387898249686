import React, { FC, useState, useCallback, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { useTypedSelector } from '../../reducers';
import PreferencesSettings from './PreferencesSettings';

interface RobotSettingsProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
}

const RobotSettings: FC<RobotSettingsProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange } = props;

	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);
	const [profile, setProfile] = useState<any>();

	const user = useTypedSelector(state => state.accountState.user);

	useEffect(() => {
		let selectedItem = document.getElementById('row-' + currentItem);
		let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
		let arrow = document.getElementById('arrow');
		if (arrow) {
			arrow.style.top = top.toString() + 'px';
		}
	});

	useEffect(() => {
		setCurrentItem(currentSubMenu);
	}, [currentSubMenu]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	const renderContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (currentItem) {
			case 'preferences':
				return <PreferencesSettings goTo={goToItem} />;

			default:
				return null;
		}
	}, [currentItem, goToItem, profile, user]);

	return renderContent();
};

export default injectIntl(isAuthenticated(RobotSettings, 'RobotSettings'));
