import styled from 'styled-components';

interface Props {
	theme: any;
	disabled: boolean;
	[key: string]: any;
}

export const Chip = styled.div<Props>`
	height: 38px;
	border-radius: 100px;
	background-color: ${p => (p.disabled ? p.theme.palette.OP_Grey[15] : 'white')};
	padding: 0px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${p => (p.disabled ? p.theme.palette.OP_Grey[20] : p.theme.palette.OP_Grey[30])};
	box-shadow: ${p => (p.disabled ? 0 : "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),	0px 0px 1px rgba(0, 0, 0, 0.04)")};
	cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
	&:hover {
		background-color: ${p =>
			p.disabled ? p.theme.palette.OP_Grey[15] : p.theme.palette.OP_Grey[10]};
	}
	color: ${p => (p.disabled ? p.theme.palette.OP_Grey[30] : p.theme.palette.OP_Grey[90])}
`;
