import { Grid, Typography, useTheme } from '@material-ui/core';
import { secondsToHours } from 'date-fns';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ProgressBar } from './ProgressBar';

const Wrapper = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 4,
	maxWidth: 224,
}));

const TextWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: 4,
}));

const StyledText = styled(Typography)(({ theme }) => ({
	display: 'block',
	color: theme.palette.OP_Grey[40],
	fontSize: '0.625rem',
	lineHeight: '0.75rem',
}));

const MAX_BATTERY_HEALTH_LEVEL = 105;
const MIN_BATTERY_HEALTH_LEVEL = 50;
const HEALTH_DIFF = MAX_BATTERY_HEALTH_LEVEL - MIN_BATTERY_HEALTH_LEVEL;

export const BatteryProgress = ({ health, index }: any) => {
	const theme = useTheme();
	const color = theme.palette.data.blue[70];

	const percentageValue = useMemo(() => {
		if (health > MAX_BATTERY_HEALTH_LEVEL) return 100;

		if (health < MIN_BATTERY_HEALTH_LEVEL) return 0;

		const healthDiff = health - MIN_BATTERY_HEALTH_LEVEL;
		return (healthDiff / HEALTH_DIFF) * 100;
	}, []);

	return (
		<Wrapper item>
			<TextWrapper>
				<StyledText>
					<FormattedMessage id="battery" defaultMessage="Battery" /> {index + 1}
				</StyledText>
				<StyledText>{Math.floor(percentageValue)}%</StyledText>
			</TextWrapper>
			<ProgressBar value={percentageValue} color={color} />
		</Wrapper>
	);
};
