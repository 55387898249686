import React, { useCallback } from 'react';
import { makeStyles, createStyles, Theme, createTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';

const defaultTheme = createTheme();
const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			root: {
				border: 'none',
				'& .MuiDataGrid-menuIcon': {
					display: 'none',
				},
				'& .MuiDataGrid-iconSeparator': {
					display: 'none',
				},
				'& .MuiDataGrid-columnHeaderWrapper': {
					borderRadius: 5,
				},
				'& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer': {
					paddingLeft: 6,
					backgroundColor: '#EEEEEE',
				},
				'& .MuiDataGrid-columnHeaderTitle': {
					fontFamily: 'Industry-Demi',
					textTransform: 'uppercase',
				},
				'& .MuiDataGrid-columnsContainer': {
					borderBottom: 'none',
				},
				'& .MuiDataGrid-cell': {
					borderBottom: `1px solid ${
						theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
					}`,
				},
			},
		}),
	{ defaultTheme }
);

export const MUIDataGrid = ({ rows, columns, pageSize, onRowClick, ...props }: any) => {
	const classes = useStyles();

	const handleRowClick = useCallback((params: any) => {
		onRowClick(params.row);
	}, []);

	return (
		<DataGrid
			{...props}
			className={classes.root}
			rows={rows}
			columns={columns}
			pageSize={pageSize || 6}
			onRowClick={handleRowClick}
			disableSelectionOnClick
		/>
	);
};
