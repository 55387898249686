import styled from 'styled-components';
import { Paper, Theme, Typography } from '@material-ui/core';
import { IonIcon } from '@ionic/react';

interface StyledProps {
	theme: Theme;
	isOffline?: boolean;
}

export const StyledPaper = styled(Paper)<StyledProps>`
	margin-bottom: ${p => p.theme.spacing(2)}px;
	overflow: hidden;
	min-height: 128px;
	width: 360px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: ${p => p.theme.spacing(2)}px;
	padding-right: ${p => p.theme.spacing(2)}px;
	background-color: ${p => (p.isOffline ? 'transparent' : 'white')};
	border: ${p => (p.isOffline ? '1px solid ' + p.theme.palette.OP_Grey[40] : 'none')};
`;

export const Header = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexDirection: 'row',
}));

export const NameWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
}));

export const RobotName = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	marginTop: theme.spacing(2),
	marginLeft: theme.spacing(4),
}));

export const StyledIonIcon = styled(IonIcon)<StyledProps>`
	height: 24px;
	width: 24px;
	margin-top: ${p => p.theme.spacing(2)};
	color: ${p => (p.isOffline ? p.theme.palette.OP_Grey[40] : p.theme.palette.success.main)};
`;

export const StatusWrapper = styled('div')(({ theme }) => ({
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
}));

export const StatusText = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

export const FooterText = styled(Typography)<StyledProps>`
	font-size: 0.625rem;
	line-height: 0.75rem;
	padding-left: ${p => p.theme.spacing(2)};
	color: ${p => (p.isOffline ? p.theme.palette.OP_Grey[50] : 'auto')};
`;

export const FooterWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingLeft: theme.spacing(2),
}));
