import React, { FC, useState, useCallback, useEffect } from 'react';
import {
	IonGrid,
	IonRow,
	IonList,
	IonItem,
	IonIcon,
	IonLabel,
	IonListHeader,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';

import AddOrganizationForm from './AddOrganizationForm';
import AddContactForm from './AddContactForm';
import AllocateRobots from './AllocateRobots';
import { publish } from '../../actions/publish';
import { business, headset } from 'ionicons/icons';
import { timeUuid } from '../../actions/timeUuid';

import classes from './AddOrganizationModal.module.css';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';

import Messages from '../AddOrganizationModal/AddOrganizationModal.messages';
import classNames from 'classnames';
import { Address } from '../../types/types';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import BasicModal from '../BasicModal/BasicModal';

const tabs = ['info', 'contact', 'allocate'];

interface AddOrganizationProps extends RouteComponentProps {
	isOpen: boolean;
	onDismiss: any;
	orgId: string;
}

const AddOrganizationModal: FC<AddOrganizationProps> = (props: any) => {
	const { organization, selectedOrganization } = props;

	const username = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const encodedUser = b64EncodeUnicode(username);
	const organizationId = selectedOrganization.orgId;
	const { orgTypesAllowed } = organization;
	const [selectedRobots, setSelectedRobots] = useState<any[]>([]);

	const [organizationType, setOrganizationType] = useState(null);
	const [typesAllowed, setTypesAllowed] = useState([]);

	const [selectedSegment, setSelectedSegment] = useState<string>(tabs[0]);
	const [organizationFormState, setOrganizationFormState] = useState({
		isValid: true,
		isSubmitted: false,
		isDirty: false,
	});
	const [contactFormState, setContactFormState] = useState({
		isValid: true,
		isSubmitted: false,
		isDirty: false,
	});
	const [newOrganization, setNewOrganization] = useState({
		orgId: '',
		name: '',
		language: '',
		billingAddress: {} as Address,
		contactPerson: {},
		childSupportKey: '',
	});
	const [country, setCountry] = useState('');

	const [readyToCreateOrg, setReadyToCreateOrg] = useState(false);

	useEffect(() => {
		const selected: any = selectedOrganization;
		if (selected) {
			setTypesAllowed(orgTypesAllowed[selected.orgType]);
			if ((orgTypesAllowed[selected.orgType] || []).length === 1) {
				setOrganizationType(orgTypesAllowed[selected.orgType][0]);
			}
		}
	}, [orgTypesAllowed, selectedOrganization]);

	useEffect(() => {
		if (selectedSegment == 'allocate') {
			setSelectedSegment(tabs[0]);
		}
	}, [selectedOrganization, organization]);

	const onNext = useCallback(
		(data: any) => {
			const currentIndex = tabs.findIndex(item => item === selectedSegment);
			setSelectedSegment(tabs[currentIndex + 1]);

			if (data.contactPerson?.email) {
				data.contactPerson.email = data.contactPerson.email.toLowerCase();
			}

			switch (selectedSegment) {
				case 'info':
					let orgTypeToBeSent = data.type;
					if (data.orgTypes && data.orgTypes.value) {
						orgTypeToBeSent = data.orgTypes.value;
					}
					setCountry(data.country.value);
					setNewOrganization({
						...newOrganization,
						orgId: timeUuid(),
						name: data.name,
						language: data.language?.value,
						childSupportKey: data.childSupportKey,
						billingAddress: {
							address: data.address,
							city: data.city,
							country: data.country.value,
							state: data.state,
							zip: data.zip,
						},
					});
					setOrganizationType(orgTypeToBeSent);
					break;
				case 'contact':
					setNewOrganization({
						...newOrganization,
						contactPerson: Object.assign(
							newOrganization.contactPerson,
							data.contactPerson
						),
					});
					if (spinoutType === 'beam' || spinoutType === 'gobe' || spinoutType === 'uvd') {
						// skip robot allocation when in beam fm
						setReadyToCreateOrg(true);
					}
					break;
				default:
					break;
			}
		},
		[newOrganization, selectedSegment, spinoutType]
	);

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
	};

	const onDismissModal = () => {
		props.onDismiss();
	};

	const onAddOrganization = useCallback(
		(devices: any) => {
			const domainUrl = window.location.hostname;

			publish(`microservice/${organizationId}/${encodedUser}/createOrg/${organizationType}`, {
				data: { ...newOrganization, devices, spinoutType, domainUrl },
				requestId: 'newOrganization',
			});

			props.onDismiss();

			props.setParameter('organizations', 'CHANGE_ADD_NEW_ORGANIZATION_STATE', false);

			setReadyToCreateOrg(false);
		},
		[encodedUser, newOrganization, organizationId, organizationType, props, spinoutType]
	);

	useEffect(() => {
		if (readyToCreateOrg) {
			onAddOrganization([]);
		}
	}, [onAddOrganization, readyToCreateOrg]);

	const onOrganizationValidation = useCallback(
		(isValid: boolean, isSubmitted: boolean, dirty: boolean) => {
			setOrganizationFormState({
				isValid: isValid,
				isSubmitted: isSubmitted,
				isDirty: dirty,
			});
		},
		[]
	);
	const onContactValidation = useCallback(
		(isValid: boolean, isSubmitted: boolean, dirty: boolean) => {
			setContactFormState({ isValid: isValid, isSubmitted: isSubmitted, isDirty: dirty });
		},
		[]
	);

	return (
		<BasicModal open={props.isOpen} onClose={onDismissModal}>
			<IonList>
				<IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonIcon
							className={classes.headerIcon}
							color="primary"
							size="large"
							icon={business}
						/>
						<IonLabel color="primary" className={classes.headerTitle}>
							<FormattedMessage {...Messages.title} />
						</IonLabel>
					</div>
				</IonListHeader>
				<IonItem lines="none">
					<IonSegment
						className={classes.tabContainer}
						mode="ios"
						onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
						value={selectedSegment}
					>
						<IonSegmentButton value="info" layout="icon-start">
							<IonIcon className={classes.iconMargin} size="small" icon={business} />
							<IonLabel>
								<FormattedMessage {...Messages.info} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton
							value="contact"
							layout="icon-start"
							disabled={
								!organizationFormState.isValid ||
								(!organizationFormState.isSubmitted &&
									!organizationFormState.isDirty)
							}
						>
							<IonIcon className={classes.iconMargin} size="small" icon={headset} />
							<IonLabel>
								<FormattedMessage {...Messages.contact} />
							</IonLabel>
						</IonSegmentButton>
						{/* {spinoutType !== 'beam' && (
							<IonSegmentButton
								value="allocate"
								layout="icon-start"
								disabled={
									!contactFormState.isValid ||
									(!contactFormState.isSubmitted && !contactFormState.isDirty)
								}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									src="./assets/icons/Robot-group.svg"
								/>
								<IonLabel>
									<FormattedMessage {...Messages.allocate} />
								</IonLabel>
							</IonSegmentButton>
						)} */}
					</IonSegment>
				</IonItem>
			</IonList>
			<IonGrid className={classes.modalContent}>
				<IonRow className={classes.formContainer} hidden={selectedSegment !== 'info'}>
					<AddOrganizationForm
						orgTypes={typesAllowed}
						onSubmit={onNext}
						onCancel={props.onDismiss}
						onValid={onOrganizationValidation}
						// onGetValues={onGetOrganizationData}
					/>
				</IonRow>
				<IonRow
					className={
						selectedSegment !== 'contact' ? classes.hidden : classes.formContainer
					}
				>
					{spinoutType !== 'beam' ? (
						<AddContactForm
							onSubmit={onNext}
							onCancel={props.onDismiss}
							onValid={onContactValidation}
							country={country}
							isLastStep
						/>
					) : (
						<AddContactForm
							onSubmit={onNext}
							onCancel={props.onDismiss}
							onValid={onContactValidation}
							country={country}
							isLastStep
						/>
					)}
				</IonRow>
				<IonRow
					className={
						selectedSegment !== 'allocate'
							? classNames(classes.allocateContainer, classes.hidden)
							: classNames(classes.allocateContainer, classes.formContainer)
					}
				>
					{/* {spinoutType !== 'beam' ? (
						<AllocateRobots
							onCreate={onAddOrganization}
							onCancel={props.onDismiss}
							orgId={newOrganization && newOrganization.orgId}
							encodedUser={encodedUser}
							isSubmittable={
								organizationFormState.isValid && contactFormState.isValid
							}
						/>
					) : (
						<div />
					)} */}
					<div />
				</IonRow>
			</IonGrid>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(AddOrganizationModal), 'AddOrganizationModal'));
