import React, { FC, useEffect, useState } from 'react';
import { IonList, IonItem, IonLabel, IonListHeader, IonButton } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import classNames from 'classnames';
import classes from './DeleteModal.module.css';
import { RouteComponentProps } from 'react-router';

import Messages from '../DeleteModal/DeleteOrgModal.messages';
import BasicModal from '../BasicModal/BasicModal';

interface DeleteOrgModalProps extends RouteComponentProps {
	title: any;
	isOpen: boolean;
	onConfirm: any;
	onDismiss: any;
	itemName: string;
}

const DeleteOrgModal: FC<DeleteOrgModalProps> = (props: any) => {
	const onDismissModal = () => {
		props.onDismiss();
	};

	const getAllChilds = (orgStructure: any) => {
		let childOrgs: any = {};

		if (orgStructure) {
			for (let child in orgStructure.children) {
				childOrgs[orgStructure.children[child].id] = orgStructure.children[child];

				const temp: any = getAllChilds(orgStructure.children[child]);

				if (temp) {
					childOrgs = { ...childOrgs, ...temp };
				}
			}
		}

		return childOrgs;
	};

	const [childOrganizations, setChildOrganizations] = useState<any>({});
	useEffect(() => {
		if (props.orgStructureForDeletingOrg?.children) {
			setChildOrganizations({ ...getAllChilds(props.orgStructureForDeletingOrg) });
		}
	}, [props.orgStructureForDeletingOrg?.children]);

	return (
		<BasicModal open={props.isOpen} onClose={onDismissModal}>
			<IonList className={classes.modalContent}>
				<IonItem lines="none" className={classes.msgContainer}>
					<div className={classes.deleteModalHeaderContainer}>
						<IonLabel
							className={classes.headerTitle}
							style={{ whiteSpace: 'pre-wrap' }}
						>
							{props.title}
						</IonLabel>
						<IonLabel
							className={classNames(
								classes.msg,
								'ion-padding-top ion-padding-bottom'
							)}
							style={{ whiteSpace: 'pre-wrap' }}
						>
							{props.orgStructureForDeletingOrg && (
								<FormattedMessage
									{...Messages.deleteMsg1}
									values={{
										deletedOrg: props.orgStructureForDeletingOrg.label,

										bold: (str: any) => <b>{str}</b>,
									}}
								/>
							)}
						</IonLabel>

						{props.orgStructureForDeletingOrg?.devices &&
							props.orgStructureForDeletingOrg.devices.length > 0 && (
								<>
									<IonLabel
										className={classNames(classes.msg)}
										style={{ whiteSpace: 'pre-wrap' }}
									>
										<FormattedMessage
											{...Messages.deleteMsg2}
											values={{
												parentOrg: props.parentOrg,
												bold: (str: any) => <b>{str}</b>,
											}}
										/>
									</IonLabel>
									{props.orgStructureForDeletingOrg.devices.map((device: any) => {
										return (
											<IonLabel
												className={classNames(classes.msg)}
												style={{ whiteSpace: 'pre-wrap' }}
											>
												{'- ' + device.serialNumber}
											</IonLabel>
										);
									})}
								</>
							)}

						{childOrganizations && Object.values(childOrganizations).length > 0 && (
							<>
								<IonLabel
									className={classNames(classes.msg, 'ion-padding-top')}
									style={{ whiteSpace: 'pre-wrap' }}
								>
									<FormattedMessage {...Messages.childOrgRemovalMsg} />
								</IonLabel>

								{Object.values(childOrganizations).map((childOrganization: any) => {
									return (
										<>
											<IonLabel
												className={classNames(
													classes.msg,
													'ion-padding-top'
												)}
												style={{ whiteSpace: 'pre-wrap' }}
											>
												<FormattedMessage
													{...Messages.deleteMsg1}
													values={{
														deletedOrg: childOrganization.label,
														bold: (str: any) => <b>{str}</b>,
													}}
												/>
											</IonLabel>
											{childOrganization?.devices &&
												childOrganization.devices.length > 0 && (
													<>
														<IonLabel
															className={classNames(
																classes.msg,
																'ion-padding-top'
															)}
															style={{ whiteSpace: 'pre-wrap' }}
														>
															<FormattedMessage
																{...Messages.deleteMsg2}
																values={{
																	parentOrg: props.parentOrg,
																	bold: (str: any) => (
																		<b>{str}</b>
																	),
																}}
															/>
														</IonLabel>
														{childOrganization.devices.map(
															(device: any) => {
																return (
																	<IonLabel
																		className={classNames(
																			classes.msg
																		)}
																		style={{
																			whiteSpace: 'pre-wrap',
																		}}
																	>
																		{'- ' + device.serialNumber}
																	</IonLabel>
																);
															}
														)}
													</>
												)}
										</>
									);
								})}
							</>
						)}
						<IonLabel
							className={classNames(
								classes.msg,
								'ion-padding-top ion-padding-bottom'
							)}
							style={{ whiteSpace: 'pre-wrap' }}
						>
							<FormattedMessage
								{...Messages.deleteMsg6}
								values={{
									supportEmail: props.supportEmail,
									bold: (str: any) => <b>{str}</b>,
								}}
							/>
						</IonLabel>
					</div>
				</IonItem>
				<IonItem lines="none" className={classes.btnContainer}>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.cancelBtn}
						onClick={props.onDismiss}
					>
						<FormattedMessage {...Messages.cancel} />
					</IonButton>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.deleteBtn}
						onClick={props.onConfirm}
					>
						<FormattedMessage {...Messages.delete} />
					</IonButton>
				</IonItem>
			</IonList>
		</BasicModal>
	);
};

export default injectIntl(isAuthenticated(DeleteOrgModal));
