import { defineMessages } from 'react-intl';

export default defineMessages({
	passwordLength: {
		id: 'Organizations.passwordLength',
		defaultMessage: 'At least 8 characters',
		description: 'Password Length',
	},
	passwordLowerCase: {
		id: 'Organizations.passwordLowerCase',
		defaultMessage: 'At least 1 lowercase character',
		description: 'lowercase characters needed',
	},
	passwordUpperCase: {
		id: 'Organizations.passwordUpperCase',
		defaultMessage: 'At least 1 uppercase character',
		description: 'uppercase characters needed',
	},
	passwordNumeric: {
		id: 'Organizations.passwordNumeric',
		defaultMessage: 'At least 1 numeric (0-9)',
		description: 'numeric value needed',
	},

	robotPasswordLength: {
		id: 'Organizations.robotPasswordLength',
		defaultMessage: 'At least 28 characters',
		description: 'Robot password length',
	},
});
