import React, { FC, useEffect, useMemo, useState } from 'react';
import {
	IonList,
	IonItem,
	IonLabel,
	IonListHeader,
	IonButton,
	IonCol,
	IonRow,
	IonNote,
	IonIcon,
} from '@ionic/react';
import { informationCircleOutline, chevronDownOutline } from 'ionicons/icons';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { FormattedMessage, injectIntl } from 'react-intl';
import classes from './OrganizationForm.module.css';

import Messages from './OrganizationForm.messages';

import OrganizationDomainsGrid from '../OrganizationDomainsGrid/OrganizationDomainsGrid';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import BasicModal from '../BasicModal/BasicModal';
interface OrganizationDomainConfigurationModalProps {
	orgDomains: object;
	onDismissModal: any;
	isOpen: boolean;
	organization: any;
}

const OrganizationDomainConfigurationModal: FC<OrganizationDomainConfigurationModalProps> = props => {
	const { onDismissModal, isOpen, organization } = props;
	const [isModalOpenState, setIsModalOpenState] = useState<any>();
	const [shouldShowDomainVerificationHelp, setShouldShowDomainVerificationHelp] = useState<
		boolean
	>(false);
	const orgDomains = useMemo(() => {
		return organization?.ssoConfiguration?.sandbox?.domainVerification || {};
	}, [organization]);

	useEffect(() => {
		setIsModalOpenState(isOpen || false);
	}, [isOpen]);

	return (
		<BasicModal
			className={classes.domainConfigurationModal}
			open={isModalOpenState}
			onClose={() => {
				setIsModalOpenState(false);
				onDismissModal();
			}}
		>
			<IonList
				className={classes.modalContent}
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<IonListHeader
					className={classes.modalHeader}
					lines="none"
					style={{ flexShrink: '0' }}
				>
					<div className={classes.modalDomainConfHeaderContainer}>
						<IonLabel
							className={classes.domainConfigurationHeaderTitle}
							color="primary"
						>
							Domain Verification
						</IonLabel>
						<IonLabel className={classes.subTitle}>
							Add/remove domains to your organisation
						</IonLabel>
						{/* <IonNote>Add/remove domains to your organisation</IonNote> */}
					</div>
				</IonListHeader>
				<IonItem style={{ width: '100%', flexShrink: '0' }} lines="none">
					<div className={classes.formBtns}>
						<IonIcon size="small" icon={informationCircleOutline} color="secondary" />
						Read more about how to verify your domains
						<IonIcon
							size="small"
							icon={chevronDownOutline}
							color="secondary"
							onClick={() =>
								setShouldShowDomainVerificationHelp(
									!shouldShowDomainVerificationHelp
								)
							}
						/>
					</div>
				</IonItem>
				{shouldShowDomainVerificationHelp && (
					<IonItem
						style={{ width: '100%', flexShrink: '0' }}
						lines="none"
						className={'ion-padding-bottom'}
					>
						<ol>
							<li>
								<IonNote>{'1- Sign In To Your Domain Hosting Account.'}</IonNote>
							</li>
							<li>
								<IonNote>{'2- Locate DNS Records.'}</IonNote>
							</li>
							<li>
								<IonNote>
									{`3- Add the TXT Record as "uvd-site-verification=<domain verification token>".`}
								</IonNote>
							</li>
							<li>
								<IonNote>{'4- Save Your Changes.'}</IonNote>
							</li>
							<li>
								<IonNote>
									{
										'5- Finally, click on Verify button to prove domain ownership.'
									}
								</IonNote>
							</li>
						</ol>
					</IonItem>
				)}
				<IonItem
					style={{
						width: '100%',
						flexGrow: '1',
						overflowY: 'scroll',
						marginBottom: '65px',
					}}
					lines="none"
				>
					<OrganizationDomainsGrid orgDomains={orgDomains} />
				</IonItem>
			</IonList>
			<IonRow className={classes.btnRow}>
				<IonCol className={classes.submitCol}>
					<IonButton
						expand="block"
						shape="round"
						type="submit"
						size="large"
						fill="outline"
						onClick={() => {
							setIsModalOpenState(false);
						}}
					>
						<FormattedMessage {...Messages.ok} />
					</IonButton>
				</IonCol>
			</IonRow>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(
		enhance(OrganizationDomainConfigurationModal),
		'OrganizationDomainConfigurationModal'
	)
);
