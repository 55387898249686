import {
	INSERT_NEW_USER,
	FETCH_USERS_BEGIN,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAILURE,
	REMOVE_USER,
	UPDATE_USER_ON_EDIT,
	UPDATE_USERS_USER_GROUP,
	UPDATE_USER_DEVICE_GROUPS,
	SET_USERS_SORT_PARAMS,
	RESET_APP,
	CHANGE_USER_INVITE_STATE,
	CHANGE_ADD_TO_GROUP_STATE,
	USER_INVITED,
	USER_ROLES_UPDATE,
	USER_ROLES_UNASSIGN,
	UPDATE_FIRSTNAME,
	UPDATE_LASTNAME,
} from '../actions/types';
import { User } from '../types/types';

export interface UsersState {
	usersByOrganizationId: Record<string, Record<string, User>>;
	items: Array<User>;
	loading: boolean;
	error: string | null;
	sortParams: Record<string, string>;
	inviteUserOpen: boolean;
	inviteUserToUserGroupIds: string[];
	addToGroupOpen: boolean;
	invitedUser: string | null;
}

const initialState: UsersState = {
	usersByOrganizationId: { noUser: { noMail: {} as User } } as Record<
		string,
		Record<string, User>
	>,
	items: [],
	loading: false,
	error: null,
	sortParams: { key: 'username', order: 'asc' },
	inviteUserOpen: false,
	inviteUserToUserGroupIds: [],
	addToGroupOpen: false,
	invitedUser: null,
};

export default (
	state = initialState,
	action: { type: string; payload: Record<string, any> }
): UsersState => {
	let orgId;
	let itemsToUpdate: Array<User>;
	let usersToChange: Record<string, User>;
	let editedUserByOrganizationId;
	switch (action.type) {
		case FETCH_USERS_BEGIN:
			// Mark the state as "loading" so we can show a spinner or something
			// Also, reset any errors. We're starting fresh.
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_USERS_SUCCESS:
			let fetchUsersByOrganizationId = state.usersByOrganizationId;
			let items = state.items;
			for (let user of action.payload.users) {
				if (fetchUsersByOrganizationId[user.orgId] == null) {
					fetchUsersByOrganizationId[user.orgId] = { [user.username]: user };
				} else {
					fetchUsersByOrganizationId[user.orgId][user.username] = user;
				}

				if (!items.find(item => item.username === user.username)) {
					items.push(user);
				}
			}

			return {
				...state,
				loading: false,
				items: items,
				usersByOrganizationId: fetchUsersByOrganizationId,
			};

		case FETCH_USERS_FAILURE:
			// The request failed. It's done. So set loading to "false".
			// Save the error, so we can display it somewhere.
			// Since it failed, we don't have items to display anymore, so set `items` empty.
			//
			// This is all up to you and your app though:
			// maybe you want to keep the items around!
			// Do whatever seems right for your use case.
			return {
				...state,
				loading: false,
				error: action.payload.error,
				items: [],
			};

		case INSERT_NEW_USER:
			let organizationId = action.payload.user.orgId;
			state.usersByOrganizationId[organizationId][action.payload.user.username] =
				action.payload.user;

			return {
				...state,
				loading: false,
				items: [...state.items, action.payload.user],
				usersByOrganizationId: {
					...state.usersByOrganizationId,
					[organizationId]: state.usersByOrganizationId[organizationId],
				},
			};

		case REMOVE_USER:
			const deletedUser = action.payload.user;
			let deletedUserByOrganizationId = state.usersByOrganizationId;
			if (
				deletedUserByOrganizationId[deletedUser.orgId] !== null &&
				deletedUserByOrganizationId[deletedUser.orgId][deletedUser.username] !== null
			) {
				delete deletedUserByOrganizationId[deletedUser.orgId][deletedUser.username];
			} else return state;

			return {
				...state,
				loading: false,
				items: state.items.filter(element => element.username !== deletedUser.username),
				usersByOrganizationId: deletedUserByOrganizationId,
			};

		case UPDATE_USER_ON_EDIT:
			//TODO: make sure to check the data here when services have been updated.
			let changedUser = action.payload.user;
			let newItems = [];
			let index = state.items.findIndex(u => u.username === changedUser.username);
			if (index < 0) return state;
			newItems = state.items;
			newItems[index] = changedUser;

			editedUserByOrganizationId = state.usersByOrganizationId;
			if (editedUserByOrganizationId[changedUser.orgId] != null) {
				editedUserByOrganizationId[changedUser.orgId][changedUser.username] = changedUser;
			} else {
				if (changedUser.orgId != null)
					editedUserByOrganizationId[changedUser.orgId] = {
						[changedUser.username]: changedUser,
					};
			}

			return {
				...state,
				loading: false,
				items: [...newItems],
				usersByOrganizationId: editedUserByOrganizationId,
			};

		case USER_ROLES_UPDATE:
			const userIds = action.payload.usersIds;
			const roleId = action.payload.roleId;
			orgId = action.payload.orgId;
			itemsToUpdate = state.items;
			usersToChange = state.usersByOrganizationId[orgId];

			userIds.forEach((element: string) => {
				const index = itemsToUpdate.findIndex(u => u.username === element);
				if (index < 0) return;

				itemsToUpdate[index].role = roleId;
				if (usersToChange) usersToChange[element].role = roleId;
			});

			return {
				...state,
				items: [...itemsToUpdate],
				usersByOrganizationId: {
					...state.usersByOrganizationId,
					[orgId]: usersToChange,
				},
			};
		case USER_ROLES_UNASSIGN:
			const ids = action.payload.usersIds;
			orgId = action.payload.orgId;
			itemsToUpdate = state.items;
			usersToChange = state.usersByOrganizationId[orgId];

			ids.forEach((element: string) => {
				const index = itemsToUpdate.findIndex(u => u.username === element);
				if (index < 0) return;

				itemsToUpdate[index].role = null;
				if (usersToChange) usersToChange[element].role = null;
			});

			return {
				...state,
				items: [...itemsToUpdate],
				usersByOrganizationId: {
					...state.usersByOrganizationId,
					[orgId]: usersToChange,
				},
			};

		case UPDATE_USERS_USER_GROUP:
			let userGroupUsers = action.payload;
			let userGroupId = userGroupUsers.userGroupId;
			for (const key in userGroupUsers) {
				if (key === 'addedUsersIds') {
					for (let i = 0; i < userGroupUsers[key].length; i++) {
						const index = state.items.findIndex(
							u => u.username === userGroupUsers[key][i]
						);
						if (index < 0) continue;

						const user = state.items[index];
						Array.isArray(user.userGroupsIds)
							? !user.userGroupsIds.includes(userGroupId) &&
							  user.userGroupsIds.push(userGroupId)
							: (user.userGroupsIds = [userGroupId]);

						state.items[index] = user;
						state.usersByOrganizationId[userGroupUsers.orgId][user.username] = user;
					}
				} else if (key === 'removedUsersIds') {
					for (let i = 0; i < userGroupUsers[key].length; i++) {
						const index = state.items.findIndex(
							u => u.username === userGroupUsers[key][i]
						);
						if (index < 0) continue;

						const user = state.items[index];

						if (Array.isArray(user.userGroupsIds)) {
							const idx = user.userGroupsIds.indexOf(userGroupId);
							if (idx < 0) continue;
							user.userGroupsIds.splice(idx, 1);
						} else user.userGroupsIds = [];

						state.items[index] = user;
						state.usersByOrganizationId[userGroupUsers.orgId][user.username] = user;
					}
				}
			}

			return {
				...state,
				loading: false,
				items: [...state.items],
				usersByOrganizationId: {
					...state.usersByOrganizationId,
					[userGroupUsers.orgId]: state.usersByOrganizationId[userGroupUsers.orgId],
				},
			};

		case UPDATE_USER_DEVICE_GROUPS:
			let userDeviceGroups = action.payload;
			for (const key in userDeviceGroups) {
				if (key === 'addedDeviceGroupsIds') {
					for (let i = 0; i < userDeviceGroups[key].length; i++) {
						const index = state.items.findIndex(
							u => u.username === userDeviceGroups.userId
						);
						const user = state.items[index];
						if (Array.isArray(user.deviceGroupsIds)) {
							if (!user.deviceGroupsIds.includes(userDeviceGroups[key][i]))
								user.deviceGroupsIds.push(userDeviceGroups[key][i]);
						} else {
							user.deviceGroupsIds = [userDeviceGroups[key][i]];
						}
						state.items[index] = user;
						state.usersByOrganizationId[userDeviceGroups.orgId][user.username] = user;
						state.usersByOrganizationId = { ...state.usersByOrganizationId };
					}
				} else if (key === 'removedDeviceGroupsIds') {
					for (let i = 0; i < userDeviceGroups[key].length; i++) {
						const index = state.items.findIndex(
							u => u.username === userDeviceGroups.userId
						);
						const user = state.items[index];
						let idx;
						if (Array.isArray(user.deviceGroupsIds)) {
							idx = user.deviceGroupsIds.indexOf(userDeviceGroups[key][i]);
							if (idx > -1) {
								user.deviceGroupsIds.splice(idx, 1);
							}
						} else user.deviceGroupsIds = [];

						state.items[index] = user;

						state.usersByOrganizationId[userDeviceGroups.orgId][user.username] = user;
						state.usersByOrganizationId = { ...state.usersByOrganizationId };
					}
				}
			}

			return {
				...state,
				loading: false,
				items: [...state.items],
				usersByOrganizationId: {
					...state.usersByOrganizationId,
					[userDeviceGroups.orgId]: state.usersByOrganizationId[userDeviceGroups.orgId],
				},
			};
		case SET_USERS_SORT_PARAMS:
			return {
				...state,
				sortParams: action.payload.sortParams,
			};

		case RESET_APP:
			return {
				...state,
				usersByOrganizationId: {},
				items: [],
				loading: false,
				error: null,
				sortParams: { key: 'username', order: 'asc' },
			};

		case CHANGE_USER_INVITE_STATE:
			let inviteUserOpen;
			let inviteUserToUserGroupIds;

			if (typeof action.payload.users === 'boolean') {
				inviteUserOpen = action.payload.users;
				inviteUserToUserGroupIds = [];
			} else {
				({ inviteUserOpen, inviteUserToUserGroupIds } = action.payload.users);
			}

			return {
				...state,
				inviteUserOpen,
				inviteUserToUserGroupIds,
			};
		case CHANGE_ADD_TO_GROUP_STATE:
			return {
				...state,
				addToGroupOpen: action.payload.users,
			};
		case USER_INVITED:
			return {
				...state,
				invitedUser: action.payload.users,
			};

		case UPDATE_FIRSTNAME:
			editedUserByOrganizationId = state.usersByOrganizationId;
			if (action.payload.selectedOrganizationId) {
				editedUserByOrganizationId[action.payload.selectedOrganizationId][
					action.payload.username
				].firstName = action.payload.firstName;
			}
			return {
				...state,
				usersByOrganizationId: editedUserByOrganizationId,
			};

		case UPDATE_LASTNAME:
			editedUserByOrganizationId = state.usersByOrganizationId;
			if (action.payload.selectedOrganizationId) {
				editedUserByOrganizationId[action.payload.selectedOrganizationId][
					action.payload.username
				].lastName = action.payload.lastName;
			}
			return {
				...state,
				usersByOrganizationId: editedUserByOrganizationId,
			};

		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
};
