import React, { FC } from 'react';
import { IonLabel } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import classes from './Robots.module.css';
import Messages from './Robots.messages';

import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';

interface RobotStatusProps {
	robotId: string;
	orgId: string;
}

const RobotStatus: FC<RobotStatusProps> = props => {
	const { robotId, orgId } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'orgId', 'currentState'],
			}),
		(left, right) => equalityFnc(left, right)
	);

	return (
		<div className={classes.statusContainer}>
			{robot.currentState != null ? (
				robot.currentState === 'inSession' ? (
					<IonLabel className={classes.statusNameLb} {...Messages.onCall}>
						<FormattedMessage {...Messages.onCall} />
					</IonLabel>
				) : robot.currentState === 'available' ? (
					<IonLabel className={classes.statusNameLb}>
						<FormattedMessage {...Messages.online} />
					</IonLabel>
				) : (
					<IonLabel className={(classes.statusNameLb, classes.textGrey)}>
						<FormattedMessage {...Messages.offline} />
					</IonLabel>
				)
			) : (
				<>
					{robot.online === true ? (
						<IonLabel className={classes.statusNameLb}>
							<FormattedMessage {...Messages.online} />
						</IonLabel>
					) : (
						<IonLabel className={(classes.statusNameLb, classes.textGrey)}>
							<FormattedMessage {...Messages.offline} />
						</IonLabel>
					)}
				</>
			)}
		</div>
	);
};

export default RobotStatus;
