import React, { FC, useState, useEffect, useCallback } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonAvatar,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
} from '@ionic/react';
import { camera, globe } from 'ionicons/icons';

import ReactSelect from '../ReactSelect/ReactSelect';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';
import { useForm } from 'react-hook-form';

import classes from './RobotsSetup.module.css';
import classNames from 'classnames';

import Messages from './RobotsSetup.messages';
import { useTypedSelector } from '../../reducers';

const moment = require('moment-timezone');

const deviceIcons: Record<string, string> = {
	beam: '../../assets/img/beam-logo-white.svg',
	gobe: '../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
};

interface RobotsDetailsProps {
	robot: any;
	goTo?: () => void;
	parentComponentRobotUpdate?: any;
}

const RobotSetupDetails: FC<RobotsDetailsProps> = (props: any) => {
	const { intl, robot, parentComponentRobotUpdate } = props;
	const { control } = useForm();

	// // set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [pictureLink, setPictureLink] = useState<any>();
	const [reader] = useState(new FileReader());

	const [robotName, setRobotName] = useState('');
	const [robotLocation, setRobotLocation] = useState('');
	const [robotTimeZone, setRobotTimeZone] = useState<any>();

	const [timeZoneOptions] = useState<any>(
		moment.tz.names().map((name: string) => ({ label: name, value: name }))
	);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		if (robot.name && robot.name !== '') {
			setRobotName(robot.name);
		} else setRobotName('');

		if (robot.timeZone && robot.timeZone !== '') {
			setRobotTimeZone(timeZoneOptions.find((op: any) => op.value === robot.timeZone));
		} else setRobotTimeZone('');
	}, [robot, timeZoneOptions]);

	const onChangeInput = (label: string, value: any) => {
		parentComponentRobotUpdate(label, value, robot);
		switch (label) {
			case 'robotName':
				setRobotName(value[label]);
				break;
		}
	};

	const handleReactSelect = (value: any) => {
		setRobotTimeZone({ value: value, label: value });
		parentComponentRobotUpdate('robotTimeZone', value, robot);
	};

	const onChangeContent = (value: string) => {
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				setPictureLink(srcObject);
			});
		};
		reader.readAsDataURL(f);
		// setEditHidden(true);
	};

	const setUrlPicture = (e: CustomEvent) => {
		if (isURL(e.detail.value, { allow_underscores: true })) {
			setPictureLink(e.detail.value);
		} else {
			// display error
		}
	};

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonIcon
							className={classes.icon}
							size="small"
							icon="../../assets/icons/Robot-single.svg"
						/>
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.robotDetails} />:
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>
			<IonRow className={classes.robotSetupForm}>
				<IonCol className={classes.firstCol}>
					<IonList>
						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({ id: 'RobotSetupDetails.name' })}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onChangeInput('robotName', {
										robotName:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								placeholder={intl.formatMessage({
									id: 'RobotSetupDetails.name',
								})}
								required
								type="text"
								name="robotName"
								value={robotName}
							/>
						</IonItem>

						<div className={classes.robotTimeZoneItem}>
							<IonLabel>
								{intl.formatMessage({ id: 'RobotSetupDetails.timeZone' })}
							</IonLabel>
							<ReactSelect
								fullWidth
								className={classes.robotTimeZoneSelect}
								onChange={(selected: any) => {
									handleReactSelect(selected.value);
								}}
								defaultValue={robotTimeZone}
								key={Math.random()}
								value={robotTimeZone}
								options={timeZoneOptions}
								placeHolder={intl.formatMessage({
									id: 'RobotSetupDetails.timeZone',
								})}
								name="timeZone"
								control={control}
								isRequired={false}
								height="120px"
							/>
						</div>
					</IonList>
				</IonCol>
				<IonCol>
					<IonList>
						<IonListHeader className={classes.profileListHeader} lines="none">
							<div className={classes.profileHeader}>
								<IonLabel>{robot.name}</IonLabel>
							</div>
						</IonListHeader>
						<IonItem lines="none">
							<IonAvatar className={classNames(classes.avatarClass)}>
								<img
									className={classNames(classes.fullImage)}
									src={deviceIcons[spinoutType]}
									alt="robots"
								/>
							</IonAvatar>
						</IonItem>
						<IonItem lines="none" className={classes.msgContainer}>
							<IonSegment
								className={classes.tabContainer}
								mode="ios"
								onIonChange={(e: any) => onChangeContent(e.detail.value)}
							>
								<IonSegmentButton
									className={
										expandUrl ? classNames(classes.hidden) : 'ion-no-padding'
									}
									value="file"
									layout="icon-start"
								>
									<IonIcon size="small" icon={camera} />
									<IonLabel>
										<FormattedMessage {...Messages.upload} />
									</IonLabel>
								</IonSegmentButton>
								{expandUrl ? (
									<div className={classNames(classes.urlExpand)}>
										<div
											className={classNames(classes.iconContainer)}
											// uncomment when the proper service is up and running
											// onClick={e => {
											// 	setExpandUrl(!expandUrl);
											// }}
										>
											<IonIcon
												className={classes.iconExpanded}
												size="small"
												icon={globe}
											/>
										</div>
										<IonInput
											className={classNames(classes.expandInput)}
											type="url"
											placeholder={intl.formatMessage({
												id: 'AccountManagementPage.addUrl',
											})}
											onIonChange={e => setUrlPicture(e)}
										/>
									</div>
								) : (
									<IonSegmentButton
										className="ion-no-padding"
										value="url"
										layout="icon-start"
									>
										<IonIcon size="small" icon={globe} />
										<IonLabel>
											<FormattedMessage {...Messages.web} />
										</IonLabel>
									</IonSegmentButton>
								)}
							</IonSegment>

							<input
								className={classNames(classes.hidden)}
								id="fileSelector"
								type="file"
								accept="image/*"
								onChange={setPicture}
							/>
						</IonItem>
					</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(RobotSetupDetails, 'RobotSetupDetails'));
