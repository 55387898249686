import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { Charts } from '../charts.enum';
import { CountChart, RoomsDisinfectedByDate, SuccessRate } from './charts';
import {
	completeDisinfectionsCountQuery,
	completeDisinfectionsPercentQuery,
	distanceCoveredInMetersQuery,
	roomsDisinfectedCountQuery,
} from './countChartQueries';

interface Props {
	filters: any;
	chartHeight: any;
}

export const WideView = ({ filters, chartHeight }: Props) => {
	const intl = useIntl();

	return (
		<div style={{ flexGrow: 2 }}>
			<Grid container spacing={4}>
				<Grid item xs={8}>
					<RoomsDisinfectedByDate filters={filters} chartHeight={chartHeight} />
				</Grid>
				<Grid item xs={4}>
					<SuccessRate filters={filters} chartHeight={chartHeight} />
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: 8 }} spacing={4}>
				<Grid item xs={3}>
					<CountChart
						aggregationPipelineQueryFunction={completeDisinfectionsCountQuery}
						filters={filters}
						smallScreen
						header={intl.formatMessage({
							id: 'DisinfectionDashboard.disinfectionsCompleted',
							defaultMessage: 'Complete disinfections (#)',
						})}
						tooltipDescription={intl.formatMessage({
							id: 'DisinfectionDashboard.disinfectionsCompleted.description',
							defaultMessage:
								'Number of disinfection cycles marked as complete in the selected time period. If the card is blank, no data is matching the filters.',
						})}
						chartId={Charts.disinfectionsCompletedCount}
					/>
				</Grid>
				<Grid item xs={3}>
					<CountChart
						aggregationPipelineQueryFunction={completeDisinfectionsPercentQuery}
						filters={filters}
						smallScreen
						header={intl.formatMessage({
							id: 'DisinfectionDashboard.successfulDisinfections',
							defaultMessage: 'Complete disinfections (%)',
						})}
						tooltipDescription={intl.formatMessage({
							id: 'DisinfectionDashboard.successfulDisinfections.description',
							defaultMessage:
								'Percentage of disinfection cycles marked as complete in the selected time period. If the card is blank, no data is matching the filters.',
						})}
						chartId={Charts.successfulDisinfectionRateCount}
					/>
				</Grid>
				<Grid item xs={3}>
					<CountChart
						aggregationPipelineQueryFunction={roomsDisinfectedCountQuery}
						filters={filters}
						smallScreen
						header={intl.formatMessage({
							id: 'DisinfectionDashboard.locationsDisinfected',
							defaultMessage: 'Rooms disinfected',
						})}
						tooltipDescription={intl.formatMessage({
							id: 'DisinfectionDashboard.locationsDisinfected.description',
							defaultMessage:
								'Number of unique rooms disinfected in the selected time period. Example: If 3 disinfection cycles are performed in one room, then it would still count as 1 unique room. If the card is blank, no data is matching the filters.',
						})}
						chartId={Charts.locationsDisinfectedCount}
					/>
				</Grid>

				<Grid item xs={3}>
					<CountChart
						aggregationPipelineQueryFunction={distanceCoveredInMetersQuery}
						filters={filters}
						smallScreen
						header={intl.formatMessage({
							id: 'DisinfectionDashboard.distanceCovered',
							defaultMessage: 'Distance covered (m)',
						})}
						tooltipDescription={intl.formatMessage({
							id: 'DisinfectionDashboard.distanceCovered.description',
							defaultMessage:
								'The distance driven, during disinfection cycles, by the selected robots in the selected time period. If the card is blank, no data is matching the filters.',
						})}
						chartId={Charts.distanceCoveredInMetersCount}
					/>
				</Grid>
			</Grid>
		</div>
	);
};
