import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { mongodbClient } from '../../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../../reducers';
import { Charts } from '../../charts.enum';
import EmptyChartComponent from '../../components/EmptyChartComponent';

type RoomsDisinfectedByDateChartProps = {
	filters: any;
};

export default function RoomsDisinfectedByDateChart({ filters }: RoomsDisinfectedByDateChartProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [roomsDisinfectedByDateData, setRoomsDisinfectedByDateData] = useState<any>(null);

	useEffect(() => {
		const getRoomsDisinfectedByDateData = async () => {
			if (mongoUser) {
				const getRoomsDisinfectedByDateQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$project: {
							yearMonthDay: { $dateToString: { format: '%Y-%m-%d', date: '$start' } },
							time: { $dateToString: { format: '%H:%M:%S:%L', date: '$start' } },
						},
					},
					{ $group: { _id: '$yearMonthDay', count: { $sum: 1 } } },
					{
						$project: {
							_id: 0,
							date: { $toDate: '$_id' },
							count: 1,
						},
					},
					{ $sort: { date: 1 } },
					{
						$project: {
							date: { $dateToString: { format: '%Y-%m-%d', date: '$date' } },
							count: 1,
						},
					},
				];

				setRoomsDisinfectedByDateData(null);

				const getRoomsDisinfectedByDateDataResult = await mongodbClient.aggregateCollection(
					getRoomsDisinfectedByDateQuery,
					DISINFECTION_COLLECTION
				);

				setRoomsDisinfectedByDateData(getRoomsDisinfectedByDateDataResult);
			}
		};
		getRoomsDisinfectedByDateData().catch(console.error);
	}, [mongoUser, filters]);

	const data = {
		labels: roomsDisinfectedByDateData?.map((item: { date: any }) => item.date),
		datasets: [
			{
				label: 'Number of rooms disinfected',
				data: roomsDisinfectedByDateData?.map((item: { count: any }) => item.count),
				borderColor: 'rgb(41, 153, 222)',
				backgroundColor: 'rgb(41, 153, 222)',
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				display: roomsDisinfectedByDateData?.length < 20 ? true : false,
				color: 'white',
				font: {
					size: 14,
					weight: 700,
				},
			},
			tooltip: {
				enabled: roomsDisinfectedByDateData?.length < 20 ? false : true,
			},
		},
	};

	if (!roomsDisinfectedByDateData) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress />
			</div>
		);
	} else if (roomsDisinfectedByDateData.length == 0) {
		return <EmptyChartComponent chartId={Charts.roomsDisinfectedByDayBar} />;
	} else {
		return (
			<div>
				<Bar data={data} options={options} />
			</div>
		);
	}
}
