import React, { FC } from 'react';
import { injectIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import './react-phone-number-input.css';

import PhoneInput from 'react-phone-number-input';

import classes from '../CustomFormComponents.module.css';
import classNames from 'classnames';

interface PhoneInputProps {
	control: any;
	intl: any;
	errors: any;
	name: string;
	initializedCountry?: string;
	initializedValue?: string;
	marginLess?: boolean;
	required?: boolean;
}

const PhoneNumberInput: FC<PhoneInputProps> = PhoneInputProps => {
	const {
		control,
		intl,
		errors,
		name,
		initializedValue,
		initializedCountry,
		marginLess,
		required,
	} = PhoneInputProps;

	const errorMessage =
		errors?.country?.type === 'required' ? (
			<p className={classes.errorMessage}>
				{intl.formatMessage({
					id: 'ContactPerson.phoneNumberError',
				})}
			</p>
		) : null;

	const requiredIndicator = required ? (
		<span className={classes.requiredIndicator}>*</span>
	) : null;

	const containerClasses = classNames({
		[classes.container]: true,
		[classes.containerMarginLess]: Boolean(marginLess),
		[classes.containerNoFloat]: true,
		[classes.containerSelected]: true,
		[classes.containerError]: Boolean(errorMessage),
	});

	return (
		<div className={containerClasses}>
			<div className={classes.label}>
				{intl.formatMessage({
					id: 'ContactPerson.phoneNumber',
				})}
				{requiredIndicator}
			</div>
			<Controller
				className={classes.controller}
				as={PhoneInput}
				control={control}
				defaultCountry={initializedCountry ? initializedCountry : ''}
				onChange={([phoneNumber]) => phoneNumber}
				rules={required ? { required: true } : {}}
				name={name}
				placeholder=""
				value={initializedValue ? initializedValue : ''}
			/>
			{errorMessage}
		</div>
	);
};

export default injectIntl(PhoneNumberInput);
