import React from 'react';
import SvgWrapper from '../../../SvgWrapper';
import styled from 'styled-components';

const Svg = styled(SvgWrapper)(() => ({
	height: 72,
}));

export const PulseIcon = () => {
	return (
		<Svg viewBox="0 0 69 72">
			<circle cx="17" cy="20" r="52" fill="#22A2D9" fill-opacity="0.02" />
			<circle cx="17" cy="20" r="32" fill="#22A2D9" fill-opacity="0.06" />
			<circle cx="17" cy="20" r="18" fill="#22A2D9" fill-opacity="0.18" />
			<circle cx="17" cy="20" r="6" fill="#22A2D9" />
		</Svg>
	);
};
