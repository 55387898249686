import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';
import SvgWrapper from '../../components/SvgWrapper';

const StyledPaper = styled(Paper)`
	margin-bottom: ${p => p.theme.spacing(2)}px;
	overflow: hidden;
	width: 360px;
	padding: 14px;
	background-color: white;
`;

const StyledTypography = styled(Typography)(({ theme }) => ({}));

const Wrapper = styled('div')(({ theme }) => ({
	cursor: 'pointer',
	display: 'flex',
	flexDirection: 'row',
	path: {
		color: theme.palette.primary.main,
	},
	':hover p': {
		color: theme.palette.primary.dark,
	},
	':hover path': {
		color: theme.palette.primary.dark,
		stroke: theme.palette.primary.dark,
	},
}));

const Svg = styled(SvgWrapper)(({ theme }) => ({
	height: 20,
	marginRight: theme.spacing(1),
}));

export const ChevronIcon = () => {
	return (
		<Svg viewBox="0 0 512 512">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="48"
				d="M328 112L184 256l144 144"
			/>
		</Svg>
	);
};

interface Props {
	onClick: () => void;
	label: string | ReactElement;
}

export const BackToListNavButton = ({ onClick, label }: Props) => {
	return (
		<StyledPaper>
			<Wrapper onClick={onClick}>
				<ChevronIcon />
				<StyledTypography color="primary">{label}</StyledTypography>
			</Wrapper>
		</StyledPaper>
	);
};
