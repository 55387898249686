import { Grid } from '@material-ui/core';
import Tooltip from 'react-tooltip-lite';
import { ChartHeader, ChartHeaderWrapper, ChartMainDiv } from '../components/ChartHeader';
import { useGraphMediaBreakpoints } from '../../utils';
import { ChartPaper, DashboardPaper } from '../components/ChartPaper';
import { ChartWrapper } from '../components/ChartWrapper';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import OldestDisinfectionsTable from '../tables/OldestDisinfectionsTable';
import { IonIcon } from '@ionic/react';
import React from 'react';
import DisinfectionsByOperatorTable from '../tables/DisinfectionsByOperatorTable';
import InterruptionRateChart from './InterruptionRateChart';
import InterruptionReasonsChart from './InterruptionReasonsChart';
import { helpCircle } from 'ionicons/icons';

function getUIInfoFromBreakpoints(breakpoints: any) {
	const { small, medium, large } = breakpoints;
	if (large) return { chartHeight: 27, tableHeight: 490, tablePageSize: 6 };
	if (medium && !large) return { chartHeight: 27, tableHeight: 490, tablePageSize: 6 };
	if (small && !medium && !large) return { chartHeight: 27, tableHeight: 490, tablePageSize: 6 };
	return { chartHeight: 27, tableHeight: 490, tablePageSize: 6 };
}

export default function TroubleshootingDataCharts({ filters }: any) {
	const intl = useIntl();
	const { small, medium, large } = useGraphMediaBreakpoints();

	const uiInfo = getUIInfoFromBreakpoints({
		large,
		medium,
		small,
	});

	return (
		<Grid container spacing={4}>
			<Grid item xs={6}>
				<DashboardPaper height={uiInfo.tableHeight}>
					<ChartMainDiv>
						<ChartHeader
							label={intl.formatMessage({
								id: 'DisinfectionDashboard.byOperator',
								defaultMessage: 'Disinfections by operator',
							})}
						/>
						<div>
							<Tooltip
								direction="bottom"
								className="tooltip-dashboard"
								content={intl.formatMessage({
									id: 'DisinfectionDashboard.byOperator.description',
									defaultMessage:
										'The number of disinfection cycles done per operator in the selected time period. If the table is blank, no data is matching the filters.',
								})}
							>
								<IonIcon size="small" icon={helpCircle} />
							</Tooltip>
						</div>
					</ChartMainDiv>
					<DisinfectionsByOperatorTable
						filters={filters}
						tablePageSize={uiInfo.tablePageSize}
					/>
				</DashboardPaper>
			</Grid>
			<Grid item xs={6}>
				<DashboardPaper height={uiInfo.tableHeight}>
					<ChartMainDiv>
						<ChartHeader
							label={intl.formatMessage({
								id: 'DisinfectionDashboard.oldestDisinfectionsCompleted',
								defaultMessage: 'Oldest disinfections completed',
							})}
						/>
						<div>
							<Tooltip
								direction="bottom"
								className="tooltip-dashboard"
								content={intl.formatMessage({
									id:
										'DisinfectionDashboard.oldestDisinfectionsCompleted.description',
									defaultMessage:
										'Oldest disinfection cycles completed in the selected time period. If the table is blank, no data is matching the filters.',
								})}
							>
								<IonIcon size="small" icon={helpCircle} />
							</Tooltip>
						</div>
					</ChartMainDiv>
					<OldestDisinfectionsTable
						filters={filters}
						tablePageSize={uiInfo.tablePageSize}
					/>
				</DashboardPaper>
			</Grid>
			<Grid item xs={12} xl={6}>
				<ChartPaper>
					<ChartHeaderWrapper>
						<ChartMainDiv>
							<ChartHeader
								label={intl.formatMessage({
									id: 'DisinfectionDashboard.interruptionRate',
									defaultMessage: 'Interruption rate',
								})}
							/>
							<div>
								<Tooltip
									direction="down"
									className="tooltip-dashboard"
									content={intl.formatMessage({
										id: 'DisinfectionDashboard.interruptionRate.description',
										defaultMessage:
											'The number of disinfection cycles with interruptions vs. disinfection cycles without any interruptions in the selected time period. If the chart is blank, no data is matching the filters.',
									})}
								>
									<IonIcon size="small" icon={helpCircle} />
								</Tooltip>
							</div>
						</ChartMainDiv>
					</ChartHeaderWrapper>
					<ChartWrapper chartHeight={uiInfo.chartHeight}>
						<InterruptionRateChart filters={filters} />
					</ChartWrapper>
				</ChartPaper>
			</Grid>
			<Grid item xs={12} xl={6}>
				<ChartPaper>
					<ChartHeaderWrapper>
						<ChartMainDiv>
							<ChartHeader
								label={intl.formatMessage({
									id: 'DisinfectionDashboard.interruptionReasons',
									defaultMessage: 'Interruption reasons',
								})}
							/>
							<div>
								<Tooltip
									direction="bottom"
									className="tooltip-dashboard"
									content={intl.formatMessage({
										id: 'DisinfectionDashboard.interruptionReasons.description',
										defaultMessage:
											'The causes of interruptions that occurred in the selected time period. If the chart is blank, no data is matching the filters.',
									})}
								>
									<IonIcon size="small" icon={helpCircle} />
								</Tooltip>
							</div>
						</ChartMainDiv>
					</ChartHeaderWrapper>
					<ChartWrapper chartHeight={uiInfo.chartHeight}>
						<InterruptionReasonsChart filters={filters} />
					</ChartWrapper>
				</ChartPaper>
			</Grid>
		</Grid>
	);
}
