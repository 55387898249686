import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { Charts } from '../charts.enum';
import { CountChart, RoomsDisinfectedByDate, SuccessRate } from './charts';
import {
	completeDisinfectionsCountQuery,
	completeDisinfectionsPercentQuery,
	distanceCoveredInMetersQuery,
	roomsDisinfectedCountQuery,
} from './countChartQueries';

interface Props {
	filters: any;
	chartHeight: any;
}

export const NarrowView = ({ filters, chartHeight }: Props) => {
	const intl = useIntl();

	return (
		<div style={{ flexGrow: 2 }}>
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<RoomsDisinfectedByDate filters={filters} chartHeight={chartHeight} />
				</Grid>
			</Grid>
			<Grid container item xs={12} style={{ marginTop: 16 }}>
				<Grid item xs={6} style={{ marginRight: 16 }}>
					<SuccessRate filters={filters} chartHeight={chartHeight} />
				</Grid>
				<Grid container item xs={6} spacing={4}>
					<Grid item xs={6}>
						<CountChart
							aggregationPipelineQueryFunction={completeDisinfectionsCountQuery}
							filters={filters}
							smallScreen
							header={intl.formatMessage({
								id: 'DisinfectionDashboard.disinfectionsCompleted',
								defaultMessage: 'Complete disinfections (#)',
							})}
							chartId={Charts.disinfectionsCompletedCount}
							tooltipDescription={intl.formatMessage({
								id: 'DisinfectionDashboard.disinfectionsCompleted.description',
								defaultMessage:
									'Number of disinfection cycles marked as complete in the selected time period. If the card is blank, no data is matching the filters.',
							})}
						/>
					</Grid>
					<Grid item xs={6}>
						<CountChart
							aggregationPipelineQueryFunction={completeDisinfectionsPercentQuery}
							filters={filters}
							smallScreen
							header={intl.formatMessage({
								id: 'DisinfectionDashboard.successfulDisinfections',
								defaultMessage: 'Complete disinfections (%)',
							})}
							chartId={Charts.successfulDisinfectionRateCount}
							tooltipDescription={intl.formatMessage({
								id: 'DisinfectionDashboard.successfulDisinfections.description',
								defaultMessage:
									'Percentage of disinfection cycles marked as complete in the selected time period. If the card is blank, no data is matching the filters.',
							})}
						/>
					</Grid>
					<Grid item xs={6}>
						<CountChart
							aggregationPipelineQueryFunction={roomsDisinfectedCountQuery}
							filters={filters}
							smallScreen
							header={intl.formatMessage({
								id: 'DisinfectionDashboard.locationsDisinfected',
								defaultMessage: 'Rooms disinfected',
							})}
							chartId={Charts.locationsDisinfectedCount}
							tooltipDescription={intl.formatMessage({
								id: 'DisinfectionDashboard.locationsDisinfected.description',
								defaultMessage:
									'Number of unique rooms disinfected in the selected time period. Example: If 3 disinfection cycles are performed in one room, then it would still count as 1 unique room. If the card is blank, no data is matching the filters.',
							})}
						/>
					</Grid>
					<Grid item xs={6}>
						<CountChart
							aggregationPipelineQueryFunction={distanceCoveredInMetersQuery}
							filters={filters}
							smallScreen
							header={intl.formatMessage({
								id: 'DisinfectionDashboard.distanceCovered',
								defaultMessage: 'Distance covered (m)',
							})}
							chartId={Charts.distanceCoveredInMetersCount}
							tooltipDescription={intl.formatMessage({
								id: 'DisinfectionDashboard.distanceCovered.description',
								defaultMessage:
									'The distance driven, during disinfection cycles, by the selected robots in the selected time period. If the card is blank, no data is matching the filters.',
							})}
						/>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
