import {
	AWAIT_DATA_FETCH,
	ORGANIZATION_INFORMATION_FETCHED,
	ORGANIZATIONS_FETCHED,
	ORGANIZATION_CHILDREN_FETCHED,
	ROLE_NAMES_FETCHED,
	ROLES_FETCHED,
	CHANGE_LOADER,
	ORGANIZATION_INFORMATION_DENIED,
} from '../actions/types';

// TODO: actions to be array
const initialState = {
	data: {
		organizationInformationStatus: false,
		organizationChildrenStatus: false,
		organizationsStatus: false,
		roleNamesStatus: false,
		rolesStatus: false,
	},
	fetched: false,
	organizationFetched: false,
	showLoader: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AWAIT_DATA_FETCH:
			return {
				...state,
				data: {
					organizationInformationStatus: false,
					organizationChildrenStatus: false,
					organizationsStatus: false,
					roleNamesStatus: false,
					rolesStatus: false,
				},
				fetched: false,
				showLoader: true,
			};

		case ORGANIZATION_INFORMATION_FETCHED:
			// if (
			// 	state.data.organizationChildrenStatus &&
			// 	state.data.organizationsStatus &&
			// 	state.data.roleNamesStatus &&
			// 	state.data.rolesStatus &&
			// 	!state.fetched
			// ) {
			if (!state.organizationFetched) setTimeout(() => {}, 100);
			// 	return {
			// 		...state,
			// 		fetched: true,
			// 	};
			// }
			if (
				state.organizationFetched &&
				!state.data.organizationInformationStatus &&
				!state.data.organizationsStatus &&
				!state.data.organizationChildrenStatus
			) {
				return {
					...state,
					data: {
						...state.data,
						organizationInformationStatus: true,
					},
					fetched: true,
					organizationFetched: true,
					// showLoader: false,
				};
			}
			return {
				...state,
				data: {
					...state.data,
					organizationInformationStatus: true,
				},
				fetched: true,
				organizationFetched: true,
				// showLoader: false,
			};

		case ORGANIZATIONS_FETCHED:
			if (
				(state.data.organizationInformationStatus &&
					state.data.organizationChildrenStatus) ||
				(state.organizationFetched &&
					!state.data.organizationInformationStatus &&
					!state.data.organizationsStatus &&
					!state.data.organizationChildrenStatus)
			) {
				return {
					...state,
					data: {
						...state.data,
						organizationsStatus: true,
					},
					// showLoader: false,
					fetched: true,
				};
			}
			return {
				...state,
				data: {
					...state.data,
					organizationsStatus: true,
				},
			};
		case ORGANIZATION_INFORMATION_DENIED:
			return {
				...state,
				// showLoader: false,
			};

		case ORGANIZATION_CHILDREN_FETCHED:
			if (
				state.data.organizationInformationStatus &&
				state.data.organizationsStatus &&
				state.data.roleNamesStatus &&
				state.data.rolesStatus &&
				!state.fetched
			) {
				setTimeout(() => {}, 100);
				return {
					...state,
					fetched: true,
				};
			}
			if (
				(state.data.organizationInformationStatus && state.data.organizationsStatus) ||
				(state.organizationFetched &&
					!state.data.organizationInformationStatus &&
					!state.data.organizationsStatus &&
					!state.data.organizationChildrenStatus)
			) {
				return {
					...state,
					data: {
						...state.data,
						organizationChildrenStatus: true,
					},
					// showLoader: false,
				};
			}
			return {
				...state,
				data: {
					...state.data,
					organizationChildrenStatus: true,
				},
			};

		case ROLE_NAMES_FETCHED:
			if (
				state.data.organizationInformationStatus &&
				state.data.organizationsStatus &&
				state.data.organizationChildrenStatus &&
				state.data.rolesStatus &&
				!state.fetched
			) {
				setTimeout(() => {}, 100);
				return {
					...state,
					fetched: true,
				};
			}
			return {
				...state,
				data: {
					...state.data,
					roleNamesStatus: true,
				},
			};

		case ROLES_FETCHED:
			if (
				state.data.organizationInformationStatus &&
				state.data.organizationsStatus &&
				state.data.organizationChildrenStatus &&
				state.data.roleNamesStatus &&
				!state.fetched
			) {
				setTimeout(() => {}, 100);
				return {
					...state,
					fetched: true,
				};
			}
			return {
				...state,
				data: {
					...state.data,
					rolesStatus: true,
				},
			};

		case CHANGE_LOADER:
			// console.log('Change Loader', Date.now());
			// console.log(action.payload);
			return {
				...state,
				showLoader: action.payload.showLoader,
			};

		default:
			return state;
	}
};
