import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const ChartPaper = styled(Paper)(({ theme }) => ({
	width: '100%',
	height: '100%',
	padding: '20px',
	boxShadow: '0px 2px 6px #0000000a',
	'& .tooltip-dashboard': {
		color: theme.palette.OP_Grey[20],
	},
	'&:hover .tooltip-dashboard': {
		color: theme.palette.OP_Grey[100],
	},
}));

export const DashboardPaper = styled(ChartPaper)`
	height: ${(p: { height?: number }) => p.height}px;
	padding-bottom: 50px;
	width: 100%;
`;
