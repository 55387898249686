import styled from 'styled-components';

type ChartWrapperProps = {
	marginTop?: any;
	chartHeight: any;
}

export const ChartWrapper = styled.div`
	position: relative;
	margin-top: ${(p: ChartWrapperProps) => p.marginTop ? p.marginTop : 0}px;
	div {
		height: ${(p: ChartWrapperProps) => p.chartHeight}em;
	}
`;
