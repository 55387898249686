export const toCamelCase = (snakeCase: string) => {
	let camelCase = '';
	let splitSnakeCase = snakeCase.split('-');

	for (let i = 0; i < splitSnakeCase.length; i++) {
		if (i === 0) {
			camelCase = splitSnakeCase[i];
		} else {
			let snakeWord = splitSnakeCase[i];
			camelCase += snakeWord.substr(0, 1).toUpperCase() + snakeWord.substring(1);
		}
	}

	return camelCase;
};
