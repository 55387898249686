import React, { FC } from 'react';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

interface PreferencesSettingsProps {
	goTo?: () => void;
}

const PreferencesSettings: FC<PreferencesSettingsProps> = (props: any) => {
	return <h1>Preferences Details</h1>;
};

export default injectIntl(isAuthenticated(PreferencesSettings, ' PreferencesSettings'));
