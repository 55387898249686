import React from 'react';
import { IonIcon } from '@ionic/react';
import { Box, createStyles, createTheme, makeStyles, Typography } from '@material-ui/core';
import { GridOverlay } from '@material-ui/data-grid';

interface NoRowsOverlayProps {
	text?: string;
	iconSize?: string;
	iconSrc?: string;
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
	() =>
		createStyles({
			chartContainerNoData: {
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				gap: '10px !important',
			},
			defaultIconContainer: {
				display: 'flex',
				alignItems: 'flex-end',
				color: 'var(--ion-color-gray-tint)',
			},
			defaultTextContainer: {
				color: 'var(--ion-color-gray)',
				textAlign: 'center',
			},
		}),
	{ defaultTheme }
);

function NoRowsOverlay(props: NoRowsOverlayProps) {
	const classes = useStyles();
	return () => (
		<GridOverlay>
			<div>
				<Box className={classes.chartContainerNoData}>
					<Box className={classes.defaultIconContainer}>
						{props?.iconSrc && (
							<IonIcon size={props?.iconSize || 'large'} src={props.iconSrc} />
						)}
					</Box>
					<Box>
						<Typography className={classes.defaultTextContainer}>
							{props?.text || 'No rows'}
						</Typography>
					</Box>
				</Box>
			</div>
		</GridOverlay>
	);
}

export default NoRowsOverlay;
