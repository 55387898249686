import React, { FC, Fragment, useEffect, useState } from 'react';
import isAuthenticated from '../Authentication/Authenticated';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import classes from './RobotEdit.module.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useTypedSelector } from '../../reducers';
import RobotDetailsUVD from './RobotDetailsUVD';
import { UVDRobotCard } from './UVDRobotCard/UVDRobotCard';
import { RobotServiceInfoUVD } from './RobotServiceInfoUVD';
import { BackToListNavButton } from './BackToListNavButton';
import { DisinfectionCard } from './DisinfectionCard';

interface RobotEditProps {
	editableDeviceId: string;
	onClose: () => void;
	mongoDevices: any;
	devicesByOrganizationId: any;
	orgId: string;
	device?: any;
	disinfectionLoader?: any;
}

const RobotEdit: FC<RobotEditProps> = props => {
	const { onClose, mongoDevices, devicesByOrganizationId, disinfectionLoader, device } = props;

	const selectedOrganizationId: string = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);

	useEffect(() => {
		if (!devicesByOrganizationId[selectedOrganizationId][device.deviceId]) return;
	}, [mongoDevices, devicesByOrganizationId]);

	return (
		<Fragment>
			<div className={classes.leftSide}>
				<BackToListNavButton
					onClick={onClose}
					label={<FormattedMessage id="all-robots" defaultMessage="All Robots" />}
				/>
				<UVDRobotCard device={device} />
				<DisinfectionCard
					robotId={device.deviceId}
					disinfectionLoader={disinfectionLoader}
					numberOfDisinfections={device?.disinfectionCount || 0}
				/>
				<RobotServiceInfoUVD
					orgId={selectedOrganizationId}
					robotId={device.deviceId}
					device={device}
				/>
			</div>
			<div className={classes.rightSide}>
				<RobotDetailsUVD robotId={device.deviceId} onClose={onClose} fullDevice={device} />
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	mongoDevices: state.deviceState.mongoDevices,
	devicesByOrganizationId: state.deviceState.devicesByOrganizationId,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(RobotEdit), 'RobotEdit')
);
