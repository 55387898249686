import * as Realm from 'realm-web';
import { store } from '../store/store';

export let mongodbClient = {
	user: null,
	app: null,
	credentials: null,
	getApp: function() {
		if (this.app) {
			return this.app;
		}

		this.app = new Realm.App({
			id: store.getState().mongoDbConfigState.mongoDbConfig.realmAppId,
		});
		return this.app;
	},
	getCredentials: function(jwt) {
		this.credentials = Realm.Credentials.jwt(jwt);
		return this.credentials;
	},
	readCollection: function(filters, collectionName) {
		return new Promise((resolve, reject) => {
			if (!this.user) {
				reject('connect to mongo to access data (read)');
				return;
			}

			const mongo = this.user.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(collectionName);

			const query = filters;
			const projection = {};
			dbCollection
				.find(query, projection)
				.then(result => {
					resolve(result);
				})
				.catch(err => {
					console.error(`MongoDB reading collection: ${err}`);
					reject(err);
				});
		});
	},
	countCollection: function(filters, collectionName) {
		return new Promise((resolve, reject) => {
			if (!this.user) {
				reject('connect to mongo to access data (count)');
				return;
			}

			const mongo = this.user.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(collectionName);

			const query = filters;
			const projection = {};
			dbCollection
				.count(query, projection)
				.then(result => {
					resolve(result);
				})
				.catch(err => {
					console.error(`MongoDB counting collection: ${err}`);
					reject(err);
				});
		});
	},
	aggregateCollection: function(query, collectionName) {
		return new Promise((resolve, reject) => {
			if (!this.user) {
				reject('Not yet connected to MongoDB to execute aggregation');
				return;
			}

			const mongo = this.user.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(collectionName);

			dbCollection
				.aggregate(query)
				.then(result => {
					resolve(result);
				})
				.catch(err => {
					console.error(`Error executing aggregation: ${err}`);
					reject(err);
				});
		});
	},
	watchCollection: function(filter={}, collectionName) {
		if (!this.user) {
			console.error('Not yet connected to MongoDB to execute watch');
			return null;
		}

		const mongo = this.user.mongoClient(
			store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
		);
		const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
		const dbCollection = db.collection(collectionName);

		return dbCollection.watch({filter: filter})
	},
	getUser: function(jwt) {
		const user = this.user;
		let that = this;
		return new Promise(function(resolve, reject) {
			if (user) {
				resolve(user);
				return;
			}

			const app = that.getApp();
			const credentials = that.getCredentials(jwt);

			try {
				app.logIn(credentials)
					.then(res => {
						if (res) {
							that.user = res;
							resolve(that.user);
						}
					})
					.catch(err => {
						console.log('MongoDB login', err);
						reject(err);
					});
			} catch (err) {
				console.error('MongoDB login', err.message);
				reject(err);
			}
		});
	},
};
