import { CircularProgress, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { OpenIcon } from '../Robots/Disinfection';

const StyledPaper = styled(Paper)`
	margin-bottom: ${p => p.theme.spacing(2)}px;
	overflow: hidden;
	width: 360px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: ${p => p.theme.spacing(2)}px;
	padding-right: ${p => p.theme.spacing(2)}px;
	background-color: white;
	padding: ${p => p.theme.spacing(6)}px 0px;
	cursor: pointer;
	font-weight: 500;
	path {
		color: ${p => p.theme.palette.primary.main};
	}
	:hover p {
		color: ${p => p.theme.palette.primary.dark};
	}
	:hover path {
		color: ${p => p.theme.palette.primary.dark};
		stroke: ${p => p.theme.palette.primary.dark};
	}
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	paddingRight: theme.spacing(2),
}));

interface Props {
	numberOfDisinfections: number;
	robotId: string;
	disinfectionLoader?: any;
}

export const DisinfectionCard = ({ numberOfDisinfections, robotId, disinfectionLoader }: Props) => {
	let history = useHistory();

	const handleGoToDashboard = () => {
		window.dispatchEvent(new Event('hashchange'));

		history.push('/disinfection-dashboard/robotId=' + robotId);
	};

	return (
		<StyledPaper onClick={handleGoToDashboard}>
			{disinfectionLoader ? (
				<CircularProgress size={16} color="primary" />
			) : (
				<>
					<StyledTypography color="primary">
						{numberOfDisinfections}{' '}
						<FormattedMessage id="disinfections" defaultMessage="Disinfections" />
					</StyledTypography>
					<OpenIcon />
				</>
			)}
		</StyledPaper>
	);
};
