import React, { useCallback } from 'react';
import { PopoverMenuItem } from './PopoverMenuItems';
import { StyledPopover, PopoverContent } from './styles';
require('react-datepicker/dist/react-datepicker.css');

type PrimaryPopoverProps = {
	open: boolean;
	anchorEl: any;
	onClose: any;
	categories: any;
	onClick: any;
	activeFilterCategories: any;
};

export const PrimaryPopover = ({
	open,
	anchorEl,
	onClose,
	categories,
	onClick,
	activeFilterCategories,
}: PrimaryPopoverProps) => {
	const handleClick = useCallback((category, event) => {
		if (category.onClick) {
			category.onClick(event);
		} else {
			onClick(category.id);
		}
	}, []);

	return (
		<StyledPopover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<PopoverContent>
				{categories.map((category: any) => {
					return (
						<PopoverMenuItem
							selected={activeFilterCategories.includes(category.id)}
							key={category.id}
							showIcon={true}
							icon={category?.icon}
							keyName={category.id}
							onClick={(event: any) => handleClick(category, event)}
						/>
					);
				})}
			</PopoverContent>
		</StyledPopover>
	);
};
