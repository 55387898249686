const url = window.location.href.toLowerCase();

export default {
	defaultSupportKey: '73cb1fe5-1cde-477d-975c-88b265a26db8',
	publicPageComponentNames: [
		'AccountCreatePage',
		'LoginPage',
		'PrivacyPolicyModal',
		'LoginSSOPage',
		'LoginSSOSandboxResultPage',
	],
};
