import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonLabel,
	IonButton,
	IonAlert,
	IonText,
} from '@ionic/react';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './OrganizationDomainsGrid.module.css';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';
import Messages from './OrganizationDomainsGrid.messages';
import { trash, checkmarkDoneCircleOutline, copyOutline } from 'ionicons/icons';
import { IonInput } from '@ionic/react';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { Button } from '@material-ui/core';
import { round, values } from 'lodash';
import { domain } from 'process';
import { ErrorMessage } from 'react-hook-form';
import { InputLimit } from '../../utils/validator';

interface OrganizationDomainsGridProps {
	data: any;
	moreContent: React.ReactNode;
	onRowClick: any;
	checkItem: any;
	handleOnCheck: any;
}

const OrganizationDomainsGrid: FC<OrganizationDomainsGridProps> = (props: any) => {
	const { selectedOrganization, intl, orgDomains } = props;

	const [newDomainInput, setNewDomainInput] = useState<string>('');

	const [domainVerificationNotification, setDomainVerificationNotification] = useState<any>({});
	const [domainAddNotification, setDomainAddNotification] = useState<string>('');
	const [showDomainDeleteConfirmation, setShowDomainDeleteConfirmation] = useState<string>('');
	const [copyDomainVerificationToken, setCopyDomainVerificationToken] = useState<string>('');
	const headers = [
		{
			title: Messages.domainName,
			property: 'domain',
			size: '3',
		},
		{
			title: Messages.domainStatus,
			property: 'verificationStatus',
			size: '3',
		},
		{
			title: Messages.domainToken,
			property: 'verificationToken',
			size: '5',
		},
	];

	const organizationId = selectedOrganization.orgId;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const handleOrganizationNotification = useCallback(notification => {
		if (notification?.type) {
			switch (notification.type) {
				case 'verifyDomain':
					if (notification?.detail?.status !== 'success') {
						setDomainVerificationNotification({
							domain: notification?.detail?.domain,
							message: 'Verification failed!',
							status: notification?.detail?.status || 'error',
						});
					}
					setTimeout(() => {
						setDomainVerificationNotification({});
					}, 5000);
					break;
				case 'addSSODomainToSandbox':
					if (notification?.detail?.status !== 'success') {
						setDomainAddNotification(
							notification?.detail?.message ||
								'There is some error in processing your request. Please try again!'
						);
						scrollToBottom();
					}
					setTimeout(() => {
						setDomainAddNotification('');
						scrollToBottom();
					}, 5000);
					break;
			}
		}
	}, []);

	const handleCustomEventListener = useCallback(event => {
		handleOrganizationNotification((event as CustomEvent) || {});
	}, []);

	useEffect(() => {
		window.addEventListener('verifyDomain', handleCustomEventListener);
		window.addEventListener('addSSODomainToSandbox', handleCustomEventListener);
		return () => {
			window.removeEventListener('verifyDomain', handleCustomEventListener);
			window.removeEventListener('addSSODomainToSandbox', handleCustomEventListener);
		};
	}, [handleOrganizationNotification]);

	useEffect(() => {
		if (copyDomainVerificationToken) {
			setTimeout(() => {
				setCopyDomainVerificationToken('');
			}, 1000);
		}
	});
	const handleVerifyDomain = (domain: string) => {
		publish(`microservice/${organizationId}/${encodedUser}/verifyDomain`, {
			data: {
				orgId: organizationId,
				domain: domain,
			},
			requestId: 'verifyDomainId',
		});
	};

	const formatTemplate = (domainKey: any, property: string) => {
		switch (property) {
			case 'domain':
				return (
					<div className={classes.nameContainer}>
						<IonLabel className={classes.domainNameLb}>
							{orgDomains[domainKey].domain}
						</IonLabel>
					</div>
				);
			case 'verificationStatus':
				return domainVerificationNotification?.domain === orgDomains[domainKey].domain ? (
					<IonLabel
						color={
							domainVerificationNotification.status === 'success'
								? 'primary'
								: 'danger'
						}
					>
						{domainVerificationNotification.message}
					</IonLabel>
				) : orgDomains[domainKey].verificationStatus !== 'VERIFIED' ? (
					<div className={classes.domainVerificationContainer}>
						<IonButton
							expand="block"
							fill="outline"
							onClick={() => handleVerifyDomain(orgDomains[domainKey].domain)}
							size="small"
							className={classes.domainVerificationBtn}
						>
							<IonIcon
								slot="start"
								size="small"
								icon={checkmarkDoneCircleOutline}
								color="primary"
							/>
							<FormattedMessage {...Messages.verify} />
						</IonButton>
					</div>
				) : (
					orgDomains[domainKey][property]
				);
			case 'verificationToken':
				return (
					<div className={classes.nameContainer}>
						{copyDomainVerificationToken &&
						copyDomainVerificationToken === orgDomains[domainKey].domain ? (
							<IonLabel color="primary">Copied!</IonLabel>
						) : (
							<>
								<IonLabel className={classes.domainNameLb} color="dark">
									{orgDomains[domainKey].verificationToken}
								</IonLabel>
								<IonIcon
									slot="start"
									size="small"
									icon={copyOutline}
									color="primary"
									className={classes.copyVerificationTokenBtn}
									onClick={() => {
										navigator.clipboard
											.writeText(
												`uvd-site-verification=${orgDomains[domainKey].verificationToken}`
											)
											.then(() => {
												setCopyDomainVerificationToken(
													orgDomains[domainKey].domain
												);
											});
									}}
								/>
							</>
						)}
					</div>
				);
			default:
				return orgDomains[domainKey][property];
		}
	};

	const onInputChange = (event: any) => {
		setNewDomainInput(event.target.value);
	};
	const handleAddDomain = () => {
		let domain = newDomainInput;
		if (domain) {
			publish(`microservice/${organizationId}/${encodedUser}/addSSODomainToSandbox`, {
				data: {
					orgId: organizationId,
					domain: domain,
				},
				requestId: 'addSSODomainToSandboxId',
			});
		}
		setNewDomainInput('');
		formik.resetForm();
	};

	const handleRemoveDomain = (domain: string) => {
		console.log('remove domain: ', domain);
		publish(`microservice/${organizationId}/${encodedUser}/removeSSODomainToSandbox`, {
			data: {
				orgId: organizationId,
				domain: domain,
			},
			requestId: 'removeSSODomainToSandboxId',
		});
		setShowDomainDeleteConfirmation('');
	};
	const onSubmit = (values: any) => {
		console.log(values);
	};

	const messagesErrorRef = useRef<null | HTMLIonTextElement>(null);

	const scrollToBottom = () => {
		setTimeout(function() {
			messagesErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
		}, 200);
	};

	const formik = useFormik({
		initialValues: {
			domain: '',
		},
		onSubmit,
		validate: values => {
			let errors = { domain: '' };
			const domainRegex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

			if (values.domain && values.domain != '' && !domainRegex.test(values.domain)) {
				errors.domain = 'Invalid domain name';
				scrollToBottom();
			}
			return errors;
		},
	});
	return (
		<>
			<IonGrid className={classes.listGrid}>
				<IonAlert
					isOpen={showDomainDeleteConfirmation ? true : false}
					onDidDismiss={() => setShowDomainDeleteConfirmation('')}
					header={'Confirm!'}
					message={'<strong>Are you sure want to delete this domain?</strong>'}
					buttons={[
						{
							text: 'Cancel',
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: 'Delete',
							handler: () => {
								handleRemoveDomain(showDomainDeleteConfirmation);
							},
						},
					]}
				/>

				<IonRow className={classes.headerRow}>
					{headers && headers.length > 0 ? (
						<>
							{headers.map((header: any, i: number) => {
								return (
									<IonCol key={i} style={header.style} size={header.size}>
										{
											<div className={classes.columnHeader}>
												<div>
													<FormattedMessage {...header.title} />
												</div>
											</div>
										}
									</IonCol>
								);
							})}
							<IonCol className={classes.moreCol}></IonCol>
						</>
					) : (
						<IonCol sizeSm="12" />
					)}
				</IonRow>
				{orgDomains && Object.keys(orgDomains).length > 0 ? (
					Object.keys(orgDomains).map((domain: string, i: number) => {
						return (
							<IonRow className={classes.dataRow} key={i * headers.length}>
								{headers.map((header: any, j: number) => {
									return (
										<IonCol key={i + j} style={header.style} size={header.size}>
											{formatTemplate(domain, header.property)}
										</IonCol>
									);
								})}
								<IonCol className={classes.moreCol} size="1">
									<IonButton
										shape="round"
										fill="clear"
										onClick={() =>
											setShowDomainDeleteConfirmation(
												orgDomains[domain].domain
											)
										}
									>
										<IonIcon
											slot="icon-only"
											size="small"
											icon={trash}
											color="danger"
										/>
									</IonButton>
								</IonCol>
							</IonRow>
						);
					})
				) : (
					<>
						<IonRow className={classes.noData}>
							<IonLabel className={classes.noData}>
								<FormattedMessage {...Messages.noDomains} />
							</IonLabel>
						</IonRow>
						<IonRow className={classes.noData}>
							<IonLabel className={classNames(classes.noData, classes.noDataHint)}>
								<FormattedMessage {...Messages.noDomainsHint} />
							</IonLabel>
						</IonRow>
					</>
				)}
				<IonRow className={'ion-padding-top ion-no-border'}>
					<IonInput
						className={classes.domainAddInput}
						name="domain"
						value={formik.values.domain}
						onIonChange={(e: any) => {
							formik.handleChange(e);
							onInputChange(e);
						}}
						placeholder={intl.formatMessage({
							id: 'SSOConfiguration.domainConfig.addDomainHint',
						})}
						maxlength={InputLimit}
					/>

					<IonButton
						disabled={!newDomainInput || formik.errors.domain ? true : false}
						onClick={handleAddDomain}
						className={'ion-padding-horizontal'}
						shape="round"
						expand="block"
					>
						<FormattedMessage {...Messages.add} />
					</IonButton>
				</IonRow>
				{formik.errors.domain ? (
					<IonText ref={messagesErrorRef} className={classes.domainValidation}>
						{formik.errors.domain}
					</IonText>
				) : null}
				{domainAddNotification && (
					<IonText ref={messagesErrorRef} className={classes.domainValidation}>
						{domainAddNotification}
					</IonText>
				)}
			</IonGrid>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganization: state.selectedOrganizationState.organization,
});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(OrganizationDomainsGrid),
		'OrganizationDomainsGrid'
	)
);
