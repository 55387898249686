import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { ChartHeader, ChartMainDiv } from '../../components/ChartHeader';
import { ChartPaper } from '../../components/ChartPaper';
import { ChartWrapper } from '../../components/ChartWrapper';
import { IonIcon } from '@ionic/react';
import { useIntl } from 'react-intl';
import SuccessRateChart from './SuccessRateChart';
import { helpCircle } from 'ionicons/icons';

interface Props {
	chartHeight: any;
	filters: any;
}

export const SuccessRate = ({ chartHeight, filters }: Props) => {
	const intl = useIntl();

	return (
		<ChartPaper>
			<ChartMainDiv>
				<ChartHeader
					label={intl.formatMessage({
						id: 'DisinfectionDashboard.successRate',
						defaultMessage: 'Success rate',
					})}
				/>
				<div>
					<Tooltip
						direction="down"
						className="tooltip-dashboard"
						content={intl.formatMessage({
							id: 'DisinfectionDashboard.successRate.description',
							defaultMessage:
								'Completed vs incomplete disinfection cycles in the selected time period. If the chart is blank, no data is matching the filters.',
						})}
					>
						<IonIcon size="small" icon={helpCircle} />
					</Tooltip>
				</div>
			</ChartMainDiv>
			<ChartWrapper chartHeight={chartHeight}>
				<SuccessRateChart filters={filters} />
			</ChartWrapper>
		</ChartPaper>
	);
};
