import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Tube } from './Tube';

interface Props {
	tubeArr: any[];
}

export const UVCTubes = ({ tubeArr }: Props) => {
	const theme = useTheme();
	const smallScreens = useMediaQuery(theme.breakpoints.down('lg'));

	const firstArr = tubeArr.slice(0, tubeArr.length / 2);
	const secondArr = tubeArr.slice(-+firstArr.length);

	return (
		<Grid
			container
			direction="row"
			xs={12}
			lg={6}
			style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 550 }}
			spacing={smallScreens ? 0 : 6}
		>
			<Grid container item direction="column" xs={12} xl={6}>
				{firstArr.map((item: any, index: number) => (
					<Tube {...item} index={index} />
				))}
			</Grid>
			<Grid container item direction="column" xs={12} xl={6}>
				{secondArr.map((item: any, index: number) => (
					<Tube {...item} index={index + firstArr.length} />
				))}
			</Grid>
		</Grid>
	);
};
