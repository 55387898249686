import React, { FC } from 'react';
import { styled, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IoChevronDown } from 'react-icons/io5';
import { makeStyles } from '@material-ui/styles';

interface ReactSelectProps {
	label?: any;
	options: any;
	defaultValue?: any;
	onChange?: any;
	required?: any;
	variant?: any;
	placeHolder?: any;
	style?: any;
	isMulti?: any;
	filterSelectedOptions?: any;
	disableClearable?: any;
}

const StyledChevronDown = styled(IoChevronDown)(() => ({
	height: 20,
	width: 17,
	color: ' hsl(0,0%,80%)',
}));
const StyledTextField = styled(TextField)(() => ({
	'& .MuiInput-underline:after': {
		borderBottomColor: '#d2d2d2',
	},
	'& .MuiInput-underline:hover:before': {
		borderBottomColor: '#d2d2d2',
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: '#eeeeee',
	},
	'& .Mui-focused': {
		color: '#1e1f22',
	},
	'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: '1px solid #1e1f22',
	},
	'& .MuiAutocomplete-inputRoot': {
		padding: 0,
	},
}));

const useStyles = makeStyles(() => ({
	asterisk: {
		color: 'var(--ion-color-primary) !important',
	},
}));

const StyledTypography = styled(Typography)(() => ({
	fontSize: 13,
}));
export const AutoComplete: FC<ReactSelectProps> = ({
	options,
	label,
	defaultValue,
	required,
	onChange,
	variant = 'standard',
	placeHolder,
	style,
	isMulti = false,
	filterSelectedOptions = false,
	disableClearable = false,
}) => {
	const classes = useStyles();

	return (
		<Autocomplete
			id="autocomplete"
			options={options}
			getOptionLabel={(option: any) => option.label}
			disableClearable={!disableClearable}
			defaultValue={defaultValue}
			getOptionSelected={(option: any, value: any) => option.label === value.label}
			multiple={isMulti}
			filterSelectedOptions={filterSelectedOptions}
			style={{ ...style }}
			renderInput={params => (
				<StyledTextField
					label={label}
					{...params}
					variant={variant}
					placeholder={placeHolder}
					InputLabelProps={{
						classes: {
							asterisk: classes.asterisk,
						},
						style: { color: '#1E1F22' },
					}}
					required={required}
				/>
			)}
			popupIcon={<StyledChevronDown />}
			renderOption={(option: any) => <StyledTypography>{option.label}</StyledTypography>}
			onChange={(e, value) => onChange(value)}
		/>
	);
};
