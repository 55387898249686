import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'RobotGroups.name',
		defaultMessage: 'Group name',
		description: 'Name of robot groups',
	},
	robotName: {
		id: 'Robot.name',
		defaultMessage: 'Name',
		description: 'Name of robots',
	},
	linkedUserGroups: {
		id: 'RobotGroups.linkedUserGroups',
		defaultMessage: 'Linked User Groups',
		description: 'Linked User Groups',
	},
	dateAdded: {
		id: 'Users.dateAdded',
		defaultMessage: 'Date Added',
		description: 'Date added',
	},
	robots: {
		id: 'RobotGroups.robots',
		defaultMessage: 'Robots',
		description: 'Robots',
	},
	edit: {
		id: 'RobotGroups.edit',
		defaultMessage: 'Edit group',
		description: 'Edit group',
	},
	addRobots: {
		id: 'RobotGroups.addRobots',
		defaultMessage: 'Add robots',
		description: 'Add robots',
	},
	createGroup: {
		id: 'RobotGroups.createGroup',
		defaultMessage: 'Create robot group',
		description: 'Create robot group',
	},
	createGroupHint: {
		id: 'RobotGroups.createGroupHint',
		defaultMessage: 'Please complete to create new robot group',
		description: 'Please complete to create new robot group',
	},
	availableRobots: {
		id: 'RobotGroups.availableRobots',
		defaultMessage: 'Available robots',
		description: 'Available robots',
	},
	selectedRobots: {
		id: 'RobotGroups.selectedRobots',
		defaultMessage: 'Selected Robots',
		description: 'Selected Robots',
	},
	robotsSelected: {
		id: 'RobotGroups.robotsSelected',
		defaultMessage:
			'{count, number} {count, plural, one {robot} other {robots}} has been selected',
		description: 'Number of selected robots',
	},
	noSelection: {
		id: 'RobotGroups.noSelection',
		defaultMessage: 'No robots selected',
		description: 'No robots selected',
	},
	noAvailable: {
		id: 'RobotGroups.noAvailable',
		defaultMessage: 'No robots available',
		description: 'No robots available',
	},
	create: {
		id: 'create',
		defaultMessage: 'Create',
		description: 'Create',
	},
	robotsCount: {
		id: 'RobotGroups.robotsCount',
		defaultMessage: '{count} Robots',
		description: 'Number of robots',
	},
	recentActivity: {
		id: 'Users.recentActivity',
		defaultMessage: 'Recent activity',
		description: 'Recent activity',
	},
	editPicTitle: {
		id: 'editPicTitle',
		defaultMessage: 'Edit {item} Image',
		description: 'Edit image',
	},
	location: {
		id: 'Robots.location',
		defaultMessage: 'Location',
		description: 'Location',
	},
	status: {
		id: 'Robots.status',
		defaultMessage: 'Status',
		description: 'Status',
	},
	charge: {
		id: 'Robots.charge',
		defaultMessage: 'Charge',
		description: 'Charge',
	},
	latestSession: {
		id: 'UsersPage.latestSession',
		defaultMessage: 'Latest session',
		description: 'Latest session',
	},
	online: {
		id: 'Robots.online',
		defaultMessage: 'Available',
		description: 'Available',
	},
	onCall: {
		id: 'Robots.onCall',
		defaultMessage: 'On Call',
		description: 'On Call',
	},
	offline: {
		id: 'Robots.offline',
		defaultMessage: 'Offline',
		description: 'Offline',
	},
	decommissioned: {
		id: 'Robots.decommissioned',
		defaultMessage: 'Decommissioned',
		description: 'Decommissioned',
	},
	editRobot: {
		id: 'Robots.edit',
		defaultMessage: 'Edit robot',
		description: 'Edit robot',
	},
	moveRobotTo: {
		id: 'Robots.moveTo',
		defaultMessage: 'Move to group',
		description: 'Move to group',
	},
	unassignRobotFromGroup: {
		id: 'Robots.unassignRobotFromGroup',
		defaultMessage: 'Unassign from group',
		description: 'Unassign from group',
	},
	editGroupModal: {
		deleteTitle: {
			id: 'deleteTitle',
			defaultMessage: 'Delete this {item}',
			description: 'Delete this {item}',
		},
		addRobots: {
			id: 'DeviceGroups.addRobots',
			defaultMessage: 'Add robots',
			description: 'Add robots',
		},
		// addRobotsHint: {
		// 	id: 'DeviceGroups.addRobotsHint',
		// 	defaultMessage: 'Please choose robots to add to {group}',
		// 	description: 'Please choose robots to add to group',
		// },
		hasRobots: {
			id: 'UserGroups.hasRobots',
			defaultMessage: 'Has {number} robots',
			description: 'Number of robots in device group',
		},
	},
	addToGroupModal: {
		addToGroupsHint: {
			id: 'RobotsGroup.addToGroupsHint',
			defaultMessage: 'Please choose robots to add to {group}',
			description: 'Please choose robots for the selected group',
		},
	},
	created: {
		id: 'UserGroups.created',
		defaultMessage: '{name} has been created',
		description: 'A new user group has been created',
	},
	justCreated: {
		id: 'UserGroups.justCreated',
		defaultMessage: 'Has just been created',
		description: 'A new device group has just been created',
	},
	yearsAgo: {
		id: 'UserGroups.yearsAgo',
		defaultMessage: 'years ago',
		description: 'years ago',
	},
});
