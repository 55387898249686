import { store } from '../store/store';
/**
 * @desc Publishes data to the specified topic
 * @param {String} topic - string representation of the desired topic to publish to
 * @param {Object} data - Object representation of the data, will be stringified automatically
 * @param {mqttClient} client - MqttClient to publish data with.
 * @public
 */
const publish = (topic, data) => {
	const state = store.getState();
	const client = state.mqttState.client;
	if (!client.mqttClient || !client.mqttClient.publish) return;
	client.mqttClient.publish(topic, JSON.stringify(data));
};

export { publish };
