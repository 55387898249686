import React, { FC } from 'react';
import { useTypedSelector } from '../../reducers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import SvgWrapper from '../../components/SvgWrapper';

const Link = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	alignItems: 'center',
	':hover p': {
		color: theme.palette.primary.dark,
	},
	':hover svg path': {
		color: theme.palette.primary.dark,
	},
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	textAlign: 'right',
	paddingRight: theme.spacing(2),
}));

const Svg = styled(SvgWrapper)(({ theme }) => ({
	height: 20,
	color: theme.palette.primary.main,
}));

export const OpenIcon = () => {
	return (
		<Svg viewBox="0 0 512 512">
			<path
				d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72"
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="32"
			/>
		</Svg>
	);
};

interface DisinfectionDefaultProps {
	robotId: string,
	count: number;
	isOffline?: boolean;
}

const Disinfection: FC<DisinfectionDefaultProps> = props => {
	const { count, robotId } = props;
	let history = useHistory();

	const handleGoToDashboard = () => {
		window.dispatchEvent(new Event('hashchange'));

		history.push('/disinfection-dashboard/robotId=' + robotId);
	};

	return (
		<Link onClick={handleGoToDashboard}>
			<StyledTypography color="primary">
				{count ? count : 0} Disinfections
			</StyledTypography>
			<OpenIcon />
		</Link>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.selectedOrganizationState.organization,
	mongoUser: state.accountState.user.mongoUser,
	mongoDevices: state.deviceState.mongoDevices,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(Disinfection), 'Disinfection'));
