import React from 'react';
import { PulseIcon } from './PulseIcon';
import { UVDIcon } from './UVDIcon';
import styled from 'styled-components';

const Wrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	width: 18 + 8,
	height: 24 + 9,
}));

const Robot = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 8,
	left: 8,
	height: 24,
}));

const Pulse = styled('div')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	top: 0,
	height: 24,
}));

interface Props {
	showPulse?: boolean;
	isOffline?: boolean;
}

export const UVDPulseIcon = ({ showPulse = false, isOffline = false }: Props) => {
	return (
		<Wrapper>
			{showPulse ? (
				<Pulse>
					<PulseIcon />
				</Pulse>
			) : null}
			<Robot>
				<UVDIcon isOffline={isOffline} />
			</Robot>
		</Wrapper>
	);
};
