import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { mongodbClient } from '../../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../../reducers';
import { Charts } from '../../charts.enum';
import EmptyChartComponent from '../../components/EmptyChartComponent';

type SuccessRateChartProps = {
	filters: any;
};

export default function SuccessRateChart({ filters }: SuccessRateChartProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [successRateData, setSuccessRateData] = useState<any>(null);

	useEffect(() => {
		const getSuccessRateData = async () => {
			if (mongoUser) {
				const getSuccessRateDataQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},

					{
						$group: {
							_id: '$status',
							count: {
								$sum: 1,
							},
						},
					},
					{
						$project: {
							_id: 0,
							status: '$_id',
							count: '$count',
						},
					},
					{
						$project: {
							complete: {
								$cond: {
									if: { $eq: ['$status', 'complete'] },
									then: '$count',
									else: '$$REMOVE',
								},
							},
							incomplete: {
								$cond: {
									if: { $eq: ['$status', 'incomplete'] },
									then: '$count',
									else: '$$REMOVE',
								},
							},
						},
					},
				];

				setSuccessRateData(null);

				const successRateDataResult = await mongodbClient.aggregateCollection(
					getSuccessRateDataQuery,
					DISINFECTION_COLLECTION
				);

				let successRateDataResultMerged = successRateDataResult;
				if (successRateDataResult.length > 0) {
					successRateDataResultMerged = successRateDataResult.reduce(
						(acc: any, item: any) => {
							return { ...acc, ...item };
						}
					);
				}

				setSuccessRateData(successRateDataResultMerged);
			}
		};
		getSuccessRateData().catch(console.error);
	}, [mongoUser, filters]);

	const data = {
		labels: ['Complete', 'Incomplete'],
		datasets: [
			{
				label: 'Success rate',
				data: [successRateData?.complete, successRateData?.incomplete],
				backgroundColor: ['rgb(7, 137, 43)', 'rgb(221, 221, 221)'],
				hoverOffset: 4,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				color: 'white',
				font: {
					size: 14,
					weight: 700,
				},
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	if (!successRateData) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress />
			</div>
		);
	} else if (Object.keys(successRateData).length == 0) {
		return <EmptyChartComponent chartId={Charts.disinfectionSuccessRateDonut} />;
	} else {
		return (
			<div>
				<Doughnut data={data} options={options} />
			</div>
		);
	}
}
