import { GET_UVD_IMAGE_CONFIG } from '../actions/types';

const Initial_State = {
	getUVDImageConfig: {
		url: '',
	},
};

export default (state = Initial_State, action: any) => {
	switch (action.type) {
		case GET_UVD_IMAGE_CONFIG:
			return {
				...state,
				getUVDImageConfig: action.payload,
			};
		default:
			return state;
	}
};
