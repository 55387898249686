import React, { FC, useState, useEffect } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
	IonButton,
	IonChip,
} from '@ionic/react';
import { add, close } from 'ionicons/icons';

import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './RobotGroupSetup.module.css';
import classNames from 'classnames';

import Messages from './RobotGroupSetup.messages';
import Tooltip from 'react-tooltip-lite';
import { useTypedSelector } from '../../reducers';

import { publish } from '../../actions/publish';
import AddItemsModal from '../CreateAddItemsModal/CreateAddItemsModal';
import CreateModal from '../CreateAddItemsModal/CreateAddItemsModal';
import { getDevices, equalityFnc } from '../../utils/conformState';
import { timeUuid } from '../../actions/timeUuid';
import { store } from '../../store/store';

const deviceIcons: Record<string, string> = {
	beam: './assets/img/beam-logo-white.svg',
	gobe: './assets/icons/GOBE-ROBOTS-logo-neg.svg',
};

interface RobotGroupSetupDetailsProps {
	robotGroup: any;
	goTo?: () => void;
	monitorizeFormChange?: any;
	disableButton?: any;
}

const RobotGroupSetupDetails: FC<RobotGroupSetupDetailsProps> = (props: any) => {
	const { intl, robotGroup, monitorizeFormChange } = props;

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);
	const deviceGroups = useTypedSelector(state => state.deviceGroupsState);
	const { addDevicesOpen } = deviceGroups;

	const username = useTypedSelector(state => state.accountState.user.username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	let encodedUser = window.btoa(username);

	const [showAddRobotsModal, setAddRobotsModal] = useState(false);
	const [availableRobots, setAvailableRobots] = useState<any[]>([]);

	const devicesByOrganizationId = useTypedSelector(state =>
		getDevices(state, selectedOrganization.orgId)
	);

	const [selectedGroup, setSelectedGroup] = useState<any>(null);
	const [groupName, setGroupName] = useState<any>();

	const [showCreateModal, setCreateModal] = useState(false);

	const [alreadyTakenNames, setAlreadyTakenNames] = useState<string[]>([]);

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const deviceGroupsState = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId
	)

	//USE EFECT FOR ROBOT ARRAY UPDATE

	useEffect(() => {
		if (robotGroup) setSelectedGroup(JSON.parse(JSON.stringify(robotGroup)));
	}, [robotGroup]);

	// TODO: needs more clarification
	// useEffect(() => {
	// 	let isDeviceWithoutGroup = false;
	// 	if (devicesByOrganizationId) {
	// 		for (let deviceSN in devicesByOrganizationId) {
	// 			if (
	// 				devicesByOrganizationId[deviceSN].deviceGroupsIds ||
	// 				devicesByOrganizationId[deviceSN].deviceGroupsIds.length < 1
	// 			) {
	// 				isDeviceWithoutGroup = true;
	// 				break;
	// 			}
	// 		}
	// 	}

	// 	isDeviceWithoutGroup ? disableButton(true) : disableButton(false);
	// }, [devicesByOrganizationId, disableButton]);

	useEffect(() => {
		if (addDevicesOpen) {
			if (!selectedGroup) return;
			setAvailableRobots(
				devicesByOrganizationId
					? !selectedGroup.devicesIds
						? devicesByOrganizationId
						: devicesByOrganizationId.filter(
								(d: any) =>
									selectedGroup.devicesIds.findIndex(
										(id: string) => id === d.deviceId
									) < 0
						  )
					: []
			);
			setAddRobotsModal(true);
		} else {
			// Get available devices
			setAvailableRobots(devicesByOrganizationId ? devicesByOrganizationId : []);
			setAddRobotsModal(false);
			if (robotGroup) setGroupName(robotGroup.name);
		}
	}, [addDevicesOpen, selectedGroup, devicesByOrganizationId, robotGroup]);

	const removeRobotFromGroup = (robotId: string) => {
		let removeGroupIds: string[] = [];
		removeGroupIds.push(robotId);

		publish(
			`microservice/${selectedOrganization.orgId}/${encodedUser}/updateDeviceGroupDevices`,
			{
				data: {
					deviceGroupId: selectedGroup.deviceGroupId,
					remove: removeGroupIds,
				},
				requestId: 'updateDeviceGroup',
			}
		);
	};

	const onAddRobots = (selectedItems: any) => {
		publish(
			`microservice/${selectedOrganization.orgId}/${encodedUser}/updateDeviceGroupDevices`,
			{
				data: {
					deviceGroupId: selectedGroup.deviceGroupId,
					add: selectedItems.map((u: any) => u.deviceId),
				},
				requestId: 'updateDeviceGroup',
			}
		);
		props.setParameter('deviceGroups', 'CHANGE_ADD_DEVICES_TO_GROUP_STATE', false);

		if (selectedItems.length > 0) {
			props.setParameter(
				'addRobotsCount',
				'DEVICES_ADDED_TO_GROUP_STATE',
				selectedItems.length
			);
		}
	};

	const onChangeInput = (label: any, value: any) => {
		monitorizeFormChange(label, value);
		switch (label) {
			case 'name':
				setGroupName(value[label]);
				break;
		}
	};

	const onCreate = (data: any, selectedItems: any) => {
		if (selectedItems.length > 0 && selectedOrganization?.orgId) {
			//make sure to remove robots that is already a part of a device group from the old group.
			selectedItems.forEach((element: any) => {
				const e = store.getState().deviceState.devicesByOrganizationId[
					selectedOrganization.orgId
				][element.deviceId];
				if (e.deviceGroupsIds)
					publish(
						`microservice/${selectedOrganization.orgId}/${encodedUser}/updateDeviceGroupDevices`,
						{
							requestId: 'updateDeviceGroupDevicesId',
							data: {
								deviceGroupId: e.deviceGroupsIds[0],
								remove: [e.deviceId],
							},
						}
					);
			});
		}

		const robotGroupId = timeUuid();
		publish(`microservice/${selectedOrganization.orgId}/${encodedUser}/createDeviceGroup`, {
			data: {
				deviceGroupId: robotGroupId,
				name: data.name,
				pictureLink: null,
				devicesIds:
					selectedItems.length > 0 ? selectedItems.map((u: any) => u.deviceId) : null,
			},
			requestId: 'newDeviceGroup',
		});

		props.setParameter('createdGroup', 'CREATED_NEW_DEVICE_GROUP', {
			deviceGroupId: robotGroupId,
			name: data.name,
		});
		setCreateModal(false);

		// setTimeout(goTo(robotGroupId), 50);
	};

	const openCreateModal = () => {
		let deviceGroupsByOrganizationId = deviceGroupsState;
		let takenNames: string[] = [];
		if (deviceGroupsByOrganizationId[selectedOrganizationId]) {
			Object.values(deviceGroupsByOrganizationId[selectedOrganizationId]).forEach(element => {
				takenNames.push(element.name);
			});
		}
		setAlreadyTakenNames(takenNames);
		setCreateModal(true);
	};

	return (
		<>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonIcon
								className={classes.icon}
								size="medium"
								icon="../../assets/icons/Robot-group.svg"
							/>
							<IonLabel className={classes.detailHeader}>
								<FormattedMessage {...Messages.robotGroupDetails} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</IonRow>
				{robotGroup ? (
					<IonRow className={classes.robotSetupForm}>
						<IonCol className={classes.firstCol}>
							<IonList>
								<IonItem className={classes.formItem}>
									<IonLabel position="floating">
										{intl.formatMessage({
											id: 'RobotGroupSetupDetails.groupName',
										})}
									</IonLabel>
									<IonInput
										onIonChange={(selected: any) => {
											onChangeInput('name', {
												name:
													selected.detail.value ||
													selected.target.children[0]?.value,
											});
										}}
										autofocus
										// placeholder={intl.formatMessage({
										// 	id: 'RobotSetupDetails.name',
										// })}
										required
										type="text"
										name="robotName"
										value={groupName}
									/>
								</IonItem>
							</IonList>
							<br />
							<span className={classes.inGroupLabel}>
								{intl.formatMessage({ id: 'RobotGroupSetupDetails.inGroup' })}:
							</span>

							<div className={classes.spanContainer}>
								{robotGroup &&
									(robotGroup?.devicesIds === null ||
										(robotGroup?.devicesIds !== null &&
											robotGroup?.devicesIds.length === 0)) && (
										<span className={classes.hasNoRobot}>
											<br />
											{intl.formatMessage({
												id: 'RobotGroupSetupDetails.hasNoRobot',
											})}
										</span>
									)}
							</div>

							{robotGroup?.devicesIds &&
								robotGroup?.devicesIds !== null &&
								robotGroup?.devicesIds.length > 0 && (
									<IonRow className={classes.devicesRow}>
										{robotGroup?.devicesIds.map((item: any, index: number) => {
											return (
												<IonChip className={classes.IonChip} key={index}>
													<IonLabel className={classes.IonChipLabel}>
														{item}{' '}
													</IonLabel>

													<Tooltip
														direction="up"
														content={intl.formatMessage({
															id:
																'RobotGroupSetupDetails.removeFromGroup',
														})}
													>
														<IonIcon
															onClick={() =>
																removeRobotFromGroup(item)
															}
															className={classes.close}
															icon={close}
														/>
													</Tooltip>
												</IonChip>
											);
										})}
									</IonRow>
								)}

							<br />
							<IonButton
								className={classes.createBtn}
								fill="outline"
								onClick={() =>
									props.setParameter(
										'deviceGroups',
										'CHANGE_ADD_DEVICES_TO_GROUP_STATE',
										true
									)
								}
							>
								<IonIcon slot="icon-only" icon={add} className={classes.addIcon} />
								<span className={classes.initialText}>
									{intl.formatMessage({ id: 'RobotGroupSetupDetails.addRobots' })}
								</span>
							</IonButton>
						</IonCol>
						<IonCol />
					</IonRow>
				) : null}

				<IonRow>
					<IonCol className={classNames(classes.linkContainer, 'ion-no-padding')}>
						<IonIcon size="small" icon={add} />

						<IonLabel
							className={classNames('ion-float-right', classes.link)}
							onClick={() => openCreateModal()}
						>
							<FormattedMessage {...Messages.createNewGroup} />
						</IonLabel>
					</IonCol>
					<IonCol />
				</IonRow>
			</IonGrid>

			<AddItemsModal
				isOpen={showAddRobotsModal}
				title={<FormattedMessage {...Messages.editGroupModal.addRobots} />}
				titleHint={
					<FormattedMessage
						{...Messages.addToGroupModal.addToGroupsHint}
						values={{
							group: selectedGroup ? (
								<span className={classes.groupName}>{selectedGroup.name}</span>
							) : (
								''
							),
						}}
					/>
				}
				availableTitle={<FormattedMessage {...Messages.availableRobots} />}
				selectedTitle={<FormattedMessage {...Messages.selectedRobots} />}
				selectedTxt={Messages.robotsSelected}
				noSelection={Messages.noSelection}
				searchMessageId="RobotsGroup.searchRobots"
				orgId={selectedOrganization.orgId}
				initialData={selectedGroup}
				nameProperty="name"
				availableItems={availableRobots}
				noAvailable={Messages.noAvailable}
				addBtnTitle={Messages.addRobots}
				onAdd={onAddRobots}
				onDismiss={() =>
					props.setParameter('deviceGroups', 'CHANGE_ADD_DEVICES_TO_GROUP_STATE', false)
				}
			/>

			<CreateModal
				isOpen={showCreateModal}
				showHeaderIcon
				headerIcon="./assets/icons/robotgroup-add.svg"
				itemIcon={deviceIcons[spinoutType]}
				itemIconStyle={{ backgroundColor: 'black' }}
				title={<FormattedMessage {...Messages.createGroup} />}
				titleHint={<FormattedMessage {...Messages.createGroupHint} />}
				availableTitle={<FormattedMessage {...Messages.availableRobots} />}
				selectedTitle={<FormattedMessage {...Messages.selectedRobots} />}
				selectedTxt={Messages.robotsSelected}
				noSelection={Messages.noSelection}
				noAvailable={Messages.noAvailable}
				searchMessageId="RobotsGroup.searchRobots"
				orgId={selectedOrganization.orgId}
				initialData={selectedGroup}
				nameProperty="name"
				availableItems={availableRobots}
				onCreate={onCreate}
				onDismiss={() => setCreateModal(false)}
				takenNames={alreadyTakenNames}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({});
const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(RobotGroupSetupDetails), 'RobotGroupSetupDetails')
);
