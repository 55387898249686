import { ExposureMapCamera } from '@blue-ocean-robotics/seto_ui';
import React from 'react';
import { MapWrapper } from './MapWrapper';

interface Props {
	mapData: any;
	disinfectionPositions: any;
	exportImageRef: any;
	srcExposure: any;
	srcMap: any;
	classes: any;
}

export const ExposureMap = ({
	mapData,
	disinfectionPositions,
	exportImageRef,
	srcExposure,
	srcMap,
	classes,
}: Props) => {
	return (
		<MapWrapper>
			<ExposureMapCamera
				resolution={mapData.resolution}
				origin={mapData.origin}
				classes={{ root: classes.map }}
				srcExposure={srcExposure}
				srcMap={srcMap}
				disinfectionPositions={disinfectionPositions}
				exportImage={(exportImage: any) => {
					exportImageRef.current = exportImage;
				}}
			/>
		</MapWrapper>
	);
};
