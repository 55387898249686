export const completeDisinfectionsCountQuery = (filters: any) => [
	{
		$match: {
			...filters,
			startedBy: {
				$nin: [],
			},
			status: {
				$in: ['complete'],
			},
		},
	},
	{
		$count: 'complete',
	},
	{
		$project: {
			value: '$complete',
		},
	},
];

export const completeDisinfectionsPercentQuery = (filters: any) => [
	{
		$match: {
			...filters,
			startedBy: {
				$nin: [],
			},
			status: {
				$in: ['complete', 'incomplete'],
			},
		},
	},
	{
		$project: {
			_id: 0,
			complete: { $cond: [{ $eq: ['$status', 'complete'] }, 1, 0] },
			incomplete: { $cond: [{ $eq: ['$status', 'incomplete'] }, 1, 0] },
		},
	},
	{
		$group: {
			_id: '$_id',
			complete: { $sum: '$complete' },
			incomplete: { $sum: '$incomplete' },
		},
	},
	{
		$project: {
			value: {
				$round: [
					{
						$multiply: [
							{
								$divide: [
									'$$ROOT.complete',
									{ $add: ['$$ROOT.incomplete', '$$ROOT.complete'] },
								],
							},
							100,
						],
					},
					1,
				],
			},
			complete: '$$ROOT.complete',
			incomplete: '$$ROOT.incomplete',
		},
	},
];

export const roomsDisinfectedCountQuery = (filters: any) => [
	{
		$match: {
			...filters,
			startedBy: {
				$nin: [],
			},
			status: {
				$in: ['complete', 'incomplete'],
			},
		},
	},
	{
		$group: {
			_id: '$room',
			uniqueRoomsCount: {
				$addToSet: '$room',
			},
		},
	},
	{ $count: 'uniqueRoomsCount' },
	{
		$project: {
			value: '$uniqueRoomsCount',
		},
	},
];

export const distanceCoveredInMetersQuery = (filters: any) => [
	{
		$match: {
			...filters,
			startedBy: {
				$nin: [],
			},
			status: {
				$in: ['complete', 'incomplete'],
			},
		},
	},
	{
		$group: {
			_id: 0,
			distanceTraveled: { $sum: '$distanceTraveled' },
		},
	},
	{
		$project: {
			value: { $round: ['$distanceTraveled', 1] },
		},
	},
];
