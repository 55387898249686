import { GridColDef } from '@material-ui/data-grid';
import moment from 'moment';
import numeral from 'numeral';
import React, { useCallback, useEffect, useState } from 'react';
import { MUIDataGrid } from '../../../../components/MUIDataGrid/MUIDataGrid';
import { mongodbClient } from '../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../reducers';
import NoRowsOverlay from '../components/NoRowsOverlay';

type OldestDisinfectionsTableProps = {
	filters: any;
	tablePageSize: number;
};

export default function OldestDisinfectionsTable({
	filters,
	tablePageSize,
}: OldestDisinfectionsTableProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [oldestDisinfectionsData, setOldestDisinfectionsData] = useState<any>(null);

	useEffect(() => {
		const getOldestDisinfectionsData = async () => {
			if (mongoUser) {
				const getOldestDisinfectionsDataQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{ $limit: 10 },
					{ $sort: { end: 1 } },
					{ $project: { _id: 0, id: '$_id', end: 1, department: 1, room: 1 } },
				];

				setOldestDisinfectionsData(null);

				const oldestDisinfectionsResult = await mongodbClient.aggregateCollection(
					getOldestDisinfectionsDataQuery,
					DISINFECTION_COLLECTION
				);

				setOldestDisinfectionsData(oldestDisinfectionsResult);
			}
		};
		getOldestDisinfectionsData().catch(console.error);
	}, [mongoUser, filters]);

	const formattedDate = useCallback((params: any) => {
		return moment(params.value).format('DD MMM YYYY');
	}, []);

	const daysAgo = useCallback((params: any) => {
		const endDate = params.getValue(params.id, 'end');
		const today = moment();

		const diffInDays = today.diff(endDate, 'days');

		return numeral(diffInDays).format('0,0');
	}, []);

	const oldestDisinfectionsColumns: GridColDef[] = [
		{
			field: 'room',
			headerName: 'Room',
			flex: 1,
		},
		{
			field: 'department',
			headerName: 'Department',
			flex: 1,
		},
		{
			field: 'end',
			headerName: 'Completed Date',
			flex: 1,
			headerAlign: 'right',
			align: 'right',
			valueFormatter: formattedDate,
		},
		{
			field: 'daysAgo',
			headerName: 'Days ago',
			flex: 1,
			headerAlign: 'right',
			align: 'right',
			valueGetter: daysAgo,
		},
	];

	return (
		<MUIDataGrid
			components={{
				NoRowsOverlay: NoRowsOverlay({
					iconSrc: './assets/img/table-outline.svg',
					text: 'The locations that were disinfected the longest time ago',
				}),
			}}
			rows={oldestDisinfectionsData || []}
			columns={oldestDisinfectionsColumns}
			onRowClick={() => {}}
			pageSize={tablePageSize}
			loading={!oldestDisinfectionsData}
		/>
	);
}
