import { Typography } from '@material-ui/core';
import { subDays } from 'date-fns';
import _ from 'lodash';
import React, { FC } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import CsvDownloader from './CsvDownloader/CsvDownloader';
import DisinfectionDashboard from './DisinfectionDashboard';
import { DisinfectionDashboardFilter } from './Filters/DisinfectionDashboardFilter';
import { getMongoFilterObj } from './utils';

const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
}));

const DisinfectionDashboardPage: FC = (props: any) => {
	const intl = useIntl();

	let { query } = useParams<Record<string, any>>();

	//TODO: factor out default filter value logic from here and the Filter component to a common place
	const defaultTimePeriod: any = [[subDays(new Date(), 10), new Date()]];
	const split = _.split(query, '=');
	const key = _.nth(split, 0) || '';
	const value = _.nth(split, 1);
	const defaultRobot = split.length > 1 ? { [key]: [value] } : null;
	const defaultFilter = getMongoFilterObj({ ...defaultRobot, timePeriod: defaultTimePeriod });
	const [filters, setFilters] = React.useState({ ...defaultFilter });

	return (
		<MainContent
			headerChildren={
				<>
					<Typography variant="h2">
						{intl.formatMessage({
							id: 'DisinfectionDashboard.title',
							defaultMessage: 'Disinfection Dashboard',
						})}
					</Typography>
					<HeaderWrapper>
						<DisinfectionDashboardFilter setFilters={setFilters} urlQuery={query} />
						<CsvDownloader filters={filters} />
					</HeaderWrapper>
				</>
			}
			renderContent={() => {
				return <DisinfectionDashboard filters={filters} />;
			}}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	mongoJWT: state.accountState.user.mongoJWT,
});

export default isAuthenticated(
	injectIntl(connect(mapStateToProps, { setParameter })(DisinfectionDashboardPage)),
	'DisinfectionDashboardPage'
);
