import { IonList } from '@ionic/react';
import React, { FC } from 'react';
import { injectIntl } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setParameter } from '../../actions/setParam';
import classes from './AddNewRobot.module.css';

import { InputLimit } from '../../utils/validator';

interface AddNewRobotFromProps {
	control: any;
	errors: any;
}

const AddNewRobotForm: FC<AddNewRobotFromProps> = (props: any) => {
	const { intl, control, errors } = props;

	return (
		<IonList className={classNames(classes.formList)}>
			<FormInputListItem
				type="text"
				name="serialNumber"
				label={intl.formatMessage({
					id: 'RobotsPage.serialNumber',
				})}
				placeholderText={intl.formatMessage({
					id: 'RobotsPage.serialNumberHint',
				})}
				control={control}
				rules={{ required: true }}
				required
				errorMsg={
					errors.serialNumber
						? intl.formatMessage({ id: 'RobotsPage.serialNumberError' })
						: errors.serialNumberUnique
						? intl.formatMessage({ id: 'RobotsPage.serialNumberUnique' })
						: ''
				}
				pattern="[0-9a-zA-Z]*"
				title={intl.formatMessage({
					id: 'RobotsPage.onlyLettersCharacters',
				})}
				maxLength={InputLimit}
			/>
		</IonList>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	devices: state.deviceState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(AddNewRobotForm), 'AddNewRobotForm'));
