import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';
import { chevronDown, filter } from 'ionicons/icons';
import { useIntl } from 'react-intl';
import { Chip } from '../components/Chip';

const StyledIcon = styled(IonIcon)(({ theme }) => ({
	fontSize: 18,
	color: "inherit",
}));

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const FilterText = styled(Typography)`
	font-weight: 500;
	font-size: 0.75rem;
	margin-left: 4px;
	margin-right: 16px;
	color: inherit;
`;

export const FilterButton = ({ onClick, disabled, isLoading }: any) => {
	const intl = useIntl();

	return (
		<Chip disabled={disabled} aria-haspopup="true" onClick={disabled ? () => {} : onClick}>
			<Wrapper>
				<StyledIcon slot="icon-only" icon={filter} />
				<FilterText>
					{intl.formatMessage({
						id: 'DisinfectionDashboardFilter.filter',
						defaultMessage: 'Filter',
					})}
				</FilterText>
			</Wrapper>
			{isLoading ? (
				<CircularProgress size={16} color="primary" />
			) : (
				<StyledIcon slot="icon-only" icon={chevronDown} />
			)}
		</Chip>
	);
};
