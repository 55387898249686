import { IonGrid, IonIcon, IonLabel, IonRow, IonSegment, IonSegmentButton } from '@ionic/react';
import { fitness, informationCircle } from 'ionicons/icons';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UVDMongoDevice } from '../../types/types';
import RobotHealthUVD from '../RobotForm/RobotHealthUVD';
import Messages from './RobotEdit.messages';
import classes from './RobotEdit.module.css';
import { UVDRobotDetails } from './UVDRobotDetails';

const uvdTabs = ['health', 'details'];

interface RobotDetailsProps {
	robotId: string;
	onClose: any;
	fullDevice?: any;
}

const RobotDetailsUVD: FC<RobotDetailsProps> = props => {
	const { robotId, fullDevice } = props;

	const [selectedSegment, setSelectedSegment] = useState<string>(uvdTabs[0]);

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
	};

	return (
		<div>
			<div className={classes.header}>
				<div className={classes.editBtnContainer}></div>
				<IonSegment
					className={classes.tabContainer}
					mode="ios"
					onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
					value={selectedSegment}
				>
					<>
						<IonSegmentButton
							value="health"
							layout="icon-start"
							className={classes.healthBtn}
						>
							<IonIcon className={classes.iconMargin} size="small" icon={fitness} />
							<IonLabel>
								<FormattedMessage {...Messages.health} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton
							value="details"
							layout="icon-start"
							className={classes.detailsBtn}
						>
							<IonIcon
								className={classes.iconMargin}
								size="small"
								icon={informationCircle}
							/>
							<IonLabel>
								<FormattedMessage {...Messages.details} />
							</IonLabel>
						</IonSegmentButton>
					</>
				</IonSegment>
			</div>
			<IonGrid className={classes.segmentContent}>
				<IonRow className={selectedSegment !== 'health' ? classes.hidden : ''}>
					<RobotHealthUVD deviceId={robotId} device={fullDevice} />
				</IonRow>
				<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
					<UVDRobotDetails
						version={fullDevice?.mongoDevice?.version}
						timezone={fullDevice?.mongoDevice?.location?.timezone}
					/>
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default RobotDetailsUVD;
