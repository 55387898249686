import { store } from '../store/store';

function getOrgName(orgId: any): any {
	const state = store.getState();
	const organizations = state.accountState.user.organizations;
	if (organizations[orgId]) {
		var orgName = organizations[orgId].name;
		if (orgName.length > 20) {
			orgName = orgName.substring(0, 20).concat('...');
		}
		return orgName;
	}
	return '...';
}

function getMongoRobotName(name: string, repeatRobotIds?: any) {
	var robotId = name.split('-')[0];
	if (repeatRobotIds && repeatRobotIds.includes(robotId)) {
		var orgId = name
			.split('-')
			.splice(1)
			.join('-');
		robotId += '-' + getOrgName(orgId);
	}
	return robotId;
}

function mapRobotName(name: string, filterCategory: string, repeatRobotIds?: any) {
	if (filterCategory === 'robotId') {
		return getMongoRobotName(name, repeatRobotIds);
	}
	return name;
}

export { mapRobotName, getMongoRobotName };
