import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { FilterButton } from './FilterButton';
import { calendar, chevronDown, closeCircleOutline } from 'ionicons/icons';
import { PrimaryPopover } from './Popovers/PrimaryPopover';
import { SecondaryPopover } from './Popovers/SecondaryPopover';
import { DatePickerPopover } from './Popovers/DatePickerPopover';
import { Chip, Popover, Typography, withStyles } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { format, subDays } from 'date-fns';
import { useIntl } from 'react-intl';
import { getMongoFilterObj, useGraphMediaBreakpoints } from '../utils';
import { getMongoRobotName, mapRobotName } from '../../../utils/mapRobotName';
import { mongodbClient } from '../../../providers/mongodbClient';
import { useTypedSelector } from '../../../reducers';

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
`;

const ChipWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: theme.spacing(1),
	justifyContent: 'flex-end',
	marginRight: theme.spacing(1),
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
	fontSize: '0.75rem',
	marginRight: theme.spacing(1),
	marginBottom: theme.spacing(1),
	backgroundColor: 'transparent',
	boxSizing: 'border-box',
	height: '38px',
	borderRadius: '100px',
}));

const LabelWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
}));

const CategoryText = styled(Typography)(({ theme }) => ({
	color: theme.palette.OP_Grey[40],
	paddingRight: theme.spacing(1),
	fontSize: '0.75rem',
}));

const ValueText = styled(Typography)(({ theme }) => ({
	fontSize: '0.75rem',
}));

export const ChevronIcon = styled(IonIcon)(({ theme }) => ({
	height: 18,
	width: 18,
	color: theme.palette.OP_Grey[50],
}));

const CloseIcon = styled(IonIcon)(({ theme }) => ({
	height: 18,
	width: 18,
	color: theme.palette.OP_Grey[90],
	marginLeft: theme.spacing(2),
	cursor: 'pointer',
	':hover': {
		color: theme.palette.OP_Grey[50],
	},
}));

export const ChipLabel = ({ filterItem }: any) => {
	const intl = useIntl();
	const [category, value] = filterItem;
	const labelValue =
		value.length > 1
			? value.length + ' selected'
			: value.map((item: string) => mapRobotName(item, category));

	let dateLabel = '';

	if (category === 'timePeriod') {
		const dateValue = value[0];
		dateLabel =
			filterItem[0] === 'timePeriod'
				? format(new Date(dateValue[0]), 'dd MMM ‘yy') +
				  ' - ' +
				  format(new Date(dateValue[1]), 'dd MMM ‘yy')
				: '';
	}

	if (value.length === 1) {
		return (
			<LabelWrapper>
				<CategoryText>
					{intl.formatMessage({
						id: 'DisinfectionDashboardFilter.' + category,
						defaultMessage: category,
					})}
				</CategoryText>
				<ValueText color="textPrimary">
					{category === 'timePeriod' ? dateLabel : labelValue}
				</ValueText>
			</LabelWrapper>
		);
	} else {
		return (
			<LabelWrapper>
				{value.length}{' '}
				{intl.formatMessage({
					id: 'DisinfectionDashboardFilter.' + category + 'Multiple',
					defaultMessage: category + 's',
				})}
			</LabelWrapper>
		);
	}
};

const SingleValueChipLabel = ({ category, value, repeatRobotIds }: any) => {
	const intl = useIntl();

	let labelValue = value;

	if (category === 'timePeriod') {
		labelValue =
			category === 'timePeriod'
				? format(new Date(value[0]), 'dd MMM ‘yy') +
				  ' - ' +
				  format(new Date(value[1]), 'dd MMM ‘yy')
				: '';
	}

	if (category === 'robotId') {
		labelValue = getMongoRobotName(value, repeatRobotIds);
	}

	return (
		<LabelWrapper>
			<CategoryText>
				{intl.formatMessage({
					id: 'DisinfectionDashboardFilter.' + category,
					defaultMessage: category,
				})}
			</CategoryText>
			<ValueText color="textPrimary">{labelValue}</ValueText>
		</LabelWrapper>
	);
};

export const StyledPopover = withStyles({
	root: {
		marginTop: 4,
		'& .MuiPopover-paper': {
			borderRadius: 10,
			boxShadow: '0px 2px 10px #00000029',
			minWidth: 185,
			maxWidth: 320,
			padding: '8px 16px',
		},
	},
})(Popover);

const ListItem = styled('div')(({ theme }) => ({
	padding: '8px 0px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

const ClearAllListItem = styled(ListItem)(({ theme }) => ({
	cursor: 'pointer',
	':hover p': {
		color: theme.palette.primary.dark,
	},
}));

const Divider = styled('div')(({ theme }) => ({
	height: 1,
	width: '100%',
	backgroundColor: theme.palette.OP_Grey[20],
	margin: '8px 0px',
}));

const ClearAllText = styled('div')(({ theme }) => ({
	cursor: 'pointer',
	marginRight: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
}));

interface Props {
	setFilters: any;
	urlQuery?: any;
}

interface DefaultFilterStateTypes {
	[key: string]: any;
}

const emptyFilterState = {
	robotId: [],
	startedBy: [],
	submittedBy: [],
	room: [],
	timePeriod: [],
	type: [],
};

export const DisinfectionDashboardFilter = ({ setFilters, urlQuery }: Props) => {
	const { large } = useGraphMediaBreakpoints();
	const [filterState, setFilterState] = useState<DefaultFilterStateTypes>(emptyFilterState);
	const [currentFilterCategory, setCurrentFilterCategory] = useState<any>(null);

	const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
	const [filterOptions, setFilterOptions] = useState<any>(null);
	const [repeatRobotIds, setRepeatRobotIds] = useState<any>([]);
	const [filtersQueryRunning, setFiltersQueryRunning] = useState<boolean>(false);

	const filterArr = Object.entries(filterState);

	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [datePickerAnchorEl, setDatePickerAnchorEl] = React.useState<null | HTMLElement>(null);
	const [chipPopoverAnchorEl, setChipPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
	const [
		allChipsPopoverAnchorEl,
		setAllChipsPopoverAnchorEl,
	] = React.useState<null | HTMLElement>(null);

	const [showPrimaryPopover, setShowPrimaryPopover] = useState(false);
	const [showSecondaryPopover, setShowSecondaryPopover] = useState(false);
	const [showDatePickerPopover, setShowDatePickerPopover] = useState(false);
	const [showChipPopover, setShowChipPopover] = useState(false);
	const [showAllChipsPopover, setShowAllChipsPopover] = useState(false);

	const activeFilterCategories = useMemo(() => {
		return filterArr
			.filter((filterOption: any) => filterOption[1].length > 0)
			.map((filterOpt: any) => filterOpt[0]);
	}, [filterArr]);

	const getIsSelected = useCallback(
		(category: string, value: string) => {
			const categoryArr = filterState[category] || [];
			return filterState ? categoryArr.includes(value) : false;
		},
		[filterState]
	);

	useEffect(() => {
		let defaultFilterState = emptyFilterState;

		if (urlQuery != null) {
			const split = _.split(urlQuery, '=');
			const key = _.nth(split, 0) || '';
			const value = _.nth(split, 1);

			defaultFilterState = { ...defaultFilterState, [key]: [value] };
		}

		const timePeriodArr: any = [[subDays(new Date(), 10), new Date()]];

		defaultFilterState = {
			...defaultFilterState,
			timePeriod: timePeriodArr,
		};

		setFilterState(defaultFilterState);
		const mongoFilterObj = getMongoFilterObj(defaultFilterState);
		setFilters(mongoFilterObj);
	}, [urlQuery]);

	const handleRemoveFilterOption = (category: string, value: any) => {
		const categoryState = filterState[category];

		const newState = {
			...filterState,
			[category]: value ? categoryState.filter((item: any) => item !== value) : [],
		};

		if (showChipPopover === true && newState[category].length <= 1) {
			setShowChipPopover(false);
			setChipPopoverAnchorEl(null);
		}

		const activeFilters = Object.entries(newState).filter((item: any) => item[1].length !== 0);
		if (activeFilters.length > 0) {
			const mongoFilterObj = getMongoFilterObj(newState);
			setFilters(mongoFilterObj);
			setFilterState(newState);
		} else {
			setShowChipPopover(false);
			setChipPopoverAnchorEl(null);
			setShowAllChipsPopover(false);
			setFilterState(emptyFilterState);
			setFilters({});
			setShowPrimaryPopover(false);
		}
	};

	const handleSelectFilterOption = (value: any) => {
		const currentValue = filterState[currentFilterCategory];
		const newArr = currentValue.includes(value)
			? currentValue.filter((item: any) => item !== value)
			: filterState[currentFilterCategory].concat([value]);

		const newState = {
			...filterState,
			[currentFilterCategory]: newArr,
		};

		if (currentFilterCategory === 'timePeriod') {
			const [start, end] = value;
			setSelectedStartDate(start);

			if (!!start && !!end) {
				setFilterState((old: any) => {
					return {
						...old,
						[currentFilterCategory]: old[currentFilterCategory].concat([value]),
					};
				});
			} else {
				return;
			}
		} else {
			setFilterState(newState);
		}

		const mongoFilterObj = getMongoFilterObj(newState);

		setFilters(mongoFilterObj);
	};

	const handleResetCategory = (category: string) => {
		const newState = {
			...filterState,
			[category]: [],
		};

		const mongoFilterObj = getMongoFilterObj(newState);
		setFilters(mongoFilterObj);
		setFilterState(newState);
		setShowChipPopover(false);
	};

	const handleResetAll = () => {
		setFilterState(emptyFilterState);
		setFilters({});
		setShowPrimaryPopover(false);
	};

	const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setShowPrimaryPopover(true);
	}, []);

	const handlePrimaryPopoverClick = useCallback((category: any) => {
		setShowSecondaryPopover(true);
		setCurrentFilterCategory(category);
	}, []);

	const handleSelectDate = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentFilterCategory('timePeriod');
		setDatePickerAnchorEl(event.currentTarget);
		setShowDatePickerPopover(true);
	}, []);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
		setDatePickerAnchorEl(null);
		setShowPrimaryPopover(false);
		setShowSecondaryPopover(false);
		setShowDatePickerPopover(false);
		setSelectedStartDate(new Date());
		setSelectedEndDate(null);
	}, []);

	const handleGoBack = () => {
		setShowSecondaryPopover(false);
	};

	const filterCategories = [
		{ id: 'startedBy' },
		{ id: 'submittedBy' },
		{ id: 'robotId' },
		{ id: 'room' },
		{ id: 'timePeriod', icon: calendar, onClick: handleSelectDate },
		{ id: 'type' },
	];

	/**
	 * Load available filters when the MongoDB client is available
	 */
	useEffect(() => {
		const getAvailableFilters = async () => {
			if (mongoUser) {
				setFiltersQueryRunning(true);
				const getAvailableFilterValuesQuery = [
					{
						$match: {
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$group: {
							_id: 1,
							startedBy: {
								$addToSet: {
									$cond: {
										if: {
											$ne: ['$startedBy', ''],
										},
										then: '$startedBy',
										else: '$$REMOVE',
									},
								},
							},
							submittedBy: {
								$addToSet: {
									$cond: {
										if: {
											$ne: ['$submittedBy', ''],
										},
										then: '$submittedBy',
										else: '$$REMOVE',
									},
								},
							},
							robotId: {
								$addToSet: {
									$cond: {
										if: {
											$ne: ['$robotId', ''],
										},
										then: '$robotId',
										else: '$$REMOVE',
									},
								},
							},
							room: {
								$addToSet: {
									$cond: {
										if: {
											$ne: ['$room', ''],
										},
										then: '$room',
										else: '$$REMOVE',
									},
								},
							},
							type: {
								$addToSet: {
									$cond: {
										if: {
											$ne: ['$type', ''],
										},
										then: '$type',
										else: '$$REMOVE',
									},
								},
							},
						},
					},
					{
						$project: { _id: 0 },
					},
				];

				const filters = await mongodbClient.aggregateCollection(
					getAvailableFilterValuesQuery,
					DISINFECTION_COLLECTION
				);

				if (filters[0] && filters[0].robotId) {
					const robotIds = filters[0].robotId.map((option: any) =>
						mapRobotName(option, 'robotId')
					);
					const set = new Set(robotIds);
					var repeatRobotIds = robotIds.filter((item: any) => {
						if (set.has(item)) {
							set.delete(item);
						} else {
							return item;
						}
					});
					setRepeatRobotIds(Array.from(new Set(repeatRobotIds)));
				} else {
					setRepeatRobotIds([]);
				}

				setFilterOptions(filters[0]);
				setFiltersQueryRunning(false);
			}
		};
		getAvailableFilters().catch(console.error);
	}, [mongoUser]);

	const secondaryFilterOptions = useMemo(() => {
		if (currentFilterCategory) {
			switch (currentFilterCategory) {
				case 'startedBy':
					return filterOptions.startedBy;
				case 'submittedBy':
					return filterOptions.submittedBy;
				case 'robotId':
					return filterOptions.robotId;
				case 'room':
					return filterOptions.room;
				case 'type':
					return filterOptions.type;
				default:
					return [];
			}
		}
		return [];
	}, [currentFilterCategory, filterOptions]);

	const handleShowChipPopover = useCallback((event: any, filterItem: any) => {
		if (filterItem[1].length > 1) {
			setChipPopoverAnchorEl(event.currentTarget);
			setShowChipPopover(true);
			setCurrentFilterCategory(filterItem[0]);
		}

		return;
	}, []);

	const renderMultipleValueChips = () => {
		return Object.entries(filterState)
			.filter((item: any) => item[1].length !== 0)
			.map((filterItem: any) => {
				const multipleValues = filterItem[1].length > 1;

				return (
					<StyledChip
						clickable={multipleValues}
						onClick={e => handleShowChipPopover(e, filterItem)}
						label={<ChipLabel filterItem={filterItem} />}
						variant="outlined"
						deleteIcon={multipleValues ? <ChevronIcon icon={chevronDown} /> : undefined}
						onDelete={
							multipleValues
								? e => handleShowChipPopover(e, filterItem)
								: () => handleRemoveFilterOption(filterItem[0], null)
						}
					/>
				);
			});
	};

	const renderSingleValueChips = () => {
		return Object.entries(filterState)
			.filter((item: any) => item[1].length !== 0)
			.map((filterItem: any) => {
				return filterItem[1].map((value: any) => (
					<StyledChip
						label={
							<SingleValueChipLabel
								category={filterItem[0]}
								value={value}
								repeatRobotIds={repeatRobotIds}
							/>
						}
						variant="outlined"
						onDelete={() => handleRemoveFilterOption(filterItem[0], value)}
					/>
				));
			});
	};

	const renderChipPopoverContent = () => {
		return filterState[currentFilterCategory]?.map((value: any) => {
			let label = value;

			if (currentFilterCategory === 'timePeriod') {
				label =
					format(new Date(value[0]), 'dd MMM ‘yy') +
					' - ' +
					format(new Date(value[1]), 'dd MMM ‘yy');
			}

			if (currentFilterCategory === 'robotId') {
				label = getMongoRobotName(value, repeatRobotIds);
			}

			return (
				<ListItem>
					<Typography variant="body1">{label}</Typography>
					<CloseIcon
						icon={closeCircleOutline}
						onClick={() => handleRemoveFilterOption(currentFilterCategory, value)}
					/>
				</ListItem>
			);
		});
	};

	const handleOpenShowAllPopover = (e: any) => {
		setAllChipsPopoverAnchorEl(e.currentTarget);
		setShowAllChipsPopover(true);
	};

	const allOptions = renderSingleValueChips();

	const renderFilterCountChip = () => {
		const activeFilters = Object.entries(filterState).filter(
			(item: any) => item[1].length !== 0
		);

		if (activeFilters.length > 0) {
			const count = _.flatten(allOptions).length;

			return (
				<StyledChip
					clickable={true}
					onClick={e => handleOpenShowAllPopover(e)}
					label={`${count} Selected`}
					variant="outlined"
					deleteIcon={<ChevronIcon icon={chevronDown} />}
					onDelete={e => handleOpenShowAllPopover(e)}
				/>
			);
		}
	};

	const renderChips = useCallback(() => {
		if (large) {
			return renderMultipleValueChips();
		} else {
			const activeFilters = Object.entries(filterState).filter(
				(item: any) => item[1].length !== 0
			);
			if (activeFilters.length > 0) {
				return renderFilterCountChip();
			}

			return null;
		}
	}, [filterState, large]);
	return (
		<Wrapper>
			<ChipWrapper>
				<>
					{activeFilterCategories.length > 0 ? (
						<ClearAllText onClick={handleResetAll} color="primary">
							Clear all
						</ClearAllText>
					) : null}
					{renderChips()}
				</>
			</ChipWrapper>
			<FilterButton
				onClick={handleClick}
				selectedFilterLabel={''}
				disabled={filtersQueryRunning || (!filtersQueryRunning && !filterOptions)}
				isLoading={filtersQueryRunning}
			/>
			<PrimaryPopover
				open={showPrimaryPopover}
				anchorEl={anchorEl}
				onClose={handleClose}
				categories={filterCategories}
				activeFilterCategories={activeFilterCategories}
				onClick={handlePrimaryPopoverClick}
			/>
			<SecondaryPopover
				open={showSecondaryPopover}
				anchorEl={anchorEl}
				onClose={handleClose}
				filterCategory={currentFilterCategory}
				repeatRobotIds={repeatRobotIds}
				onGoBack={handleGoBack}
				options={secondaryFilterOptions}
				onSelectOption={handleSelectFilterOption}
				getIsSelected={getIsSelected}
			/>
			<DatePickerPopover
				open={showDatePickerPopover}
				anchorEl={datePickerAnchorEl}
				onClose={handleClose}
				startDate={selectedStartDate}
				endDate={selectedEndDate}
				onChange={handleSelectFilterOption}
			/>
			<StyledPopover
				open={showChipPopover}
				anchorEl={chipPopoverAnchorEl}
				onClose={() => setShowChipPopover(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<>
					{renderChipPopoverContent()}
					<Divider />
					<ClearAllListItem onClick={() => handleResetCategory(currentFilterCategory)}>
						<Typography color="primary">Clear all</Typography>
					</ClearAllListItem>
				</>
			</StyledPopover>
			<StyledPopover
				open={showAllChipsPopover}
				anchorEl={allChipsPopoverAnchorEl}
				onClose={() => setShowAllChipsPopover(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{allOptions}
			</StyledPopover>
		</Wrapper>
	);
};
