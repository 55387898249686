import React, { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { store } from '../../store/store';

import { mongodbClient } from './../../providers/mongodbClient';
import { FETCH_MONGO_DEVICES_SUCCESS, SET_ACCOUNT_USER_MONGO_USER } from '../../actions/types';
import { useTypedSelector } from '../../reducers';

const MongoClientLoader: React.FC = (props: any) => {
	const { intl, mongoJWT, mongoUser } = props;

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	useEffect(() => {
		if (spinoutType !== 'uvd') return;

		if (mongoJWT) {
			mongodbClient
				.getUser(mongoJWT)
				.then(user => {
					//console.log('GETTING MONGO USER', user);

					store.dispatch({
						type: SET_ACCOUNT_USER_MONGO_USER,
						payload: { mongoUser: user },
					});
				})
				.catch(err => {
					console.log('Get MongoDB user:', err);
				});
		}
	}, [mongoJWT]);

	useEffect(() => {
		if (spinoutType !== 'uvd') return;

		if (mongoUser) {
			mongodbClient
				.readCollection({}, mongoDbConfig.collections.robotCollection)
				.then((result: any) => {
					store.dispatch({
						type: FETCH_MONGO_DEVICES_SUCCESS,
						payload: { mongoDevices: result },
					});
				})
				.catch((err: any) => {
					console.error(`MongoDB read collection: ${err}`);
				});
		}
	}, [mongoUser]);

	return <></>;
};

const mapStateToProps = (state: any) => ({
	mongoJWT: state.accountState.user.mongoJWT,
	mongoUser: state.accountState.user.mongoUser,
	mongoDevices: state.deviceState.mongoDevices,
});

export default injectIntl(connect(mapStateToProps, { setParameter })(MongoClientLoader));
