import React, { FC, useState, useCallback, useEffect } from 'react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import classes from './Settings.module.css';
import classNames from 'classnames';
import Tabs from '../../components/Tabs/Tabs';
import Messages from './Settings.messages';
import { laptop, notifications } from 'ionicons/icons';
import { IonLabel, IonList, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { useTypedSelector } from '../../reducers';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import PilotAppSettings from '../../components/PilotAppSettings/PilotAppSettings';
import RobotSettings from '../../components/RobotSettings/RobotSettings';
const SettingsPage: FC = () => {
	const user = useTypedSelector(state => state.accountState.user);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const [profile, setProfile] = useState<any>();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [currentSubMenu, setCurrentSubMenu] = useState<string | undefined>('profile');

	const tabsData = [
		{
			title: Messages.account,
			path: 'profile',
			icon: notifications,
			subMenus: [
				{ message: Messages.profile, path: 'profile' },
				// FIXME: Show 'notifications' item in sub menu once implemented in the backend
				// ...(spinoutType === 'gobe'
				// 	? [{ message: Messages.notifications, path: 'notifications' }]
				// 	: []),
				{ message: Messages.agreements, path: 'agreements' },
			],
		},
		...(spinoutType === 'gobe'
			? [
					{
						title: Messages.pilotApp,
						path: 'pilotApp',
						icon: laptop,
						subMenus: [
							{ message: Messages.audioVideo, path: 'audio/video' },
							//{ message: Messages.views, path: 'views' },
						],
					},
			  ]
			: []),
		// {
		// 	title: Messages.robot,
		// 	path: 'robot',
		// 	// icon: laptop,
		// 	subMenus: [{ message: Messages.preferences, path: 'preferences' }],
		// },
	];

	const onTabChange = (selectedTab: any) => {
		let indexOfSelectedTab = tabsData.findIndex((tab: any) => tab.path === selectedTab);

		setCurrentTab(0);
		setTimeout(() => {
			setCurrentTab(indexOfSelectedTab);

			if (
				tabsData[indexOfSelectedTab]?.subMenus &&
				tabsData[indexOfSelectedTab]?.subMenus.length >= 1
			) {
				let firstSubmenu: any = tabsData[indexOfSelectedTab]?.subMenus[0]?.path;

				if (firstSubmenu) setCurrentSubMenu(firstSubmenu);
			}

			// setCurrentSubMenu();
		}, 10);
	};

	const renderTabContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (tabsData[currentTab].path) {
			case 'profile':
				return (
					<ProfileSettings
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
					/>
				);
			case 'pilotApp':
				return (
					<PilotAppSettings
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
					/>
				);
			case 'robot':
				return (
					<RobotSettings
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
					/>
				);
			default:
				return null;
		}
	}, [currentSubMenu, currentTab, profile, tabsData, user]);

	return (
		<MainContent
			title="Settings"
			renderContent={history => {
				return (
					<div className={classes.main}>
						<Tabs
							data={tabsData}
							currentTab={tabsData[currentTab].path}
							onClick={onTabChange}
							content={
								<IonGrid force-overscroll className={classes.tabContentGrid}>
									<IonRow>
										<IonGrid className={classes.contentGrid}>
											<IonRow>
												<IonCol className={classes.leftSide}>
													<IonList
														className={classes.list}
														id="container"
													>
														{tabsData[currentTab].subMenus.map(
															(item: any, index: number) => {
																return (
																	<IonItem
																		id={'row-' + item.path}
																		key={index}
																		lines="none"
																		className={
																			currentSubMenu ===
																			item.path
																				? classNames(
																						classes.selectedItem,
																						'selected'
																				  )
																				: ''
																		}
																		onClick={() => {
																			setCurrentSubMenu(
																				item.path
																			);
																		}}
																	>
																		<IonLabel>
																			<FormattedMessage
																				{...item.message}
																			/>
																		</IonLabel>
																	</IonItem>
																);
															}
														)}
													</IonList>
												</IonCol>
												<IonCol className={classes.rightSide}>
													{renderTabContent()}
													{tabsData[currentTab].subMenus.length < 10 && (
														<div
															className={classes.triangle}
															id="arrow"
														/>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonRow>

									<IonRow>
										<IonCol className={classes.leftCol} />
									</IonRow>
								</IonGrid>
							}
							tabBtnClass={classes.setupTabBtn}
						/>
					</div>
				);
			}}
		/>
	);
};

export default isAuthenticated(SettingsPage, 'SettingsPage');
