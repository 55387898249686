import { useState, useEffect, useCallback } from 'react';
import { mongodbClient } from './../../providers/mongodbClient';
import { store } from '../../store/store';
import { useTypedSelector } from '../../reducers';
import { differenceInSeconds } from 'date-fns';
import { formatDistance } from 'date-fns';

let fetchedOnce = false;
let watchesCreated = false;

function statusFromLastTimeActive(lastTimeActive : Date) {
	const online = differenceInSeconds(new Date(), lastTimeActive) < 60
	return {
		online: online,
		lastTimeActive: lastTimeActive,
		lastSeen: lastTimeActive && !online
			? formatDistance(new Date(lastTimeActive), new Date(), {addSuffix: true})
			: null
	};
}

function useConnectionStatus(mongoUser: any) {
	const [periodicUpdate, setPeriodicUpdate] = useState<any>({});
	const [connectionStatus, setConnectionStatus] = useState<any>({});
	const [lastTimeActiveUpdate, setLastTimeActiveUpdate] = useState<any>({});
	const [fetchError, setFetchError] = useState<any>([]);

	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);

	useEffect(() => {
		const id = setInterval(() => {
			setPeriodicUpdate({});
		}, 30000);
		return () => clearInterval(id);
	}, []);

	useEffect(() => {
		let localStatus = { ... connectionStatus };
		for (let cs in localStatus) {
			localStatus[cs] = statusFromLastTimeActive(localStatus[cs].lastTimeActive);
		}
		setConnectionStatus(localStatus);
	}, [periodicUpdate]);

	useEffect(() => {
		connectionStatus[lastTimeActiveUpdate.robotId] = statusFromLastTimeActive(lastTimeActiveUpdate.lastTimeActive);
		setConnectionStatus({ ...connectionStatus });
	}, [lastTimeActiveUpdate]);

	const trackLastTimeActive = useCallback(async () => {
		if (watchesCreated) return;
		watchesCreated = true;

		try {
			const filter = {
				operationType: { $in: ['insert', 'update'] },
			};

			const mongo = mongoUser.mongoClient(
				store.getState().mongoDbConfigState.mongoDbConfig.mongoClient
			);
			const db = mongo.db(store.getState().mongoDbConfigState.mongoDbConfig.database);
			const dbCollection = db.collection(mongoDbConfig.collections.robotConnectionStatusCollection);

			for await (const change of dbCollection.watch(filter)) {
				if (!change.fullDocument?.robotId) continue;
				if (!change.fullDocument?.lastTimeActive) continue;
				if (!['insert', 'update', 'replace'].includes(change.operationType)) continue;

				setLastTimeActiveUpdate({
					robotId: change.fullDocument.robotId,
					lastTimeActive: change.fullDocument.lastTimeActive
				});
			}
		} catch (error) {
			console.warn('Watch robotConnectionStatusCollection ERROR:', error);
			watchesCreated = false;
			fetchedOnce = false;
			setFetchError(error);
		}
	}, [mongoUser]);

	useEffect(() => {
		if (fetchedOnce && connectionStatus.length == 0) {
			fetchedOnce = false;
		}
	}, [connectionStatus, fetchedOnce]);

	useEffect(() => {
		if (fetchedOnce) return;
		if (!mongodbClient.user) return;
		fetchedOnce = true;
		const query = {};
		mongodbClient
			.readCollection(query, mongoDbConfig.collections.robotConnectionStatusCollection)
			.then((result: any) => {
				let status: any = [];
				result.forEach (function (elem: any) {
					if (!elem.robotId) return;
					status[elem.robotId] = statusFromLastTimeActive(elem.lastTimeActive);
				});
				setConnectionStatus(status);
				trackLastTimeActive();
			})
			.catch((err: any) => {
				console.error(`Failed to fetch disinfection collection count: ${err}`);
				fetchedOnce = false;
				setFetchError(err);
			});
	}, [mongoUser, fetchError]);

	return connectionStatus;
}

export { useConnectionStatus };
