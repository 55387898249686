import { IonIcon } from '@ionic/react';
import { Box, Typography } from '@material-ui/core';
import { barChart, map, pieChart } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Charts } from '../charts.enum';
import classes from './EmptyChartComponent.module.css';

interface EmptyChartComponentProps {
	chartId: Charts;
}

const EmptyChartComponent: React.FC<EmptyChartComponentProps> = ({ chartId }: any) => {
	const [defaultContentForEmptyChart, setDefaultContentForEmptyChart] = useState<any>({
		icon: '',
		customIcon: '',
		customIconText: '',
		text: '',
	});

	useEffect(() => {
		switch (chartId) {
			case Charts.roomsDisinfectedByDayBar:
				setDefaultContentForEmptyChart({
					icon: barChart,
					text: 'The number of rooms that were disinfected by day',
				});
				break;
			case Charts.successfulDisinfectionRateCount:
				setDefaultContentForEmptyChart({
					customIconText: '#',
					text: 'The percentage of disinfections that were completed',
				});
				break;
			case Charts.disinfectionsCompletedCount:
				setDefaultContentForEmptyChart({
					customIconText: '#',
					text: 'The total number of disinfections that were completed',
				});
				break;
			case Charts.locationsDisinfectedCount:
				setDefaultContentForEmptyChart({
					customIconText: '#',
					text: 'The total number of locations that were disinfected',
				});
				break;
			case Charts.distanceCoveredInMetersCount:
				setDefaultContentForEmptyChart({
					customIconText: '#',
					text: 'The total distance driven by the robot',
				});
				break;
			case Charts.disinfectionSuccessRateDonut:
				setDefaultContentForEmptyChart({
					icon: pieChart,
					text:
						'The number of completed disinfections, compared to the number of failed disinfections',
				});
				break;
			case Charts.interruptionRateDonut:
				setDefaultContentForEmptyChart({
					icon: pieChart,
					text:
						'The number of disinfections with interruptions, compared to the number of disinfections without interruptions',
				});
				break;
			case Charts.interruptionReasonsBar:
				setDefaultContentForEmptyChart({
					icon: barChart,
					text: 'The types of interruptions during all disinfections',
				});
				break;
		}
	}, [chartId]);

	return (
		<div>
			<Box className={classes.chartContainerNoData}>
				<Box className={classes.defaultIconContainer}>
					{defaultContentForEmptyChart.icon ? (
						<IonIcon size="large" icon={defaultContentForEmptyChart.icon} />
					) : defaultContentForEmptyChart.customIcon ? (
						<IonIcon size="large" src={defaultContentForEmptyChart.customIcon} />
					) : (
						<Typography>{defaultContentForEmptyChart.customIconText || ''}</Typography>
					)}
				</Box>
				<Box>
					<Typography className={classes.defaultTextContainer}>
						{defaultContentForEmptyChart.text}
					</Typography>
				</Box>
			</Box>
		</div>
	);
};

export default EmptyChartComponent;
