import React from 'react';
import styled from 'styled-components';

interface Props {
	light?: boolean;
	theme: any;
}

export const MapWrapper = styled.div<Props>`
	min-width: 528px;
	height: 468px;
	background-color: ${p => (p.light ? 'white' : p.theme.palette.OP_Grey[50])};
	margin-right: ${p => p.theme.spacing(16)};
	border: 1px solid ${p => p.theme.palette.OP_Grey[30]};
	border-radius: 2.5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
