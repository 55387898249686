import React from 'react';
import { UVDMongoDevice } from '../../types/types';
import { getRobotStatus } from '../../utils/robotStatusConverter';
import styled from 'styled-components';
import { Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import classes from './Robots.module.css';

interface StatusTextProps {
	theme?: Theme;
	disabled: boolean;
}

const StatusText = styled(Typography)<StatusTextProps>`
	color: ${p => (p.disabled ? p.theme.palette.OP_Grey[40] : 'auto')};
`;

interface Props {
	device: UVDMongoDevice;
	isOffline?: boolean;
	isDecommissioned?: boolean;
}

export const UVDRobotStatus = ({ device, isOffline, isDecommissioned }: Props) => {
	return (
		<StatusText
			variant="body1"
			disabled={isDecommissioned || isOffline || false}
			className={isDecommissioned ? classes.decommissionedColor : ''}
		>
			{isDecommissioned ? (
				<FormattedMessage id="decommissioned" defaultMessage="Decommissioned" />
			) : isOffline ? (
				<FormattedMessage id="offline" defaultMessage="Offline" />
			) : device?.status?.job ? (
				getRobotStatus(device?.status?.job)
			) : (
				''
			)}
		</StatusText>
	);
};
