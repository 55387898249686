import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import moment from 'moment';

export function secondsToHMS(seconds: any) {
	seconds = Number(seconds);
	// const d = Math.floor(seconds / (3600 * 24));
	const h = Math.floor((seconds % (3600 * 24)) / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor(seconds % 60);

	const hours = h > 9 ? h : '0' + h;
	const minutes = m > 9 ? m : '0' + m;
	const sec = s > 9 ? s : '0' + s;

	return `${hours}:${minutes}:${sec}`;
}

export const useGraphMediaBreakpoints = () => {
	const largeBreakpoint = useMediaQuery('(min-width: 1800px)');
	const mediumBreakpoint = useMediaQuery('(min-width: 1350px)');
	const smallBreakpoint = useMediaQuery('(min-width: 1100px)');

	return {
		small: smallBreakpoint,
		medium: mediumBreakpoint,
		large: largeBreakpoint,
	};
};

export const additionalFilterToApply = () => {
	return { status: { $in: ['complete', 'incomplete'] } };
};

export const getMongoFilterObj = (newState: any) => {
	return _.reduce(
		newState,
		function(result: {}, value: Array<any>, key: string) {
			if (value.length > 0) {
				if (key === 'timePeriod') {
					return _.reduce(
						value,
						(result: any, value: Array<any>) => {
							result.$or.push({
								start: {
									$gte: {
										$date: moment(value[0])
											.startOf('day')
											.format(),
									},
									$lte: {
										$date: moment(value[1])
											.endOf('day')
											.format(),
									},
								},
							});
							return result;
						},
						{ ...result, $or: [] }
					);
				}

				return { ...result, [key]: { $in: value } };
			}

			return result;
		},
		{}
	);
};

export const getLocationString = (params: any) => {
	const room = params.row.room || '';
	const department = params.row.department || '';

	return department ? room + ' - ' + department : room;
};
