import { defineMessages } from 'react-intl';

export default defineMessages({
	userGroupDetails: {
		id: 'UserSetupDetail.details',
		defaultMessage: 'User Group Details',
		description: 'User Group Details',
	},

	userGroupName: {
		id: 'UserGroupSetupDetails.name',
		defaultMessage: 'Group Name',
		description: 'Group Name',
	},

	inGroup: {
		id: 'UserGroupSetupDetails.inGroup',
		defaultMessage: 'Group Name',
		description: 'Group Name',
	},

	userLocation: {
		id: 'UserSetupDetails.location',
		defaultMessage: 'User Location ',
		description: 'User Location',
	},

	userTimeZone: {
		id: 'UserSetupDetails.timeZone',
		defaultMessage: 'User time zone',
		description: 'User time zone',
	},

	createdOn: {
		id: 'UserSetupDetails.createdOn',
		defaultMessage: 'Created on',
		description: 'Created on',
	},
	upload: {
		id: 'AccountManagementPage.upload',
		defaultMessage: 'Upload Picture',
		description: 'Upload Picture',
	},
	web: {
		id: 'AccountManagementPage.web',
		defaultMessage: 'From The Web',
		description: 'From The Web',
	},

	addUsers: {
		id: 'DeviceGroups.addUsers',
		defaultMessage: 'Add users',
		description: 'Add users',
	},

	createNewGroup: {
		id: 'UserGroupSetupDetails.creatNewGroup',
		defaultMessage: 'Create New User Group',
		description: 'Create New User Group',
	},

	createGroup: {
		id: 'UserGroups.createGroup',
		defaultMessage: 'Create user group',
		description: 'Create user group',
	},
	createGroupHint: {
		id: 'UserGroups.createGroupHint',
		defaultMessage: 'Please complete to create new user group',
		description: 'Please complete to create new user group',
	},

	editGroupModal: {
		deleteTitle: {
			id: 'deleteTitle',
			defaultMessage: 'Delete this {item}',
			description: 'Delete this {item}',
		},
		addUsers: {
			id: 'DeviceGroups.addUsers',
			defaultMessage: 'Add users',
			description: 'Add users',
		},
		// addUsersHint: {
		// 	id: 'DeviceGroups.addUsersHint',
		// 	defaultMessage: 'Please choose users to add to {group}',
		// 	description: 'Please choose users to add to group',
		// },
		hasUsers: {
			id: 'UserGroups.hasUsers',
			defaultMessage: 'Has {number} users',
			description: 'Number of users in device group',
		},
	},
	addToGroupModal: {
		addToGroupsHint: {
			id: 'UsersGroup.addToGroupsHint',
			defaultMessage: 'Please choose users to add to {group}',
			description: 'Please choose users for the selected group',
		},
	},
	hasNoUsers: {
		id: 'UserGroups.hasNoUsers',
		defaultMessage: 'Has no users',
		description: 'Number of users in device group',
	},
	created: {
		id: 'UserGroups.created',
		defaultMessage: '{name} has been created',
		description: 'A new user group has been created',
	},
	justCreated: {
		id: 'UserGroups.justCreated',
		defaultMessage: 'Has just been created',
		description: 'A new device group has just been created',
	},
	yearsAgo: {
		id: 'UserGroups.yearsAgo',
		defaultMessage: 'years ago',
		description: 'years ago',
	},

	availableUsers: {
		id: 'UserGroups.availableUsers',
		defaultMessage: 'Available users',
		description: 'Available users',
	},

	selectedUsers: {
		id: 'UserGroups.selectedUsers',
		defaultMessage: 'Selected Users',
		description: 'Selected Users',
	},

	usersSelected: {
		id: 'UserGroups.usersSelected',
		defaultMessage:
			'{count, number} {count, plural, one {user} other {users}} has been selected',
		description: 'Number of selected users',
	},
	noSelection: {
		id: 'UserGroups.noSelection',
		defaultMessage: 'No users selected',
		description: 'No users selected',
	},

	noAvailable: {
		id: 'UserGroups.noAvailable',
		defaultMessage: 'No users available',
		description: 'No users available',
	},
	invited: {
		id: 'InviteModal.invited',
		defaultMessage: '{name} has been invited',
		description: 'A new user has been invited',
	},
});
