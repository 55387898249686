export enum Charts {	
	roomsDisinfectedByDayBar,
	interruptionReasonsBar,
  interruptionRateDonut,
	disinfectionSuccessRateDonut,
	distanceCoveredInMetersCount,
	locationsDisinfectedCount,
	successfulDisinfectionRateCount,
	disinfectionsCompletedCount,
}
