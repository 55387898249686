import React from 'react';
import styled from 'styled-components';
import { Typography, MenuItem } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { chevronForward, checkmark, chevronBack } from 'ionicons/icons';
import { useIntl } from 'react-intl';

const StyledMenuItem = styled(MenuItem)`
	padding: 8px;
	border-radius: 10px;
	align-items: center;
	justify-content: space-between;
`;

const SelectWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledIcon = styled(IonIcon)`
	margin-right: 8px;
`;

const NavigationTypography = styled(Typography)`
	font-weight: 500;
`;

const StyledTypography = styled(Typography)`
	color: ${(props: { selected: boolean; theme: any }) => (props.selected ? '#22A2D9' : 'auto')};
`;

const PopoverMenuItemIcon = ({ selected, icon }: any) => {
	return (
		<IonIcon
			color={selected ? 'primary' : undefined}
			slot="icon-only"
			size="small"
			icon={icon ? icon : chevronForward}
		/>
	);
};

export const PopoverMenuItem = ({
	selected,
	showIcon: showIconProps,
	keyName,
	onClick,
	icon,
}: any) => {
	const intl = useIntl();
	const showIcon = showIconProps && keyName !== 'none';

	return (
		<StyledMenuItem onClick={onClick}>
			<SelectWrapper>
				{selected ? (
					<StyledIcon color="primary" slot="icon-only" size="small" icon={checkmark} />
				) : null}
				<StyledTypography selected={selected}>
					{intl.formatMessage({
						id: `DisinfectionDashboardFilter.${keyName}`,
						defaultMessage: keyName,
					})}
				</StyledTypography>
			</SelectWrapper>
			{showIcon ? <PopoverMenuItemIcon selected={selected} icon={icon} /> : null}
		</StyledMenuItem>
	);
};

export const NavigationMenuItem = ({ keyName, onClick }: any) => {
	const intl = useIntl();
	return (
		<StyledMenuItem onClick={onClick}>
			<SelectWrapper>
				<StyledIcon slot="icon-only" size="small" icon={chevronBack} />
				<NavigationTypography>
					{intl.formatMessage({
						id: `DisinfectionDashboardFilter.${keyName}`,
						defaultMessage: keyName,
					})}
				</NavigationTypography>
			</SelectWrapper>
		</StyledMenuItem>
	);
};
