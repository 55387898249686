import React from 'react';
import styled from 'styled-components';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
	backgroundColor: theme.palette.OP_Grey[20],
	borderRadius: 5,
}));

export const LoadingSkeleton = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', padding: 32, height: 680 }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: 24,
				}}
			>
				<Skeleton
					variant="circle"
					width={72}
					height={72}
					style={{ marginRight: 24, borderRadius: 100 }}
				/>
				<Skeleton variant="text" width={274} height={32} />
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
				}}
			>
				<Skeleton variant="rect" width={528} height={468} />
				<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 64, flex: 1 }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: 32,
						}}
					>
						<Skeleton
							variant="rect"
							width={100}
							height={32}
							style={{ marginRight: 32 }}
						/>
						<Skeleton
							variant="rect"
							width={100}
							height={32}
							style={{ marginRight: 32 }}
						/>
						<Skeleton variant="rect" width={100} height={32} />
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: '16px 0px',
								borderBottom: '1px solid #DDDDDD',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Skeleton variant="rect" width={150} height={24} />
							<Skeleton variant="rect" width={100} height={24} />
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: '16px 0px',
								borderBottom: '1px solid #DDDDDD',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Skeleton variant="rect" width={150} height={24} />
							<Skeleton variant="rect" width={100} height={24} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
