//TODO: rewrite file to .tsx
import React, { useState, useEffect, useRef, Fragment } from 'react';
import propsTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { CircularProgress } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { cloudDownloadOutline } from 'ionicons/icons';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Chip } from '../components/Chip';

const FilterText = styled(Typography)(({ theme }) => ({
	fontWeight: '500',
	fontSize: '0.75rem',
	marginRight: theme.spacing(1),
	color: 'inherit',
}));

const StyledIcon = styled(IonIcon)(() => ({
	fontSize: 18,
	color: "inherit",
}));

const Wrapper = styled('div')(({ theme }) => ({
	marginLeft: theme.spacing(8),
}));

const CsvExporter = ({ asyncExportMethod, disabled }) => {
	const [csvData, setCsvData] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const csvInstance = useRef();

	useEffect(() => {
		if (csvData && csvInstance.current && csvInstance.current.link) {
			setTimeout(() => {
				csvInstance.current.link.click();
				setCsvData(false);
			});
		}
	}, [csvData]);

	return (
		<Wrapper>
			<Chip
				disabled={disabled}
				onClick={async () => {
					if (disabled || isLoadingData) {
						return;
					}

					setIsLoadingData(true);
					const newCsvData = await asyncExportMethod();
					setIsLoadingData(false);
					setCsvData(newCsvData);
				}}
			>
				<FilterText variant="body1">
					<FormattedMessage id="csv" defaultMessage="CSV" />
				</FilterText>
				{isLoadingData ? (
					<CircularProgress size={16} color="primary" />
				) : (
					<StyledIcon icon={cloudDownloadOutline} />
				)}
			</Chip>
			{csvData ? (
				<CSVLink
					data={csvData.data}
					headers={csvData.headers}
					filename={csvData.filename}
					ref={csvInstance}
				/>
			) : (
				undefined
			)}
		</Wrapper>
	);
};

export default CsvExporter;

CsvExporter.defaultProps = {
	children: undefined,
	asyncExportMethod: () => null,
	disabled: false,
	className: undefined,
};

CsvExporter.propTypes = {
	children: propsTypes.node,
	asyncExportMethod: propsTypes.func,
	disabled: propsTypes.bool,
	className: propsTypes.string,
};
