import { fontFamilies } from './fonts/fontFamilies';
import createTheme, { Theme, ThemeOptions } from '@material-ui/core/styles/createTheme';
import { Palette } from '@material-ui/core/styles/createPalette';
import _ from 'lodash';
import { Shadows } from '@material-ui/core/styles/shadows';

// OP stands for One Point(One Point Digital Design System)

const OP_GreyPalette = {
	100: '#000000',
	90: '#242525',
	80: '#444444',
	50: '#666666',
	40: '#AAAAAA',
	30: '#CCCCCC',
	20: '#DDDDDD',
	15: '#EEEEEE',
	10: '#FAFAFA',
	0: '#FFFFFF',
};

const OP_Breakpoints = {
	xs: 576, // 576 - 767 px
	sm: 768, // 768 - 991 px
	md: 992, // 992 - 1199 px
	lg: 1200, // 1200 - 1399 px
	xl: 1400, // 1400px -
};

const defaultTheme = {
	palette: {
		primary: {
			main: '#007398',
			light: '#4FA2C9',
			dark: '#00486A',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#84BD00',
			light: '#B8F04A',
			dark: '#518C00',
			contrastText: '#000000',
		},
		success: {
			main: '#07892B',
			light: '#4FBA58',
			dark: '#005A00',
			contrastText: '#FFFFFF',
		},
		warning: {
			main: '#FFC400',
			light: '#F2D472',
			dark: '#FDB500',
			contrastText: '#242525',
		},
		error: {
			main: '#D92E2E',
			light: '#DE8989',
			dark: '#B5221B',
			contrastText: '#FFFFFF',
		},
		data: {
			blue: {
				100: '#003556',
				90: '#004B78',
				80: '#08629A',
				70: '#167DBC',
				60: '#2999DE',
				50: '#40B6FF',
				40: '#6BC7FF',
				30: '#96D7FF',
				20: '#C1E7FF',
				10: '#ECF8FF',
			},
		},
		OP_Grey: OP_GreyPalette,
		text: {
			primary: OP_GreyPalette[90],
			secondary: OP_GreyPalette[50],
			disabled: OP_GreyPalette[40],
		},
		background: {
			paper: '#FAFAFA',
			page: '#EEEEEE',
			card: '#FFFFFF',
			default: '#FFFFFF',
		},
		battery: {
			red: '#A81B14',
			yellow: '#FFC400',
			green: 'rgba(7, 137, 43)',
			grey: '#AAAAAA',
		},
	},
	typography: {
		fontFamily: fontFamilies.roboto,
		fontSize: ('0.875rem' as unknown) as number, // 14px
		h1: {
			// Heading M
			fontFamily: fontFamilies.industryMedium,
			fontSize: ('1.06rem' as unknown) as number, // 17px
			lineHeight: ('1.5rem' as unknown) as number, // 24px
			margin: 0,
			padding: 0,
		},
		h2: {
			// Heading L
			fontFamily: fontFamilies.industryDemi,
			fontSize: ('1.75rem' as unknown) as number, // 28px
			lineHeight: ('3rem' as unknown) as number, // 48px
			margin: 0,
			padding: 0,
		},
		h3: {
			// Heading
			fontFamily: fontFamilies.industryMedium,
			fontSize: ('1.5rem' as unknown) as number, // 24px
			lineHeight: ('2rem' as unknown) as number, // 32px
		},
		h4: {
			// Heading XL
			fontFamily: fontFamilies.industryDemi,
			fontSize: ('2.5rem' as unknown) as number, // 40px
			lineHeight: ('3.25rem' as unknown) as number, // 52px
			margin: 0,
			padding: 0,
		},
		h5: {
			// Heading M - Demi
			fontFamily: fontFamilies.industryDemi,
			fontSize: ('1.06rem' as unknown) as number, // 17px
			lineHeight: ('1.5rem' as unknown) as number, // 24px
			fontStyle: 'initial',
		},
		h6: {
			// Heading L with Industry Medium
			fontFamily: fontFamilies.industryMedium,
			fontSize: ('1.75rem' as unknown) as number, // 28px
			lineHeight: ('3rem' as unknown) as number, // 48px
		},
		body1: {
			// Body
			fontFamily: fontFamilies.roboto,
			fontSize: ('0.875rem' as unknown) as number, // 14px
			lineHeight: ('1.25rem' as unknown) as number, // 20px
		},
		body2: {
			// Body L
			fontFamily: fontFamilies.roboto,
			fontSize: ('1.06rem' as unknown) as number, // 17px
			lineHeight: ('1.5rem' as unknown) as number, // 24px
		},
	},
	spacing: 4, // spacing is defined in increments of 4px
	shadows: [
		'none',
		'0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)', // base
		'0px 0px 1px 0px rgba(36, 37, 37, 0.04), 0px 2px 6px 0px rgba(36, 37, 37, 0.04), 0px 10px 20px 0px rgba(36, 37, 37, 0.04);', // medium
		'0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06);', // large
	],
	breakpoints: {
		values: OP_Breakpoints,
	},
	props: {
		MuiPaper: {
			elevation: 0,
		},
	},
	overrides: {
		MuiChip: {
			root: {
				fontSize: ('0.62rem' as unknown) as number, // 10px
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: '#FFFFFF',
			},
		},
	},
};

const uvdTheme = {
	palette: {
		primary: {
			main: '#167DBC',
			light: '#C5DFEE',
			dark: '#125572',
		},
		secondary: {
			main: '#167DBC',
			light: '#C5DFEE',
			dark: '#125572',
		},
	},
};

const customTheme = _.merge(defaultTheme, uvdTheme); // this will change based on the environment variable

interface OPPalette extends Palette {
	//extending the Palette types from MUI and adding our own
	OP_Grey: {
		100: string;
		90: string;
		80: string;
		50: string;
		40: string;
		30: string;
		20: string;
		15: string;
		10: string;
		0: string;
	};
	background: {
		paper: string;
		page: string;
		card: string;
		default: string;
	};
	battery: {
		red: string;
		yellow: string;
		green: string;
		grey: string;
	};
	data: {
		blue: {
			100: string;
			90: string;
			80: string;
			70: string;
			60: string;
			50: string;
			40: string;
			30: string;
			20: string;
			10: string;
		};
	};
}

interface OPShadows extends Shadows {
	shadows: ['none', string, string, string];
}

export interface OPTheme extends Theme {
	// extending the MUI Theme type with our new palette
	palette: OPPalette;
}

interface OPThemeOptions extends ThemeOptions {
	// extending the MUI ThemeOptions type with our new palette
	palette: OPPalette;
	shadows: OPShadows;
}

declare module '@material-ui/core/styles/createBreakpoints' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		xxl: true;
	}
}

export const muiTheme = createTheme(customTheme as OPThemeOptions) as OPTheme;
