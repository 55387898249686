import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { mongodbClient } from '../../../providers/mongodbClient';
import { useTypedSelector } from '../../../reducers';
import { mapRobotName } from '../../../utils/mapRobotName';
import CsvExporter from './CsvExporter';

type CsvDownloaderProps = {
	filters: any;
};

export default function CsvDownloader({ filters }: CsvDownloaderProps) {
	const intl = useIntl();

	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const csvFileName = useMemo(() => {
		let filtersText = 'All';
		const transformFilterName = (filter: string) => {
			return _.map(
				intl
					.formatMessage({
						id: `DisinfectionDashboardFilter.${filter}`,
						defaultMessage: filter,
					})
					.split(' '),
				w => _.capitalize(w.toLowerCase())
			).join('');
		};
		if (!_.isEmpty(filters)) {
			filtersText = _.sortBy(
				_.keys(filters).map(filter => {
					if (filter === '$or') {
						return _.keys(filters[filter][0]).map(subFilter => {
							return transformFilterName(subFilter);
						});
					} else {
						return transformFilterName(filter);
					}
				})
			).join('+');
		}
		return `UVD-Robots-Disinfections_${moment().format('YYYYMMDD_hhmmss')}_${filtersText}.csv`;
	}, [filters]);

	const getCsvData = async () => {
		if (mongoUser) {
			const headers = [
				{ label: 'Robot type', key: 'robotType' },
				{ label: 'Robot name', key: 'robotName' },
				{ label: 'Disinfection type', key: 'disinfectionType' },
				{ label: 'Status', key: 'status' },
				{ label: 'Room', key: 'room' },
				{ label: 'Department', key: 'department' },
				{ label: 'Start time', key: 'startTime' },
				{ label: 'End time', key: 'endTime' },
				{ label: 'Total duration in s', key: 'totalDurationInSecond' },
				{ label: 'UV-C light duration', key: 'uvcLightDuration' },
				{ label: 'Started By', key: 'startedBy' },
				{ label: 'Finished By', key: 'submittedBy' },
				{
					label: 'Positions disinfected (count)',
					key: 'positionsDisinfectedCount',
				},
				{
					label: 'Positions remaining (count)',
					key: 'positionsRemainingCount',
				},
				{
					label: 'Positions failed driving to point (count)',
					key: 'positionsFailedDrivingToPoint',
				},
				{ label: 'Times localisation lost', key: 'timesLocalisationLost' },
				{
					label: 'Times Aborted by operator',
					key: 'timesAbortedByOperator',
				},
				{ label: 'Times Fn button pressed', key: 'timesFnButtonPressed' },
				{ label: 'Times Low battery level', key: 'timesLowBatteryLevel' },
				{
					label: 'Times Reset button pressed',
					key: 'timesResetButtonPressed',
				},
				{
					label: 'Times Body heat detection',
					key: 'timesBodyHeatDetection',
				},
				{ label: 'Times Walk detection', key: 'timesWalkDetection' },
				{ label: 'Could not access iPad motion sensors', key: 'timesTabletPermission' },
				{ label: 'Times Tablet movement', key: 'timesTabletMovement' },
				{
					label: 'Times App not relaunched in time',
					key: 'timesAppNotRelaunchedInTime',
				},
				{ label: 'Times Connection lost', key: 'timesConnectionLost' },
				{ label: 'Times Robot stuck', key: 'timesRobotStuck' },
				{ label: 'Times Fatal error', key: 'timesFatalError' },
			];

			const getDisinfectionsDataQuery = [
				{
					$match: {
						...filters,
						startedBy: {
							$nin: [],
						},
						status: {
							$in: ['complete', 'incomplete'],
						},
					},
				},
				{ $sort: { start: -1 } },
			];

			const disinfectionsResult = await mongodbClient.aggregateCollection(
				getDisinfectionsDataQuery,
				DISINFECTION_COLLECTION
			);

			const formattedDisinfectionsResult = disinfectionsResult.map((record: any) => {
				const positionsCount = _.countBy(record?.positions, 'status');
				return {
					robotType: 'UVD Robot',
					robotName: mapRobotName(record.robotId, 'robotId'),
					disinfectionType: record.type,
					status: record.status,
					room: record.room,
					department: record.department,
					startTime: record.start,
					endTime: record.end,
					totalDurationInSecond:
						record.end && record.start
							? moment(record.end).diff(moment(record.start), 'seconds')
							: 0,
					uvcLightDuration: record.uvcLightDuration,
					startedBy: record.startedBy,
					submittedBy: record.submittedBy,
					positionsDisinfectedCount: positionsCount?.disinfected,
					positionsRemainingCount: positionsCount?.remaining,
					positionsFailedDrivingToPoint: positionsCount?.failed,
					timesLocalisationLost: record?.interruptions?.localizationLoss,
					timesAbortedByOperator: record?.interruptions?.fromUI,
					timesFnButtonPressed: record?.interruptions?.functionButton,
					timesLowBatteryLevel: record?.interruptions?.lowBattery,
					timesResetButtonPressed: record?.interruptions?.resetButton,
					timesBodyHeatDetection: record?.interruptions?.heatDetection,
					timesWalkDetection: record?.interruptions?.walkDetection,
					timesTabletPermission: record?.interruptions?.tabletPermission,
					timesTabletMovement: record?.interruptions?.tabletMovement,
					timesAppNotRelaunchedInTime: record?.interruptions?.connectionClosed,
					timesConnectionLost: record?.interruptions?.connectionUnstable,
					timesRobotStuck: record?.interruptions?.robotStuck,
					timesFatalError: record?.interruptions?.internalError,
				};
			});

			return { data: formattedDisinfectionsResult, headers, filename: csvFileName };
		}
	};

	return <CsvExporter asyncExportMethod={getCsvData} disabled={!mongoUser} />;
}
