import { defineMessages } from 'react-intl';

export default defineMessages({
	hint: {
		id: 'Agreements.hint',
		defaultMessage: 'Privacy policy',
		description: 'Privacy policy',
	},
	language: {
		id: 'Organizations.language',
		defaultMessage: 'Language',
		description: 'Language',
	},
	acceptAll: {
		id: 'Agreements.acceptAll',
		defaultMessage: 'Accept all',
		description: 'Accept all',
	},
	acceptContinue: {
		id: 'Agreements.acceptContinue',
		defaultMessage: 'Accept & continue',
		description: 'Accept & continue',
	},
	privacy: {
		id: 'Agreements.privacy',
		defaultMessage: 'Privacy policy',
		description: 'Privacy policy',
	},
	dataProcessing: {
		id: 'Agreements.dataProcessing',
		defaultMessage: 'Data processing agreement',
		description: 'Data processing agreement',
	},
	termsOfUse: {
		id: 'Agreements.termsOfUse',
		defaultMessage: 'Terms of use',
		description: 'Terms of use',
	},
	termsConditions: {
		id: 'Agreements.termsConditions',
		defaultMessage: 'Terms and conditions',
		description: 'Terms and conditions',
	},
	dataProcessingAgreement: {
		id: 'Agreements.dataProcessingAgreement',
		defaultMessage: 'Data Processing Agreement ',
		description: 'Data Processing Agreement ',
	},
	subProcessingAgreement: {
		id: 'Agreements.subProcessingAgreement',
		defaultMessage: 'Sub-processing Agreement',
		description: 'Sub-processing Agreement',
	},
	safetyAgreement: {
		id: 'Agreements.safetyAgreement',
		defaultMessage: 'Safety Agreement',
		description: 'Safety Agreement',
	},
	businessTermsAndConditions: {
		id: 'Agreements.businessTermsAndConditions',
		defaultMessage: 'Business Terms and Conditions ',
		description: 'Business Terms and Conditions ',
	},
});
