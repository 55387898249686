import React from 'react';
import { IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

const StyledIcon = styled(IonIcon)`
	margin-right: 8px;
	color: var(--ion-color-gray);
`;

const StartedByCell = ({ value }: any) => {
	return (
		<Wrapper>
			<StyledIcon size="large" icon={personCircleOutline} />
			{value}
		</Wrapper>
	);
};

export default StartedByCell;
