import React, { FC, useState, useEffect } from 'react';
import {
	IonList,
	IonItem,
	IonLabel,
	IonListHeader,
	IonButton,
	IonAvatar,
	IonIcon,
	IonSegment,
	IonSegmentButton,
	IonInput,
} from '@ionic/react';
import { globe, camera } from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';

import classes from './EditImageModal.module.css';
import { RouteComponentProps } from 'react-router';

import Messages from '../EditImageModal/EditImageModal.messages';
import classNames from 'classnames';
import userAvatar from '../../assets/images/userAvatar.svg';
import { InputLimit } from '../../utils/validator';
import BasicModal from '../BasicModal/BasicModal';

interface EditImageModalProps extends RouteComponentProps {
	title: any;
	subTitle: any;
	pictureLink: any;
	noImg?: any;
	isOpen: boolean;
	onSave: (link: any) => void;
	onDismiss: any;
	avatarClass?: any;
}

const EditImageModal: FC<EditImageModalProps> = (props: any) => {
	const { intl } = props;

	// set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [pictureLink, setPictureLink] = useState<any>();
	const [reader] = useState(new FileReader());

	useEffect(() => {
		setPictureLink(props.pictureLink);
	}, [props.pictureLink]);

	const onChangeContent = (value: string) => {
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				setPictureLink(srcObject);
			});
		};
		reader.readAsDataURL(f);
		// setEditHidden(true);
	};

	const setUrlPicture = (e: CustomEvent) => {
		if (isURL(e.detail.value, { allow_underscores: true })) {
			setPictureLink(e.detail.value);
		} else {
			// display error
		}
	};

	const onDismissModal = () => {
		props.onDismiss();
	};

	return (
		<BasicModal open={props.isOpen} onClose={onDismissModal}>
			<IonList className={classes.modalContent}>
				<IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle} color="primary">
							{props.title}
						</IonLabel>
						<IonLabel className={classes.subTitle}>{props.subTitle}</IonLabel>
					</div>
				</IonListHeader>
				<IonItem lines="none">
					<IonAvatar
						className={
							props.avatarClass
								? classNames(props.avatarClass, 'ion-margin-end ion-margin-start')
								: classNames(classes.userAvatar, 'ion-margin-end ion-margin-start')
						}
					>
						<img
							className={classNames(classes.fullImage)}
							src={props.noImg ? props.noImg : userAvatar}
							alt="avatar"
						/>
					</IonAvatar>
				</IonItem>
				<IonItem lines="none" className={classes.msgContainer}>
					<IonSegment
						mode="ios"
						onIonChange={(e: any) => onChangeContent(e.detail.value)}
					>
						<IonSegmentButton
							className={expandUrl ? classNames(classes.hidden) : 'ion-no-padding'}
							value="file"
							layout="icon-start"
						>
							<IonIcon size="small" icon={camera} />
							<IonLabel>
								<FormattedMessage {...Messages.upload} />
							</IonLabel>
						</IonSegmentButton>
						{expandUrl ? (
							<div className={classNames(classes.urlExpand)}>
								<div
									className={classNames(classes.iconContainer)}
									// uncomment when the proper service is up and running
									// onClick={e => {
									// 	setExpandUrl(!expandUrl);
									// }}
								>
									<IonIcon
										className={classes.iconExpanded}
										size="small"
										icon={globe}
									/>
								</div>
								<IonInput
									className={classNames(classes.expandInput)}
									type="url"
									placeholder={intl.formatMessage({
										id: 'AccountManagementPage.addUrl',
									})}
									maxlength={InputLimit}
									onIonChange={e => setUrlPicture(e)}
								/>
							</div>
						) : (
							<IonSegmentButton
								className="ion-no-padding"
								value="url"
								layout="icon-start"
							>
								<IonIcon size="small" icon={globe} />
								<IonLabel>
									<FormattedMessage {...Messages.web} />
								</IonLabel>
							</IonSegmentButton>
						)}
					</IonSegment>

					<input
						className={classNames(classes.hidden)}
						id="fileSelector"
						type="file"
						accept="image/*"
						onChange={setPicture}
					/>
					{/* 
					<IonLabel>{user.firstName + ' ' + user.lastName}</IonLabel>
					<IonIcon className={classes.icon} size="small" icon={call} />
					<IonLabel className={classes.info}>
						<FormattedMessage {...Messages.invited} />
					</IonLabel>
					<IonIcon
						className={classes.timeIcon}
						size="small"
						slot="end"
						icon={timeOutline}
					/> */}
					{/* <div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle}>{props.title}</IonLabel>
					</div> */}
				</IonItem>
				<IonItem lines="none" className={classes.btnContainer}>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.cancelBtn}
						onClick={props.onDismiss}
					>
						<FormattedMessage {...Messages.cancel} />
					</IonButton>
					<IonButton
						expand="block"
						shape="round"
						size="large"
						fill="outline"
						className={classes.saveBtn}
						onClick={() => props.onSave(pictureLink)}
					>
						<FormattedMessage {...Messages.save} />
					</IonButton>
				</IonItem>
			</IonList>
		</BasicModal>
	);
};

export default injectIntl(isAuthenticated(EditImageModal));
