import React, { FC, useEffect, useState } from 'react';

import classes from './Agreements.module.css';

interface AgreementProps {
	agreement: { value: string; description: string; message: string };
	messageHeight: number;
}

const Frame: FC<AgreementProps> = props => {
	const { agreement, messageHeight } = props;

	const [height, setHeight] = useState(1000);

	useEffect(() => {
		if (messageHeight && messageHeight > 1000) setHeight(messageHeight);
	}, [messageHeight]);

	return (
		<div>
			<iframe
				className={classes.agreementFrame}
				style={{
					height,
				}}
				title={agreement.value}
				id={agreement.value}
				src={
					'data:text/html;charset=utf-8;http-equiv=content-type,' +
					escape(
						agreement.description
							.replace('72pt 72pt 72pt 72pt', '0')
							.replace(/<body /g, `<body id="${'body-' + agreement.value}" `)
							.replace(/href/g, ' target="_blank" href')
							.replace(
								/<\/body>/g,
								`<script>
								let height;
								const sendPostMessage = () => {
									if(document.getElementsByTagName('html')[0].offsetHeight === 0)
									{
										setTimeout(() => {sendPostMessage},100);
									}
									if (height !== document.getElementsByTagName('html')[0].offsetHeight) {
										height = document.getElementsByTagName('html')[0].offsetHeight;
										window.parent.postMessage({
										${agreement.value}: height
										}, '*');
									}
								}
								window.onload = () => sendPostMessage();
								</script></body>`
							)
					)
				}
			/>
		</div>
	);
};

export default Frame;
