import React, { FC, useState, useCallback, useEffect, useRef } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import {
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonCol,
	IonRow,
	IonGrid,
	IonSelect,
	IonSelectOption,
	IonIcon,
} from '@ionic/react';
import classes from './PilotAppSettings.module.css';
import Messages from './PilotAppSettings.messages';

import isAuthenticated from '../Authentication/Authenticated';
import { camera, mic, micOff, volumeHigh, volumeMute } from 'ionicons/icons';
import classNames from 'classnames';
import { setParameter } from '../../actions/setParam';
import Slider from '../Slider';
import { useTypedSelector } from '../../reducers';
import { UPDATE_MICROPHONE, UPDATE_SPEAKER, UPDATE_CAMERA } from '../../actions/types';
import { connect } from 'react-redux';
import { HardwareState } from '../../reducers/hardwareReducer';
import { SelectChangeEventDetail } from '@ionic/core';
import { debounce, throttle } from 'lodash';

interface AudioVideoSettingsProps {}

type MediaDevice = Omit<NonNullable<HardwareState['settings']['microphone']>, 'microphoneLevel'>;
type SelectCustomEvent<T> = { detail: SelectChangeEventDetail<T> };

const DEFAULT_AUDIO_DEVICE_LEVEL = 40; // 40 is just an arbitrary value, feel free to change

const AudioVideoSettings: FC<AudioVideoSettingsProps> = (props: any, localStream) => {
	const [speakersList, setSpeakersList] = useState<MediaDevice[]>([]);
	const [microphonesList, setMicrophonesList] = useState<MediaDevice[]>([]);
	const [camerasList, setCamerasList] = useState<MediaDevice[]>([]);

	const videoElementRef = useRef<HTMLVideoElement>(null);

	const hardwareSettings = useTypedSelector(
		state => (state.hardwareState as HardwareState).settings
	);
	const selectedCameraId = hardwareSettings.camera?.id;
	const selectedMicrophoneId = hardwareSettings.microphone?.id;
	const selectedSpeakersId = hardwareSettings.speakers?.id;

	/** Used to animated the audio levels of the analyser/equalizer */
	const [microphoneSound, setMicrophoneSound] = useState<number>(0);

	const microphoneVolume =
		hardwareSettings.microphone?.microphoneLevel ?? DEFAULT_AUDIO_DEVICE_LEVEL;
	const speakersVolume = hardwareSettings.speakers?.speakerLevel ?? DEFAULT_AUDIO_DEVICE_LEVEL;

	const updateDevicesList = useCallback(() => {
		navigator.mediaDevices
			.enumerateDevices()
			.then(mediaDevices => {
				const microphones = mediaDevices
					.filter(device => device.kind === 'audioinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Microphone ${i}`,
					}));

				const speakers = mediaDevices
					.filter(device => device.kind === 'audiooutput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Speaker ${i}`,
					}));

				const cameras = mediaDevices
					.filter(device => device.kind === 'videoinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Camera ${i}`,
					}));

				setMicrophonesList(microphones);
				setSpeakersList(speakers);
				setCamerasList(cameras);
			})
			.catch(error => console.error('Error enumerating media devices', error));
	}, []);

	// update the device list when the available media devices change - eg: when user plugs in a new device
	useEffect(() => {
		updateDevicesList();
		navigator.mediaDevices.addEventListener('devicechange', updateDevicesList);
		return () => navigator.mediaDevices.removeEventListener('devicechange', updateDevicesList);
	}, []);

	// update the audio analyser when the selected microphone changes
	useEffect(() => {
		if (!selectedMicrophoneId) return;

		let mediaStream: MediaStream | undefined;
		let audioContext: AudioContext | undefined;
		let analyser: AnalyserNode | undefined;
		let streamSourceNode: MediaStreamAudioSourceNode | undefined;
		// FIXME: ScriptProcessorNode is deprecated. Use AudioWorklet instead https://developer.mozilla.org/en-US/docs/Web/API/AudioWorklet
		let javascriptNode: ScriptProcessorNode | undefined;

		const updateSoundLevel = throttle(setMicrophoneSound, 200);

		navigator.mediaDevices
			.getUserMedia({ audio: { deviceId: { exact: selectedMicrophoneId } } })
			.then(stream => {
				audioContext = new AudioContext();

				analyser = audioContext.createAnalyser();
				streamSourceNode = audioContext.createMediaStreamSource(stream);
				javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

				analyser.smoothingTimeConstant = 0.8;
				analyser.fftSize = 1024;
				streamSourceNode.connect(analyser);
				analyser.connect(javascriptNode);
				javascriptNode.connect(audioContext.destination);

				javascriptNode.onaudioprocess = function() {
					if (!analyser) return;

					let array = new Uint8Array(analyser.frequencyBinCount);
					analyser.getByteFrequencyData(array);
					let values = 0;

					let length = array.length;
					for (let i = 0; i < length; i++) {
						values += array[i];
					}

					let average = values / length;

					if (average > 100) {
						average = 100;
					}

					updateSoundLevel(average);
					// changeHeight((average / 100) * 12);
				};
			})
			.catch(error => console.error('Error getting microphone media stream', error));

		return () => {
			if (javascriptNode) {
				javascriptNode.onaudioprocess = null;
				javascriptNode.disconnect();
			}

			analyser?.disconnect();

			streamSourceNode?.disconnect();

			audioContext?.close();
			mediaStream?.getTracks().forEach(track => track.stop());
		};
	}, [selectedMicrophoneId]);

	// update the camera view when selected camera changes
	useEffect(() => {
		let mediaStream: MediaStream | undefined;

		navigator.mediaDevices
			.getUserMedia({
				video: !selectedCameraId ? false : { deviceId: { exact: selectedCameraId } },
				audio: !selectedMicrophoneId
					? false
					: { deviceId: { exact: selectedMicrophoneId } },
			})
			.then(stream => {
				mediaStream = stream;
				if (videoElementRef.current) {
					videoElementRef.current!.srcObject = stream;
				}
			})
			.catch(error => {
				console.error('Error getting camera media stream', error);
			});

		// cleanup camera media stream when component is unmounting
		return () => {
			if (videoElementRef.current) {
				videoElementRef.current!.srcObject = null;
			}
			mediaStream?.getTracks().forEach(track => track.stop());
		};
	}, [selectedCameraId, selectedSpeakersId, selectedMicrophoneId]);

	const onMicrophoneVolumeChange = (deltaY: string) => {
		const microphoneLevel = Number.parseFloat(`${deltaY}`);
		props.setParameter('microphone', UPDATE_MICROPHONE, {
			microphoneLevel,
		});
	};

	const onSpeakerVolumeChange = (deltaY: string) => {
		const speakerLevel = Number.parseFloat(`${deltaY}`);
		props.setParameter('speakers', UPDATE_SPEAKER, {
			speakerLevel: speakerLevel,
		});
	};

	const onSelectedCameraChanged = (selectedCameraId: string | undefined) => {
		const camera = camerasList.find(({ id }) => id === selectedCameraId);
		props.setParameter('camera', UPDATE_CAMERA, camera);
	};

	const onSelectedMicrophoneChanged = (selectedMicrophoneId: string | undefined) => {
		const microphone = microphonesList.find(({ id }) => id === selectedMicrophoneId);
		props.setParameter('microphone', UPDATE_MICROPHONE, microphone);
	};

	const onSelectedSpeakerChanged = (selectedSpeakersId: string | undefined) => {
		const speaker = speakersList.find(({ id }) => id === selectedSpeakersId);
		props.setParameter('speakers', UPDATE_SPEAKER, speaker);
	};

	// auto select first device in list, when the list of devices change,
	// 		and a previously selected device is no longer available
	useEffect(() => {
		if (camerasList.find(camera => camera.id === selectedCameraId) === undefined) {
			onSelectedCameraChanged(camerasList[0]?.id);
		}

		if (
			microphonesList.find(microphone => microphone.id === selectedMicrophoneId) === undefined
		) {
			onSelectedMicrophoneChanged(microphonesList[0]?.id);
		}

		if (speakersList.find(speaker => speaker.id === selectedSpeakersId) === undefined) {
			onSelectedSpeakerChanged(speakersList[0]?.id);
		}
	}, [camerasList, microphonesList, speakersList]);

	// auto-save the selected devices
	useEffect(() => {
		sessionStorage.setItem(
			'preferredMediaDevices.camera',
			JSON.stringify(hardwareSettings.camera ?? null)
		);
		sessionStorage.setItem(
			'preferredMediaDevices.microphone',
			JSON.stringify(hardwareSettings.microphone ?? null)
		);
		localStorage.setItem(
			'preferredMediaDevices.speakers',
			JSON.stringify(hardwareSettings.speakers ?? null)
		);
	}, [hardwareSettings]);

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.audioVideoSettings} />:
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>

			<IonRow>
				<IonCol sizeLg="6" sizeMd="12" sizeSm="12" className={classes.leftColWithSelect}>
					<IonRow>
						<IonCol size="12" className={classes.firstCol}>
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.microphone" />
								</IonLabel>
								<IonSelect
									interface="popover"
									value={selectedMicrophoneId}
									onIonChange={(e: SelectCustomEvent<string>) =>
										onSelectedMicrophoneChanged(e.detail?.value)
									}
									key="microphoneName"
									placeholder="No microphone selected"
								>
									{microphonesList.map(({ id, name }) => {
										return (
											<IonSelectOption key={id} value={id}>
												{name}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow>

					{/* <IonRow>
						<IonCol size="12">
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.speakers" />
								</IonLabel>
								<IonSelect
									value={selectedSpeakers?.id}
									interface="popover"
									onIonChange={(e: SelectCustomEvent<string>) =>
										onSelectedSpeakerChanged(e.detail?.value)
									}
									key="speakerName"
									placeholder="No speakers selected"
								>
									{speakersList.map(({ id, name }) => {
										return (
											<IonSelectOption key={id} value={id}>
												{name}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow> */}

					<IonRow>
						<IonCol size="12">
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.camera" />
								</IonLabel>

								<IonSelect
									interface="popover"
									onIonChange={(e: SelectCustomEvent<string>) =>
										onSelectedCameraChanged(e.detail?.value)
									}
									value={hardwareSettings.camera?.id}
									key="cameraName"
									placeholder="No camera selected"
								>
									{camerasList.map(({ id, name }) => {
										return (
											<IonSelectOption key={id} value={id}>
												{name}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow>
				</IonCol>

				<IonCol sizeLg="6" sizeMd="12" sizeSm="12" className={classes.rightColWithSelect}>
					<IonRow className={classes.rowSliders}>
						<IonCol size="2">
							<div className={classes.audioRecognizeContainer}>
								<div
									className={classes.audioStrength}
									style={{ height: `${4 + microphoneSound / 2}px` }}
								/>
								<div
									className={classes.audioStrengthMax}
									style={{ height: `${4 + microphoneSound}px` }}
								/>
								<div
									className={classes.audioStrength}
									style={{ height: `${4 + microphoneSound / 2}px` }}
								/>
							</div>
						</IonCol>
						<IonCol size="10">
							<IonRow className={classes.sliderDiv}>
								<IonCol size="2" className={classes.leftCol}>
									{microphoneVolume !== 0 && (
										<IonIcon
											size="large"
											icon={mic}
											className={classes.ionIcon}
										/>
									)}

									{microphoneVolume === 0 && (
										<IonIcon
											size="large"
											icon={micOff}
											className={classes.ionIconOff}
										/>
									)}
								</IonCol>

								<IonCol size="3" className={classes.rightCol}>
									<IonLabel
										className={
											microphoneVolume !== 0
												? classNames(classes.volumeStatusText)
												: classNames(classes.volumeStatusTextWhenIs0)
										}
									>
										{microphoneVolume}%
									</IonLabel>
								</IonCol>

								<IonCol size="7" className={classes.sliderCol}>
									<Slider
										onChange={onMicrophoneVolumeChange}
										value={microphoneVolume.toString()}
										icon="speed-green.svg"
										id="navVideoSpeed"
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>
					{/* Speaker volume feature not yet implemented  */}
					{/* <IonRow>
						<IonCol size="2" className={classes.secondSliderLabel} />

						<IonCol size="10">
							<IonRow className={classes.sliderDiv2}>
								<IonCol size="2" className={classes.leftCol}>
									{speakerVolume !== 0 && speakerVolume !== '0' && (
										<IonIcon
											size="large"
											icon={volumeHigh}
											className={classes.ionIcon}
										/>
									)}

									{(speakerVolume === 0 || speakerVolume === '0') && (
										<IonIcon
											size="large"
											icon={volumeMute}
											className={classes.ionIconOff}
										/>
									)}
								</IonCol>

								<IonCol size="3" className={classes.rightCol}>
									<IonLabel
										className={
											speakerVolume !== '0' && speakerVolume !== 0
												? classNames(classes.volumeStatusText)
												: classNames(classes.volumeStatusTextWhenIs0)
										}
									>
										{speakerVolume}%
									</IonLabel>
								</IonCol>
								<IonCol size="7" className={classes.sliderCol}>
									<Slider
										onChange={onSpeakerVolumeChange}
										value={speakerVolume}
										icon="speed-green.svg"
										id="navVideoSpeed"
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow> */}

					<IonRow className={classes.videoRow}>
						<IonCol sizeLg="2" />

						<IonCol sizeLg="10" sizeSm="12">
							<div className={classes.videoLandscape}>
								<video
									ref={videoElementRef}
									className={classes.videoStream}
									id="video"
									playsInline
									autoPlay
								/>
							</div>
						</IonCol>
					</IonRow>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(
	isAuthenticated(connect(null, { setParameter })(AudioVideoSettings), 'AudioVideoSettings')
);
