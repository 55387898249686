import { Theme, useTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled('div')(({ theme }) => ({
	height: 6,
	width: '100%',
	backgroundColor: theme.palette.OP_Grey[30],
	borderRadius: 100,
}));

interface FillProps {
	theme: Theme;
	completedValue: number;
}

const Fill = styled.div<FillProps>`
	height: 100%;
	width: ${p => p.completedValue}%;
	background-color: ${p => p.color};
	border-radius: inherit;
	text-align: right;
	border-radius: 100px;
`;

interface ProgressProps {
	value: number;
	color?: string | 'auto';
}

export const ProgressBar = ({ value = 50, color = 'auto' }: ProgressProps) => {
	const theme = useTheme();
	const colorCode = color === 'auto' ? theme.palette.data.blue[50] : color;

	return (
		<Container>
			<Fill completedValue={value} color={colorCode} />
		</Container>
	);
};
