import React from 'react';
import { UVDMongoDevice } from '../../types/types';
import classes from '../RobotForm/RobotForm.module.css';
import classNames from 'classnames';
import { IonGrid, IonRow, IonList, IonListHeader, IonLabel, IonItem, IonIcon } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';

interface Props {
	version: any;
	timezone: any;
}

export const UVDRobotDetails = ({ version, timezone }: Props) => {
	const timezoneString = timezone ? `${timezone.name} (${timezone.utcOffset})` : '';

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel>
							<FormattedMessage id="details" defaultMessage="Details" />
						</IonLabel>
					</IonListHeader>
					<IonItem>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage
								id="uvd-app-build"
								defaultMessage="UVD Robots App build"
							/>
						</IonLabel>
						<IonLabel className={classes.robotName}>
							{version?.setoBuild || ''}
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage
								id="system-manager-build"
								defaultMessage="System manager build"
							/>
						</IonLabel>
						<IonLabel className={classes.robotName}>
							{version?.setoManagerBuild || ''}
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage
								id="Language-pack-build"
								defaultMessage="Language pack build"
							/>
						</IonLabel>
						<IonLabel className={classes.robotName}>{version?.languageBuild}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel position="fixed" className={classes.formLb}>
							<FormattedMessage id="timezone" defaultMessage="Time zone" />
						</IonLabel>
						<IonLabel className={classes.robotName}>{timezoneString}</IonLabel>
					</IonItem>
				</IonList>
			</IonRow>
		</IonGrid>
	);
};
