import { GridColDef } from '@material-ui/data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { MUIDataGrid } from '../../../../components/MUIDataGrid/MUIDataGrid';
import { mongodbClient } from '../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../reducers';
import NoRowsOverlay from '../components/NoRowsOverlay';
import StartedByCell from '../components/StartedByCell';

type DisinfectionsByOperatorTableProps = {
	filters: any;
	tablePageSize: number;
};

export default function DisinfectionsByOperatorTable({
	filters,
	tablePageSize,
}: DisinfectionsByOperatorTableProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;

	const [disinfectionsByOperator, setDisinfectionsByOperator] = useState<any>(null);

	const [sortModel, setSortModel] = useState([{ field: 'complete', sort: 'desc' }]);

	useEffect(() => {
		const getDisinfectionsByOperator = async () => {
			if (mongoUser) {
				const getDisinfectionsByOperatorQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$group: {
							_id: { $concat: ['$startedBy', '-', '$status'] },
							disinfections: { $push: '$$ROOT' },
							status: { $first: '$status' },
							startedBy: { $first: '$startedBy' },
						},
					},
					{
						$group: {
							_id: '$startedBy',
							startedBy: { $first: '$$ROOT.startedBy' },
							states: {
								$addToSet: {
									k: '$$ROOT.status',
									v: { $size: '$$ROOT.disinfections' },
								},
							},
						},
					},
					{ $project: { _id: 0, startedBy: 1, states: { $arrayToObject: '$states' } } },
				];

				setDisinfectionsByOperator(null);

				const disinfectionsByOperatorResult = await mongodbClient.aggregateCollection(
					getDisinfectionsByOperatorQuery,
					DISINFECTION_COLLECTION
				);

				const disinfectionByOperatorFormatted = disinfectionsByOperatorResult.map(
					(row: any) => {
						const total = (row.states.complete || 0) + (row.states.incomplete || 0);
						return {
							id: row.startedBy,
							startedBy: row.startedBy,
							complete: row.states.complete || 0,
							incomplete: row.states.incomplete || 0,
							total,
						};
					}
				);

				setDisinfectionsByOperator(disinfectionByOperatorFormatted);
			}
		};
		getDisinfectionsByOperator().catch(console.error);
	}, [mongoUser, filters]);

	const disinfectionsByOperatorColumns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'startedBy',
				headerName: 'Operator',
				flex: 1,
				renderCell: (params: any) => <StartedByCell value={params.value} />,
			},
			{
				field: 'complete',
				headerName: 'Completed',
				flex: 1,
				headerAlign: 'right',
				align: 'right',
			},
			{
				field: 'incomplete',
				headerName: 'Incomplete',
				flex: 1,
				headerAlign: 'right',
				align: 'right',
			},
			{
				field: 'total',
				headerName: 'Total',
				flex: 1,
				headerAlign: 'right',
				align: 'right',
			},
		];
	}, []);

	return (
		<MUIDataGrid
			components={{
				NoRowsOverlay: NoRowsOverlay({
					iconSrc: './assets/img/table-outline.svg',
					text:
						'The total number of complete and incomplete disinfections performed by operator',
				}),
			}}
			rows={disinfectionsByOperator || []}
			columns={disinfectionsByOperatorColumns}
			sortModel={sortModel}
			onSortModelChange={setSortModel}
			pageSize={tablePageSize}
			onRowClick={() => {}}
			loading={!disinfectionsByOperator}
		/>
	);
}
