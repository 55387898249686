import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledTypo = styled(Typography)`
	padding: 0px;
	font-family: Industry-Demi;
	font-style: normal;
	font-weight: 400;
	margin: 0px;
	font-size: 17px;
	padding-bottom: ${p => (p?.prefix ? '0' : '10')}px;
	color: #2f2d2e;
	//text-transform: uppercase;
`;

type ChartHeaderProps = {
	label: string;
	smallChart?: string;
};

export const ChartHeader: React.FC<ChartHeaderProps> = ({ label, smallChart }) => {
	return <StyledTypo prefix={smallChart}>{label}</StyledTypo>;
};

export const ChartHeaderWrapper = styled.div`
	height: ${(p: { smallScreen?: boolean }) => (p.smallScreen ? 60 : 45)}px;
`;

export const ChartMainDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;
