import { IonIcon } from '@ionic/react';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, createStyles, createTheme, makeStyles, Theme, Typography } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { getLocationString, secondsToHMS } from '../../utils';
import { ChartHeader, ChartMainDiv } from '../components/ChartHeader';
import TooltipCustom from 'react-tooltip-lite';
import { DashboardPaper } from '../components/ChartPaper';
import { GridCellParams, GridColDef, GridComparatorFn } from '@material-ui/data-grid';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MUIDataGrid } from '../../../../components/MUIDataGrid/MUIDataGrid';
import { mongodbClient } from '../../../../providers/mongodbClient';
import { useTypedSelector } from '../../../../reducers';
import { getMongoRobotName } from '../../../../utils/mapRobotName';
import DisinfectionReport from '../../DisinfectionReport/DisinfectionReport';
import NoRowsOverlay from '../components/NoRowsOverlay';
import StartedByCell from '../components/StartedByCell';
import { helpCircle } from 'ionicons/icons';
import { store } from '../../../../store/store';
const completeStatusIcon = './assets/icons/completeStatus.svg';
const incompleteStatusIcon = './assets/icons/incompleteStatus.svg';

const CellWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	cursor: pointer;
`;

const Circle = styled.div`
	position: relative;
	text-align: center;
	justify-content: center;
	height: 17px;
	width: 17px;
	border-radius: 8px;
	background-color: ${(p: any) => p.color};
	margin-right: 6px;
`;

const IconDiv = styled.div`
	margin-right: 6px;
	margin-top: 6px;
`;

const StatusLink = styled.p`
	font-size: 14px;
	padding: 0px;
	margin: 0px;
	color: ${(p: any) => p.color};
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: roboto, sans-serif;
	max-width: ${(p: { computedWidth: any; smallWidth: boolean }) =>
		p.smallWidth ? p.computedWidth - 30 : 'none'};
`;

function getFullTimeString(params: any) {
	const formattedStartTime = moment(params.getValue(params.id, 'start')).format('HH:mm');
	const formattedEndTime = moment(params.getValue(params.id, 'end')).format('HH:mm');
	return `${formattedStartTime} - ${formattedEndTime}`;
}

function getStatusColor(status: string) {
	switch (status) {
		case 'incomplete':
			return '#AAAAAA';
		case 'complete':
			return '#07892B';
		default:
			return '#FFC400';
	}
}
function getStatusIcon(status: string, color: string) {
	switch (status) {
		case 'incomplete':
			return (
				<IconDiv>
					<IonIcon size="small" icon={incompleteStatusIcon} />
				</IconDiv>
			);
		case 'complete':
			return (
				<IconDiv>
					<IonIcon size="small" icon={completeStatusIcon} />
				</IconDiv>
			);
		default:
			return <Circle color={color} />;
	}
}

const StatusCell = ({ params, onStatusClick }: any) => {
	const computedWidthOfCell = params?.colDef?.computedWidth || 0;
	const smallWidth = computedWidthOfCell < 140;
	const statusColor = getStatusColor(params.value);
	return (
		<Tooltip disableHoverListener={!smallWidth} title={params.value || ''}>
			<CellWrapper onClick={onStatusClick}>
				{params.value ? getStatusIcon(params.value, statusColor) : null}
				<StatusLink
					color={statusColor}
					computedWidth={computedWidthOfCell}
					smallWidth={smallWidth}
				>
					{_.capitalize(params.value || '')}
				</StatusLink>
			</CellWrapper>
		</Tooltip>
	);
};

type TempRowData = {
	id: any;
	robot: any;
	location: any;
	start: any;
	end: any;
	type: any;
	uvcLightDuration: any;
	status: any;
	startedBy: any;
	submittedBy: any;
	reportLink: any;
	interruptions: any;
	positions: any;
	_id: any;
};

type DisinfectionTableProps = {
	filters: any;
};

export default function DisinfectionTable({ filters }: DisinfectionTableProps) {
	const mongoUser = useTypedSelector(state => state.accountState.user.mongoUser);
	const mongoDbConfig = useTypedSelector(state => state.mongoDbConfigState.mongoDbConfig);
	const isReloadDisinfection = useTypedSelector(
		state => state.mongoDbConfigState.reloadDisinfectionCollection
	);
	const DISINFECTION_COLLECTION = mongoDbConfig.collections.disinfectionCollection;
	const [disinfectionsData, setDisinfectionsData] = useState<any>(null);
	const [reportOpen, setReportOpen] = useState(false);
	const [tempRowData, setTempRowData] = useState<TempRowData | null>(null);
	const [sortModel, setSortModel] = useState([{ field: 'date', sort: 'desc' }]);

	const intl = useIntl();

	const formattedStartDate = useCallback((params: any) => {
		const startDate = params.getValue(params.id, 'start');
		return moment(startDate).format('DD MMM YYYY');
	}, []);

	const stringDateComparator: GridComparatorFn = (v1: any, v2: any) => {
		return moment(v1, 'DD MMM YYYY').diff(moment(v2, 'DD MMM YYYY'));
	};

	const handleOpenReportModal = useCallback(rowData => {
		setTempRowData(rowData);
		setReportOpen(true);
	}, []);

	const handleCloseReportModal = useCallback(() => {
		setReportOpen(false);
		setTempRowData(null);
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'date',
			headerName: 'Date',
			headerAlign: 'right',
			align: 'right',
			flex: 1,
			valueGetter: formattedStartDate,
			sortComparator: stringDateComparator,
		},
		{
			field: 'startedBy',
			headerName: 'By',
			flex: 1,
			renderCell: (params: any) => <StartedByCell value={params.value} />,
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params: GridCellParams) => <StatusCell params={params} />,
		},
		{
			field: 'room',
			headerName: 'Room',
			flex: 1,
			valueGetter: getLocationString,
		},
		{
			field: 'startEndTime',
			headerName: 'Start & end time',
			valueGetter: getFullTimeString,
			sortable: false,
			flex: 1,
		},
		{
			field: 'type',
			headerName: 'Disinfection Type',
			flex: 1,
		},
		{
			field: 'uvcLightDuration',
			headerName: 'Duration',
			flex: 1,
			headerAlign: 'right',
			align: 'right',
			valueGetter: params => secondsToHMS(params.value),
		},
		{
			field: 'robot',
			headerName: 'Robot',
			flex: 1,
			valueGetter: params => getMongoRobotName(params.value as string),
		},
	];

	const handleRowClick = useCallback(row => {
		if (row.status !== 'complete' && row.status !== 'incomplete') {
			return;
		} else {
			handleOpenReportModal(row);
		}
	}, []);

	useEffect(() => {
		if (isReloadDisinfection) {
			store.dispatch({
				type: 'RELOAD_DISINFECTION_COLLECTION',
				payload: {
					reloadDisinfectionCollection: false,
				},
			});
		}
	}, [disinfectionsData]);

	useEffect(() => {
		const getDisinfectionsData = async () => {
			if (mongoUser) {
				const getDisinfectionsDataQuery = [
					{
						$match: {
							...filters,
							startedBy: {
								$nin: [],
							},
							status: {
								$in: ['complete', 'incomplete'],
							},
						},
					},
					{
						$project: {
							_id: 1,
							id: '$_id',
							end: 1,
							department: 1,
							room: 1,
							date: 1,
							startedBy: 1,
							status: 1,
							start: 1,
							type: 1,
							uvcLightDuration: 1,
							robot: '$robotId',
						},
					},
					{ $sort: { start: -1 } },
				];

				setDisinfectionsData(null);

				const disinfectionsResult = await mongodbClient.aggregateCollection(
					getDisinfectionsDataQuery,
					DISINFECTION_COLLECTION
				);

				setDisinfectionsData(disinfectionsResult);
			}
		};
		getDisinfectionsData().catch(console.error);
	}, [mongoUser, filters, isReloadDisinfection]);

	return (
		<DashboardPaper height={710}>
			<ChartMainDiv>
				<ChartHeader
					label={intl.formatMessage({
						id: 'DisinfectionDashboard.disinfectionsByDate',
						defaultMessage: 'Recent disinfections',
					})}
				/>
				<div>
					<TooltipCustom
						direction="down"
						className="tooltip-dashboard"
						content={intl.formatMessage({
							id: 'DisinfectionDashboard.disinfectionsByDate.description',
							defaultMessage:
								'Click on a row to see the Disinfection report. If the table is blank, no data is matching the filters.',
						})}
					>
						<IonIcon size="small" icon={helpCircle} />
					</TooltipCustom>
				</div>
			</ChartMainDiv>
			<MUIDataGrid
				components={{
					NoRowsOverlay: NoRowsOverlay({
						iconSrc: './assets/img/table-outline.svg',
						text: 'Details on the latest disinfections in the time period',
					}),
				}}
				rows={disinfectionsData || []}
				columns={columns}
				pageSize={10}
				onRowClick={handleRowClick}
				sortModel={sortModel}
				onSortModelChange={(model: any) => {
					if (JSON.stringify(sortModel) !== JSON.stringify(model)) {
						setSortModel(model);
					}
				}}
				loading={!disinfectionsData}
			/>
			{reportOpen ? (
				<DisinfectionReport
					disinfectionId={tempRowData ? tempRowData._id : null}
					open={reportOpen}
					onClose={handleCloseReportModal}
				/>
			) : null}
		</DashboardPaper>
	);
}
