import React, { FC, useEffect, useState, useCallback } from 'react';
import {
	IonGrid,
	IonRow,
	IonList,
	IonItem,
	IonLabel,
	IonCol,
	IonTextarea,
	IonItemGroup,
	IonItemDivider,
	IonChip,
	IonIcon,
} from '@ionic/react';
import { useForm } from 'react-hook-form';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';

import classes from './OrganizationForm.module.css';

import 'react-phone-input-2/lib/style.css';
import { getSSoTypeString } from '../../config/ssoTypeOptions';

import { copyOutline } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';

interface OrganizationSSOLiveModeFormProps {
	organization: any;
	isEditable?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
	setEditable: any;
}

type FormData = {
	name: string;
	domains: string;
	idpEntryPoint: string;
	idpIssuer: string;
	idpCertificate: string;
	issuer: string;
	clientId: string;
	clientSecret: string;
};

const OrganizationSSOLiveModeForm: FC<OrganizationSSOLiveModeFormProps> = (props: any) => {
	const { isEditable, organization, intl } = props;
	const [copyOrgCallbackURL, setCopyOrgCallbackURL] = useState<string>('');

	const ssoConfig = useTypedSelector(state => state.ssoConfigReducerState.ssoConfig);

	const parseLiveSSOConfiguration = useCallback(
		(organization: any) => {
			let ssoConfiguration = JSON.parse(JSON.stringify({ ...organization }))?.ssoConfiguration
				?.live;
			if (ssoConfiguration && !ssoConfiguration?.oidcConfig) {
				ssoConfiguration.oidcConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration?.samlConfig) {
				ssoConfiguration.samlConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration.googleConfig) {
				ssoConfiguration.googleConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration.azureAdOidcConfig) {
				ssoConfiguration.azureAdOidcConfig = {};
			}
			return ssoConfiguration || {};
		},
		[organization]
	);

	const [changingSSOConfiguration, setChangingSSOConfiguration] = useState(
		parseLiveSSOConfiguration(organization)
	);

	useEffect(() => {
		setChangingSSOConfiguration(parseLiveSSOConfiguration(organization));
	}, [organization, parseLiveSSOConfiguration]);

	useEffect(() => {
		if (copyOrgCallbackURL) {
			setTimeout(() => {
				setCopyOrgCallbackURL('');
			}, 1000);
		}
	}, [copyOrgCallbackURL]);

	return (
		<div className={classes.ssoDiv}>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList>
							<IonItem>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.orgDomains} />
								</IonLabel>
								{changingSSOConfiguration?.domains?.length &&
									changingSSOConfiguration?.domains.map((domain: string) => {
										return (
											<IonChip>
												<IonLabel color="dark"> {domain} </IonLabel>
											</IonChip>
										);
									})}
							</IonItem>
							<IonItem>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.ssoType} />
								</IonLabel>
								<IonLabel className={classes.inputLb}>
									{getSSoTypeString(changingSSOConfiguration?.ssoType)}
								</IonLabel>
							</IonItem>
							<IonItem>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.callbackURL} />
								</IonLabel>
								{changingSSOConfiguration?.callbackId && (
									<>
										<IonLabel>
											{`${ssoConfig?.baseUrl}login/callback/${changingSSOConfiguration.callbackId}`}
										</IonLabel>
										{copyOrgCallbackURL ? (
											<IonLabel
												color="primary"
												className={classes.copiedOrgCallbackURL}
											>
												Copied!
											</IonLabel>
										) : (
											<IonIcon
												slot="end"
												size="small"
												icon={copyOutline}
												color="primary"
												className={classes.copyOrgCallbackURLBtn}
												onClick={() => {
													navigator.clipboard
														.writeText(
															`${ssoConfig?.baseUrl}login/callback/${changingSSOConfiguration.callbackId}`
														)
														.then(() => {
															setCopyOrgCallbackURL(
																`${ssoConfig?.baseUrl}login/callback/${changingSSOConfiguration.callbackId}`
															);
														});
												}}
											/>
										)}
									</>
								)}
							</IonItem>

							{changingSSOConfiguration.ssoType && (
								<IonItemDivider>
									<IonLabel>
										<FormattedMessage {...Messages.configuration} />
									</IonLabel>
								</IonItemDivider>
							)}
							{changingSSOConfiguration.ssoType === 'saml' && (
								<IonItemGroup>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.samlConfig.idpEntryPoint}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.samlConfig?.idpEntryPoint}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage {...Messages.samlConfig.idpIssuer} />
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.samlConfig?.idpIssuer}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.samlConfig.idpCertificate}
											/>
										</IonLabel>
										<IonTextarea
											name="idpCertificate"
											rows={20}
											cols={20}
											placeholder={intl.formatMessage({
												id:
													'SSOConfiguration.samlConfig.idpCertificateHint',
											})}
											readonly={true}
											value={
												changingSSOConfiguration?.samlConfig?.idpCertificate
											}
										/>
									</IonItem>
								</IonItemGroup>
							)}
							{changingSSOConfiguration.ssoType === 'oidc' && (
								<IonItemGroup>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage {...Messages.oidcConfig.idpIssuer} />
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.oidcConfig?.issuer}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage {...Messages.oidcConfig.idpClient} />
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.oidcConfig?.clientId}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.oidcConfig.idpClientSecret}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.oidcConfig?.clientSecret}
										</IonLabel>
									</IonItem>
								</IonItemGroup>
							)}
							{changingSSOConfiguration.ssoType === 'google' && (
								<IonItemGroup>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage {...Messages.googleConfig.clientId} />
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.googleConfig?.clientId}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.googleConfig.clientSecret}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.googleConfig?.clientSecret}
										</IonLabel>
									</IonItem>
								</IonItemGroup>
							)}
							{changingSSOConfiguration.ssoType === 'azureAdOidc' && (
								<IonItemGroup>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.azureAdOidcConfig.idpMetadataEndpoint}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{
												changingSSOConfiguration?.azureAdOidcConfig
													?.metaDataEndpoint
											}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.azureAdOidcConfig.idpClient}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{changingSSOConfiguration?.azureAdOidcConfig?.clientId}
										</IonLabel>
									</IonItem>
									{/* <IonItem>
										<IonLabel position="fixed" className={classes.formLb}>
											<FormattedMessage
												{...Messages.azureAdOidcConfig.idpClientSecret}
											/>
										</IonLabel>
										<IonLabel className={classes.inputLb}>
											{
												changingSSOConfiguration?.azureAdOidcConfig
													?.clientSecret
											}
										</IonLabel>
									</IonItem> */}
								</IonItemGroup>
							)}
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
};
const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(OrganizationSSOLiveModeForm), 'OrganizationSSOLiveModeForm')
);
